import { from as observableFrom, Observable, Subject, of, combineLatest } from 'rxjs'
import { map, toArray, groupBy, filter, mergeMap, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { AdministrationService } from './administration.service'
import { Address, Company, ContactBankAccount, Contact, Person } from '../../domain/administration/contact.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { InfiniteScrollSearchObject } from '../../core/logic/infinite-scroll.searchobject'
import { PaginatedResponse } from '../../domain/helpers/pagination.model'
import { Cacheable } from 'ts-cacheable'
import { SegmentHelper } from '../external-services/segment.helper'

const cacheBusterObserver = new Subject<void>()
export interface GroupedContacts {
  key: string
  entries: Contact[] | Company[]
}

/**
 * TODO: Improve types. Not all responses are paginated. Union type should be applied.
 */

@Injectable()
export class ContactsService {
  /**
   * Remember sorting.
   *
   * Used in e.g. these:
   * - 'tlw-contact-modal'
   * - 'contact-overview'
   */
  public companyIsSelected: boolean = true

  constructor(private readonly _administration: AdministrationService, private readonly _api: ApiGateway, private readonly _segment: SegmentHelper) {}

  public clearCache(): void {
    void cacheBusterObserver.next()
  }

  /**
   * Fetching.
   */
  @Cacheable({ cacheBusterObserver })
  getContacts(query?: string): Observable<Contact[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = <any>{ administrationId: administration.id }

        // Set search query param
        if (query) {
          params.q = query
        }

        return this._api.get<Contact[]>(endpoints.contacts.contacts, params)
      }),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getCompanies<Company>(searchObject?: ContactsSearchObject): Observable<PaginatedResponse<Company>> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._api.get<PaginatedResponse<Company>>(endpoints.contacts.companies, params)
      }),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getPersons<Person>(searchObject?: ContactsSearchObject): Observable<PaginatedResponse<Person>> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._api.get<PaginatedResponse<Person>>(endpoints.contacts.persons, params)
      }),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getSupplierContacts(): Observable<Contact[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => this._api.get<Contact[]>(endpoints.contacts.suppliers, { administrationId: administration.id })),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getContact(contactId: number): Observable<Contact> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = <any>{
          administrationId: administration.id,
          contactId: contactId,
        }

        return this._api.get<Contact>(endpoints.contacts.contacts, params)
      }),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getPerson(contactId: number): Observable<Person> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = <any>{
          administrationId: administration.id,
          id: contactId,
        }

        return this._api.get<Person>(endpoints.contacts.persons, params)
      }),
    )
  }

  @Cacheable({ cacheBusterObserver })
  getCompany(contactId: number): Observable<Company> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = <any>{
          administrationId: administration.id,
          id: contactId,
        }

        return this._api.get<Company>(endpoints.contacts.companies, params)
      }),
    )
  }

  /**
   * Saving.
   */
  saveNewCompany(company: Company, iban?: string): Observable<Company> {
    const companyWithoutPersons = JSON.parse(JSON.stringify(company))
    delete companyWithoutPersons.persons

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
        }

        return combineLatest([of(administration.id), this._api.post<Company>(endpoints.contacts.companies, companyWithoutPersons, params)])
      }),
      mergeMap(([administrationId, company]: [number, Company]) => {
        let bankAccount$ = of(null)

        if (iban) {
          const params = {
            administrationId: administrationId,
            companyId: company.id,
          }

          bankAccount$ = this._api.post(endpoints.contacts.addBankAccount.company, { name: 'Company account', iban }, params)
        }

        return combineLatest([of(company), bankAccount$])
      }),
      mergeMap(([company, bankAccount]: [Company, ContactBankAccount | null]) => {
        return of({ ...company, bank_accounts: bankAccount ? [bankAccount] : [{} as ContactBankAccount] })
      }),
      tap(() => this.clearCache()),
    )
  }

  saveNewPerson(person: Person, iban?: string): Observable<Person> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
        }

        return combineLatest([of(administration.id), this._api.post<Person>(endpoints.contacts.persons, person, params)])
      }),
      mergeMap(([administrationId, person]: [number, Person]) => {
        let bankAccount$ = of(null)

        if (iban) {
          const params = {
            administrationId: administrationId,
            personId: person.id,
          }

          bankAccount$ = this._api.post(endpoints.contacts.addBankAccount.person, { name: 'Person account', iban }, params)
        }

        return combineLatest([of(person), bankAccount$])
      }),
      mergeMap(([person, bankAccount]: [Person, ContactBankAccount | null]) => {
        return of({ ...person, bank_accounts: bankAccount ? [bankAccount] : [{} as ContactBankAccount] })
      }),
      tap(() => this.clearCache()),
    )
  }

  updateCompany(company: Company, bankAccount?: Partial<ContactBankAccount>): Observable<Company> {
    company.persons.filter((p) => p.addresses && !p.addresses.length).forEach((p) => delete p.addresses)

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
          id: company.id,
        }

        return combineLatest([of(administration.id), this._api.patch<Company>(endpoints.contacts.companies, company, null, params)])
      }),
      mergeMap(([administrationId, company]: [number, Company]) => {
        let bankAccount$ = of(bankAccount)

        if (bankAccount.id && bankAccount.iban) {
          const params = {
            administrationId: administrationId,
            companyId: company.id,
            bankAccountId: bankAccount.id,
          }

          bankAccount$ = this._api.patch(endpoints.contacts.updateBankAccount.company, { name: 'Company account', iban: bankAccount.iban }, null, params)
        } else if (bankAccount.iban) {
          const params = {
            administrationId: administrationId,
            companyId: company.id,
          }

          bankAccount$ = this._api.post(endpoints.contacts.addBankAccount.company, { name: 'Company account', iban: bankAccount.iban }, params)
        }

        return combineLatest([of(company), bankAccount$])
      }),
      mergeMap(([company, bankAccount]: [Company, ContactBankAccount | null]) => {
        return of({ ...company, bank_accounts: bankAccount ? [bankAccount] : [{} as ContactBankAccount] })
      }),
      tap(() => this.clearCache()),
    )
  }

  updatePerson(person: Person, bankAccount?: Partial<ContactBankAccount>): Observable<Person> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
          id: person.id,
        }

        return combineLatest([of(administration.id), this._api.patch<Person>(endpoints.contacts.persons, person, null, params)])
      }),
      mergeMap(([administrationId, person]: [number, Person]) => {
        let bankAccount$ = of(bankAccount)

        if (bankAccount.id && bankAccount.iban) {
          const params = {
            administrationId: administrationId,
            personId: person.id,
            bankAccountId: bankAccount.id,
          }

          bankAccount$ = this._api.patch(endpoints.contacts.updateBankAccount.person, { name: 'Person account', iban: bankAccount.iban }, null, params)
        } else if (bankAccount.iban) {
          const params = {
            administrationId: administrationId,
            personId: person.id,
          }

          bankAccount$ = this._api.post(endpoints.contacts.addBankAccount.person, { name: 'Person account', iban: bankAccount.iban }, params)
        }

        return combineLatest([of(person), bankAccount$])
      }),
      mergeMap(([person, bankAccount]: [Person, ContactBankAccount | null]) => {
        return of({ ...person, bank_accounts: bankAccount ? [bankAccount] : [{} as ContactBankAccount] })
      }),
      tap(() => this.clearCache()),
    )
  }

  deleteContact(contact: Contact): Observable<void> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.delete(endpoints.contacts.contacts, p), { contactId: contact.contact_id })
      .pipe(tap(() => this.clearCache()))
  }

  deleteCompanyBankAccount(companyId: number, bankAccountId: number): Observable<void> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.delete(endpoints.contacts.updateBankAccount.company, p), { companyId, bankAccountId })
      .pipe(tap(() => this.clearCache()))
  }

  deletePersonBankAccount(personId: number, bankAccountId: number): Observable<void> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.delete(endpoints.contacts.updateBankAccount.person, p), { personId, bankAccountId })
      .pipe(tap(() => this.clearCache()))
  }

  getContactName(person: Person): string {
    if (person.first_name && person.middle_name && person.last_name) {
      return `${person.first_name} ${person.middle_name} ${person.last_name}`
    }

    if (person.first_name && person.last_name) {
      return `${person.first_name} ${person.last_name}`
    }

    if (person.first_name && !person.last_name) {
      return `${person.first_name}`
    }

    if (!person.first_name) {
      return `${person.last_name}`
    }

    // Fallback
    return ''
  }

  // Street + Number + addition
  getFormattedStreet(address: Address): string {
    let formattedAddress = ''

    if (address.street) {
      formattedAddress += address.street
    }

    if (address.number) {
      formattedAddress += ` ${address.number}`
    }

    if (address.number_addition) {
      formattedAddress += ' ' + address.number_addition
    }

    return formattedAddress
  }

  /**
   * Group contacts by first letter
   * @param contacts the contact list to group
   * @param type
   * @returns {Observable<GroupedContacts[]>}
   */
  // public group(contacts: Contact[], type?: string): Observable<GroupedContacts[]> {
  group(contacts: any, type?: string): Observable<GroupedContacts[]> {
    // let filterFunc: (c: Contact) => boolean;
    // let groupFunc: (c: Contact) => any;

    let filterFunc
    let groupFunc

    // Prepare required grouping statements.
    if (type == 'companies') {
      filterFunc = (c) => !!c.name
      groupFunc = (c) => c.name.charAt(0).capitalize()
    } else if (type == 'persons') {
      filterFunc = (c) => c.last_name || c.first_name
      groupFunc = (c) => (c.last_name ? c.last_name.charAt(0).capitalize() : c.first_name.charAt(0).capitalize())
    } else {
      filterFunc = () => true
      groupFunc = (c) => (c.company ? c.company.name.charAt(0).capitalize() : c.person.last_name.charAt(0).capitalize())
    }

    // Perform grouping
    return observableFrom(contacts).pipe(
      filter(filterFunc),
      groupBy(groupFunc),
      mergeMap((group) =>
        group.pipe(
          toArray(),
          map((a) => <GroupedContacts>{ entries: a, key: group.key }),
        ),
      ),
      toArray(),
    )
  }
}

type ContactSearchType = {
  paginated?: boolean
}

export class ContactsSearchObject extends InfiniteScrollSearchObject {
  searchQuery: string
  sort: string

  constructor(opts: ContactSearchType = {}) {
    super()
    this.paginated = opts?.paginated
    this.addExtraParameters = (params) => this.addParams(params)
  }

  private addParams(params) {
    if (this.searchQuery) {
      params.q = this.searchQuery
    }

    if (this.sort) {
      params.sort = this.sort
    }
  }
}
