import { Injectable } from '@angular/core'
import sbjs from 'sourcebuster'

export interface AttributionCurrent {
  // Traffic type. Possible values: utm, organic, referral, typein
  typ?: string
  // Source. utm_source, actually.
  src?: string
  // Medium, utm_medium. Values can be customized via utm-params and referrals.
  mdm?: string
  // Campaign. Value of utm_campaign.
  cmp?: string
  // Content. Value of utm_content.
  cnt?: string
  // Keyword. Value of utm_term.
  trm?: string
}

export interface AttributionCurrentAdditional {
  // Date and time of a visit. Format: yyyy-mm-dd hh:mm:ss. Time zone can be customized via timezone_offset.
  fd?: string
  // Entrance point.
  ep?: string
  // Referer URL.
  rf?: string
}

export interface AttributionSession {
  // How many pages user have seen during the current session.
  pgs?: number
  // Current page URL.
  cpg?: string
}

export interface AttributionUserData {
  // How many times user visited site.
  vst?: number
  // Current ip-address.
  uip?: string
  // Current user-agent (browser).
  uag: string
}

export type AttributionFirst = AttributionCurrent
export type AttributionFirstAdditional = AttributionCurrentAdditional

@Injectable({
  providedIn: 'root',
})
export class AttributionHelper {
  private static consentToCookies: boolean = true

  /**
   * Init Sourcebuster attribution tracking; keep in
   * mind Sourcebuster is also installed on marketing
   * website tellow.nl to have cross-stack tracking;
   * with the same config!
   *
   * @see http://sbjs.rocks/#/installation
   */
  init(): void {
    if (AttributionHelper.consentToCookies) {
      sbjs.init({
        // Custom expiration period of sourcebuster cookies (in months).
        lifetime: 1,
        host: 'tellow.nl',
        callback: (sbData: {
          current: AttributionCurrent
          current_add: AttributionCurrentAdditional
          first: AttributionFirst
          first_add: AttributionFirstAdditional
          session: AttributionSession
          udata: AttributionUserData
        }) => {
          console.debug('Attribution', sbData)
        },
      })
    }
  }

  /**
   * Params of the latest visitor's source. If a visitor
   * had more than one source, this will be the latest.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  current(): AttributionCurrent | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.current : undefined
  }

  /**
   * Additional info about a visit.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  currentAdditional(): AttributionCurrentAdditional | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.current_add : undefined
  }

  /**
   * Just like sbjs.get.current & sbjs.get.current_add, but holds
   * data of the very first visit. Stored once, never overwritten.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  first(): AttributionFirst | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.first : undefined
  }

  /**
   * Additional info about first visit. Stored once, never overwritten.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  firstAdditional(): AttributionFirstAdditional | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.first_add : undefined
  }

  /**
   * Current opened session data.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  session(): AttributionSession | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.session : undefined
  }

  /**
   * Additional user data: visits, ip & user-agent.
   *
   * @see https://github.com/alexfedoseev/sourcebuster-js
   */
  userData(): AttributionUserData | undefined {
    return AttributionHelper.consentToCookies ? sbjs.get.udata : undefined
  }
}
