export function initDatePrototype() {
  Date.prototype.getDayName = function (short: boolean) {
    short = !!short

    switch (this.getDay()) {
      case 0:
        return short ? 'Zo' : 'Zondag'
      case 1:
        return short ? 'Ma' : 'Maandag'
      case 2:
        return short ? 'Di' : 'Dinsdag'
      case 3:
        return short ? 'Wo' : 'Woensdag'
      case 4:
        return short ? 'Do' : 'Donderdag'
      case 5:
        return short ? 'Vr' : 'Vrijdag'
      case 6:
        return short ? 'Za' : 'Zaterdag'
    }
  }
}
