import { combineLatest as observableCombineLatest, Observable, of as observableOf } from 'rxjs'
import { filter, first, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { TranslateService } from '@ngx-translate/core'
import { ApiGateway } from '../../core/remote/api.gateway'
import { VatDeclaration, VatDeclarationTaxService } from '../../domain/vat/vat-declaration.model'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationService } from '../administration/administration.service'
import { VatYear } from '../../domain/vat/vat-year.model'
import { LoginService } from '../../core/security/login.service'
import { VatDeclartionPeriods } from '../../domain/vat/vat-declaration-periods.constants'
import { Suppletion } from '../../domain/vat/suppletion.model'
import { VatPeriod } from '../../domain/vat/vat-period.model'
import { VatPeriodStates } from '../../domain/vat/vat-period-states.enum'
import { AccountingService } from '../accounting/accounting.service'
import { SuppletionService } from './suppletion.service'
import { OtherPrivateUse } from '../../domain/vat/other-private-use.model'
import { CarPrivateUse } from '../../domain/vat/car-private-use.model'
import { FiscalYearWithYearIdentifier } from '../../domain/accounting/fiscal-year.model'
import { PrivateUseBooking } from '../../domain/vat/private-use-booking.model'
import { PrivateVatBookingType } from '../../domain/vat/private-vat-booking-type.class'
import { DeclarationType } from '../../domain/vat/declaration-type.enum'
import { PeriodOrDeclaration } from '../../domain/vat/period-or-declaration.type'
import { VatPeriodDisplayItem } from '../../domain/administration/vat-period.model'
import { JustificationSuppletion } from '../../domain/justification/justification-suppletion.model'
import { VatDeclarationSubmitted } from '../../domain/vat/vat-declaration-submitted'
import { SecureHttp } from '../../core/remote/httpclient'
import { environment } from '../../../environments/environment'
import { Cacheable } from 'ts-cacheable'

const DAYS_IN_YEAR = 365
const OLD_CAR = 0.015
const NEW_CAR = 0.027
const YEARS = 5

@Injectable()
export class VatService {
  private _vatYearsObservable: Observable<VatYear[]>
  private _vatRates: VatPeriodDisplayItem[]

  private _now = moment()

  constructor(
    private readonly _api: ApiGateway,
    private readonly _http: SecureHttp,
    private readonly _login: LoginService,
    private readonly _translate: TranslateService,
    private readonly _administration: AdministrationService,
    private readonly _accountingService: AccountingService,
    private readonly _suppletionService: SuppletionService,
  ) {
    this._login.onLoggedIn.subscribe(() => this.clearCache())
    this._administration.onSwitchedAdministration.subscribe(() => this.clearCache())
    this._administration.vatPurchaseRatePeriods.subscribe((vatRates) => (this._vatRates = vatRates))
  }

  @Cacheable()
  yearHasEntries(year: number): Observable<boolean> {
    return this.getPeriodByYear(year).pipe(
      map((period) => period.any((entry) => Boolean(entry?.id))),
      take(1),
    )
  }

  @Cacheable()
  checkForUnperformedPeriods(year: string): Observable<boolean> {
    return this._administration.defaultAdministration.pipe(
      mergeMap(({ id }) => this._api.get<VatPeriod[]>(endpoints.vat.periods, { year, administrationId: id })),
      map((periods: VatPeriod[]) => periods.filter((p) => p.date_start.startsWith(year)).any((p) => p.performed === false)),
    )
  }

  getCurrentPeriod(): Observable<VatPeriod> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((a) => this._api.get<VatPeriod[]>(endpoints.vat.periods, { year: this._now.year(), administrationId: a.id })),
      map((periods: VatPeriod[]) => periods.firstOrUndefined((p) => this._now.isBetween(moment(p.date_start).startOf('day'), moment(p.date_end).endOf('day')))),
    )
  }

  @Cacheable()
  getPeriodByYear(year: number): Observable<VatPeriod[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((a) => this._api.get<VatPeriod[]>(endpoints.vat.periods, { year, administrationId: a.id })),
      map((periods: VatPeriod[]) => periods.filter((p) => p.date_start.startsWith(String(year)))),
    )
  }

  getDeclarationsForPeriod(period: VatPeriod | { id: number }): Observable<VatDeclaration> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((a) => this._api.get<VatDeclaration>(endpoints.vat.declaration, { administrationId: a.id, id: period.id })),
    )
  }

  /**
   * Get submitted VAT declarations from
   * Tellow tax service.
   */
  getSubmittedVatDeclarations(period: VatPeriod | { id: number }): Observable<VatDeclarationTaxService[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((a) =>
        this._api.get<VatDeclarationTaxService[]>(
          endpoints.vat.listSubmittedDeclarations,
          {
            administrationId: a.id,
            id: period.id,
          },
          null,
          false,
          true,
          environment.taxServiceApiUrl,
        ),
      ),
    )
  }

  getDeclarationDifferencesForPeriod(period: VatPeriod | { id: number }): Observable<VatDeclaration> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((a) => this._api.get<VatDeclaration>(endpoints.vat.declarationDifference, { administrationId: a.id, id: period.id })),
    )
  }

  getVatYears(): Observable<VatYear[]> {
    this._vatYearsObservable = this._administration.defaultAdministration.pipe(
      mergeMap((a) => this._api.get<VatYear[]>(endpoints.vat.years, { administrationId: a.id })),
      switchMap((x) => x),
      tap((y) =>
        y.periods.forEach((p) => {
          if (this._now.isAfter(p.date_end)) {
            p.__status = VatPeriodStates.HISTORY
          } else if (this._now.isBefore(p.date_start)) {
            p.__status = VatPeriodStates.FUTURE
          } else {
            p.__status = VatPeriodStates.CURRENT
          }
        }),
      ),
      toArray(),
    )

    return this._vatYearsObservable
  }

  // Check if 9% or 6% should be displayed
  getVatDisplayText(vatDeclarationDate: string): string {
    const date = new Date(vatDeclarationDate)
    const vat_rate_new = this._vatRates.firstOrUndefined((v) => v.balance_name.contains('9%'))

    if (vat_rate_new) {
      const vatStartDateNew = new Date(vat_rate_new.start_date)

      return date >= vatStartDateNew ? 'VAT.VAT_CODES.1b_9' : 'VAT.VAT_CODES.1b_6'
    }

    return 'VAT.VAT_CODES.1b_6'
  }

  getVatYearsAndSuppletions(): Observable<VatYear[]> {
    return observableCombineLatest([
      this.getVatYears().pipe(
        map((yearsPeriods) => yearsPeriods.reverse()),
        switchMap((y) => y),
        tap(
          (yearPeriods) => (yearPeriods.periods = yearPeriods.periods.filter((p) => this._now.isAfter(moment(p.date_start))).orderByDesc((p) => p.date_start)),
        ),
        toArray(),
      ),

      this.getSuppletions(),
    ]).pipe(map(([years, suppletions]: [VatYear[], Suppletion[]]) => this.augmentVatListWithSuppletions(years, suppletions)))
  }

  saveDeclaration(declaration: VatDeclaration, year: number, periodId: number, force: boolean): Observable<VatDeclaration> {
    const url = endpoints.vat.declaration
    const params: any = { year: year, id: periodId }

    if (force) {
      params.force = 1
    }

    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.post(url, declaration, p), params)
  }

  /**
   * Submit VAT declaration to backend,
   * which sends it in to Tellow tax service
   * to submit to Belastingdienst.
   *
   * @see https://api.test.tellow.nl/doc#post--v2-administration-{administrationId}-vat-declaration-{id}-submit
   */
  submitDeclaration(periodId: number, data: { [key: string]: string }): Observable<VatDeclarationSubmitted> {
    const url = endpoints.vat.submitDeclaration
    const params: any = { id: periodId }

    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.post(url, data, p), params)
  }

  deleteDeclaration(year: number, periodId: number, force: boolean): Observable<void> {
    const url = endpoints.vat.declaration

    const params: any = { year: year, id: periodId }

    if (force) {
      params.force = 1
    }

    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.delete(url, p), params)
  }

  getSuppletions(): Observable<Suppletion[] | JustificationSuppletion[]> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.get<Suppletion[]>(endpoints.vat.suppletion, p))
      .pipe(mergeMap((suppletions) => this._suppletionService.augmentSuppletions(suppletions)))
  }

  getSuppletion(id: number): Observable<Suppletion> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.get<Suppletion>(endpoints.vat.suppletion, p), { id: id })
      .pipe(
        mergeMap((suppletion) => this._suppletionService.augmentSuppletions([suppletion])),
        switchMap((x: Suppletion[]) => x),
        first(),
      )
  }

  submitSuppletion(suppletion: Suppletion): Observable<Suppletion> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.vat.suppletion, suppletion, p))
  }

  updateSuppletion(suppletion: Suppletion): Observable<Suppletion> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.put(endpoints.vat.suppletion, suppletion, p), { id: suppletion.id })
  }

  deleteSuppletion(suppletion: Suppletion): Observable<any> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.delete(endpoints.vat.suppletion, p), { id: suppletion.id })
  }

  generateSuppletion(year: VatYear): Observable<any> {
    return this._accountingService.fiscalYears.pipe(
      switchMap((x) => x),
      filter((fy: FiscalYearWithYearIdentifier) => fy.year == year.year),
      mergeMap((fy) =>
        this._administration.makeApiCallForDefaultAdministration((p) => this._api.get(endpoints.vat.generateSuppletion, p, null, null, true), {
          fiscalYearId: fy.id,
        }),
      ),
    )
  }

  getPrivateUsageBooking(id: number): Observable<PrivateUseBooking> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.get<PrivateUseBooking>(endpoints.vat.privateUse, p), { action: id })
  }

  getPrivateUsageBookings(): Observable<PrivateUseBooking[]> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.get<PrivateUseBooking[]>(endpoints.vat.privateUse, p))
      .pipe(
        mergeMap((bookings) =>
          this._accountingService.fiscalYears.pipe(
            map((years: FiscalYearWithYearIdentifier[]) => {
              bookings.forEach((b) => {
                b.__vatAmount =
                  b.type == PrivateVatBookingType.OTHER.type ? this.calculateVatAmountForOtherModel(<any>b) : this.calculateVatAmountForCarModel(<any>b, years)

                b.__year = years.find((y) => y.id == b.fiscal_year_id).year
              })

              return bookings
            }),
          ),
        ),
      )
  }

  saveOtherPrivateBooking(formModel: OtherPrivateUse): Observable<any> {
    formModel.fiscal_year_id = parseInt(<any>formModel.fiscal_year_id)
    formModel.account_id = parseInt(<any>formModel.account_id)

    return this._administration.makeApiCallForDefaultAdministration(
      (p, o) => this._api.post(endpoints.vat.privateUse, o, p),
      {
        action: 'other',
      },
      formModel,
    )
  }

  saveCarPrivateBooking(formModel: CarPrivateUse): Observable<any> {
    formModel.fiscal_year_id = parseInt(<any>formModel.fiscal_year_id)

    return this._administration.makeApiCallForDefaultAdministration(
      (p, o) => this._api.post(endpoints.vat.privateUse, o, p),
      {
        action: 'car',
      },
      formModel,
    )
  }

  deletePrivateBooking(booking: PrivateUseBooking): Observable<any> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.delete(endpoints.vat.privateUse, p), { action: booking.id })
  }

  calculateVatAmountForCarModel(formModel: CarPrivateUse, openYears: FiscalYearWithYearIdentifier[]): number {
    if (!formModel) {
      return 0
    }

    let result = 0
    const now = moment()
    const purchaseDate = moment(formModel.purchase_date)
    const bookdate = moment(`${openYears.find((oy) => oy.id == formModel.fiscal_year_id).year}-12-31`)
    let dayFraction = 1 + bookdate.diff(purchaseDate, 'day', true)
    const ownedForMoreThan5Years = now.diff(purchaseDate, 'year', true) > YEARS

    if (dayFraction > DAYS_IN_YEAR) {
      dayFraction = DAYS_IN_YEAR
    }

    if (formModel.is_margin || ownedForMoreThan5Years) {
      result = OLD_CAR * formModel.catalog_price * (dayFraction / DAYS_IN_YEAR)
    } else {
      result = NEW_CAR * formModel.catalog_price * (dayFraction / DAYS_IN_YEAR)
    }

    return Math.floor(result)
  }

  calculateVatAmountForOtherModel(formModel: OtherPrivateUse): number {
    return Math.floor(formModel.amount ? formModel.amount * 0.21 : 0)
  }

  getPeriodName(period: PeriodOrDeclaration, appendYear: boolean): Observable<string> {
    if (!period) {
      return observableOf(null)
    }

    if (period.__type && period.__type == DeclarationType.Suppletion) {
      return (
        this._translate
          .get('VAT.SUPPLETION.SUPPLETION')
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          .pipe(map((t) => (appendYear ? `${t} ${period.__year}` : t)))
      )
    }

    return this._translate.get('VAT.PERIOD_QUARTER').pipe(
      map((translation) => {
        let result: string

        const date = moment(period.date_start)

        switch (period.period) {
          case VatDeclartionPeriods.MONTH:
            result = date.format('MMMM').capitalize()
            break
          case VatDeclartionPeriods.QUARTER:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            result = `${translation} ${date.format('Q')}`
            break
          case VatDeclartionPeriods.YEAR:
            result = <any>date.get('year')
            break
        }

        if (appendYear) {
          result += ` ${date.get('year')}`
        }

        return result
      }),
    )
  }

  /**
   * Mark period checked
   * by given bookkeeper.
   */
  checkedByBookkeeper(period: VatPeriod | { id: number }): Observable<VatDeclaration> {
    return this._administration.makeApiCallForDefaultAdministration((params, object) =>
      this._api.post(endpoints.vat.markAsChecked, object, { ...params, id: period.id }),
    )
  }

  clearCache(): void {
    this._vatYearsObservable = null
  }

  augmentVatListWithSuppletions(vatYears: VatYear[], suppletions: Suppletion[]): VatYear[] {
    vatYears.forEach((y) => {
      const ySups = suppletions.filter((s) => s.__year == y.year)
      y.periods = [...(<any>ySups), ...y.periods.filter((p: PeriodOrDeclaration) => p.__type != DeclarationType.Suppletion)]
    })

    return vatYears
  }
}
