import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'
import { AuthorizationService } from '../authorization/authorization.service'

@Injectable()
export class OwnerGuard implements CanActivate {
  constructor(private readonly _authService: AuthorizationService) {}

  canActivate(): any {
    return this._authService.isCurrentAdministrationOwner()
  }
}
