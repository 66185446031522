import { Observable, BehaviorSubject, defer } from 'rxjs'
import { finalize } from 'rxjs/operators'

/**
 * @see: https://nils-mehlhorn.de/posts/indicating-loading-the-right-way-in-angular
 *
 * Operators that set a loading state when subscribing to a service or value.
 */

export const prepare = <T>(callback: () => void) => {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback()

      return source
    })
}

export const indicate = <T>(indicator: BehaviorSubject<boolean>) => {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false)),
    )
}
