import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { mergeMap, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { Statuspage } from './statuspage.models'
import { UserService } from '../user/user.service'
import { User } from '../../domain/user/user.model'
import { ReplaySubject, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StatuspageService {
  statusMessages$ = new ReplaySubject<Statuspage.IncidentResponse>(null)
  private readonly statuspageUrl: string
  private static ShowMessageAfterDays = 7

  constructor(private readonly http: HttpClient, private readonly user: UserService) {
    this.statuspageUrl = `https://${environment.statuspageKey}.statuspage.io/api/v2`
  }

  /**
   * Requests statuspage and returns observable with all incidents
   */
  getAllIncidents(): Observable<Statuspage.IncidentResponse> {
    return this.http.get<Statuspage.IncidentResponse>(`${this.statuspageUrl}/incidents.json`)
  }

  /**
   * Requests statuspage and returns observable with unresolved incidents
   */
  getUnresolvedIncidents(): Observable<Statuspage.IncidentResponse> {
    return this.http.get<Statuspage.IncidentResponse>(`${this.statuspageUrl}/incidents/unresolved.json`)
  }

  /**
   * Requests statuspage and returns observable with unresolved incidents.
   * Show only if user is created_at longer than ${StatusPageService.ShowMessageAfterDays} days ago
   * Otherwise return empty array which results in nothing being showed in the view
   */
  getUnresolvedIncidentsForUser(): Observable<Statuspage.IncidentResponse> {
    return this.user.userData.pipe(
      mergeMap((u: User) => {
        if (!u) {
          return of(null)
        }

        const diff = Math.abs(new Date(u.created_at).getTime() - new Date().getTime()),
          diffDays = Math.ceil(diff / (1000 * 3600 * 24))

        return diffDays >= StatuspageService.ShowMessageAfterDays ? this.getUnresolvedIncidents() : of(null)
      }),
      tap((inc) => {
        this.statusMessages$.next(inc)
      }),
    )
  }
}
