import { debounceTime } from 'rxjs/operators'
import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { FormControl } from '@angular/forms'
import { AdministrationService } from '../../../../logic/administration/administration.service'
import { AdministrationListItem } from '../../../../domain/administration/administration-list-item.model'
import { globals } from '../../../../shared/config/globals'
import { SessionService } from '../../../../logic/user/session.service'
import { FncModalComponent } from '../../../../ui-components/fnc-modal/fnc-modal.component'
import { TranslateRouteService } from '../../../../core/logic/i18n/translate-route.service'
import { FncMainLayoutComponent } from '../../../fnc-main-layout/fnc-main-layout.component'
import { FilterService } from '../../../../logic/filters/filter.service'

@Component({
  selector: 'administration-switcher',
  styleUrls: ['./administration-switcher.component.scss'],
  template: `
    <fnc-input-action class="search">
      <input type="text" [(ngModel)]="search" placeholder="{{ 'MAIN.SEARCH' | translate }}" [formControl]="searchElement" />
      <tlw-icon icon="search" variant="mini" height="12"></tlw-icon>
    </fnc-input-action>
    <ul *ngIf="administrations && administrations.length">
      <li *ngFor="let a of administrations" (click)="switchToAdministration(a)">
        <div class="administration-item-left">{{ a.owner }}</div>
        <div class="administration-item-right">{{ a.name }}</div>
      </li>
    </ul>
    <ul *ngIf="!administrations || !administrations.length">
      <li style="white-space: nowrap;">{{ 'MAIN.ADMIN_SWITCHER_SEARCH' | translate }}</li>
    </ul>
    <fnc-modal #modal [size]="'large'" [document]="true">
      <modal-content>
        <div class="admin-switching-modal">
          <fnc-loader translatableMessage="MESSAGES.WAIT_A_MOMENT"></fnc-loader>
        </div>
      </modal-content>
    </fnc-modal>
  `,
})
export class AdministrationSwitcherComponent implements OnInit {
  @HostBinding('style.max-height')
  maxHeight = '0px'
  @HostBinding('style.opacity')
  opacity = 0
  @HostBinding('style.position')
  position = 'static'
  @HostBinding('style.transition')
  transition = this._sanitizer.bypassSecurityTrustStyle('none')
  @ViewChild('modal', { static: true })
  modal: FncModalComponent
  administrations: AdministrationListItem[]
  search: string
  searchElement = new FormControl()
  @Input() layout: FncMainLayoutComponent
  private _mainAnimationTimeout: any
  private _subAnimationTimeout: any
  private _subSubAnimationTimeout: any
  private _subSubSubAnimationTimeout: any
  private _firstAdministrations: AdministrationListItem[] = []
  private _open: boolean

  constructor(
    private _el: ElementRef,
    private _administrationService: AdministrationService,
    private _sanitizer: DomSanitizer,
    private _sessionService: SessionService,
    private _router: Router,
    private _translateRouteService: TranslateRouteService,
    private _filterService: FilterService,
  ) {}

  ngOnInit() {
    this.searchElement.valueChanges.pipe(debounceTime(globals.defaultDebounceTime)).subscribe(() => {
      this.filterChanged()
    })

    this._administrationService.userAdministrationList.subscribe((administrations) => {
      this._firstAdministrations = administrations
      this.administrations = administrations
    })
  }

  switchToAdministration(admin: AdministrationListItem) {
    this.modal.open()
    this.close()

    const subscription = this._administrationService.onSwitchedAdministration.subscribe(() => {
      subscription.unsubscribe()
      // Wait till caches are cleared
      setTimeout(() => {
        this._sessionService.startSession().subscribe(() => {
          // Routing to the active link seems te be a pain, so use a dummy route and redirect right after to refresh properly
          void this._router.navigateByUrl(this._translateRouteService.translate('/settings'), { skipLocationChange: true }).then(() => {
            this._filterService.resetAll()
            void this._router.navigate([''])
          })
          this.modal.close()
        })
      }, 0)
    })

    this._administrationService.setDefaultAdministrationId(admin.id, true)
  }

  open() {
    if (this._open) {
      return
    }

    this._open = true
    this.resize()
  }

  close() {
    if (!this._open) {
      return
    }

    this.maxHeight = '0px'
    this._open = false
  }

  private resize(skipAnimation?: boolean) {
    this.transition = this._sanitizer.bypassSecurityTrustStyle('none')

    clearTimeout(this._mainAnimationTimeout)
    clearTimeout(this._subAnimationTimeout)
    clearTimeout(this._subSubAnimationTimeout)
    clearTimeout(this._subSubSubAnimationTimeout)

    this._mainAnimationTimeout = setTimeout(() => {
      this.position = 'absolute'
      this.opacity = 0
      this.maxHeight = 'none'

      this._subAnimationTimeout = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const maxHeight = `${this._el.nativeElement.getBoundingClientRect().height}px`

        this.position = 'static'
        this.opacity = 1
        this.maxHeight = '100px'

        this._subSubAnimationTimeout = setTimeout(() => {
          if (!skipAnimation) {
            this.transition = this._sanitizer.bypassSecurityTrustStyle(`max-height ${globals.animations.speed.default}ms ${globals.animations.defaultTiming}`)
          }

          this._subSubSubAnimationTimeout = setTimeout(() => (this.maxHeight = maxHeight), 0)
        }, 0)
      }, 0)
    }, 0)
  }

  private filterChanged() {
    if (!this.search || this.search == '') {
      this.administrations = this._firstAdministrations
      setTimeout(() => this.resize())
    } else {
      this._administrationService.searchAdministrations(this.search).subscribe((administrations) => {
        this.administrations = administrations
        setTimeout(() => this.resize(true))
      })
    }
  }
}
