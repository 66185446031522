import { Observable, Subject } from 'rxjs'
import { map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { EventEmitter, Injectable } from '@angular/core'
import { User } from '../../domain/user/user.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { LoginService } from '../../core/security/login.service'
import { endpoints } from '../../shared/config/endpoints'
import { DeviceService } from '../helpers/device.service'
import { UserOriginTypes } from '../../domain/user/user-origin-types.constants'
import { Module } from '../../domain/products/module.model'
import { UserRoleTypes } from '../../domain/user/user-role-types.constants'
import { Cacheable } from 'ts-cacheable'
import { DeviceType, DeviceWithActiveSession } from '../../domain/user/device.model'

const cacheBusterObserver = new Subject<void>()

@Injectable()
export class UserService {
  onUserAgreedToTerms = new EventEmitter()
  onIdentifyUser = new EventEmitter()

  /**
   * In some cases, visitors will not complete the onboarding process.
   * In these cases we collect the appropriate data so we can pass it further
   */
  private firstNameOnReturn: string = null
  private emailOnReturn: string = null
  private stepOnReturn: number = null

  get isTellowEmployee(): Observable<boolean> {
    return this.user.pipe(
      tap(() => console.warn('Checking wether user is Tellow employee.')),
      map((user) => user.role === UserRoleTypes.ADMIN && user.email.endsWith('@tellow.nl')),
    )
  }

  get isTellowBookkeeper(): Observable<boolean> {
    return this.user.pipe(
      tap(() => console.warn('Checking wether user is Tellow bookkeeper.')),
      map((user) => {
        const match = ['@tellow.nl', '@brandboekhouders.nl'].find((element) => {
          if (user.email.includes(element)) {
            return true
          }
        })

        return match !== undefined
      }),
    )
  }

  /**
   * Returns the name of the origin based on an origin_id. For example SELF_REGISTRATION
   */
  static getUserTypeName(originId: number, fallback: string = 'UNKNOWN'): string {
    if (!originId) {
      return null
    }

    // Define the origin of the user based on it's origin_id
    const userTypeIds: number[] = (Object as any).values(UserOriginTypes),
      activeUserTypeIndex: number = userTypeIds.findIndex((userTypeId) => originId === userTypeId),
      activeUserTypeNames: string[] = Object.keys(UserOriginTypes)

    return activeUserTypeNames[activeUserTypeIndex] ? activeUserTypeNames[activeUserTypeIndex] : fallback
  }

  /**
   * Creates and returns a fullname based on user data
   */
  static getFullName(user: User): string {
    let name = `${user.first_name} `

    if (user.middle_name) {
      name += `${user.middle_name} `
    }

    name += user.last_name

    return name
  }

  constructor(private readonly _api: ApiGateway, private readonly _login: LoginService, private readonly _deviceService: DeviceService) {
    this._login.onLoggedIn.subscribe(() => this.clearCache())
  }

  /**
   * Getter that returns
   * currently cached function.
   *
   * (getters cannot be cached
   * with 'ts-cacheable' dependency).
   */
  get user(): Observable<User> {
    return this._getUserWithDevice()
  }

  /**
   * Register device and get the user data.
   * This is used in the login/registration flow,
   * so the 'checkDevice' MUST execute.
   *
   * If you are to take it out, shift the
   * responsibility of loading this data
   * to given domain (login/registration).
   */
  @Cacheable({ cacheBusterObserver })
  private _getUserWithDevice(): Observable<User | null> {
    return this._deviceService.checkDevice().pipe(
      mergeMap(() => {
        return this._api.get<User>(endpoints.user)
      }),
    )
  }

  /**
   * Getter that returns
   * currently cached function.
   *
   * (getters cannot be cached
   * with 'ts-cacheable' dependency).
   */
  get userData(): Observable<User> {
    return this._getUserData()
  }

  /**
   * Get user data without registering device.
   * Only used in a few instances; preferably
   * use the 'user' getter listed above.
   */
  @Cacheable({ cacheBusterObserver })
  private _getUserData(): Observable<User> {
    return this._api.get<User>(endpoints.user)
  }

  clearCache(): void {
    void cacheBusterObserver.next()
  }

  // Activate invited user
  // activateInvitedUser(data: any): Observable<any> {
  //   return this._api.post(endpoints.onboarding.activateInvitee, data);
  // }

  // Gets a code required for changing emailaddress
  sendChangeEmailVerificationCode(): Observable<any> {
    return this.user.pipe(
      mergeMap((user) => {
        return this._api.post(endpoints.login.sendChangeEmailVerificationCode, {}, { id: user.id })
      }),
    )
  }

  validateChangeEmailVerificationCode(code: string): Observable<any> {
    return this.user.pipe(
      mergeMap((user) => {
        return this._api.post(endpoints.login.validateChangeEmailVerificationCode, { sms_code: code }, { id: user.id })
      }),
    )
  }

  verifyNewEmailAddress(verify_token: string, email: string): Observable<any> {
    return this.user.pipe(
      mergeMap((user) => {
        return this._api.post(endpoints.login.verifyNewEmailAddress, { verify_token: verify_token, email: email }, { id: user.id })
      }),
    )
  }

  changeEmailAddress(token: string, email: string) {
    return this.user.pipe(
      mergeMap((user) => {
        return this._api.post(
          endpoints.login.changeEmailAddress,
          {
            email: email,
            email_change_token: token,
          },
          { id: user.id },
        )
      }),
    )
  }

  isBetaUser(): Observable<boolean> {
    return this.user.pipe(map((user) => user.is_beta))
  }

  hasCompletedOnboardingChecklist(): Observable<boolean> {
    return this.user.pipe(map((user) => user.completed_onboarding_checklist))
  }

  getSubscriptions(): Observable<Module[]> {
    return this._api.get(endpoints.modules.subscriptions)
  }

  subscribe(moduleId: string): Observable<any> {
    return this._api.post(endpoints.modules.subscribe, { module_id: moduleId })
  }

  unsubscribe(moduleId: number): Observable<any> {
    return this._api.post(endpoints.modules.unsubscribe, { module_id: moduleId })
  }

  agreeToTerms(): Observable<any> {
    return this._api.post(endpoints.terms, undefined).pipe(
      mergeMap(() => this.user),
      map((user) => {
        user.latest_terms_accepted = true
        this.onUserAgreedToTerms.emit()

        return null
      }),
    )
  }

  agreeToLicenseAndTerms(): Observable<any> {
    return this._api.post(endpoints.terms, undefined).pipe(
      mergeMap(() => this.userData),
      mergeMap((user) => {
        user.latest_terms_accepted = true

        return this._deviceService.checkDevice().pipe(
          map((response) => {
            this.onUserAgreedToTerms.emit()

            return response
          }),
        )
      }),
    )
  }

  /**
   * Toggles the newsletters and beta subscription + the completed checklist flag of the user.
   * @param newsletter
   * @param beta
   * @param completedChecklist
   */
  toggleSignUps({ newsletter, beta, completedChecklist }: { newsletter?: boolean; beta?: boolean; completedChecklist?: boolean }): Observable<any> {
    return this.user.pipe(
      mergeMap((user: User) => {
        return this._api
          .post(endpoints.subscriptions, {
            // Take either the input, or the existing value from the 'user' object
            // Number() casts truthy and falsey values to either 1 or 0, accordingly
            newsletter: Number(newsletter ?? user.is_subscribed_to_newsletter),
            beta: Number(beta ?? user.is_beta),
            completedOnboardingChecklist: Number(completedChecklist ?? user.completed_onboarding_checklist),
          })
          .pipe(tap(() => this.clearCache()))
      }),
    )
  }

  /**
   * Returns the data which is needed when users return in the onboarding after creating only a user account
   */
  getUserOnReturnData(): { firstName: string; email: string; step: number } {
    return {
      firstName: this.firstNameOnReturn,
      email: this.emailOnReturn,
      step: this.stepOnReturn,
    }
  }

  /**
   * Sets the data which is needed when users return in the onboarding after creating only a user account
   * @param user
   * @param step
   */
  setUserOnReturnData(user: User, step: number): void {
    this.firstNameOnReturn = user.first_name
    this.emailOnReturn = user.email
    this.stepOnReturn = step
  }

  getAllDevices(): Observable<DeviceWithActiveSession[]> {
    return this.user.pipe(
      switchMap(({ id }) => this._api.get<any[]>(endpoints.device.allDevices, { id })),
      map((devices) =>
        devices.map(
          (device) =>
            ({
              ...device,
              type: device.type === '1' ? DeviceType.ANDROID : device.type === '2' ? DeviceType.IOS : DeviceType.WEB,
              updated_at: new Date(device.updated_at),
            } as DeviceWithActiveSession),
        ),
      ),
    )
  }

  deleteDevice(deviceId: string): Observable<any> {
    return this._api.delete(endpoints.device.logout, { deviceId })
  }

  deleteAllDevices(): Observable<any> {
    return this._api.delete(endpoints.device.logoutAll)
  }
}
