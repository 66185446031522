import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { environment } from '../environments/environment'
import { RedirectComponent } from './redirect/redirect.component'
import { NetworkAwarePreloadingStrategy } from './custom-route-preloading'
import { TlwOnboardingModalComponent } from './ui-components/tlw-onboarding-modal/tlw-onboarding-modal.component'
import { TlwEmailVerificationModalComponent } from './ui-components/tlw-email-verification-modal/tlw-email-verification-modal.component'
import { FncMainLayoutComponent } from './layout-components/fnc-main-layout/fnc-main-layout.component'

const appRoutes: Routes = [
  { path: 'login', redirectTo: environment.routerLinks.login.index },
  { path: 'inloggen/registreer', redirectTo: environment.routerLinks.register.index },
  { path: 'inloggen/registreer-snel', redirectTo: environment.routerLinks.register.index },
  { path: 'onboarding', outlet: 'modal', component: TlwOnboardingModalComponent },
  { path: 'email-verification', outlet: 'modal', component: TlwEmailVerificationModalComponent },
  {
    path: '',
    component: FncMainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule),
      },
      {
        path: environment.routerLinks.expenses.index,
        loadChildren: () => import('app/expenses/expenses.module').then((m) => m.ExpensesModule),
      },
      {
        path: environment.routerLinks.invoices.index,
        loadChildren: () => import('app/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: environment.routerLinks.quotes.index,
        loadChildren: () => import('app/quotes/quotes.module').then((m) => m.QuotesModule),
      },
      {
        path: environment.routerLinks.transactions.index,
        loadChildren: () => import('app/transactions/transactions.module').then((m) => m.TransactionsModule),
      },
      {
        path: environment.routerLinks.vat.index,
        loadChildren: () => import('app/vat/vat.module').then((m) => m.VatModule),
      },
      {
        path: environment.routerLinks.contacts.index,
        loadChildren: () => import('app/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: environment.routerLinks.payments.index,
        loadChildren: () => import('app/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: environment.routerLinks.settings.index,
        loadChildren: () => import('app/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: environment.routerLinks.transferMoney.index,
        loadChildren: () => import('app/transfer-money/transfer-money.module').then((m) => m.TransferMoneyModule),
      },
      {
        path: environment.routerLinks.finance.index,
        loadChildren: () => import('app/finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: environment.routerLinks.banking.index,
        loadChildren: () => import('app/banking/banking.module').then((m) => m.BankingModule),
      },
      {
        path: environment.routerLinks.templating.index,
        loadChildren: () => import('app/templating/templating.module').then((m) => m.TemplatingModule),
      },
      {
        path: environment.routerLinks.sending.index,
        loadChildren: () => import('app/sending/sending.module').then((m) => m.SendingModule),
      },
      {
        path: environment.routerLinks.reporting.index,
        loadChildren: () => import('app/reporting/reporting.module').then((m) => m.ReportingModule),
      },
    ],
  },
  {
    path: environment.routerLinks.documents.index,
    loadChildren: () => import('app/documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: environment.routerLinks.emailVerification.index,
    loadChildren: () => import('app/email-verification/email-verification.module').then((m) => m.EmailVerificationModule),
  },
  {
    path: environment.routerLinks.login.index,
    loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: environment.routerLinks.register.index,
    loadChildren: () => import('app/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: environment.routerLinks.onboarding.index,
    loadChildren: () => import('app/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('app/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'test',
    loadChildren: () => import('app/test/test.module').then((m) => m.TestModule),
  },
  { path: '**', component: RedirectComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.isDevelopmentMachine,
      preloadingStrategy: NetworkAwarePreloadingStrategy,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
