import { TranslateLoader } from '@ngx-translate/core'
import { Observable, from } from 'rxjs'

/**
 * Loads our translations
 */
export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`))
  }
}
