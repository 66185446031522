export interface Plan {
  data: PlanData[]
  meta: PlanMeta
}

export enum PlanInterval {
  Yearly = 'yearly',
  Monthly = 'monthly',
}

export enum TellowPlans {
  Gratis = 'Gratis',
  Basis = 'Basis',
  Plus = 'Plus',
  Compleet = 'Compleet',
}

export type PlanFeature =
  | string
  | {
      title: string
      description?: string
      icon?: string
    }

export type PlanTiers = 0 | 1 | 2 | 3
export type PlanId =
  | 'free_plan_endless'
  | 'basis_monthly_tier1'
  | 'basis_yearly_tier1'
  | 'plus_monthly_tier2'
  | 'plus_yearly_tier2'
  | 'compleet_yearly_tier3'
  | 'free_plan_with_banking_endless'
  | 'basis_with_banking_monthly_tier1'
  | 'basis_with_banking_yearly_tier1'
  | 'plus_with_banking_monthly_tier2'
  | 'plus_with_banking_yearly_tier2'
  | 'compleet_with_banking_yearly_tier3'

export interface PlanData {
  name: TellowPlans
  tier: PlanTiers
  planId: PlanId
  lockUserToThisPlanForMonths: number
  stripePlanId: string
  stripePlanIdForBizzyUsers?: string
  appleProductId: string
  previousAppleProductIds?: string[]
  priceInCents: number
  applePriceInCents: number
  interval: PlanInterval
  features: PlanFeature[]
  comingAtMessage?: string
  isActive: boolean
}

export interface PlanMeta {
  trialExpireMessage: string
  mandateAuthorization: PlanMandate
}

export interface PlanMandate {
  title: string
  text: string
}

export type SubscriptionResponseType = string | 'free' | 'trial' | 'manual' | 'stripe' | 'apple'

export interface SubscriptionResponse {
  type: SubscriptionResponseType
  data: SubscriptionData
}

export interface CommissionPaymentLinkDTO {
  value: number
}

export interface Features {
  [key: string]: boolean | number | object
  canMakePhoneCalls: boolean
  canSubmitSalesTax: boolean
  canUseCompanyInfoApi: boolean
  canUseSalesInvoiceWithoutWatermark: boolean
  numberOfAllowedTransactionPerMonth: 25 | 0 // 0 equals no limit
  canMakeQuotes: boolean
  canAddUsers: boolean
  canAddAttachments: boolean
  canOrderPhysicalCard: boolean
  bankUpdateFrequency: 4 | 1
  numberOfAllowedFinalQuotes: 0 | 1
  numberOfAllowedBankAccounts: 999 | 5 | 1
  canAskFinancialAdvice: boolean
  canPersonalizeTemplate: boolean
  canUseSmartPay: boolean
  canUseSmartBoxEmail: boolean
  canUseStandardProducts: boolean
  canUseTaxBucket: boolean
  numberOfAllowedInvoicesPerMonth: 3 | 0
  commissionPaymentLink: {
    percentage: CommissionPaymentLinkDTO | null
    fixedRate: CommissionPaymentLinkDTO | null
  }
}

// Keep in mind some properties are (not)
// present depending on SubscriptionResponse.type
export interface SubscriptionData {
  address?:
    | {
        city: string
        line1: string
        line2: string
        postalCode: string
      }
    | undefined
  customerId?: string | undefined
  email?: string | undefined
  expiresAt: Date | undefined
  features: Features
  paymentMethod?: PaymentMethodObject | undefined
  name?: string | undefined
  subscriptionExempt?: boolean
  status?: string | 'trialActive' | 'trialExpired' | 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid'
  monthsTrial?: number
  trialEndsAt?: Date | undefined | null
  periodEndsAt?: Date | undefined
  yearlyPlanExtendsAt?: Date | undefined
  cancelledAt?: Date | undefined
  cancelAt?: Date | undefined
  plan: PlanData
  discount?: DiscountObject | undefined
  description?: string | undefined
  isExempt?: boolean
}

export type PaymentMethodObject = SEPADebitPaymentMethod | CreditCardPaymentMethod

export enum PaymentMethodType {
  SEPA_DEBIT = 'sepa_debit',
  CARD = 'card',
}

export interface SEPADebitPaymentMethod {
  type: PaymentMethodType.SEPA_DEBIT
  country: string
  bankCode: string
  lastFour: string
}

export interface CreditCardPaymentMethod {
  type: PaymentMethodType.CARD
  country: string
  brand: string
  lastFour: string
}

export interface DiscountObject {
  name: string
  amountInCents?: number
  amountPercentage?: number
  planPriceInCents: number
  duration: 'forever' | 'once' | 'repeating'
  durationMonths: number | null
  validUntil: string | null
}
