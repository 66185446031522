import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpHeaders, HttpResponse } from '@angular/common/http'
import { ApiGateway } from '../../remote/api.gateway'
import { TellowRequestOptionsArgs } from '../../remote/httpclient'
import { Observable } from 'rxjs'

@Injectable()
export class FileService {
  private fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

  constructor(private readonly _apiGateway: ApiGateway) {}

  fetchAsyncFileContent(observable: Observable<{}>, mime: string): Observable<Blob> {
    return observable.pipe(
      map(
        (response: HttpResponse<any>) => {
          const finalResponse = response.body ? response.body : response

          let blob = new Blob([finalResponse], {
            type: mime ? mime : 'application/zip',
          })

          blob = blob.slice(0, blob.size, mime)

          return blob
        },
        (err) => {
          console.info('Error getting file', err)
        },
      ),
    )
  }

  openAsyncFile(observable: Observable<{}>, mime: string) {
    return this.fetchAsyncFileContent(observable, mime).pipe(
      map((blob) => {
        this.safeOpenBlobAsUrl(blob, (url) => window.open(url))

        return true
      }),
    )
  }

  // public downloadUrl(url: string, mime_type: string): Observable<Blob> {
  //   const options = this.createFileOptions(mime_type);
  //   return this.fetchAsyncFileContent(this._apiGateway.get(url, options, true), mime_type);
  // }

  offerFileAsDownload(blob: Blob, name: string): void {
    // create a download anchor tag
    const downloadLink = document.createElement('a')
    downloadLink.download = name

    this.safeOpenBlobAsUrl(
      blob,
      (url) => {
        // set object URL as the anchor's href
        downloadLink.href = url

        // append the anchor to document body
        document.body.appendChild(downloadLink)

        // fire a click event on the anchor
        downloadLink.click()
      },

      // cleanup: remove element and revoke object URL
      () => document.body.removeChild(downloadLink),
    )
  }

  safeOpenBlobAsUrl(blob: Blob, safeAction: (url: string) => void, afterReleaseAction?: () => void): void {
    // create an object URL from the Blob
    const objectUrl = this.unSafeOpenBlobAsUrl(blob)

    safeAction(objectUrl)

    // Release after action performed
    setTimeout(() => {
      URL.revokeObjectURL(objectUrl)

      // Execute after release
      if (afterReleaseAction) {
        afterReleaseAction()
      }
    }, 1000)
  }

  unSafeOpenBlobAsUrl(blob: Blob): string {
    const URL = window.URL || (<any>window).webkitURL

    return URL.createObjectURL(blob)
  }

  revokeUnSafeBlobUrl(objectUrl: string) {
    URL.revokeObjectURL(objectUrl)
  }

  fileToBase64src(blob: Blob): Observable<string> {
    const reader = new FileReader()

    const obs = Observable.create((observer) => {
      reader.onload = () => {
        observer.next(reader.result)
        observer.complete()
      }
    })

    reader.readAsDataURL(blob)

    return obs
  }

  /**
   * @param filename Te original filename
   * @param extension The desired file extension
   * @returns the filename with new extesion
   */
  changeFileExtension(filename: string, extension: string): string {
    return filename.replace(/\.[0-9a-z]+$/i, '.' + extension)
  }

  /**
   * Convenience function to quickly create the right RequestOptions object for viewing or downloading files async.
   * @param mime
   * @param observe
   * @returns {TellowRequestOptionsArgs}
   */
  createFileOptions(mime: string, observe: boolean = true): TellowRequestOptionsArgs {
    const options: TellowRequestOptionsArgs = {}

    options.responseType = 'arraybuffer'
    options.headers = new HttpHeaders()

    if (mime) {
      options.headers = options.headers.append('Accept', mime)
    }

    if (observe) {
      options.observe = 'response'
    }

    return options
  }

  /**
   * Takes a blob and gives a new blob back with new mime-type
   * @param blob
   * @param mimeType
   * @returns {Blob}
   */
  changeBlobMimeType(blob: Blob, mimeType: string): Blob {
    return new Blob([blob], { type: mimeType })
  }

  getFilenameFromResponse(res: HttpResponse<any>): string {
    // eslint-disable-next-line no-useless-escape
    return this.fileNameRegex.exec(res.headers.get('content-disposition'))[1].replace(/\"/g, '')
  }
}
