import { combineLatest as observableCombineLatest, from as observableFrom, Observable } from 'rxjs'
import { groupBy, map, mergeMap, publishReplay, refCount, switchMap, tap, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { ApiGateway } from '../../core/remote/api.gateway'
import { ColumnBalanceGroup, ColumnBalanceRecord, ColumnBalanceSection, DebitCredit } from '../../domain/accounting/column-balance-record.model'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationService } from '../administration/administration.service'
import { Mutation } from '../../domain/accounting/mutation.model'
import { Journal, JournalEntry } from '../../domain/accounting/journal.model'
import { LoginService } from '../../core/security/login.service'
import { Administration } from '../../domain/administration/administration.model'
import { JournalTypes } from '../../domain/accounting/journal-types.constants'
import { StartBalanceAccount } from '../../domain/accounting/start-balance-account.model'
import { StartBalance } from '../../domain/administration/start-balance.model'
import { AccountTypes } from '../../domain/administration/account-types.constants'
import { CreateJournalEntry } from '../../domain/accounting/create-journal.model'
import { FiscalYear, FiscalYearWithYearIdentifier } from '../../domain/accounting/fiscal-year.model'
import { Transaction } from '../../domain/transaction/transaction.model'
import { AccountingSearchObject } from './accounting-search-object'
import { InvoiceListItem } from '../../domain/invoice/invoice-listitem.model'
import { FiscalYearStates } from '../../domain/accounting/fiscal-year.constants'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import printJS from 'print-js'
import { FncLoadingModalComponent } from '../../ui-components/fnc-modal/fnc-loading-modal/fnc-loading-modal.component'
import { ToastrHelper } from '../helpers/toastr.helper'
import { TranslateService } from '@ngx-translate/core'
import { BrowserService } from '../../core/logic/browser.service'

export interface GroupedBalancesResult {
  result: ColumnBalanceGroup[]
  original: ColumnBalanceGroup[]
}

@Injectable()
export class AccountingService {
  static GROUP_PASSIVE = 'Passiva'
  static GROUP_ACTIVE = 'Activa'
  static GROUP_REVENUE = 'Omzet'
  static GROUP_COSTS = 'Kosten'
  static GROUP_PROFIT_LOSS_RESULT = 'Verschil Omzet - Kosten'
  private _starBalanceAccountsObservable: Observable<StartBalanceAccount[]>
  private _startBalanceProfitLossAccountsObservable: Observable<StartBalanceAccount[]>
  private _fiscalYearsObservable: Observable<FiscalYear[]>
  private _minimumBookableDateObservable: Observable<moment.Moment>

  constructor(
    private readonly _administrationService: AdministrationService,
    private readonly _apiGateway: ApiGateway,
    private readonly _loginService: LoginService,
    private readonly _toastr: ToastrHelper,
    private readonly _translate: TranslateService,
  ) {
    this._loginService.onLoggedIn.subscribe(() => this.clearCache())
    this._administrationService.onSwitchedAdministration.subscribe(() => this.clearCache())
  }

  get journals(): Observable<Journal[]> {
    return this._administrationService.defaultAdministration.pipe(map((a) => a.journals))
  }

  get fiscalYears(): Observable<FiscalYear[] | FiscalYearWithYearIdentifier[]> {
    if (this._fiscalYearsObservable) {
      return this._fiscalYearsObservable
    }

    this._fiscalYearsObservable = this._administrationService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.get<FiscalYear[]>(endpoints.accounting.fiscalYears, p))
      .pipe(
        switchMap((x) => x),
        map<FiscalYear, FiscalYearWithYearIdentifier>((fy) => {
          const fywyi = <FiscalYearWithYearIdentifier>fy
          fywyi.year = moment(fy.date_start).year()

          return fywyi
        }),
        toArray(),
        map((a: FiscalYearWithYearIdentifier[]) => a.orderBy((fy) => fy.year * -1)),
        publishReplay(1),
        refCount(),
      )

    return this._fiscalYearsObservable
  }

  get minimumBookableDate(): Observable<moment.Moment> {
    if (this._minimumBookableDateObservable) {
      return this._minimumBookableDateObservable
    }

    let years: FiscalYear[]
    let settings: AdministrationSettings

    this._minimumBookableDateObservable = observableCombineLatest(
      this.fiscalYears.pipe(map((y) => (years = y))),
      this._administrationService.defaultAdministrationSettings.pipe(map((s) => (settings = s))),
    ).pipe(
      map(() => {
        const startDate = moment(settings.start_date)
        let maxYear: FiscalYear

        const closedYears = years.filter((y) => y.status == FiscalYearStates.CLOSED)

        if (closedYears && closedYears.length) {
          closedYears.forEach((y) => {
            if (!maxYear || moment(maxYear.date_end).isBefore(moment(y.date_end))) {
              maxYear = y
            }
          })
        }

        return (maxYear && moment(maxYear.date_end).add(1, 'day')) || startDate
      }),
    )

    return this._minimumBookableDateObservable
  }

  get currentFiscalYear(): Observable<FiscalYear> {
    const now = moment()

    return this.fiscalYears.pipe(map((years: FiscalYear[]) => years.find((y) => now.isBetween(moment(y.date_start), moment(y.date_end)))))
  }

  get startBalanceProfitLossAccounts(): Observable<StartBalanceAccount[]> {
    if (this._startBalanceProfitLossAccountsObservable) {
      return this._startBalanceProfitLossAccountsObservable
    }

    this._startBalanceProfitLossAccountsObservable = this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<StartBalanceAccount[]>(endpoints.accounting.startBalanceProfitLossAccounts, {
          administrationId: administration.id,
        }),
      ),
      publishReplay(1),
      refCount(),
    )

    return this._startBalanceProfitLossAccountsObservable
  }

  get startBalanceAccounts(): Observable<StartBalanceAccount[]> {
    if (this._starBalanceAccountsObservable) {
      return this._starBalanceAccountsObservable
    }

    this._starBalanceAccountsObservable = this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<StartBalanceAccount[]>(endpoints.accounting.startBalanceAccounts, {
          administrationId: administration.id,
        }),
      ),
      switchMap((x) => x),
      tap((a) => {
        a._roles = a.start_balance_role.split(',')
        a._roles = a._roles.map((r) => r.trim()) // Ronan RGS 3.0 fix;
      }),
      toArray(),
      map((list) => {
        // Make sure saldo account is the last entry in the list.
        const saldo = list.find((a) => a._roles.contains('Saldo'))

        list.remove(saldo)
        list.push(saldo)

        return list
      }),
      publishReplay(1),
      refCount(),
    )

    return this._starBalanceAccountsObservable
  }

  // public getGroupedBalances(start: string, end: string, balanceType?: string, params?: any): Observable<GroupedBalancesResult> {
  getGroupedBalances(searchObject: AccountingSearchObject): Observable<GroupedBalancesResult> {
    let original: ColumnBalanceRecord[]
    const result: GroupedBalancesResult = <any>{}
    let params: any

    if (searchObject) {
      params = searchObject.getParams()
    }

    const balanceType = searchObject.type

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        params.administrationId = a.id

        return this._apiGateway.get<ColumnBalanceRecord[]>(endpoints.accounting.columnBalance, params)
      }),
      map((r) => {
        original = r

        return balanceType ? this.filterEmptyRecords(r, balanceType) : r
      }),
      mergeMap((r) => {
        return observableCombineLatest(
          this.group(r).pipe(
            map((groups) => {
              this.sum(groups)

              return groups
            }),
            map((summed) => (result.result = summed)),
          ),
          this.group(original).pipe(
            map((groups) => {
              this.sum(groups)

              return groups
            }),
            map((summed) => (result.original = summed)),
          ),
        )
      }),
      map(() => result),
    )
  }

  /**
   * Download a file from
   * a created objectURL.
   */
  protected _download(url: string, filename: string): void {
    const anchorElement = document.createElement('a')
    anchorElement.href = url
    anchorElement.download = `${filename}.pdf`

    anchorElement.click()
    anchorElement.remove()
    URL.revokeObjectURL(url)
  }

  printPdfFromBalance(searchObject: AccountingSearchObject, title: string, loadingModalToClose: FncLoadingModalComponent, filename?: string): void {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    this._administrationService.defaultAdministration
      .pipe(
        mergeMap(({ id }) => this._apiGateway.get<Blob>(endpoints.accounting.columnBalance, { ...params, administrationId: id }, { responseType: 'blob' })),
        map((result) => URL.createObjectURL(result)),
      )
      .subscribe(
        (printable) => {
          /**
           * Safari has a bug with printing in version 14 and higher.
           * Download the PDF instead, so the user can print.
           */
          if (BrowserService.isSafari) {
            this._download(printable, filename)
          } else {
            printJS({
              printable,
              documentTitle: title,
              type: 'pdf',
            })
          }

          loadingModalToClose.close()
        },
        () => {
          loadingModalToClose.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  getBalances(searchObject: AccountingSearchObject): Observable<ColumnBalanceRecord[]> {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        params.administrationId = a.id

        return this._apiGateway.get<ColumnBalanceRecord[]>(endpoints.accounting.columnBalance, params)
      }),
    )
  }

  getMutations(searchObject: AccountingSearchObject): Observable<Mutation[]> {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    return this._administrationService.makeApiCallForDefaultAdministration((p) => this._apiGateway.get<Mutation[]>(endpoints.accounting.mutations, p), params)
  }

  printMutations(searchObject: AccountingSearchObject, title: string, loadingModalToClose: FncLoadingModalComponent, filename?: string): void {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    this._administrationService.defaultAdministration
      .pipe(
        mergeMap(({ id }) => this._apiGateway.get<Blob>(endpoints.accounting.mutations, { ...params, administrationId: id }, { responseType: 'blob' })),
        map((result) => URL.createObjectURL(result)),
      )
      .subscribe(
        (printable) => {
          /**
           * Safari has a bug with printing in version 14 and higher.
           * Download the PDF instead, so the user can print.
           */
          if (BrowserService.isSafari) {
            this._download(printable, filename)
          } else {
            printJS({
              printable,
              documentTitle: title,
              type: 'pdf',
            })
          }

          loadingModalToClose.close()
        },
        () => {
          loadingModalToClose.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  getProfitLossResult(groups: ColumnBalanceGroup[]): number {
    const debitsTotal = groups.sum((g) =>
      g.sections.sum((s) => s.balances.filter((b) => b.account.type == AccountTypes.PROFIT_LOSS).sum((b) => b.profit_loss.debit)),
    )
    const creditsTotal = groups.sum((g) =>
      g.sections.sum((s) => s.balances.filter((b) => b.account.type == AccountTypes.PROFIT_LOSS).sum((b) => b.profit_loss.credit)),
    )

    const result = creditsTotal - debitsTotal

    return Math.round(result * 100) / 100
  }

  getProfitGroup(groups: ColumnBalanceGroup[]): ColumnBalanceGroup {
    return groups.find((g) => g.group == AccountingService.GROUP_REVENUE)
  }

  getLossGroup(groups: ColumnBalanceGroup[]): ColumnBalanceGroup {
    return groups.find((g) => g.group == AccountingService.GROUP_COSTS)
  }

  getJournalEntryLines(journalId: number, searchObject: AccountingSearchObject): Observable<JournalEntry[]> {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        params.administrationId = a.id
        params.id = journalId

        return this._apiGateway.get<JournalEntry[]>(endpoints.accounting.journalEntryLines, params)
      }),
    )
  }

  printJournals(
    journalId: number,
    searchObject: AccountingSearchObject,
    title: string,
    loadingModalToClose: FncLoadingModalComponent,
    filename?: string,
  ): void {
    let params: any
    if (searchObject) {
      params = searchObject.getParams()
    }

    this._administrationService.defaultAdministration
      .pipe(
        mergeMap(({ id }) =>
          this._apiGateway.get<Blob>(endpoints.accounting.journalEntryLines, { ...params, id: journalId, administrationId: id }, { responseType: 'blob' }),
        ),
        map((result) => URL.createObjectURL(result)),
      )
      .subscribe(
        (printable) => {
          /**
           * Safari has a bug with printing in version 14 and higher.
           * Download the PDF instead, so the user can print.
           */
          if (BrowserService.isSafari) {
            this._download(printable, filename)
          } else {
            printJS({
              printable,
              documentTitle: title,
              type: 'pdf',
            })
          }

          loadingModalToClose.close()
        },
        () => {
          loadingModalToClose.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  closeFiscalYear(year: FiscalYear): Observable<any> {
    return this._administrationService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.post(endpoints.accounting.fiscalYears, undefined, p), { id: year.id, action: 'close' })
      .pipe(
        tap(() => {
          this._fiscalYearsObservable = null
          this._minimumBookableDateObservable = null
        }),
      )
  }

  openFiscalYear(year: FiscalYear): Observable<any> {
    return this._administrationService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.post(endpoints.accounting.fiscalYears, undefined, p), { id: year.id, action: 'open' })
      .pipe(
        tap(() => {
          this._fiscalYearsObservable = null
          this._minimumBookableDateObservable = null
        }),
      )
  }

  actionInFiscalYearAllowed(actionableDate: moment.Moment): Observable<boolean> {
    let years: FiscalYear[]
    let settings: AdministrationSettings

    return observableCombineLatest([
      this.fiscalYears.pipe(map((y) => (years = y))),
      this._administrationService.defaultAdministrationSettings.pipe(map((s) => (settings = s))),
    ]).pipe(
      map(() => {
        const startDate = moment(settings.start_date)

        if (actionableDate.isBefore(startDate)) {
          return false
        }

        // Subtract and Add a day to prevent 1/1 or 31/12 to fall outside the current year
        const invoiceYear = years.find((y) => actionableDate.isBetween(moment(y.date_start).subtract(1, 'day'), moment(y.date_end).add(1, 'day')))

        // Year does not exist yet.
        if (!invoiceYear) {
          return true
        }

        return invoiceYear.status == FiscalYearStates.OPEN || invoiceYear.status == FiscalYearStates.FUTURE
      }),
    )
  }

  getGeneralJournal(): Observable<Journal> {
    return this.journals.pipe(map((journals) => journals.find((j) => j.type == JournalTypes.GENERAL)))
  }

  getGeneralJournalEntries(searchObject?: AccountingSearchObject): Observable<JournalEntry[]> {
    return this.journals.pipe(
      map((journals) => journals.find((j) => j.type == JournalTypes.GENERAL)),
      mergeMap((journal) => {
        const params = searchObject ? searchObject.getParams() : {}
        params.journalId = journal.id

        return this._administrationService.makeApiCallForDefaultAdministration(
          (p) => this._apiGateway.get<JournalEntry[]>(endpoints.accounting.journalEntry, p),
          params,
        )
      }),
    )
  }

  getEditableGeneralJournalEntry(journalEntryId: number): Observable<CreateJournalEntry> {
    return this.getGeneralJournal().pipe(
      mergeMap((journal) =>
        this._administrationService.makeApiCallForDefaultAdministration<JournalEntry>(
          (p) => this._apiGateway.get(endpoints.accounting.journalEntryDetails, p),
          { journalId: journal.id, id: journalEntryId },
        ),
      ),
      map((entry: JournalEntry) => {
        const result: CreateJournalEntry = <any>{}

        result.id = entry.id
        result.description = entry.description
        result.booking_date = entry.booking_date
        result.invoice_id = entry.invoice_id
        result.deletable = entry.deletable
        result.updatable = entry.updatable

        result.journal_entry_lines = entry.journal_entry_lines.map((jel) => {
          return {
            description: jel.description,
            debit_amount: jel.debit_amount,
            credit_amount: jel.credit_amount,
            account_id: (<any>jel).account_id,
            vat_rate_id: jel.vat_rate && jel.vat_rate.id,
          }
        })

        return result
      }),
    )
  }

  saveGeneralJournalEntry(generalEntry: CreateJournalEntry): Observable<JournalEntry> {
    let administration: Administration

    generalEntry.journal_entry_lines.filter((jel) => jel.vat_rate_id === null).forEach((jel) => delete jel.vat_rate_id)

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        administration = a

        return this.getGeneralJournal()
      }),
      mergeMap((journal) =>
        this._apiGateway.post<JournalEntry>(endpoints.accounting.journalEntry, generalEntry, {
          administrationId: administration.id,
          journalId: journal.id,
        }),
      ),
    )
  }

  updateGeneralJournalEntry(generalEntry: CreateJournalEntry): Observable<JournalEntry> {
    return this.getGeneralJournal().pipe(
      mergeMap((journal) =>
        this._administrationService.makeApiCallForDefaultAdministration(
          (p, o) => this._apiGateway.put<JournalEntry>(endpoints.accounting.journalEntry, o, p),
          { journalId: journal.id, journalEntryId: generalEntry.id },
          generalEntry,
        ),
      ),
    )
  }

  // public removeGeneralJournalEntry(entry: JournalEntry): Observable<void> {
  removeGeneralJournalEntry(id: number): Observable<void> {
    return this.getGeneralJournal().pipe(
      mergeMap((journal) =>
        this._administrationService.makeApiCallForDefaultAdministration((p) => this._apiGateway.delete(endpoints.accounting.journalEntry, p), {
          journalId: journal.id,
          journalEntryId: id,
        }),
      ),
    )
  }

  submitStartBalance(startBalance: StartBalance, previousBalanceFound: boolean): Observable<any> {
    let administration: Administration

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        administration = a

        return this._administrationService.defaultAdministrationSettings
      }),
      mergeMap((settings) => {
        if (settings.start_balance_filled || previousBalanceFound) {
          return this._apiGateway.put(endpoints.administration.startBalance, startBalance, {
            administrationId: administration.id,
          })
        }

        return this._apiGateway.post(endpoints.administration.startBalance, startBalance, {
          administrationId: administration.id,
        })
      }),
    )
  }

  getPreviousStartBalance(): Observable<StartBalance> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<StartBalance>(endpoints.administration.startBalance, {
          administrationId: administration.id,
        }),
      ),
    )
  }

  clearFiscalYearCache() {
    this._fiscalYearsObservable = null
  }

  clearStartBalanceAccounts() {
    this._starBalanceAccountsObservable = null
  }

  clearCache() {
    this._starBalanceAccountsObservable = null
    this._startBalanceProfitLossAccountsObservable = null
    this._fiscalYearsObservable = null
    this._minimumBookableDateObservable = null
  }

  getReconcilableInvoicesForTransaction(tx: Transaction): Observable<InvoiceListItem[]> {
    return this._administrationService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.get<InvoiceListItem[]>(endpoints.accounting.txReconcilableInvoices, p), {
        transactionId: tx.id,
      })
      .pipe(map((invoices) => invoices.orderBy((i) => moment(i.date))))
  }

  private filterEmptyRecords(balance: ColumnBalanceRecord[], balanceType: string) {
    return balance.filter((b) => {
      const db = <DebitCredit>b[balanceType]

      return db.credit || db.debit
    })
  }

  private group(balance: ColumnBalanceRecord[]): Observable<ColumnBalanceGroup[]> {
    // - groups: ColumnBalanceGroup[]
    //    - sections: ColumnBalanceSection[]
    //        - balances: ColumnBalanceRecord[]

    return observableFrom(balance).pipe(
      groupBy((r) => r.grouping.group),
      mergeMap((group) =>
        group.pipe(
          toArray(),
          switchMap((x) => x),
          groupBy((i) => i.grouping.section),
          mergeMap((subGroup) =>
            subGroup.pipe(
              toArray(),
              map((si) => <ColumnBalanceSection>{ section: subGroup.key, balances: si }),
            ),
          ),
          toArray(),
          map((sectionsByGroup) => <ColumnBalanceGroup>{ group: group.key, sections: sectionsByGroup }),
        ),
      ),
      toArray(),
    )
  }

  private sum(groups: ColumnBalanceGroup[]) {
    for (let i = 0; i < groups.length; i++) {
      groups[i].totals = {
        balance: {
          credit: groups[i].sections.sum((s) => s.balances.sum((b) => b.balance.credit)),
          debit: groups[i].sections.sum((s) => s.balances.sum((b) => b.balance.debit)),
        },
        profit_loss: {
          credit: groups[i].sections.sum((s) => s.balances.sum((b) => b.profit_loss.credit)),
          debit: groups[i].sections.sum((s) => s.balances.sum((b) => b.profit_loss.debit)),
        },
        trial: {
          credit: groups[i].sections.sum((s) => s.balances.sum((b) => b.trial.credit)),
          debit: groups[i].sections.sum((s) => s.balances.sum((b) => b.trial.debit)),
        },
        saldi: {
          credit: groups[i].sections.sum((s) => s.balances.sum((b) => b.saldi.credit)),
          debit: groups[i].sections.sum((s) => s.balances.sum((b) => b.saldi.debit)),
        },
      }
    }
  }
}
