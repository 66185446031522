import { map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable, of as observableOf } from 'rxjs'
import { ApiGateway } from '../../core/remote/api.gateway'
import { Transaction } from '../../domain/transaction/transaction.model'
import { endpoints } from '../../shared/config/endpoints'
import { TransactionListItem } from '../../domain/transaction/transaction-listitem.model'
import { AdministrationService } from '../administration/administration.service'
import { BankAccountService } from '../administration/bankaccounts.service'
import { TransactionSearchObject } from './transaction-search-object'
import { JustificationTypes } from '../../domain/justification/justificationtypes.constants'
import { SuppletionService } from '../vat/suppletion.service'
import { AdministrationBankAccount } from '../../domain/bankAccount/administration-bank-account.model'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'

@Injectable()
export class TransactionService {
  constructor(
    private readonly _administration: AdministrationService,
    private readonly _api: ApiGateway,
    private readonly _bankAccounts: BankAccountService,
    private readonly _suppletionService: SuppletionService,
    private readonly _list: ListHelper,
  ) {}

  getTransactions(searchObject: TransactionSearchObject): Observable<TransactionListItem[]> {
    return this._bankAccounts.defaultAdministrationCheckingsAccount.pipe(
      mergeMap((account: AdministrationBankAccount) => {
        if (!searchObject.bankAccountId && account) {
          searchObject.bankAccountId = account.id
        }

        if (!account) {
          const emptyList: TransactionListItem[] = []

          return observableOf(emptyList)
        }
        {
          const params = searchObject.getParameters()

          return this._administration.makeApiCallForDefaultAdministration<TransactionListItem[]>(
            (p) => this._api.get(endpoints.transactions.transactions, p),
            params,
          )
        }
      }),
    )
  }

  getTransaction(id: number): Observable<Transaction> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
          id: id,
        }

        return this._api.get<Transaction>(endpoints.transactions.transaction, params)
      }),
      mergeMap((tx: TransactionListItem) =>
        tx.justifications && tx.justifications.length
          ? this._suppletionService
              .augmentSuppletions(tx.justifications.filter((j) => j.type == JustificationTypes.SUPPLETION).map((j) => j.suppletion))
              .pipe(map(() => tx))
          : observableOf(tx),
      ),
    )
  }

  updateMasterList(list: GroupedMonthlyState<TransactionListItem[]> | TransactionListItem[], tx: Transaction): void {
    if (!list || !tx) {
      return
    }

    // TODO: Remove array logic when recon is also migrated to new list view.
    const listTx = Array.isArray(list) ? list.find((t) => t.id == tx.id) : this._list.findInStateObject(list, tx)

    if (!listTx) {
      return
    }

    listTx.justified_amount = tx.justified_amount
    listTx.fully_justified = tx.fully_justified
    listTx.status = tx.status
    listTx.justification_in_progress = tx.justification_in_progress
  }

  /**
   * Gets the administrationId and performs a delete request for the given transaction
   * @param transactionId
   */
  deleteTransaction(transactionId: number): Observable<{}> {
    if (!transactionId) {
      return
    }

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = {
          administrationId: administration.id,
          transactionId: transactionId,
        }

        return this._api.delete(endpoints.transactions.remove, params)
      }),
    )
  }

  /**
   * For getting transactions including the all bank accounts
   */
  getTransactionsForAllBankAccounts(searchObject: TransactionSearchObject): Observable<TransactionListItem[]> {
    const params = searchObject.getParameters()

    return this._administration.makeApiCallForDefaultAdministration<TransactionListItem[]>(
      (p) => this._api.get(endpoints.transactions.allTransactions, p),
      params,
    )
  }
}
