export type KlippaLedgerOption = 'Support' | 'Other' | 'Representation' | 'Office' | 'Phone' | 'Travel'

export interface Icon {
  name: string
  number: string
  code: string
  icon: string

  klippaLedger?: KlippaLedgerOption
}

/**
 * Contains both:
 * - Icon mapping
 * - Klippa ledger mapping
 */
export const AccountIconMapping: Icon[] = [
  {
    name: 'Algemene kosten',
    number: '4215010',
    code: 'WBedAlkOal',
    icon: 'wallet',
    klippaLedger: 'Other',
  },
  {
    name: 'Autokosten',
    number: '4204200',
    code: 'WBedAutOak',
    icon: 'car',
  },
  {
    name: 'Kantoorkosten',
    number: '4206010',
    code: 'WBedKanKan',
    icon: 'office',
    klippaLedger: 'Office',
  },
  {
    name: 'Representatiekosten',
    number: '4203060',
    code: 'WBedVkkRep',
    icon: 'coffee',
    klippaLedger: 'Representation',
  },
  {
    name: 'Rente en kosten bank',
    number: '8406050',
    code: 'WFbeOrlRkb',
    icon: 'piggy',
  },
  {
    name: 'Reis- en verblijfkosten',
    number: '4203070',
    code: 'WBedVkkRev',
    icon: 'plane',
    klippaLedger: 'Travel',
  },
  {
    name: 'Telefoon- en internetkosten',
    number: '4206030',
    code: 'WBedKanTef',
    icon: 'mobilephone',
    klippaLedger: 'Phone',
  },
  {
    name: 'Verzekeringspremies',
    number: '4208020',
    code: 'WBedAssOva',
    icon: 'insurance',
  },
  {
    name: 'Kosten grond- en hulpstoffen',
    number: '7001010',
    code: 'WKprKvgKvg',
    icon: 'block',
    klippaLedger: 'Support',
  },
  {
    name: 'Huur',
    number: '4201040',
    code: 'WBedHuiBeh',
    icon: 'rent',
  },
  {
    name: 'Verkoopkosten',
    number: '4203220',
    code: 'WBedVkkOvr',
    icon: 'invoice',
  },
  {
    name: 'Energie en water',
    number: '4201105',
    code: 'WBedHuiGwe',
    icon: 'power',
  },
  {
    name: 'Contributies en abonnementen',
    number: '4206070',
    code: 'WBedKanCea',
    icon: 'weights',
  },
  {
    name: 'Advieskosten',
    number: '4209080',
    code: 'WBedAeaAdv',
    icon: 'support',
  },
  {
    name: 'Werkkleding',
    number: '4004170',
    code: 'WBedAlkOal',
    icon: 'shirt',
  },
  {
    name: 'Personeelskosten',
    number: '4012190',
    code: 'WBedOvpOvp',
    icon: 'personel',
  },
  {
    name: 'Opleidingskosten',
    number: '4006050',
    code: 'WBedAlkOal',
    icon: 'book',
  },
  {
    name: 'Kosten uitbesteed werk',
    number: '7003010',
    code: 'WKprKuwKuw',
    icon: 'human-resource',
  },
  {
    name: 'Rente leningen (te betalen)',
    number: '8404050',
    code: 'WFbeRlsRle',
    icon: 'out',
  },
  {
    name: 'Rente leningen (te ontvangen)',
    number: '8403020',
    code: 'WFbeRlmAre',
    icon: 'in',
  },
  {
    name: 'Pensioenkosten',
    number: '4003010',
    code: 'WPerPenPen',
    icon: 'retirement',
  },

  // Balance
  {
    name: 'Netto lonen',
    number: '1204010',
    code: 'BSchSalNet',
    icon: 'payment',
  },
  {
    name: 'Financiering 1',
    number: '0805020',
    code: 'BLasSakFvl',
    icon: 'currency',
  },
  {
    name: 'Lening (te betalen) 1',
    number: '0805030',
    code: 'BLasSakLvl',
    icon: 'out',
  },
  {
    name: 'Lening (te ontvangen) 1',
    number: '1103005',
    code: 'BVorOvrLvb',
    icon: 'in',
  },
  {
    name: 'Loonbelasting',
    number: '1206010',
    code: 'BSchBepLhe',
    icon: 'decrease',
  },
  {
    name: 'Pensioenpremies',
    number: '1204090',
    code: 'BSchStzPen',
    icon: 'retirement',
  },
]
