export function initArrayPrototype() {
  Array.prototype.contains = function (v) {
    for (let i = 0; i < this.length; i++) {
      if (this[i] === v) {
        return true
      }
    }

    return false
  }

  Array.prototype.unique = function () {
    const arr = []
    for (let i = 0; i < this.length; i++) {
      if (!arr.contains(this[i])) {
        arr.push(this[i])
      }
    }

    return arr
  }

  Array.prototype.distinct = function (filter?: (x: any) => any) {
    if (!filter) {
      return this.unique()
    }

    const result = []
    const found = []

    for (let i = 0; i < this.length; i++) {
      const val = filter(this[i])

      if (found.contains(val)) {
        continue
      }

      found.push(val)
      result.push(this[i])
    }

    return result
  }

  Array.prototype.firstOrUndefined = function (filter?: (x: any) => boolean): any {
    if (!filter) {
      return this && this.length ? this[0] : null
    }

    return this.filter(filter)[0]
  }

  Array.prototype.lastOrUndefined = function (filter?: (x: any) => boolean): any {
    if (!filter) {
      return this && this.length ? this[this.length - 1] : null
    }

    const filtered = this.filter(filter)

    return filtered && filtered.length ? filtered[filtered.length - 1] : null
  }

  Array.prototype.any = function (filter: (x: any) => boolean): boolean {
    return this.filter(filter).length > 0
  }

  Array.prototype.remove = function (x: any) {
    const key = this.indexOf(x)
    this.splice(key, 1)

    return this
  }

  Array.prototype.removeById = function (x: { id: number | string }) {
    if ('id' in x === false) {
      console.warn('Array.prototype.removeIndex wrongfully utilized.')
    }

    const key = this.findIndex((item: { id: number | string }) => item.id === x.id)
    this.splice(key, 1)

    return this
  }

  Array.prototype.sum = function (prop: (entry: any) => number): number {
    let result = 0

    this.forEach((e) => {
      let num = prop(e)

      if (typeof num != 'number' || isNaN(num)) {
        num = 0
      }

      result += num
    })

    return result
  }

  Array.prototype.orderBy = function (prop: (entry: any) => any) {
    return this.sort((a, b) => (prop(a) > prop(b) ? 1 : prop(b) > prop(a) ? -1 : 0))
  }

  Array.prototype.orderByDesc = function (prop: (entry: any) => any) {
    return this.sort((a, b) => (prop(a) > prop(b) ? -1 : prop(b) > prop(a) ? 1 : 0))
  }

  Array.toArray = function (input: any): any[] {
    return Array.from(input)
  }
}
