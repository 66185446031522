import { combineLatest as observableCombineLatest } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import moment from 'moment'
import { globals } from '../../shared/config/globals'
import { FncDatepickerComponent } from '../fnc-datepicker/fnc-datepicker.component'
import { FncPeriodSelectorComponent } from '../fnc-period-selector/fnc-period-selector.component'
import { SelectablePeriod } from '../../domain/administration/time/selectable-period'
import { FilterService } from '../../logic/filters/filter.service'
import { ContactsService } from '../../logic/administration/contacts.service'
import { Contact } from '../../domain/administration/contact.model'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'

@Component({
  selector: 'fnc-date-range-selector',
  styleUrls: ['./fnc-date-range-selector.component.scss'],
  template: `
    <fnc-input-container #contactSelect modern fixedLabel [hidden]="!showContactFilter">
      <fnc-select modern>
        <select [(ngModel)]="selectedContact" name="contact" [placeholder]="'INVOICES.CHOOSE_CUSTOMER' | translate">
          <option *ngFor="let contact of contacts" [value]="contact.id">
            {{ contact.company ? contact.company.name : contact.person.first_name + ' ' + contact.person.last_name }}
          </option>
        </select>
      </fnc-select>
      <label fixed>{{ 'INVOICES.CUSTOMER' | translate }}</label>
    </fnc-input-container>
    <fnc-input-container modern>
      <fnc-datepicker #startPicker [ngModel]="startPickerDate" (ngModelChange)="updateFromStartDatePicker($event)"></fnc-datepicker>
      <label fixed>{{ 'COMPONENTS.DATE_RANGE_SELECTOR.FROM_DATE' | translate }}</label>
    </fnc-input-container>
    <fnc-input-container modern>
      <fnc-datepicker #endPicker [ngModel]="endPickerDate" (ngModelChange)="updateFromEndDatePicker($event)"></fnc-datepicker>
      <label fixed>{{ 'COMPONENTS.DATE_RANGE_SELECTOR.TILL_DATE' | translate }}</label>
    </fnc-input-container>
    <div class="period-selector-with-label">
      <label>{{ 'COMPONENTS.DATE_RANGE_SELECTOR.PERIOD' | translate }}</label>
      <fnc-period-selector #period (onOpened)="endPicker.close(); startPicker.close()" (onSelected)="periodSelected($event)"></fnc-period-selector>
    </div>
    <div class="reset-container" [class.disabled]="!showResetButton()">
      <svg xmlns="http://www.w3.org/2000/svg" (click)="reset()" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </div>
  `,
})
export class FncDateRangeSelectorComponent implements OnInit {
  opened: boolean
  contacts: Contact[]

  // private _startDate: moment.Moment;
  @Input() hasAmountFilter: boolean // Screen also has amount filter. if amount is selected then this should be opened
  @Input() startOpened: boolean

  @Output() contactChanged = new EventEmitter<number>()
  @Output() startDateChanged = new EventEmitter<moment.Moment>()
  @Output() endDateChanged = new EventEmitter<moment.Moment>()

  @HostBinding('style.--data-row-count') rows = 3
  @Input() showContactFilter: boolean = false

  @Output() periodChanged = new EventEmitter<void>()

  @ViewChild('contactSelect', { static: true }) contactSelect: FncInputContainerComponent
  @ViewChild('startPicker', { static: true }) startPicker: FncDatepickerComponent
  @ViewChild('endPicker', { static: true }) endPicker: FncDatepickerComponent
  @ViewChild('period', { static: true }) periodSelector: FncPeriodSelectorComponent

  startPickerDate = moment().format(globals.dateFormat)
  endPickerDate = moment().format(globals.dateFormat)

  private _openedTimeout: any
  // private _endDate: moment.Moment;
  private _startInitialized: boolean
  private _endInitialized: boolean
  private _selectedContact: number

  constructor(private readonly _el: ElementRef, private readonly _filterService: FilterService, private readonly _contactsService: ContactsService) {}

  get selectedContact(): number {
    return this._selectedContact
  }

  set selectedContact(id: number) {
    this._selectedContact = id

    if (id) {
      this._filterService.setSelectedContact(id)
      this.contactChanged.emit(id)
    } else {
      this._filterService.setSelectedContact(null)
    }
  }

  get startDate(): moment.Moment {
    return this._filterService.startDate
    // return this._startDate;
  }

  @Input()
  set startDate(startDate: moment.Moment) {
    // this._startDate = startDate;
    this._filterService.setStartDate(startDate)
    this.startPickerDate = (startDate ?? moment()).format(globals.dateFormat)
  }

  get endDate(): moment.Moment {
    return this._filterService.endDate
    // return this._endDate;
  }

  @Input()
  set endDate(endDate: moment.Moment) {
    // this._endDate = endDate;
    this._filterService.setEndDate(endDate)
    this.endPickerDate = endDate.format(globals.dateFormat)
  }

  get displayDateFormat() {
    return globals.displayDateFormat.toLowerCase()
  }

  ngOnInit() {
    if (this._filterService.hasDateFilters()) {
      this.startDate = this._filterService.startDate
      this.endDate = this._filterService.endDate
      this.open()
    } else if ((this.hasAmountFilter && this._filterService.showFilters()) || this.startOpened) {
      this.open()
    }

    if (this.showContactFilter) {
      this.rows = 4

      this._contactsService.getContacts().subscribe((contacts) => {
        this.contacts = contacts
      })
    }

    // Catch and combine start/end date changes and re-emit as period changes.
    observableCombineLatest([this.startDateChanged, this.endDateChanged])
      .pipe(debounceTime(1))
      .subscribe(() => this.periodChanged.emit())
  }

  open() {
    clearTimeout(this._openedTimeout)
    this._el.nativeElement.classList.add('open')
    this.opened = true

    this._openedTimeout = setTimeout(() => this._el.nativeElement.classList.add('opened'), globals.animations.speed.default)
  }

  close() {
    clearTimeout(this._openedTimeout)
    this._el.nativeElement.classList.remove('opened')
    this._el.nativeElement.classList.remove('open')
    this.opened = false
  }

  showResetButton() {
    return (this.startDate && this.endDate) || this.periodSelector.selectedPeriod || this.selectedContact
  }

  toggle(focus?: string) {
    if (this.opened) {
      this.close()
    } else {
      this.open()

      if (focus === 'customer') {
        setTimeout(() => this.contactSelect.select.toggleActive(new Event('')), globals.animations.speed.default + 10)
      }
    }
  }

  updateFromStartDatePicker(date: string) {
    if (!this._startInitialized) {
      this._startInitialized = true
    }

    this.periodSelector.selectedPeriod = null

    if (date) {
      // this._startDate = moment(date);
      this._filterService.setStartDate(moment(date))
      this.startDateChanged.emit(this.startDate)
    } else {
      // this._startDate = null;
      this._filterService.setStartDate(null)
    }
  }

  updateFromEndDatePicker(date: string) {
    if (!this._endInitialized) {
      this._endInitialized = true
    }

    this.periodSelector.selectedPeriod = null

    if (date) {
      // this._endDate = moment(date);
      this._filterService.setEndDate(moment(date))
      this.endDateChanged.emit(this.endDate)
    } else {
      // this._endDate = null;
      this._filterService.setEndDate(null)
    }
  }

  reset() {
    this._filterService.resetFilters()
    this.periodSelector.selectedPeriod = null
    this.selectedContact = null

    // Trigger
    this.startDateChanged.emit(this.startDate)
    this.endDateChanged.emit(this.endDate)
    this.contactChanged.emit(this.selectedContact)
  }

  periodSelected(period: SelectablePeriod) {
    this._startInitialized = false
    this._endInitialized = false

    // this._startDate = period.startDate;
    this._filterService.setStartDate(period.startDate)
    this.startDateChanged.emit(this.startDate)
    this.startPickerDate = period.startDate.format(globals.dateFormat)

    // this._endDate = period.endDate;
    this._filterService.setEndDate(period.endDate)
    this.endDateChanged.emit(this.endDate)
    this.endPickerDate = period.endDate.format(globals.dateFormat)

    this._filterService.setPeriod(period)
  }
}
