import { filter, map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs'
import { NavigationEnd, Router } from '@angular/router'
import { ApiGateway } from '../../core/remote/api.gateway'
import { DocumentListItem } from '../../domain/document/document-listitem.model'
import { endpoints } from '../../shared/config/endpoints'
import { InfiniteScrollSearchObject } from '../../core/logic/infinite-scroll.searchobject'
import { FileService } from '../../core/logic/files/file.service'
import { Document } from '../../domain/document/document.model'
import { AdministrationService } from '../administration/administration.service'
import { UploadDocument } from '../../domain/document/upload-document.model'
import { JustificationDocument } from '../../domain/justification/justification-document.model'
import { PdfService } from './pdf.service'
import { globals } from '../../shared/config/globals'
import { environment } from '../../../environments/environment'
import { DocumentTypes } from '../../domain/document/document-types.constants'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { PaginatedResponse } from '../../domain/helpers/pagination.model'
import { TellowContext } from '../../domain/tellow/tellow-context'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'
import { OnboardingModalSources } from '../../ui-components/tlw-onboarding-modal/tlw-onboarding-modal.component'

export type DocumentType = Document | JustificationDocument

export interface DownloadedBlobWithImage {
  blob: Blob
  image: string
  pages?: number
}

@Injectable()
export class DocumentService {
  // public allowedTypes = ['pdf', 'jpeg', 'jpg', 'tiff', 'png'];
  allowedTypes = ['pdf', 'jpeg', 'jpg', 'png']

  rerouteUrl: string
  tmpFileStore: File[]

  /**
   * Returns a random string of characters for cachebust purposes
   * @param length
   */
  static getCacheBustString(length: number = 10): string {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      charactersLength = characters.length

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  constructor(
    private readonly _apiGateway: ApiGateway,
    private readonly _administrationService: AdministrationService,
    private readonly _fileService: FileService,
    private readonly _pdfService: PdfService,
    private readonly _router: Router,
    private readonly _translateRoute: TranslateRouteService,
    private readonly _list: ListHelper,
  ) {
    const documentDetailsUrl = environment.routerLinks.documents.index + '/'

    // Clear tmpFileStore when it is no longer of use
    this._router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((evt: NavigationEnd) => {
      if (!evt.url.contains(documentDetailsUrl)) {
        this.tmpFileStore = null
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigateToNewDocumentScreen(requestedUrl?: string, params?: any, back?: any): void {
    void this._translateRoute.navigate('/documents/addDocument', params)
  }

  /**
   * Method to immediately navigate to purchase annotation,
   * this was instated to bypass the selection of either
   * purchase or sales after uploading a receipt.
   *
   * This method adds the 'step=PURCHASE' query parameter.
   *
   * Largely the same as 'navigateToNewDocumentScreen' (above).
   */
  navigateToPurchaseAnnotation(context?: TellowContext): void {
    this._administrationService.defaultAdministrationSettings.subscribe((settings) => {
      if (this.hasMissingAnswers(settings)) {
        const { index, addDocument } = environment.routerLinks.documents
        const callback = `/${index}/${addDocument}`

        void this._administrationService.openOnboardingModal(OnboardingModalSources.Document, callback)
      } else {
        void this._translateRoute.navigateWithQueryParam('/documents/addDocument', context, { step: 'PURCHASE' })
      }
    })
  }

  /**
   * DRY method to check if all required fields for
   * document processing are present.
   *
   * @param {AdministrationSettings} settings for default administration.
   */
  private hasMissingAnswers(settings: AdministrationSettings): boolean {
    return settings.missing_context_answers.contains('document')
  }

  getDocuments<T = void>(searchObject?: DocumentSearchObject): Observable<PaginatedResponse<T>> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._apiGateway.get<PaginatedResponse<T>>(endpoints.documents.documentList, params)
      }),
    )
  }

  getDocument(id: number): Observable<Document> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<Document>(endpoints.documents.documents, {
          administrationId: administration.id,
          documentId: id,
          cachebust: DocumentService.getCacheBustString(),
        }),
      ),
    )
  }

  updateDocumentDescription(documentId: number, description: string): Observable<any> {
    return this._administrationService.makeApiCallForDefaultAdministration(
      (p) => this._apiGateway.patch(endpoints.documents.documents, { description: description }, ['description'], p),
      { documentId: documentId },
    )
  }

  deleteDocument(document: Document): Observable<void> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.delete(endpoints.documents.documents, {
          administrationId: administration.id,
          documentId: document.id,
        }),
      ),
    )
  }

  removeFromOverviewList(list: GroupedMonthlyState<DocumentListItem[]>, document: Document): void {
    if (!list || !document) {
      return
    }

    const listDocument = this._list.findInStateObject(list, document)

    if (listDocument) {
      this._list.removeFromStateObject<DocumentListItem>(list, document)
    }
  }

  uploadDocument(document: UploadDocument): Observable<Document> {
    const data = <any>{ json: document.json, file: document.file }

    if (data.json.type && data.json.type != DocumentTypes.ATTACHMENT && data.json.type != DocumentTypes.SOURCE) {
      data.json.type = 'SOURCE'
    }

    data.json.file_name = document.file.name

    if (document.original) {
      data.original = document.original
      data.json.original_file_name = document.original.name
    }

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) => this._apiGateway.postMultipart(endpoints.documents.documents, data, { administrationId: administration.id })),
    )
  }

  filterAllowedTypes(list: File[]): File[] {
    return list.filter((f) => {
      const typeInfo = f.type.split('/')

      return typeInfo && typeInfo.length > 0 && typeInfo[1] && this.allowedTypes.contains(typeInfo[1].toLowerCase())
    })
  }

  downloadImageOrPdf(document: DocumentType, original?: boolean, page?: number): Observable<DownloadedBlobWithImage> {
    if (document.mime_type == 'application/pdf') {
      const result: DownloadedBlobWithImage = {} as any

      return this.downloadDocument(document.id, document.mime_type, original).pipe(
        mergeMap((blob) => {
          result.blob = blob

          return this._pdfService.pdfToImage(blob, 2, page)
        }),
        map((response) => ({ ...result, ...response })),
      )
    }

    return this.downloadImageToBlobAndImage(document.id, document.mime_type)
  }

  private downloadImageToBlobAndImage(id: number, mime: string): Observable<DownloadedBlobWithImage> {
    const result: DownloadedBlobWithImage = <any>{}

    return this.downloadDocument(id, mime).pipe(
      mergeMap((blob) => {
        result.blob = blob

        return this._fileService.fileToBase64src(blob)
      }),
      map((src) => {
        result.image = src

        return result
      }),
    )
  }

  private downloadDocument(id: number, mime: string, original?: boolean): Observable<Blob> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) => {
        const options = this._fileService.createFileOptions(mime)
        const params = <any>{ documentId: id, administrationId: administration.id }

        if (original) {
          params.original = original
        }

        return this._fileService.fetchAsyncFileContent(this._apiGateway.get(endpoints.documents.download, params, options, true), mime)
      }),
    )
  }

  /**
   * Public method to download a PDF
   * from an arraybuffer when returned
   * in this format from the backend.
   */
  public downloadPdf(buffer: ArrayBuffer, fileType: string = 'application/pdf', fileName: string = 'download.pdf'): void {
    const file: Blob = new Blob([buffer], { type: fileType })
    const url: string = window.URL.createObjectURL(file)

    // Create anchor
    const anchorElement: HTMLAnchorElement = document.createElement('a')

    // Set attributes
    anchorElement.download = fileName
    anchorElement.href = url

    // 'Click' the anchor
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  }
}

export class DocumentSearchObject extends InfiniteScrollSearchObject {
  status: string
  startDate?: moment.Moment
  endDate?: moment.Moment

  constructor() {
    super()
    this.addExtraParameters = (params) => this.addParams(params)
  }

  private addParams(params: any): void {
    if (this.status) {
      params.status = this.status
    }

    if (this.startDate) {
      params.startDate = this.startDate.format(globals.dateFormat)
    }

    if (this.endDate) {
      params.endDate = this.endDate.format(globals.dateFormat)
    }

    params.type = 'SOURCE'
    params.sort = 'date'
    params.direction = 'desc'
  }
}
