import { mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from '../administration/administration.service'
import { endpoints } from '../../shared/config/endpoints'
import { Expense } from '../../domain/expenses/expense.model'
import { ExpenseTypes } from '../../domain/expenses/expensetypes.constants'
import { ExpenseSearchObject } from './expense-search-object'
import { Invoice } from '../../domain/invoice/invoice.model'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'

@Injectable()
export class ExpenseService {
  constructor(private readonly _api: ApiGateway, private readonly _administration: AdministrationService, private readonly _list: ListHelper) {}

  getExpenses(searchObject: ExpenseSearchObject): Observable<Expense[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const params = searchObject.getParameters()

        params.administrationId = administration.id

        return this._api.get<Expense[]>(endpoints.administration.expenses, params)
      }),
    )
  }

  updateInvoiceOnMasterList(list: GroupedMonthlyState<Expense[]>, invoice: Invoice): void {
    if (!list || !invoice) {
      return
    }

    const listInvoice = this._list.findInFilteredStateObject(
      (expense) => expense.type == ExpenseTypes.INVOICE && expense.related_id == invoice.id,
      list,
      invoice,
    )

    if (!listInvoice) {
      return
    }

    listInvoice.remaining = invoice.total_inc - invoice.amount_paid
  }
}
