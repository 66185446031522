import { map, startWith } from 'rxjs/operators'
import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'
import { TranslateRouteService } from '../../../core/logic/i18n/translate-route.service'
import { UserService } from '../../user/user.service'

@Injectable()
export class TermsAndAgreementsGuard implements CanActivate {
  constructor(private readonly _user: UserService, private readonly _router: TranslateRouteService) {}

  canActivate(): any {
    return this._user.user.pipe(
      /**
       * If there is no user yet;
       * return as per usual.
       *
       * Not accepting the terms
       * should not prevent logins.
       */
      startWith({ latest_terms_accepted: true }),
      map((user) => user?.latest_terms_accepted),
      map((hasAccepted) => {
        if (hasAccepted) {
          return true
        }

        void this._router.navigate('/login/termsAndAgreement')

        return false
      }),
    )
  }
}
