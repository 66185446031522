<tlw-auxilary-modal variant="large">
  <div class="onboarding-modal">
    <!--
      Title and subtitle to introduce user to
      whatever is being aksed of them.
     -->
    <div class="onboarding-modal--intro">
      <div>
        <h1>{{ 'COMPONENTS.ONBOARDING_MODAL.TITLE' | translate }}</h1>
        <p
          [innerHTML]="
            (getAuxilarySubmit ? 'COMPONENTS.ONBOARDING_MODAL.SUBTITLE_ON_SUBMIT' : 'COMPONENTS.ONBOARDING_MODAL.SUBTITLE')
              | translate : { href: moreAboutInvoiceDetailsUrl[i18n.language], entity: getEntityName | lowercase }
          "
        ></p>
      </div>
      <div (click)="auxilary.close()">
        <tlw-icon icon="close" library="heroicons" variant="mini"></tlw-icon>
      </div>
    </div>

    <!--
      Component to allow users to
      search in the CoC database.
     -->
    <tlw-company-searcher
      [tlwIcon]="true"
      class="onboarding-modal--search-bar"
      [noLabel]="true"
      [companyName]="companyName"
      (selectedCompany)="onSelectCompany($event)"
      showNotFound="true"
      view="required-fields"
    ></tlw-company-searcher>

    <!--
      Button to let users manually
      fill in their company details.
     -->
    <div *ngIf="!isFormVisible" (click)="isFormVisible = !isFormVisible" class="onboarding-modal--manual-divider">
      <p>{{ 'COMPONENTS.ONBOARDING_MODAL.MANUAL_ENTRY' | translate }}</p>
    </div>

    <!--
      Form to fill in.
      Wait with rendering until all fields are loaded.
     -->
    <ng-container *ngIf="!(loading$ | async)">
      <form (submit)="onSaveHandler()" [formGroup]="form" [class.hidden]="!isFormVisible" class="onboarding-modal--form" novalidate>
        <!--
          Start off with all the required fields (non-optional.)
         -->
        <fnc-input-container
          modern
          fixedLabel
          class="is-userflow-field"
          [style.--grid-area-name]="item.title"
          [class.hidden]="item.title === 'sbi'"
          [customClasses]="item.required ? ['is-required'] : null"
          *ngFor="let item of nonOptionalFields; trackBy: trackByFn"
        >
          <input [formControlName]="item.title" [type]="item.type" [autocomplete]="item.autocomplete" />
          <label>{{ 'COMPONENTS.ONBOARDING_MODAL.LABELS.' + (item?.title | uppercase) | translate }}</label>

          <fnc-validator-messages [parentFormGroup]="form" [parentControlName]="item.title" modern dropdown>
            <span *for="'pattern'">{{ 'VALIDATION.PATTERN' | translate }}</span>
            <span *for="'required'">{{
              'VALIDATION.REQUIRED' | translate : { fieldName: 'COMPONENTS.ONBOARDING_MODAL.LABELS.' + (item?.title | uppercase) | translate }
            }}</span>
          </fnc-validator-messages>
        </fnc-input-container>

        <!--
          Inform users that anything below this line
          will be optional fields, non-required.
         -->
        <span class="optional" [style.grid-area]="'hr'">
          <p>{{ 'COMPONENTS.ONBOARDING_MODAL.OPTIONAL' | translate }}</p>
        </span>

        <!--
          Render all non-required fields.
         -->
        <fnc-input-container
          [class.hidden]="item.title === 'sbi'"
          modern
          fixedLabel
          [style.--grid-area-name]="item.title"
          *ngFor="let item of optionalFields; trackBy: trackByFn"
        >
          <input [formControlName]="item.title" [type]="item.type" [autocomplete]="item.autocomplete" />
          <label>{{ 'COMPONENTS.ONBOARDING_MODAL.LABELS.' + (item.title | uppercase) | translate }}</label>

          <fnc-validator-messages [parentFormGroup]="form" [parentControlName]="item.title" modern dropdown>
            <span *for="'pattern'">{{ 'VALIDATION.PATTERN' | translate }}</span>
            <span *for="'required'">{{
              'VALIDATION.REQUIRED' | translate : { fieldName: 'COMPONENTS.ONBOARDING_MODAL.LABELS.' + (item?.title | uppercase) | translate }
            }}</span>
          </fnc-validator-messages>
        </fnc-input-container>
      </form>
    </ng-container>

    <!--
      Allow users to save the data.
     -->
    <div *ngIf="isFormVisible" class="onboarding-modal--buttons">
      <fnc-button (fncClick)="onSaveHandler()" [disabled]="form.invalid || (saving$ | async)" refactored-small purple wide>
        {{ 'BUTTONS.CONTINUE' | translate }}
      </fnc-button>
    </div>
  </div>
</tlw-auxilary-modal>
