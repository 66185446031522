import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

@Injectable()
export class TranslateHelper {
  constructor(private readonly _translateService: TranslateService) {}

  public hasTranslation(key: string, language?: string): boolean {
    const translation: object | string = this._translateService.translations[language || this._translateService.currentLang]
    const value = key.split('.').reduce((t, k) => t[k] || {}, translation || {})

    return value !== undefined
  }

  getDayOfWeek1stCharByIndex(index: number): Observable<string> {
    return this._translateService.get('LOCALIZATION.DAYS_1_CHAR.' + this.getDayOfWeekByIndex(index))
  }

  private getDayOfWeekByIndex(index: number): string {
    switch (index) {
      case 0:
        return 'MONDAY'
      case 1:
        return 'TUESDAY'
      case 2:
        return 'WEDNESDAY'
      case 3:
        return 'THURSDAY'
      case 4:
        return 'FRIDAY'
      case 5:
        return 'SATURDAY'
      case 6:
        return 'SUNDAY'

      default:
        return '__INVALID__'
    }
  }
}
