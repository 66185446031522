import moment from 'moment'

export class SelectablePeriod {
  constructor(public label: string, public externalLabel: string, public startDate: moment.Moment, public endDate: moment.Moment) {}
}

export class SelectableQuarter extends SelectablePeriod {
  constructor(label: string, externalLabel: string, startDate, endDate, public months?: SelectablePeriod[]) {
    super(label, externalLabel, startDate, endDate)

    if (!months) {
      this.months = []
    }
  }
}

export class SelectableYear extends SelectablePeriod {
  constructor(label: string, externalLabel: string, startDate, endDate, public quarters?: SelectableQuarter[]) {
    super(label, externalLabel, startDate, endDate)

    if (!quarters) {
      this.quarters = []
    }
  }
}
