import { Observable, Subscription, of } from 'rxjs'
import { catchError, filter, map, tap } from 'rxjs/operators'
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { SessionService } from '../../logic/user/session.service'
import { TlwLeftSlideoutMenuComponent } from '../tlw-left-slideout-menu/tlw-left-slideout-menu.component'
import { TellowContext } from '../../domain/tellow/tellow-context'
import { FncPageHeaderComponent } from '../fnc-page-header/fnc-page-header.component'
import { FncPageSubheaderComponent } from '../fnc-page-subheader/fnc-page-subheader.component'
import { TlwFloatingButtonBarComponent } from '../../ui-components/tlw-floating-button-bar/tlw-floating-button-bar.component'
import { TlwMessageboxComponent } from '../../ui-components/tlw-messagebox/tlw-messagebox.component'
import { ApiGateway } from '../../core/remote/api.gateway'
import { User } from '../../domain/user/user.model'
import { endpoints } from '../../shared/config/endpoints'
import { UserLockType } from '../../domain/user/user-lock-type.enum'

@Component({
  selector: 'fnc-main-layout',
  styleUrls: ['./fnc-main-layout.component.scss'],
  template: `
    <fnc-loader *ngIf="!applicationLoaded" [fullPageSize]="true" translatableMessage="MESSAGES.WAIT_UNTIL_DATA_LOADED"></fnc-loader>
    <ng-container *ngIf="applicationLoaded && !lockedOut">
      <tlw-left-slideout-menu [class.application-loaded]="applicationLoaded" #outlet>
        <router-outlet></router-outlet>
      </tlw-left-slideout-menu>
      <tlw-messagebox></tlw-messagebox>
    </ng-container>
    <locked-out-page *ngIf="applicationLoaded && lockedOut" [lockType]="lockType" [blockUrl]="blockUrl"></locked-out-page>
  `,
})
export class FncMainLayoutComponent implements OnInit, OnDestroy {
  applicationLoaded = false
  isDetailPage: boolean
  showPaywall: boolean
  pageHeader: FncPageHeaderComponent
  pageSubHeader: FncPageSubheaderComponent
  floatingButtonBar: TlwFloatingButtonBarComponent
  showNotifications = false
  lockedOut = false
  blockUrl: string = ''
  lockType: UserLockType

  @ViewChild(TlwLeftSlideoutMenuComponent) tlwLeftMenu: TlwLeftSlideoutMenuComponent
  @ViewChild(TlwMessageboxComponent) messagebox: TlwMessageboxComponent
  private _routerSubscription: Subscription

  betaActive: boolean = false

  constructor(private readonly _sessionService: SessionService, public el: ElementRef, private readonly _router: Router, private readonly _api: ApiGateway) {}

  ngOnInit(): void {
    this._isLockedOut$.subscribe((lockedOut) => {
      this.lockedOut = lockedOut

      if (lockedOut) {
        this.applicationLoaded = true
      } else {
        void this.beginSession()
      }
    })
  }

  private _isLockedOut$: Observable<boolean> = this._api.get<User>(endpoints.user).pipe(
    tap((user) => {
      if (user?.block_reason) {
        this.blockUrl = user.block_reason.url
      }

      switch (user?.status) {
        case '3':
          this.lockType = UserLockType.USER_BLOCKED
          break
        case '4':
          this.lockType = UserLockType.INVITE_NOT_ACCEPTED
          break
      }
    }),
    map((user) => user.status === '3' || user.status === '4'),
    catchError(() => of(false)),
  )

  beginSession(): void {
    this._routerSubscription = this._router.events.pipe(filter((evt) => evt instanceof NavigationStart)).subscribe(() => {
      this.el.nativeElement.classList.remove('has-floating-menu-bar')
      this.el.nativeElement.classList.remove('has-paywall')

      if (this.pageHeader) {
        this.pageHeader.el.nativeElement.classList.remove('is-cardview-page')
      }
    })

    this._sessionService.startSession('fnc-main-layout').subscribe(() => (this.applicationLoaded = true))
  }

  /**
   * Set routing context for non-routed paths.
   * (i.e. 'mijn-account' routed under 'settings' without being '/settings' path)
   *
   * TODO: Both ViewChilds are not available 'on init'. There needs to be some kind of queue to bypass this.
   */
  manuallySetActiveContext(context: TellowContext): void {
    this.tlwLeftMenu.activeContext = context
  }

  /**
   * This is used by the administration switcher to
   * toggle visibility of the floating button bar.
   */
  setPageHasFloatingButtonBar(buttonBar: TlwFloatingButtonBarComponent) {
    this.floatingButtonBar = buttonBar
  }

  ngOnDestroy() {
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe()
    }
  }

  setDetailPage(isDetailPage: boolean) {
    this.isDetailPage = isDetailPage
  }

  setShowPaywall() {
    this.el.nativeElement.classList.add('has-paywall')
  }

  toggleNotifications() {
    this.messagebox.toggleMessagebox()
  }
}
