import { debounceTime, distinctUntilChanged, filter, refCount, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators'
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Company } from '../../login/models/company.model'
import { CompanyService } from '../../logic/companies/company.service'
import { Details } from '../../login/models/business-details.model'
import { BrowserService } from '../../core/logic/browser.service'
import { indicate } from '../../core/operators/indicate'

@Component({
  selector: 'tlw-company-searcher',
  templateUrl: './tlw-company-searcher.component.html',
  styleUrls: ['./tlw-company-searcher.component.scss'],
})
export class TlwCompanySearcherComponent {
  /** Main value observable */
  companyName$ = new BehaviorSubject<string>('')

  /** Loading states */
  loading$ = new BehaviorSubject<boolean>(false)
  isShowingAutoComplete$ = new BehaviorSubject<boolean>(false)

  // JAN 2024: Chrome 'hack' is now `one-time-code`
  // @see https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
  protected disableAutocomplete = BrowserService.isChrome ? 'one-time-code' : 'off'

  /** Main processing of data */
  protected companyInfo$ = this.companyName$.asObservable().pipe(
    // Start with empty string
    startWith(''),
    // Prevent null values from being sent
    filter(({ length }) => length > 0),
    // Wait for the user to complete typing
    debounceTime(500),
    // Don't act when value does not change
    distinctUntilChanged(),
    // Switch calls, cancel old call when new one is initiated
    switchMap((name: string) => this._companies.getCompanies(name).pipe(indicate(this.loading$))),
    // Share the result to multiple subscribers (in the template, otherwise you get double calls).
    shareReplay(),
    refCount(),
  )

  /** Internal value to mirror input element against */
  private _internalValue: string

  protected get inputFieldValue(): string {
    return this._internalValue
  }

  protected set inputFieldValue(name: string) {
    this._internalValue = name
  }

  @Input('tlwIcon') useTellowIcon: boolean = false
  @Input('noLabel') useWithoutLabel: boolean = false

  // Mind that 'default' is just a placeholder.
  @Input() variant: 'default' | 'green'
  @Input() view = 'registration'
  @Input() placeholder: string
  @Input() showNotFound: boolean

  @Output() selectedCompany = new EventEmitter<Details | string>()
  @Output() touched = new EventEmitter<boolean>(false)
  @Output() busy = new EventEmitter<boolean>(false)

  @ViewChild('autocompleteTarget', { static: true }) autocompleteTarget: ElementRef<HTMLDivElement>
  @ViewChild('autocompleteInput', { static: true }) autocompleteInput: ElementRef<HTMLInputElement>

  constructor(private readonly _companies: CompanyService) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: Element): void {
    if (this.autocompleteTarget) {
      this.touched.emit(true)
      const clickedInside = this.autocompleteTarget.nativeElement.contains(targetElement)

      if (!clickedInside) {
        this.loading$.next(false)
        this.isShowingAutoComplete$.next(false)
      }
    }
  }

  /**
   * Focus on outside click.
   */
  focusOnInput(): void {
    void this.autocompleteInput.nativeElement.focus()
  }

  /**
   * Submit on Enter press.
   */
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Enter':
        this.onCompanyNameChange(this.inputFieldValue)
    }
  }

  /**
   * On autocomplete set status.
   */
  onAutoComplete(status: boolean): void {
    this.isShowingAutoComplete$.next(status)

    // Scroll to top on mobile view if autocomplete status is true. E.g. when input field is focussed.
    if (status && window.innerWidth < 1024) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 100)
    }
  }

  /**
   * Notify the parent if not found is clicked
   */
  onNotFound(): void {
    this.loading$.next(false)
    this.isShowingAutoComplete$.next(false)
    this.selectedCompany.emit(this.inputFieldValue)
  }

  /**
   * Listen to company name changes.
   */
  onCompanyNameChange(search: string): void {
    this.loading$.next(true)
    this.isShowingAutoComplete$.next(true)
    this.companyName$.next(search)
  }

  /**
   * When a company is selected, emit the values to the parent.
   */
  selectCompany(company: Company): void {
    void this.fetchCompanyDetails(parseInt(company.coc_number))
      .pipe(
        tap(() => this.isShowingAutoComplete$.next(false)),
        tap(() => this.busy.emit(false)),
        tap((details) => {
          if (details.company_name) {
            this.inputFieldValue = details.company_name
          }
        }),
      )
      .subscribe(
        (companyDetails: Details) => {
          this.selectedCompany.emit(companyDetails)
        },
        () => {
          this.selectedCompany.emit(null)
        },
      )
  }

  /**
   * Fetches all company details and returns the observable.
   */
  fetchCompanyDetails(dossierNumber: number): Observable<Details> {
    return this._companies.getAllCompanyDetails(dossierNumber)
  }
}
