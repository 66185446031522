import { Directive, ElementRef } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'

@Directive({
  selector: '.container',
})
export class InputContainerDirective extends BaseHtmlDirective {
  constructor(element: ElementRef) {
    super(element)
  }
}
