import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'fnc-page-header-title',
  template: `
    <tlw-context-aware-view #contextView [defaultContext]="'DOCUMENTS'">
      <h1>{{ title }}</h1>
      <p class="subtitle color {{ color || contextView.context.color }}">{{ subtitle }}</p>
    </tlw-context-aware-view>
  `,
})
export class FncPageHeaderTitleComponent {
  @Input() title: string
  @Input() subtitle: string
  @Input() color: string

  @Output() onTitleChanged = new EventEmitter<string>()

  constructor(private readonly _translateService: TranslateService, private readonly _cdRef: ChangeDetectorRef) {}

  setTitleKey(translationKey: string) {
    this._translateService.get(translationKey).subscribe((t) => {
      this.title = t
      this.onTitleChanged.emit(t)
    })
    this._cdRef.detectChanges()
  }

  setSubtitleKey(translationKey: string) {
    this._translateService.get(translationKey).subscribe((t) => (this.subtitle = t))
  }
}
