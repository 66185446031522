import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, throwError } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { SecureHttp, TellowRequestOptionsArgs } from '../../core/remote/httpclient'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { Administration } from '../../domain/administration/administration.model'
import { Invoice } from '../../domain/invoice/invoice.model'
import { User } from '../../domain/user/user.model'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationService } from '../administration/administration.service'
import { UserService } from '../user/user.service'
import { AvailableLoanData, SuccessfulLoanApplicationReturn } from '../../domain/finance/loans.model'
import { FinancingEstimate, SuccessfulFinancingApplicationReturn } from '../../domain/finance/financing.model'
import { AccessToken, AgerasFinanceCustomer, AgerasFinanceCustomerApprovalState } from '../../domain/finance/customers.model'
import { AvailableCreditLineData, SuccessfulCreditLineApplicationReturn } from '../../domain/finance/creditLines.model'

@Injectable()
export class FinanceService {
  public isAlreadyAnApprovedCustomer$: Observable<boolean> = this.getCustomerData().pipe(
    map((customer: AgerasFinanceCustomer) => customer !== null && customer.approval_state === AgerasFinanceCustomerApprovalState.APPROVED),
  )

  constructor(private readonly _http: SecureHttp, private readonly _userService: UserService, private readonly _administrationService: AdministrationService) {}

  /**
   * Retrieve loan data for the user from Ageras Finance
   *
   * @returns {AvailableLoanData} data about availability of loan and the possible options
   */
  getLoanData(): Observable<AvailableLoanData> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration: Administration) =>
        this._http.get<AvailableLoanData>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.getLoansData}/${administration.id.toString()}`,
          this.getOptions(),
        ),
      ),
    )
  }

  /**
   * Send application to Ageras Finance for a loan
   *
   * @param {AvailableLoanData} loanData The fetched loan data that the user is applying for
   * @param {number} amount Amount of loan to apply for (in euro)
   * @param {number} duration Duration of loan (in months)
   * @returns {SuccessfulLoanApplicationReturn} Application id and token for completing the loan application process
   */
  signUpForLoan(loanData: AvailableLoanData, amount: number, duration: number): Observable<SuccessfulLoanApplicationReturn> {
    const administration = this._administrationService.defaultAdministration
    const administration_settings = this._administrationService.defaultAdministrationSettings
    const user = this._userService.user

    return forkJoin([administration, administration_settings, user]).pipe(
      switchMap(([administration, administration_settings, user]: [Administration, AdministrationSettings, User]) =>
        this._http.post<SuccessfulLoanApplicationReturn>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.applyForLoan}`,
          {
            loan_data: {
              id: loanData.id,
              loan_amount: amount.toString(),
              loan_currency: 'EUR',
              loan_duration: duration.toString(),
              loan_interest_rate: loanData.loan_interest_rate,
            },
            customer_data: {
              external_id: administration.id.toString(),
              locale: 'nl-NL',
              company_registration_number: administration_settings?.coc_number,
              country: 'NL',
              name: `${user.first_name} ${user.middle_name ? `${user.middle_name} ` : ''}${user.last_name}`,
            },
          },
          this.getOptions(),
        ),
      ),
    )
  }

  /**
   * Retrieve credit line data for the user from Ageras Finance
   *
   * @returns {AvailableCreditLineData} data about availability of credit line and the possible options
   */
  getCreditLineData(): Observable<AvailableCreditLineData> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration: Administration) =>
        this._http.get<AvailableCreditLineData>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.getCreditLineData}/${administration.id.toString()}`,
          this.getOptions(),
        ),
      ),
    )
  }

  /**
   * Send application to Ageras Finance for a credit line
   *
   * @param {AvailableCreditLineData} creditLineData The fetched credit line data that the user is applying for
   * @param {number} amount Amount of credit line to apply for (in euro)
   * @returns {SuccessfulCreditLineApplicationReturn} Application id and token for completing the credit line application process
   */
  signUpForCreditLine(creditLineData: AvailableCreditLineData, amount: number): Observable<SuccessfulCreditLineApplicationReturn> {
    const administration = this._administrationService.defaultAdministration
    const administration_settings = this._administrationService.defaultAdministrationSettings
    const user = this._userService.user

    return forkJoin([administration, administration_settings, user]).pipe(
      switchMap(([administration, administration_settings, user]: [Administration, AdministrationSettings, User]) =>
        this._http.post<SuccessfulCreditLineApplicationReturn>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.applyForCreditLine}`,
          {
            credit_line_data: {
              id: creditLineData.id,
              limit_amount: amount.toString(),
              currency: 'EUR',
            },
            customer_data: {
              external_id: administration.id.toString(),
              locale: 'nl-NL',
              company_registration_number: administration_settings?.coc_number,
              country: 'NL',
              name: `${user.first_name} ${user.middle_name ? `${user.middle_name} ` : ''}${user.last_name}`,
            },
          },
          this.getOptions(),
        ),
      ),
    )
  }

  /**
   * Get the financing estimation details for a specific invoice
   *
   * Requirements for invoice to be eligible for financing:
   * - The due date of the invoice must be at least 14 days and at max 120 days (4 months).
   * - The invoice amount must be between 1.000 - 30.000 EUR
   *
   * @param {Invoice} invoice Invoice to be processed for estimation
   * @returns {FinancingEstimate} Estimation data for the provided invoice
   */
  getInvoiceFinancingEstimation(invoice: Invoice): Observable<FinancingEstimate> {
    const administration = this._administrationService.defaultAdministration
    const administration_settings = this._administrationService.defaultAdministrationSettings

    return forkJoin([administration, administration_settings]).pipe(
      switchMap(([administration, administration_settings]: [Administration, AdministrationSettings]) =>
        this._http.post<FinancingEstimate>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.financingEstimate}`,
          {
            external_customer_id: administration.id.toString(),
            company_registration_number: administration_settings.coc_number,
            external_invoice_id: invoice.id.toString(),
            invoice_number: invoice.number,
            invoice_amount: invoice.total_inc,
            invoice_currency: 'EUR',
            invoice_due: invoice.expiry_date,
          },
          this.getOptions(),
        ),
      ),
      catchError((error) => {
        return throwError(error)
      }),
    )
  }

  /**
   * Apply for a specific invoice financing estimation
   *
   * @param id ID of the invoice financing estimation returned by the getInvoiceFinancingEstimation function
   * @returns {SuccessfulFinancingApplicationReturn} Invoice Financing details regarding this specific invoice - not used after successful submission of application
   */
  applyForInvoiceFinancing(id: string): Observable<SuccessfulFinancingApplicationReturn> {
    const administration = this._administrationService.defaultAdministration
    const administration_settings = this._administrationService.defaultAdministrationSettings

    return forkJoin([administration, administration_settings]).pipe(
      switchMap(([administration, administration_settings]: [Administration, AdministrationSettings]) =>
        this._http.post<SuccessfulFinancingApplicationReturn>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.applyForFinancing}`,
          {
            id: id,
            customer_data: {
              external_id: administration.id.toString(),
              locale: 'nl-NL',
              company_registration_number: administration_settings.coc_number,
              country: 'NL',
              name: administration.name,
            },
          },
          this.getOptions(),
        ),
      ),
    )
  }

  /**
   * Get all the customer data from Ageras Finance - based on Administration ID
   *
   * @returns {AgerasFinanceCustomer} All customer data held by Ageras Finance
   */
  getCustomerData(): Observable<AgerasFinanceCustomer> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration: Administration) =>
        this._http.get<AgerasFinanceCustomer>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.getCustomer}/${administration.id.toString()}`,
          this.getOptions(),
        ),
      ),
      catchError((error) => {
        return error?.error?.message.endsWith('404') ? of(null) : throwError(error)
      }),
    )
  }

  /**
   * Gets the token used to redirect to Ageras Finance Frontend for the KYC procedure
   *
   * @returns {AccessToken} The token used for safe redirects
   */
  getCustomerToken(): Observable<AccessToken> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration: Administration) =>
        this._http.get<AccessToken>(
          `${environment.integrationsServiceApiUrl}${endpoints.finance.getCustomerToken}/${administration.id.toString()}`,
          this.getOptions(),
        ),
      ),
    )
  }

  private getOptions(): TellowRequestOptionsArgs {
    // Create options param with the correct apiKey for the endopint
    const options: TellowRequestOptionsArgs = {}
    options.headers = new HttpHeaders()
    options.headers = options.headers.append('apiKey', environment.integrationsServiceApiKey)

    return options
  }
}
