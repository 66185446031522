import { ContentChildren, Directive, ElementRef, Input, QueryList } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'
import { OptionDirective } from './option.directive'

@Directive({
  selector: 'select',
})
export class SelectDirective extends BaseHtmlDirective {
  @ContentChildren(OptionDirective)
  options: QueryList<OptionDirective>

  @Input()
  placeholder

  constructor(element: ElementRef) {
    super(element)
  }
}
