import { map } from 'rxjs/operators'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { TranslateRouteService } from '../logic/i18n/translate-route.service'
import { SecurityService } from './security.service'

@Injectable()
export class PaywallGuard implements CanActivate {
  constructor(private readonly _securityService: SecurityService, private readonly _router: TranslateRouteService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._securityService.getUserPaywallStatus().pipe(
      map((result) => {
        if (result) {
          return result
        }

        void this._router.navigate('')
      }),
    )
  }
}
