import { mergeMap } from 'rxjs/operators'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectablePeriod, SelectableYear } from '../../domain/administration/time/selectable-period'
import { PeriodsService } from '../../logic/administration/periods.service'
import { Subscription } from 'rxjs'
import { AdministrationService } from '../../logic/administration/administration.service'
import { VatDeclartionPeriods } from '../../domain/vat/vat-declaration-periods.constants'

@Component({
  selector: 'fnc-period-selector',
  styleUrls: ['./fnc-period-selector.component.scss'],
  host: {
    '(document:onClick)': 'close()',
    '(document:onTouchstart)': 'close()',
    '(document:click)': 'close()',
  },
  template: `
    <div class="fnc-period-selector-container">
      <button (click)="togglePicker(); $event.stopPropagation()">
        <span class="placeholder" *ngIf="!selectedPeriod">{{ 'COMPONENTS.PERIOD_SELECTOR.PERIOD_PLACEHOLDER' | translate }}</span>
        <span class="value" *ngIf="selectedPeriod">{{ selectedPeriod.externalLabel }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="selector" [class.active]="opened">
        <div class="header">
          {{ 'COMPONENTS.PERIOD_SELECTOR.SELECT_PERIOD' | translate }}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="year" *ngFor="let year of selectableYears" [class.selected]="selectedPeriod == year">
          <h2 (click)="selected(year); $event.stopPropagation()">{{ year.label }}</h2>
          <div class="quarters">
            <div
              class="period quarter"
              *ngFor="let quarter of year.quarters"
              [class.selected]="selectedPeriod == quarter"
              (click)="selected(quarter); $event.stopPropagation()"
            >
              {{ quarter.label }}

              <div class="months">
                <div
                  class="period month"
                  *ngFor="let month of quarter.months"
                  [class.selected]="selectedPeriod == month"
                  (click)="selected(month); $event.stopPropagation()"
                >
                  {{ month.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class FncPeriodSelectorComponent implements OnInit {
  opened: boolean
  selectableYears: SelectableYear[]
  @Input() selectedPeriod: SelectablePeriod
  @Output() onOpened = new EventEmitter<void>()
  @Output() onSelected = new EventEmitter<SelectablePeriod>()
  private _currentPeriodSubscription: Subscription

  constructor(private readonly _periodsService: PeriodsService, private readonly _administrationService: AdministrationService) {}

  @Input()
  set selectCurrentPeriod(select: boolean) {
    if (this._currentPeriodSubscription) {
      this._currentPeriodSubscription.unsubscribe()
      this._currentPeriodSubscription = null
    }

    if (select) {
      this._currentPeriodSubscription = this._administrationService.defaultAdministrationSettings
        .pipe(
          mergeMap((settings) => {
            // Select the kind of period in which this administration declares its VAT
            switch (settings.vat_declaration_period) {
              case VatDeclartionPeriods.YEAR:
                return this._periodsService.currentYear
              case VatDeclartionPeriods.QUARTER:
                return this._periodsService.currentQuarter
              case VatDeclartionPeriods.MONTH:
                return this._periodsService.currentMonth
            }
          }),
        )
        .subscribe((period) => (this.selectedPeriod = period))
    } else {
      this.selectedPeriod = null
    }
  }

  ngOnInit() {
    this._periodsService.allSelectablePeriods.subscribe((years) => (this.selectableYears = years))
  }

  togglePicker() {
    this.opened = !this.opened

    if (this.opened) {
      this.onOpened.emit()
    }
  }

  close() {
    this.opened = false
  }

  selected(period: SelectablePeriod) {
    this.selectedPeriod = period
    this.onSelected.emit(period)

    this.close()
  }
}
