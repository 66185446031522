<div
  #autocompleteTarget
  [class.autocomplete--loading]="loading$ | async"
  [class.autocomplete--active]="isShowingAutoComplete$ | async"
  class="autocomplete autocomplete__{{ view }}"
>
  <div class="icon close-autocomplete">
    <fnc-icon (click)="onAutoComplete(false)" [icon]="'exit'"></fnc-icon>
  </div>
  <fnc-input-action (click)="focusOnInput()" class="search">
    <fnc-input-container class="input-container" [class.with-icon]="useTellowIcon" fixedLabel="true">
      <input
        #autocompleteInput
        (focus)="onAutoComplete(true)"
        [(ngModel)]="inputFieldValue"
        (ngModelChange)="onCompanyNameChange($event)"
        [autocomplete]="disableAutocomplete"
        name="c0mp4ny_n4m3"
        type="search"
        class="tlw-company-search-input-field"
        [placeholder]="(placeholder ? placeholder : 'CONTACTS.SEARCH_BY_COMPANY_NAME') | translate"
        required
      />
      <tlw-icon *ngIf="useTellowIcon" icon="search" [bold]="true" height="16"></tlw-icon>
      <spinner *ngIf="loading$ | async" hex="#002E33"></spinner>
      <label [class.hide]="useWithoutLabel">{{ placeholder | translate }}</label>
    </fnc-input-container>
  </fnc-input-action>

  <ul *ngIf="isShowingAutoComplete$ | async" [class.autocomplete__list--with-results]="(companyInfo$ | async)?.length > 0" class="autocomplete__list">
    <li (click)="onNotFound()" *ngIf="showNotFound && view !== 'required-fields'" class="autocomplete__item autocomplete__item--not-found">
      <span *ngIf="view === 'contacts'" bold class="autocomplete__item__text">
        {{ 'CONTACTS.SEARCH_NOT_FOUND_TITLE' | translate }}
      </span>
      <span *ngIf="view === 'registration'" bold class="autocomplete__item__text">
        {{ 'CONTACTS.SEARCH_YOURS_FOUND_TITLE' | translate }}
      </span>
      <span bold class="autocomplete__item__text" white>
        <div *ngIf="view === 'registration'">
          {{ 'LOGIN.REGISTRATION.COMPANY_SEARCH_NOT_FOUND' | translate }}
        </div>
        <div *ngIf="view === 'contacts'">
          {{ 'CONTACTS.SEARCH_NOT_FOUND_MESSAGE' | translate }}
        </div>
      </span>
    </li>
    <li (click)="selectCompany(business)" *ngFor="let business of companyInfo$ | async" class="autocomplete__item">
      <h3 class="autocomplete__item__title">{{ business?.company_name }}</h3>
      <span class="autocomplete__item__text">{{ 'ONBOARDING.STEP_BUSINESS_COC' | translate }} {{ business?.coc_number }}</span>
      <span class="autocomplete__item__text">{{ business?.street_name }}, {{ business?.city }}</span>
    </li>
  </ul>
</div>
