export const shared = {
  isDevelopmentMachine: false,
  ng2ProductionMode: true,
  clientId: '1_wfj2y7d2phwsgw0w4w04ko0kw0000g4s48ow88ggg480wgwow',
  clientSecret: '3mm8ox14ham80s0sk8o8kc4go0wks8kw0gck40cw4kkw8w8wk8',
  baremetricsCancellationAccessToken: '2bea0365-f343-4b6c-bc89-4b5e38987935',
  integrationsServiceApiKey: '81477a91-6465-4269-8e46-73ccc4737f6a',
  versionWorkerApiUrl: 'https://version.tellow.workers.dev/',
  billingSwitchDate: '2020-04-18',
  basisUpsellPrice: 12.99,
  plusUpsellPrice: 22.99,
  completeUpsellPrice: 69.99,
}
