export class PrivateVatBookingType {
  static CAR = new PrivateVatBookingType('CAR', 'SETTINGS.VAT_PRIVATE_USE.TYPE_CAR')
  static OTHER = new PrivateVatBookingType('OTHER', 'SETTINGS.VAT_PRIVATE_USE.TYPE_OTHER')

  static types = [PrivateVatBookingType.CAR, PrivateVatBookingType.OTHER]

  constructor(private readonly _type: string, private readonly _labelKey: string) {}

  get type(): string {
    return this._type
  }

  get labelKey(): string {
    return this._labelKey
  }

  getPrivateVatBooktingType(type: string) {
    return PrivateVatBookingType.types.find((pvbt) => pvbt.type == type)
  }
}
