import { Component, ElementRef, HostListener, ViewChild } from '@angular/core'
import { NotificationService } from '../../logic/notifications/notification.service'

@Component({
  selector: 'tlw-messagebox',
  template: `
    <div class="messagebox" #box>
      <div class="messagebox-header">
        <h2>
          {{ 'MESSAGEBOX.NOTIFICATIONS' | translate }}
          <img [src]="'../../../assets/iconfont/close.png'" (click)="closeMessageBox()" style="cursor: pointer; float: right; margin-top: 6px;" />
        </h2>
      </div>

      <div class="messagebox-content">
        <div *ngIf="!(notifications.isLoadingNotfications$ | async); else loader">
          <div *ngIf="notifications.hasNotifications; else upToDate" class="notifications-container">
            <ng-container *ngFor="let notification of notifications.notificationsToDisplayInDrawer">
              <tlw-notification [notification]="notification" [inDrawer]="true"></tlw-notification>
            </ng-container>
          </div>

          <ng-template #upToDate class="notifications-container">
            <tlw-notification [notification]="notifications.noNotificationsNotification" [inDrawer]="true"></tlw-notification>
          </ng-template>
        </div>

        <ng-template #loader>
          <div class="spinner-container">
            <div #spinner class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active"></div>
          </div>
        </ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./tlw-messagebox.component.scss'],
})
export class TlwMessageboxComponent {
  // Closed by default
  showMessageBox = false
  @ViewChild('box', { static: true }) box: ElementRef

  constructor(public readonly notifications: NotificationService, private readonly _eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this._eRef.nativeElement.contains(event.target)) {
      if (this.showMessageBox) {
        this.closeMessageBox()
      }
    }
  }

  toggleMessagebox() {
    // Timeout so outside click resolves first
    setTimeout(() => {
      this.showMessageBox = true
      this.box.nativeElement.classList.add('show')
    }, 1)
  }

  closeMessageBox() {
    this.showMessageBox = false
    this.box.nativeElement.classList.remove('show')
  }
}
