import { Injectable } from '@angular/core'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import { environment } from '../../../environments/environment'
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { Database, getDatabase } from 'firebase/database'
import { isSupported, getMessaging, Messaging } from 'firebase/messaging'

@Injectable()
export class FirebaseCoreService {
  private readonly _firebaseApp: FirebaseApp
  private _firebaseRemoteConfig: RemoteConfig
  private _firebaseAnalytics: Analytics
  private _firebaseMessaging: Messaging
  private _firebaseDatabase: Database

  constructor() {
    this._firebaseApp = initializeApp(environment.firebase)
    this._firebaseRemoteConfig = getRemoteConfig(this.app)
    this._firebaseAnalytics = getAnalytics(this.app)
    this._firebaseDatabase = getDatabase(this.app)

    void this._loadAsyncMessaging()

    if (environment.ng2ProductionMode) {
      getPerformance(this.app)
    }
  }

  private async _loadAsyncMessaging(): Promise<void> {
    if (await isSupported()) {
      this._firebaseMessaging = getMessaging(this.app)
    }
  }

  get app(): FirebaseApp {
    return this._firebaseApp
  }

  get remoteConfig(): RemoteConfig {
    return this._firebaseRemoteConfig
  }

  get messaging(): Analytics {
    return this._firebaseMessaging
  }

  get analytics(): Analytics {
    return this._firebaseAnalytics
  }

  get database(): Database {
    return this._firebaseDatabase
  }
}
