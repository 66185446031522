import { Component, Input, OnInit } from '@angular/core'
import { TellowContext } from '../../../domain/tellow/tellow-context'
import { ActivatedRoute } from '@angular/router'
import { TranslateRouteService } from '../../../core/logic/i18n/translate-route.service'

@Component({
  selector: 'tlw-context-aware-view',
  template: '<ng-content></ng-content>',
})
export class TlwContextAwareViewComponent implements OnInit {
  @Input()
  set defaultContext(context: string) {
    const ctx = TellowContext.TellowContextDictionary[context]

    if (!ctx) {
      throw new Error(`[TlwContextAwarePageComponent.defaultContext()] Unknown Tellow Context (${context})`)
    }

    this._defaultContext = ctx
  }

  get context(): TellowContext {
    return this._context || this._defaultContext
  }

  @Input() setActiveMenuItem: boolean
  private _back: string

  private _defaultContext: TellowContext

  private _context: TellowContext

  constructor(private _route: ActivatedRoute, private _translateRouter: TranslateRouteService) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this._context = null
      this._back = null

      if (params['context']) {
        const ctx = TellowContext.TellowContextDictionary[params['context']]

        if (!ctx) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`[TlwContextAwarePageComponent.ngOnInit()] Unknown Tellow Context (${params['context']})`)
        }

        this._context = ctx
      }

      if (params['back']) {
        this._back = params['back']
      }
    })
  }

  goToContextOverview(params?: any) {
    if (this._back) {
      void this._translateRouter.navigate(this._back, params)
    } else {
      void this._translateRouter.navigate(this.context.route, params)
    }
  }
}
