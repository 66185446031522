import { Component, forwardRef, HostBinding, Inject, Input, OnInit } from '@angular/core'
import { FncRadioGroupComponent } from '../fnc-radio-group/fnc-radio-group.component'

@Component({
  selector: 'fnc-radio',
  styleUrls: ['./fnc-radio.component.scss'],
  host: {
    '(click)': 'clicked($event)',
  },
  template: `
    <div class="radio"></div>
    <label>
      <ng-content></ng-content>
    </label>
  `,
})
export class FncRadioComponent implements OnInit {
  @Input() value: any
  @Input() checked: boolean

  constructor(@Inject(forwardRef(() => FncRadioGroupComponent)) private _parent: FncRadioGroupComponent) {}

  @HostBinding('class.selected')
  get selected(): boolean {
    return this.checked || this._parent.value == this.value
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clicked(evt: any): void {
    if (!this._parent.disabled) {
      this._parent.value = this.value
    }
  }
}
