import { Component, HostBinding, Input } from '@angular/core'
import { AccountIconMapping, Icon } from '../../domain/tellow/accounts-icon-map'

export type IconVariants = 'mini' | 'regular' | 'bold'
export type IconLibraries = 'streamline' | 'heroicons' | 'custom'

/**
 * Based on this library, and `fnc-icon` logic:
 * https://github.com/czeckd/angular-svg-icon#readme
 */

@Component({
  selector: 'tlw-icon',
  styleUrls: ['./tlw-icon.component.scss'],
  template: `
    <svg-icon
      [src]="getIconSource"
      [stretch]="false"
      [class.hidden]="hidden"
      [class]="animation"
      [class]="color"
      [applyClass]="animation"
      [svgStyle]="{ width: 'auto', 'height.px': height, 'margin-top.px': opticalOffset }"
    ></svg-icon>
  `,
})
export class TlwIconComponent {
  @Input() icon: string

  // Switch to different icon on 'true' (default to 'false').
  @Input() switchIcon: boolean = false
  @Input() switchTo: string

  @Input() library: IconLibraries = 'streamline'
  @Input() variant: IconVariants = 'bold'
  @Input() color: string

  @Input() height: number = 24
  @Input() hidden: boolean

  @Input() opticalOffset: number = 0
  @Input() animation: string
  @Input() active: boolean

  // Account logic.
  @Input('rgs') rgsSpecificIcon: string

  @HostBinding('attr.disabled')
  @Input()
  disabled: boolean

  get isStreamlineIcon(): boolean {
    return this.library === 'streamline'
  }

  /**
   * Get the basic path to the icon.
   * This is the same in all cases,
   * so lets keep it in here.
   */
  private get _baseUrl(): string {
    const baseString = `assets/icons/${this.library}`
    const variantString = this.isStreamlineIcon ? `/${this.variant}` : ''

    return `${baseString}${variantString}`
  }

  /**
   * Find a corresponding icon to an account code.
   * Mapping is stored in '/domain/tellow/'.
   * Update above file when adding new icons.
   */
  protected get getRgsIcon(): string | null {
    return AccountIconMapping.find((icon: Icon) => icon.number.includes(this.rgsSpecificIcon))?.icon ?? null
  }

  /**
   * Resolve icon from the assets folder; mind how the ternary operator switches between folders.
   * Please save these icons accordingly, until we have implemented the Streamline dependency (that is currently in beta).
   */
  protected get getIconSource(): string {
    if (this.rgsSpecificIcon) {
      if (this.getRgsIcon) {
        return `${this._baseUrl}/${this.getRgsIcon}.svg`
      } else {
        return null
      }
    }

    if (this.switchIcon) {
      return `${this._baseUrl}/${this.switchTo}.svg`
    }

    return `${this._baseUrl}/${this.icon}.svg`
  }
}
