import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, publishReplay, refCount } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { ScriptService } from './script.service'

export enum AdblockerSeverity {
  Normal = 0,
  High = 1,
}

@Injectable()
export class AdblockerHelper {
  private _tracker: Observable<boolean>
  private _advertising: Observable<boolean>

  constructor(private scriptService: ScriptService) {}

  /**
   * Detect adblocker.
   * Mind you, adblockers are less strict than
   * 'additional tracking' in e.g. Adblocker Plus.
   * @see https://www.webtips.dev/how-to-reliably-check-if-adblocker-is-enabled
   */
  isBlockingAdvertising(): Observable<boolean> {
    if (this._advertising) {
      return this._advertising
    }

    const script = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    this._advertising = this.scriptService.loadScript(script).pipe(
      map(() => false),
      catchError(() => of(true)),
    )

    return this._advertising
  }

  /**
   * Detect if Segment script
   * is being blocked by user.
   * (e.g. 'additional tracking').
   */
  isBlockingTracking(level: keyof typeof AdblockerSeverity = 'Normal') {
    const script = `https://cdn.segment.com/analytics.js/v1/${environment.segmentApiKey}/analytics.min.js`
    const proxy = `https://analyse.tellow.nl/ajs/${environment.segmentApiKey}`

    if (this._tracker) {
      return this._tracker
    }

    let source: string = script
    const severity = AdblockerSeverity[level]
    if (severity === AdblockerSeverity.High) source = proxy

    /**
     * Load all scripts that are
     * pushed to sources array.
     *
     * Any failure means that
     * an adblocker seems active.
     */
    this._tracker = this.scriptService.loadScript(source).pipe(
      map(() => false),
      catchError(() => of(true)),
      publishReplay(1),
      refCount(),
    )

    return this._tracker
  }

  /**
   * Clear saved caches
   * about earlier adblocking.
   */
  clearCaches() {
    this._tracker = null
    this._advertising = null
  }
}
