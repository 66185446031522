import { Pipe, PipeTransform } from '@angular/core'
import { TranslateRouteService } from '../logic/i18n/translate-route.service'

/**
 * Helper for templates.
 */
@Pipe({
  name: 'translateRoute',
})
export class TranslateRoutePipe implements PipeTransform {
  constructor(private readonly _translateRouteService: TranslateRouteService) {}

  transform(value: string): any {
    return this._translateRouteService.translate(value)
  }
}
