export interface SwanCard {
  id: string
  card_id: string
  type: CardType
  status: CardStatus
  e_commerce: boolean
  card_masked_number: string
  settings_url: string
  physical_card_status: PhysicalCardStatus | null
  tracking_number: string | null
  estimated_delivery_date: string | null
  shipping_provider: string | null
  is_pin_ready: boolean
  consent_url_physical_card: string | null
  available_value: number
}

/** These are not fully integrated types from Swan , but we will do as much as is needed */
export enum PhysicalCardStatus {
  CONSENT_PENDING = 'ConsentPending',
  PROCESSING = 'Processing',
  ACTIVATED = 'Activated',
  SUSPENDED = 'Suspended',
  CANCELED = 'Canceled',
  CANCELING = 'Canceling',
  TO_ACTIVATE = 'ToActivate',
  RENEWED = 'Renewed',
}

export enum CardType {
  VIRTUAL = 'Virtual',
  VIRTUAL_AND_PHYSICAL = 'VirtualAndPhysical',
  SINGLE_USE_VIRTUAL = 'SingleUseVirtual',
}

export enum CardStatus {
  CONSENT_PENDING = 'ConsentPending',
  PROCESSING = 'Processing',
  ENABLED = 'Enabled',
  CANCELED = 'Canceled',
  CANCELING = 'Canceling',
}
