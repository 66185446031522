<fnc-modal size="xlarge">
  <modal-content>
    <div class="paywall-content-wrapper">
      <fnc-icon (click)="close()" icon="exit" white></fnc-icon>

      <div class="paywall-content">
        <!-- Owner screen -->
        <div #payContent *ngIf="!paid && userIsOwner">
          <h1>{{ 'COMPONENTS.PAYWALL.TITLE_OWNER' | translate }}</h1>

          <p *ngIf="!newYear">{{ 'COMPONENTS.PAYWALL.MESSAGE_1_OWNER_2019' | translate }}</p>
          <p *ngIf="newYear">{{ 'COMPONENTS.PAYWALL.MESSAGE_1_OWNER_2020' | translate }}</p>

          <p>{{ 'COMPONENTS.PAYWALL.MESSAGE_2_OWNER' | translate }}</p>

          <fnc-button (fncClick)="pay()" [disabled]="paying" [showSpinner]="paying" green>
            {{ 'COMPONENTS.PAYWALL.BUTTON_AGREED' | translate }}
          </fnc-button>

          <br />

          <a href="{{ agreementsUrl }}" target="_blank">{{ 'COMPONENTS.PAYWALL.TERMS_AND_AGREEMENT' | translate }}</a>
        </div>

        <!-- UserRegistration screen -->
        <div *ngIf="!paid && !userIsOwner" class="user">
          <h1>{{ 'COMPONENTS.PAYWALL.TITLE_USER' | translate : { userName: userName } }}</h1>

          <p>{{ 'COMPONENTS.PAYWALL.MESSAGE_1_USER' | translate : { administrationName: adminName } }}</p>
        </div>

        <!-- Finished screen -->
        <div #confettiContent *ngIf="paid">
          <confetti *ngIf="releaseConfetti" [confettiCount]="30 + (timeForSnow ? 30 : 0)" [snow]="timeForSnow"></confetti>

          <div class="confetti-text">
            <h1>{{ 'COMPONENTS.PAYWALL.TITLE_CONFIRMED_1' | translate }}</h1>
            <h1>{{ 'COMPONENTS.PAYWALL.TITLE_CONFIRMED_2' | translate }}</h1>
            <p class="color green" subtitle>{{ 'COMPONENTS.PAYWALL.SUBTITLE_CONFIRMED' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </modal-content>
</fnc-modal>

<tlw-error-modal></tlw-error-modal>
