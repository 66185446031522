import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'

export type ModalSizes = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'full-screen'
export type ModalSizeWithPadding = ModalSizes | `${ModalSizes} padded`

const DIALOG = trigger('dialog', [
  transition(':enter', [style({ opacity: 0 }), animate('0.3s cubic-bezier(0, 0, 0.17, 1.08)', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.3s cubic-bezier(0, 0, 0.17, 1.08)', style({ opacity: 0 }))]),
])

@Component({
  selector: 'fnc-modal',
  animations: [DIALOG],
  host: {
    '(document:keyup)': 'keypressed($event)',
  },
  template: `
    <div
      [@dialog]
      *ngIf="visible"
      [style.z-index]="zIndex"
      (click)="documentClick($event)"
      [class.low-z-index]="size === 'full-screen' && !useHighZIndex && !zIndex"
      class="modal {{ modalClasses }} {{ assignClassName }} child-size-{{ size }}"
      #modal
    >
      <div
        class="modal-window {{ windowClasses }}"
        [style.overflow]="noOverflow ? 'hidden' : 'visible'"
        [class.with-padding]="withDefaultPadding"
        [class.no-min-height]="withMinHeight"
        [style.maxWidth]="maxWidth"
        [style.minWidth]="minWidth"
        [class.pdf]="document"
        [ngClass]="getSize()"
        #window
      >
        <div class="modal-center-fix">
          <div [class.row]="useFoundationRow" *ngIf="!noHeader && !modalWithoutHeader">
            <div [class.columns]="useFoundationColumns" class="modal-header" #header>
              <ng-content select="modal-header"></ng-content>
            </div>
          </div>
          <div [class.row]="useFoundationRow">
            <div [class.columns]="useFoundationColumns" class="modal-content" #content>
              <ng-content select="modal-content"></ng-content>
            </div>
          </div>
          <!--Dont show button row when it's a document modal (to prevent border) -->
          <div [class.row]="useFoundationRow" *ngIf="!noButtons">
            <div [class.columns]="useFoundationColumns" class="modal-buttons" #buttons [class.no-border]="noBorder">
              <ng-content select="modal-buttons" class="modal-buttons"></ng-content>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-message" class="{{ size }}" #message>
        <ng-content select="modal-message"></ng-content>
      </div>

      <div class="modal-banner" class="{{ size }}" #banner>
        <ng-content select="modal-banner"></ng-content>
      </div>
    </div>
    <div
      *ngIf="visible"
      class="modal-overlay"
      [class.blur]="useBlur"
      [class.dark-overlay]="useDarkOverlay"
      [class.no-overlay]="size === 'full-screen'"
      [style.z-index]="withHigherZIndex ? 1234512 : 99"
      (click)="close($event)"
      #overlay
    ></div>
  `,
})
export class FncModalComponent implements OnDestroy {
  noButtons: boolean
  noHeader: boolean

  @Input() public visible: boolean

  // Foundation exceptions
  @Input() useFoundationColumns: boolean = true
  @Input() useFoundationRow: boolean = true

  // Styling
  @Input() withHigherZIndex: boolean = false
  @Input() withDefaultPadding: boolean = true
  @Input() useDarkOverlay: boolean = false
  @Input() withMinHeight: boolean = true
  @Input() useBlur: boolean = false
  @Input() zIndex: number

  // Regular inputs
  @Input() modalWithoutHeader: boolean = false
  @Input() dontCloseOnDocumentClick: boolean
  @Input() dontCloseOnEscape: boolean
  @Input() maxWidth: string
  @Input() minWidth: string
  @Input() noBorder: boolean
  @Input() noOverflow: boolean
  @Input() height: string
  @Input() width: string
  @Input() size: ModalSizeWithPadding
  @Input() document: boolean
  @Input() windowClasses: string
  @Input() modalClasses: string
  @Input() assignClassName: string

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() documentClicked: EventEmitter<boolean> = new EventEmitter<boolean>()

  @ViewChild('modal') modal: ElementRef
  @ViewChild('overlay') overlay: ElementRef
  @ViewChild('window') window: ElementRef
  @ViewChild('banner') banner: ElementRef
  @ViewChild('content') content: ElementRef
  @ViewChild('buttons') buttons: ElementRef
  @ViewChild('header') header: ElementRef

  private _modalElement: any
  private _overlayElement: any
  private _modalWindow: any
  private _content: any
  private _loaded = false

  ngOnDestroy(): void {
    setTimeout(() => {
      if (this._modalElement && this._modalElement.parentNode) {
        this._modalElement.parentNode.removeChild(this._modalElement)
      }

      if (this._overlayElement && this._overlayElement.parentNode) {
        this._overlayElement.parentNode.removeChild(this._overlayElement)
      }
    })
  }

  // Open this modal
  open() {
    this.noHeader = false
    this.noButtons = false

    this.visible = true
    this.visibleChange.emit(true)

    document.documentElement.classList.add('modal-visible')
    document.documentElement.classList.add('pdf')

    setTimeout(() => {
      if (!this.modal) {
        return
      }

      this._modalElement = this.modal.nativeElement
      this._overlayElement = this.overlay.nativeElement
      this._modalWindow = this.window.nativeElement
      this._content = this.content
      document.documentElement.appendChild(this.modal.nativeElement)
      document.documentElement.appendChild(this.overlay.nativeElement)

      setTimeout(() => {
        this._loaded = true
        this.setVerticalPosition()
        this.modal.nativeElement.classList.add('modal-visible')
        this.overlay.nativeElement.classList.add('modal-visible')
      })

      setTimeout(() => {
        let trimmed = this.buttons.nativeElement.innerHTML.trim()
        this.noButtons = '<!---->' === trimmed || '' === trimmed

        if (this.header) {
          trimmed = this.header.nativeElement.innerHTML.trim()
          this.noHeader = '<!---->' === trimmed || '' === trimmed
        }
      })
    })
  }

  documentClick(event: any) {
    this.documentClicked.emit()

    if (this.dontCloseOnDocumentClick) {
      return false
    }

    this.close(event)
  }

  // Close this modal
  close(evt?: any) {
    if (evt && !(evt.target.classList.contains('modal') || evt.target.classList.contains('modal-overlay'))) {
      return
    }

    this.visible = false
    this.visibleChange.emit(this.visible)
    this._loaded = false

    setTimeout(() => {
      if (this.modal) {
        this.modal.nativeElement.classList.remove('modal-visible')
        this.modal.nativeElement.classList.add('modal-hidden')
      }
    })
  }

  setVerticalPosition() {
    // Add top margin -> Get the center and add element/2 to it
    let topMargin = this.modal.nativeElement.clientHeight / 2 - this.window.nativeElement.clientHeight / 2

    /**
     * Prevent margins breaking the view on mobile.
     * This mostly happens when substracting env() from 100vh.
     */
    if (this.size === 'full-screen') {
      return
    }

    if (this.banner) {
      topMargin -= this.banner.nativeElement.clientHeight / 2
    }

    if (topMargin < 0) {
      topMargin = 0
    }

    if (this.size?.includes('padded')) {
      return
    }

    if (this.size === 'xxlarge' && this.window.nativeElement.clientWidth <= 400) {
      topMargin = topMargin / 2
    }

    this.window.nativeElement.style.marginTop = `${topMargin}px`
  }

  // Get small/medium/large/xlarge/xxlarge/full-screen modal size
  getSize(): string {
    if (this._loaded) {
      this.setVerticalPosition()
    }

    return this.size
  }

  keypressed(event: any) {
    if (this.dontCloseOnEscape) {
      event.stopPropagation()
      event.preventDefault()

      return false
    }

    if (!this.visible || !event.key || !event.key.toLowerCase().contains('esc')) {
      event.stopPropagation()
      event.preventDefault()

      return false
    }

    this.close()
  }
}
