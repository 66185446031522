import { ModuleWithProviders, NgModule } from '@angular/core'
import { FncAccountMenuComponent } from './fnc-account-menu/fnc-account-menu.component'
import { FncPageHeaderComponent } from './fnc-page-header/fnc-page-header.component'
import { FncPageSubheaderComponent } from './fnc-page-subheader/fnc-page-subheader.component'
import { FncPageContentComponent } from './fnc-page-content/fnc-page-content.component'
import { TlwLeftSlideoutMenuComponent } from './tlw-left-slideout-menu/tlw-left-slideout-menu.component'
import { UiComponentsModule } from '../ui-components/ui-components.module'
import { MenuDirective } from './tlw-left-slideout-menu/menu.directive'
import { LayoutService } from './logic/layout.service'
import { FncMainLayoutComponent } from './fnc-main-layout/fnc-main-layout.component'
import { RouterModule } from '@angular/router'
import { FncCardComponent } from './fnc-card/fnc-card.component'
import { CoreModule } from '../core/core.module'
import { AdministrationSwitcherComponent } from './fnc-account-menu/components/administration-switcher/administration-switcher.component'
import { ReactiveFormsModule } from '@angular/forms'
import { CardHeaderComponent } from './fnc-card/components/card-header/card-header.component'
import { CardSectionComponent } from './fnc-card/components/card-section/card-section.component'
import { TlwCardManagerComponent } from './fnc-card/components/tlw-card-manager/tlw-card-manager.component'
import { TlwContextAwareBackButtonComponent } from './context/tlw-context-aware-back-button/tlw-context-aware-back-button.component'
import { TlwContextAwareViewComponent } from './context/tlw-context-aware-view/tlw-context-aware-view.component'
import { FncPageHeaderTitleComponent } from './fnc-page-header/components/fnc-page-header-title/fnc-page-header-title.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TlwMenuItemComponent } from './tlw-left-slideout-menu/menu-item/menu-item.component'
import { TlwMenuModalsComponent } from './tlw-left-slideout-menu/modals/modals.component'
import { TlwMenuGroupComponent } from './tlw-left-slideout-menu/menu-group/menu-group.component'
import { TlwSubMenuComponent } from './tlw-left-slideout-menu/sub-menu/sub-menu.component'
import { MenuService } from './tlw-left-slideout-menu/menu.service'
import { TlwPageProgress } from './tlw-page-progress/tlw-page-progress.component'
import { LockedOutPageComponent } from './fnc-main-layout/locked-out-page/locked-out-page.component'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  imports: [CoreModule, UiComponentsModule, RouterModule, ReactiveFormsModule, DragDropModule, LottieModule],

  declarations: [
    FncPageHeaderComponent,
    FncPageHeaderTitleComponent,
    FncAccountMenuComponent,
    FncPageSubheaderComponent,
    FncPageContentComponent,
    FncCardComponent,
    TlwLeftSlideoutMenuComponent,
    TlwMenuItemComponent,
    TlwMenuGroupComponent,
    TlwSubMenuComponent,
    TlwMenuModalsComponent,
    FncMainLayoutComponent,
    TlwPageProgress,
    AdministrationSwitcherComponent,
    LockedOutPageComponent,

    MenuDirective,

    CardHeaderComponent,
    CardSectionComponent,
    TlwCardManagerComponent,
    TlwContextAwareViewComponent,
    TlwContextAwareBackButtonComponent,
  ],

  exports: [
    FncPageHeaderComponent,
    FncPageHeaderTitleComponent,
    FncPageSubheaderComponent,
    TlwLeftSlideoutMenuComponent,
    TlwMenuItemComponent,
    TlwMenuModalsComponent,
    TlwMenuGroupComponent,
    TlwSubMenuComponent,
    FncCardComponent,
    FncPageContentComponent,
    FncMainLayoutComponent,
    TlwPageProgress,

    CardHeaderComponent,
    CardSectionComponent,
    TlwCardManagerComponent,
    TlwContextAwareViewComponent,
    TlwContextAwareBackButtonComponent,
    DragDropModule,
  ],
})
export class LayoutComponentsModule {
  static forRoot(): ModuleWithProviders<LayoutComponentsModule> {
    return {
      ngModule: LayoutComponentsModule,

      providers: [LayoutService, MenuService],
    }
  }
}
