import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SecureHttp, TellowRequestOptionsArgs } from './httpclient'
import { environment } from '../../../environments/environment'
import { DebugService } from '../logic/debug.service'
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'

// TODO: Cleanup this mess. Preferably with/after upgrading to Angular5. As noted in the Angular5 JIRA ticket
@Injectable()
export class ApiGateway {
  constructor(private readonly _http: SecureHttp, private readonly _debug: DebugService) {}

  /**
   * Higher level HTTP GET for common API operations
   * @param url {string} can contain tokens (ie. /v1/account/:id/:action)
   * @param params {any} token parameters ({id: 1, action: 'activate'})
   * @param options {TellowRequestOptionsArgs} NG2 RequestOptionsArgs
   * @param noUnwrapping
   * @param noErrorNotification {boolean} If true any error that occurs during this call will not be emitted application wide.
   * @param baseUrl {string} environment.apiUrl by default
   * @returns {Observable<HttpResponse<T>>}
   */
  get<T>(
    url: string,
    params?: any,
    options?: TellowRequestOptionsArgs,
    noUnwrapping?: boolean,
    noErrorNotification?: boolean,
    baseUrl: string = environment.apiUrl,
  ): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const getFunc = this._http.get<T>(baseUrl + self.interpolateUrl(url, params), options)

    if (noUnwrapping) {
      if (!noErrorNotification) {
        // Errors on GET are usually not handled by the application. Give application a chance to handle it generically.
        getFunc.pipe(catchError((e) => this.httpGetCatch(e)))
      }

      return getFunc as any
    }

    return self.unWrapper(getFunc, !noErrorNotification)
  }

  /**
   * Higher level HTTP POST for common API operations
   * @param url {string} can contain tokens (ie. /v1/account/:id/:action)
   * @param data {any} POST body (will be serialized automatically)
   * @param params {any} token parameters ({id: 1, action: 'activate'})
   * @param options {TellowRequestOptionsArgs} NG2 RequestOptionsArgs
   * @param noUnwrapping {any} token parameters ({id: 1, action: 'activate'})
   * @param baseUrl {string} environment.apiUrl by default
   * @returns {Observable<HttpResponse<T>>>}
   */
  post<T>(
    url: string,
    data: any,
    params?: any,
    options: TellowRequestOptionsArgs = {},
    noUnwrapping?: boolean,
    baseUrl: string = environment.apiUrl,
  ): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    const postFunc = this._http.post(baseUrl + self.interpolateUrl(url, params), data && JSON.stringify(data), self.addContentType(options))

    if (noUnwrapping) {
      return <any>postFunc
    }

    return <Observable<T>>self.unWrapper(postFunc)
  }

  /**
   * Higher level HTTP PUT for common API operations
   * @param url {string} can contain tokens (ie. /v1/account/:id/:action)
   * @param data {any} PUT body (will be serialized automatically)
   * @param params {any} token parameters ({id: 1, action: 'activate'})
   * @param options {TellowRequestOptionsArgs} NG2 RequestOptionsArgs
   * @returns {Observable<HttpResponse<T>>>}
   */
  put<T>(url: string, data: any, params?: any, options?: TellowRequestOptionsArgs): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    return self.unWrapper(this._http.put(environment.apiUrl + self.interpolateUrl(url, params), JSON.stringify(data), self.addContentType(options)))
  }

  /**
   * Higher level HTTP PATCH for common API operations
   * @param url {string} can contain tokens (ie. /v1/account/:id/:action)
   * @param data {any} POST body (will be serialized automatically)
   * @param patchFields {string[]} list of fields that will be send with PATCH command.
   * @param params {any} token parameters ({id: 1, action: 'activate'})
   * @param options {TellowRequestOptionsArgs} NG2 RequestOptionsArgs
   * @param baseUrl {string} environment.apiUrl by default
   * @returns {Observable<HttpResponse<T>>>}
   */
  patch<T>(
    url: string,
    data: any,
    patchFields?: string[],
    params?: any,
    options?: TellowRequestOptionsArgs,
    baseUrl: string = environment.apiUrl,
  ): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    let patchable = {}

    // pluck fields out of original data, to create PATCH body.
    if (patchFields) {
      patchFields.forEach((pf) => (patchable[pf] = data[pf]))
    } else {
      patchable = data
    }

    return self.unWrapper(this._http.patch(baseUrl + self.interpolateUrl(url, params), JSON.stringify(patchable), self.addContentType(options)))
  }

  /**
   * Multipart upload helper
   * @param url endpoint
   * @param data can contain multiple keys. File or otherwise
   * @param params endpoint params
   * @param format formats the post body
   * @param baseUrl {string} environment.apiUrl by default
   */
  postMultipart(url: string, data: any, params?: any, format?: boolean, baseUrl: string = environment.apiUrl): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    const json = new FormData()

    for (const key in data) {
      if (data[key] instanceof File) {
        json.append(key, data[key], data[key].name)
      } else if (data[key] instanceof Array<File>) {
        data[key].forEach((image: File) => {
          json.append('file', image, image.name)
        })
      } else if (data[key] instanceof Blob) {
        json.append(key, data[key], (<Blob>data[key]).name)
      } else if (typeof data[key] === 'string' || data[key] instanceof String) {
        json.append(key, data[key])
      } else {
        if (!format) {
          json.append(key, JSON.stringify(data[key]))
        } else {
          const a = data[key].replace(/['"]+/g, '')
          json.append(key, a)
        }
      }
    }

    const headers = new HttpHeaders().append('Accept', 'application/json')
    const options: TellowRequestOptionsArgs = { headers }

    return self.unWrapper(this._http.post(baseUrl + self.interpolateUrl(url, params), json, options))
  }

  /**
   * Multipart (patch) upload helper
   * @param url endpoint
   * @param data can contain multiple keys. File or otherwise
   * @param params endpoint params
   * @param format formats the post body
   * @param baseUrl {string} environment.apiUrl by default
   */
  patchMultipart<T>(url: string, data: any, params?: any, format?: boolean, baseUrl: string = environment.apiUrl): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    const json = new FormData()

    for (const key in data) {
      if (data[key] instanceof File) {
        json.append(key, data[key], data[key].name)
      } else if (data[key] instanceof Blob) {
        json.append(key, data[key], (<Blob>data[key]).name)
      } else if (typeof data[key] === 'string' || data[key] instanceof String) {
        json.append(key, data[key])
      } else {
        if (!format) {
          json.append(key, JSON.stringify(data[key]))
        } else {
          const a = data[key].replace(/['"]+/g, '')
          json.append(key, a)
        }
      }
    }

    const headers = new HttpHeaders().append('Accept', 'application/json')
    const options: TellowRequestOptionsArgs = { headers }

    return self.unWrapper(this._http.patch(baseUrl + self.interpolateUrl(url, params), json, options))
  }

  /**
   * Higher level HTTP DELETE for common API operations
   * @param url {string} can contain tokens (ie. /v1/account/:id/:action)
   * @param params {any} token parameters ({id: 1, action: 'activate'})
   * @param options {TellowRequestOptionsArgs} NG2 RequestOptionsArgs
   * @param noUnwrapping
   * @param baseUrl {string} environment.apiUrl by default
   * @returns {Observable<void>}
   */
  delete<T>(url: string, params?: any, options?: TellowRequestOptionsArgs, noUnwrapping?: boolean, baseUrl: string = environment.apiUrl): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    const deleteFunc = this._http.delete<T>(baseUrl + self.interpolateUrl(url, params), options)

    if (noUnwrapping) {
      return <any>deleteFunc
    }

    return self.unWrapper(deleteFunc)
  }

  // See: http://www.bennadel.com/blog/3047-creating-specialized-http-clients-in-angular-2-beta-8.htm
  interpolateUrl(url: string, params: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    url = url.replace(/:([a-zA-Z]+[\w-]*)/g, function replaceToken($0, token) {
      // Try to move matching token from the params collection.
      // eslint-disable-next-line no-prototype-builtins
      if (params && params.hasOwnProperty(token)) {
        return self.extractValue(params, token)
      }

      // If a matching value couldn't be found, just replace
      // the token with the empty string.
      return ''
    })

    // Clean up any trailing comma's
    url = url.replace(/,+$/g, '')
    // Clean up any repeating slashes.
    url = url.replace(/\/{2,}/g, '/')
    // Clean up any trailing slashes.
    url = url.replace(/\/+$/g, '')

    if (!params) {
      return url
    }

    const keys = Object.keys(params)

    if (keys && keys.length) {
      for (let i = 0; i < keys.length; i++) {
        url += i == 0 ? '?' : '&'
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        url += keys[i] + '=' + params[keys[i]]
      }
    }

    return url
  }

  private unWrapper<T>(observable: Observable<T>, emit?: boolean): Observable<T> {
    return observable.pipe(
      map((res: any) => {
        if (!res) {
          return null
        }

        return res
      }),
      catchError((error: HttpErrorResponse) => {
        try {
          const result = error.error

          if (emit) {
            this.httpGetCatch(error)
          } else if (result && !result.error_code) {
            this._debug.logOutput({ status: error.status, statusText: error.statusText, body: error.message })
          }

          return observableThrowError(result)
        } catch (e) {
          this._debug.logOutput({ status: error.status, statusText: error.statusText, body: error.message })

          return observableThrowError(error)
        }
      }),
    )
  }

  private extractValue(collection, key) {
    const value = collection[key]
    delete collection[key]

    return value
  }

  private addContentType(options: TellowRequestOptionsArgs = {}) {
    if (!options.headers) {
      options.headers = new HttpHeaders()
    }

    ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('Content-Type', 'application/json; charset=UTF-8')

    return options
  }

  private httpGetCatch(e: any) {
    this._debug.logErrorOnGet(e)

    return observableThrowError(e)
  }
}
