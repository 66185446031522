import { EventEmitter, Injectable, NgZone } from '@angular/core'
import { FirebasePushMessageService } from './firebase-push-message.service'
import { AdministrationService } from '../administration/administration.service'
import { PushNotification } from './push-notification.model'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { PushActionTypes } from './push-action-types.constants'
import { SegmentHelper } from '../external-services/segment.helper'

const DEVELOPMENT = process.env.NODE_ENV === 'development'

@Injectable()
export class AdministrationNotificationService {
  onAdministrationNotificationReceived = new EventEmitter<PushNotification>()

  constructor(
    private _firebasePushService: FirebasePushMessageService,
    private _administrationService: AdministrationService,
    private _router: TranslateRouteService,
    private _segment: SegmentHelper,
    private _ngZone: NgZone,
  ) {
    this._firebasePushService.onPushNotificationReceived.subscribe((n) => this.filterNotification(n))
  }

  private filterNotification(notification: PushNotification): void {
    this._administrationService.defaultAdministration.subscribe((administration) => {
      // Only continue if addressed administration is the current administration.
      if (notification.data.administration == administration.id) {
        this.onAdministrationNotificationReceived.emit(notification)
        this.createNavigatableNotification(notification)
      }

      if (DEVELOPMENT) {
        this.debugFirebaseMessage(notification)
      }
    })
  }

  private debugFirebaseMessage(data: PushNotification): void {
    new window.Notification(data.notification.title, {
      icon: 'assets/images/push-message-logo.png',
      body: data.notification.body,
    })
  }

  private createNavigatableNotification(notification: PushNotification): void {
    const push = new window.Notification(notification.notification.title, {
      icon: 'assets/images/push-message-logo.png',
      body: notification.notification.body,
    })

    this._segment.track('Notification - Open', {
      content: notification.data.action,
      id: notification.data.id,
      source: 'tellow',
    })

    push.onclick = () => {
      this._ngZone.run(() => {
        push.close()

        let url: string

        switch (notification.data.action) {
          case PushActionTypes.TRANSACTION:
            url = `/transactions/${notification.data.id};bankAccount=${notification.data.bank_account_id}`
            break
          case PushActionTypes.INVOICE:
            url = `/invoices/${notification.data.id}`
            break
          case PushActionTypes.EXPENSE:
            url = `/expenses/purchaseInvoice/${notification.data.id}`
            break
          default:
            break
        }

        void this._router.navigate(url)
      })
    }
  }
}
