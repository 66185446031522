import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Moment } from 'moment'
import { Justification } from '../../domain/justification/justification.model'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationService } from '../administration/administration.service'
import { ApiGateway } from '../../core/remote/api.gateway'
import { TransactionListItem } from '../../domain/transaction/transaction-listitem.model'
import { Transaction } from '../../domain/transaction/transaction.model'
import { JustificationTypes } from '../../domain/justification/justificationtypes.constants'
import { Account } from '../../domain/administration/account.model'
import { InvoiceType } from '../../domain/invoice/type'
import { Invoice } from '../../domain/invoice/invoice.model'
import { globals } from '../../shared/config/globals'
import { SegmentHelper } from '../external-services/segment.helper'

@Injectable()
export class InvoiceJustificationService {
  constructor(
    private readonly _administrationService: AdministrationService,
    private readonly _apiGateway: ApiGateway,
    private readonly _segment: SegmentHelper,
  ) {}

  getJustifications(invoice: InvoiceType): Observable<Justification[]> {
    return this.makeInvoiceApiCall(invoice, (p) => this._apiGateway.get(endpoints.justifications.invoice, p))
  }

  justifyWithTransaction(invoice: InvoiceType, tx: Transaction | TransactionListItem, amount?: number): Observable<Invoice> {
    const body = {
      type: JustificationTypes.INVOICE,
      transaction_id: tx.id,
      amount: amount,
    }

    return this.makeInvoiceApiCall(invoice, (p, o) => this._apiGateway.post(endpoints.justifications.invoice, o, p), body)
  }

  justifyAsPrivate(invoice: InvoiceType, description?: string, amount?: number, bookingDate?: Moment): Observable<Invoice> {
    const body = {
      type: JustificationTypes.PRIVATE,
      amount: amount,
      remarks_user: description,
      booking_date: bookingDate && bookingDate.format(globals.dateFormat),
    }

    if (!body.remarks_user) {
      delete body.remarks_user
    }
    if (amount === null || amount === undefined) {
      delete body.amount
    }
    if (!bookingDate) {
      delete body.booking_date
    }

    return this.makeInvoiceApiCall(invoice, (p, o) => this._apiGateway.post(endpoints.justifications.invoice, o, p), body)
  }

  justifyAsPaymentDifference(invoice: InvoiceType, amount?: number, bookingDate?: Moment): Observable<Invoice> {
    const body = {
      type: JustificationTypes.PAYMENT_DIFFERENCE,
      amount: amount,
      booking_date: bookingDate && bookingDate.format(globals.dateFormat),
    }

    if (amount === null || amount === undefined) {
      delete body.amount
    }
    if (!bookingDate) {
      delete body.booking_date
    }

    return this.makeInvoiceApiCall(invoice, (p, o) => this._apiGateway.post(endpoints.justifications.invoice, o, p), body)
  }

  justifyWithManualAccount(invoice: InvoiceType, account: Account, description?: string, amount?: number, bookingDate?: Moment): Observable<Invoice> {
    const body = {
      type: JustificationTypes.MANUAL,
      account_id: account.id,
      amount: amount,
      remarks_user: description,
      booking_date: bookingDate && bookingDate.format(globals.dateFormat),
    }

    return this.makeInvoiceApiCall(invoice, (p, o) => this._apiGateway.post(endpoints.justifications.invoice, o, p), body)
  }

  deleteJustification(invoice: InvoiceType, justification: Justification, location?: string): Observable<Invoice> {
    // Segment
    this._segment.track('Justification - Deleted', {
      id: justification.id,
      location: location,
      'transaction id': justification.transaction ? justification.transaction.id : null,
      'invoice id': justification.invoice ? justification.invoice.id : null,
      'document id': justification.document ? justification.document.id : null,
      'vat declaration id': justification.vat_declaration ? justification.vat_declaration.id : null,
      type: justification.type.toLowerCase().replace('_', ' '),
    })

    return this.makeInvoiceApiCall(invoice, (p, o) => this._apiGateway.delete(endpoints.justifications.invoice, p, o), null, {
      justificationId: justification.id,
    })
  }

  private makeInvoiceApiCall<T>(invoice: InvoiceType, call: (params?: any, object?: any) => Observable<T>, object?: any, params?: any): Observable<T> {
    if (!params) {
      params = {}
    }

    params.invoiceId = invoice.id

    return this._administrationService.makeApiCallForDefaultAdministration(call, params, object)
  }
}
