import { InfiniteScrollSearchObject } from '../../core/logic/infinite-scroll.searchobject'
import moment from 'moment'
import { globals } from '../../shared/config/globals'

export class TransactionSearchObject extends InfiniteScrollSearchObject {
  debit?: boolean
  bankAccountId: number
  filterjustified: boolean
  is_bookable?: boolean

  startDate?: moment.Moment
  endDate?: moment.Moment

  minAmount?: number
  maxAmount?: number

  status: string

  constructor() {
    super()
    this.addExtraParameters = (params) => this.addParams(params)
  }

  private addParams(params: any): void {
    if (!this.bankAccountId) {
      throw new Error('TransactionSearchObject: bankAccountId is required!')
    }

    if (this.bankAccountId !== Infinity) {
      params.bankAccountId = this.bankAccountId
    }

    params.filterjustified = this.filterjustified
    if (this.is_bookable !== undefined) {
      params.is_bookable = this.is_bookable
    }

    if (this.startDate) {
      params.start_date = this.startDate.format(globals.dateFormat)
    }

    if (this.endDate) {
      params.end_date = this.endDate.format(globals.dateFormat)
    }

    if (this.minAmount) {
      params.amount_min = this.minAmount
    }

    if (this.maxAmount) {
      params.amount_max = this.maxAmount
    }

    if (this.debit === true) {
      params.type = 'debit'
    } else if (this.debit === false) {
      params.type = 'credit'
    }
  }
}
