import { AccountingService } from './accounting.service'
import { Injectable } from '@angular/core'
import { FiscalYearStates } from '../../domain/accounting/fiscal-year.constants'
import moment from 'moment'
import { FiscalYearWithYearIdentifier } from '../../domain/accounting/fiscal-year.model'

/*
This class wil help overview screens to quickly and efficiently determine if entries are removable.
Based on the booking date and fiscal year state.
 */
@Injectable()
export class RemovableBookingHelper {
  private _removableYears: { [year: number]: boolean }
  private _removableYearsInitialized: boolean
  private _removeableBookingResults: { [id: number]: boolean }

  constructor(private readonly _accountingService: AccountingService) {}

  init() {
    this._removableYearsInitialized = false
    this._removableYears = {}
    this._removeableBookingResults = {}

    this._accountingService.fiscalYears.subscribe((years: FiscalYearWithYearIdentifier[]) => {
      years.forEach((year) => (this._removableYears[year.year] = year.status != FiscalYearStates.CLOSED))
      this._removableYearsInitialized = true
    })
  }

  isRemovableBooking<TB extends { id: number }>(booking: TB, getBookingDate: (b: TB) => string): boolean {
    if (!this._removableYearsInitialized) {
      return false
    }

    const cachedResult = this._removeableBookingResults[booking.id]

    if (cachedResult === true || cachedResult === false) {
      return cachedResult
    }

    const date = moment(getBookingDate(booking))
    this._removeableBookingResults[booking.id] = this._removableYears[date.year()]

    return this._removeableBookingResults[booking.id]
  }
}
