export class BrowserHelper {
  private _window: Window = window
  private _root: HTMLElement

  private get root(): HTMLElement {
    if (this._root) {
      return this._root
    }

    this._root = this._window.document.getElementsByTagName('html')[0]

    return this._root
  }

  isIE(): boolean {
    return this.root.classList.contains('ie')
  }
}
