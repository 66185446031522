import { Component, Input, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { ContactsService } from '../../../logic/administration/contacts.service'
import { environment } from '../../../../environments/environment'
import { MenuItems, MenuService } from '../menu.service'

@Component({
  selector: 'menu-item',
  template: `
    <a
      routerLinkActive="active"
      [class.force-active]="getForcedActiveState"
      [routerLinkActiveOptions]="{ exact: exact || true }"
      [routerLink]="'/' + path || '' | translateRoute"
      [attr.section]="formatSection()"
      (click)="closeOtherMenus()"
    >
      <ng-content></ng-content>
    </a>
  `,
})
export class TlwMenuItemComponent implements OnDestroy {
  @Input() path: string
  @Input() paths: string[]

  @Input() exact: boolean
  @Input() section: string

  @Input() closeOthers?: MenuItems | 'all'

  protected get hasMultiplePaths(): boolean {
    return this.paths !== undefined
  }

  protected get getForcedActiveState(): boolean {
    return this.hasMultiplePaths
      ? // Use this for multiple paths
        this.menu.isActiveArray(this.paths, this.exact)
      : // Find for single path
        this.menu.isActive(this.path, this.exact)
  }

  constructor(private readonly _contact: ContactsService, private readonly _router: Router, public readonly menu: MenuService) {}

  /**
   * Set contact filter back to
   * default when leaving contact route
   */
  ngOnDestroy() {
    const { url } = this._router
    if (url.startsWith(`/${environment.routerLinks.contacts.index}`)) {
      this._contact.companyIsSelected = true
    }
  }

  closeOtherMenus(): void {
    if (this.closeOthers) this.menu.closeAllOthers(this.closeOthers === 'all' ? undefined : this.closeOthers)
  }

  /**
   * Format sections and paths
   * depending on component inputs.
   */
  formatSection(): string {
    return this.section ? this.section.toUpperCase() : this.path.split('/')[0].toUpperCase()
  }
}
