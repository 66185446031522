/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core'
import { concatMap, take, takeWhile } from 'rxjs/operators'
import { Observable, timer } from 'rxjs'
import * as compareVersions from 'compare-versions'
import { version } from '../../../environments/version'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { AdministrationService } from '../administration/administration.service'
import { ToastrHelper } from '../helpers/toastr.helper'

type SemanticVersion = `${number}.${number}.${number}`
type VersionWorkerResponse = {
  latestKnownBuild: SemanticVersion
  latestNotionVersion: SemanticVersion
}

@Injectable({
  providedIn: 'root',
})
export class CloudflareWorkerHelper {
  private version$: Observable<VersionWorkerResponse> = this._http.get<VersionWorkerResponse>(environment.versionWorkerApiUrl)
  private _done: boolean

  constructor(private readonly _toastr: ToastrHelper, private readonly _http: HttpClient, private readonly _administration: AdministrationService) {
    void this.init()
  }

  init() {
    void this.pollForNewVersion()
  }

  isStatusCompleted(): boolean {
    return Boolean(this._done)
  }

  /**
   * RxJS Polling mechanism.
   * Checks if a new version is present once per hour.
   *
   * Version is fetched from Github, it is set in the deployment pipeline,
   * through a Cloudflare Worker, which securely filters
   * the data.
   *
   * @param {number} seconds to set the timer wait for.
   *
   * @see https://betterprogramming.pub/implement-polling-using-rxjs-in-your-angular-application-278129d5a9a9
   * @see https://blog.strongbrew.io/rxjs-polling/
   */
  pollForNewVersion(seconds: number = 3600): void {
    if (process.env.NODE_ENV === 'development') {
      return console.debug('Not notifying new version. Development mode.')
    }

    const secondsToMilliseconds = seconds * 1000
    timer(0, secondsToMilliseconds)
      .pipe(
        concatMap(() => this.version$),
        takeWhile(() => !this.isStatusCompleted()),
      )
      .subscribe(({ latestKnownBuild }: { latestKnownBuild: SemanticVersion }) => {
        this._done = false

        if (this.latestNotification() === version) {
          console.info('Already notified of new release.')

          return
        }

        const notice = `
        Er is een nieuwe software-versie van Tellow beschikbaar.
        Voor optimale werking van Tellow raden we aan de pagina te verversen.
        `.trim()

        const title = 'Nieuwe versie'

        /**
         * Check if:
         * 1. Current version in use is lower than new version.
         */
        const newVersionAvailable = compareVersions.compare(version, latestKnownBuild, '<')

        this.saveNotification(version)

        if (newVersionAvailable) {
          this._toastr.info(notice, title, { autoClose: false }).afterClosed.subscribe(() => {
            window.location.href = window.location.href
          })
        }
      })
  }

  saveNotification(version: string): void {
    localStorage.setItem('latestNotifiedVersion', version)
  }

  latestNotification(): string {
    return localStorage.getItem('latestNotifiedVersion')
  }
}
