export enum ActivationState {
  IN_REVIEW_AT_SWAN = 'in_review_at_swan',
  ACTIVATE_CARD = 'activate_card',
  FUND_ACCOUNT = 'fund_account',
  ADD_CARD_TO_WALLET = 'add_card_to_wallet',
  RECEIVE_A_GIFT = 'receive_a_gift',
}

export type ActivationStateComponentModel = {
  name: ActivationState
  title: string
  description: string
  image: {
    url: string
    rightAligned: boolean
  }
  action?: {
    actionText: string
    redirectAction: () => void
  }
  isDismissable: boolean
}
