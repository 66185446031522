import { FormControl, ValidatorFn, Validators } from '@angular/forms'
import { QuestionKeys } from './tlw-onboarding-modal.component'

export type FieldControlTypes = string | string[] | number | null
export type FieldControlOptions = {
  title?: string
  type?: string
  field: string
  pattern: string | RegExp
  required: boolean
  validators?: ValidatorFn[]
}

/**
 * Extended class based on FormControl.
 * This is required as backend passes us back 'uuid' keys
 * for field names, and we do not want to keep passing
 * these around in other places other than this control.
 */
export default class FieldControl<T = any> extends FormControl {
  private _type: string
  private _field: string
  private _title: string
  private _required: boolean

  constructor(value: string | string[] | number | null, options: FieldControlOptions) {
    super()

    // Set variables
    this.type = options?.type
    this.title = options?.title
    this.field = options?.field
    this.pattern = options?.pattern
    this.required = options?.required

    // Warn developer when required data is missing.
    if (
      Object.entries(options)
        .filter(([key]) => ['title', 'field', 'required'].includes(key))
        .some((option) => option === undefined)
    ) {
      throw new Error('Not all values set properly.')
    }

    // Run methods.
    this.setValue(value)
    this.addValidators(options?.validators)
    this.updateValueAndValidity()
  }

  get required(): boolean {
    return this._required
  }

  /**
   * Add the Validators.required validator
   * to this FormControl programmatically.
   */
  set required(value: boolean) {
    this._required = value
    if (value === true) this.addValidators(Validators.required)
  }

  /**
   * Add a validation pattern through
   * the Validators.pattern method.
   */
  set pattern(value: string | RegExp) {
    this.addValidators(Validators.pattern(value))
    this.updateValueAndValidity()
  }

  /**
   * Add the proper autocomplete strings
   * to the inputs based on field titles.
   */
  get autocomplete(): string {
    switch (this.title as QuestionKeys) {
      case 'bedrijfsnaam':
        return 'organization'
      case 'postcode':
        return 'postal-code'
      case 'plaats':
        return 'address-line3'
      case 'telefoonnummer':
        return 'tel'
      case 'land':
        return 'country'
      case 'straat':
        return 'street-address'
      default:
        break
    }
  }

  /**
   * Get the input type;
   * useful for HTML input.
   */
  get type(): string {
    return this._type
  }

  /**
   * Set the HTML input field
   * type according to backend
   * indicated type value.
   */
  set type(type: string) {
    switch (type) {
      case 'integer': {
        this._type = 'number'
        break
      }
      case 'text':
      default: {
        this._type = 'text'
        break
      }
    }
  }

  /**
   * Get the i18n token.
   * You will likely have to concat this
   * with the object keys it is nested in.
   */
  get title(): string {
    return this._title
  }

  /**
   * Set the internal i18n token.
   */
  set title(value: string) {
    this._title = value
  }

  /**
   * get the UUID returned by backend.
   */
  get field(): string {
    return this._field
  }

  /**
   * Set the UUID, in the response,
   * the key is called 'field'.
   */
  set field(v: string) {
    this._field = v
  }

  /**
   * Method to convert values
   * easily when saving to backend.
   */
  get getAsField(): { [key: string]: T } {
    return { [this.field]: this.value }
  }
}
