import { environment } from '../../../environments/environment'
import { TellowColor } from './tellow-color'

export class TellowContext {
  static HOME = new TellowContext('HOME', TellowColor.GREEN, '/')
  static INVOICES = new TellowContext('INVOICES', TellowColor.BLUE, environment.routerLinks.invoices.index)
  static QUOTES = new TellowContext('QUOTES', TellowColor.BLUE, environment.routerLinks.quotes.index)
  static EXPENSES = new TellowContext('EXPENSES', TellowColor.ORANGE, environment.routerLinks.expenses.index)
  static VAT = new TellowContext('VAT', TellowColor.PURPLE, environment.routerLinks.vat.index)
  static TRANSACTIONS = new TellowContext('TRANSACTIONS', TellowColor.GREEN, environment.routerLinks.transactions.index)
  static DOCUMENTS = new TellowContext('DOCUMENTS', TellowColor.GREEN, environment.routerLinks.documents.index)
  static CONTACTS = new TellowContext('CONTACTS', TellowColor.GREEN, environment.routerLinks.contacts.index)
  static REPORTING = new TellowContext('REPORTING', TellowColor.BLUE, environment.routerLinks.reporting.index)
  static SETTINGS = new TellowContext('SETTINGS', TellowColor.GREEN, environment.routerLinks.settings.index)

  static TellowContextDictionary = {
    HOME: TellowContext.HOME,
    INVOICES: TellowContext.INVOICES,
    QUOTES: TellowContext.QUOTES,
    EXPENSES: TellowContext.EXPENSES,
    VAT: TellowContext.VAT,
    TRANSACTIONS: TellowContext.TRANSACTIONS,
    DOCUMENTS: TellowContext.DOCUMENTS,
    CONTACTS: TellowContext.CONTACTS,
    REPORTING: TellowContext.REPORTING,
    SETTINGS: TellowContext.SETTINGS,
  }

  constructor(private readonly _context: string, private readonly _color: TellowColor, private readonly _route: string) {}

  get context(): string {
    return this._context
  }

  get color(): string {
    return this._color.className
  }

  get route(): string {
    return this._route
  }

  // TODO: Plural context object type translation key/Single context object type translation key?
}
