import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[for]',
})
export class FncValidatorMessageForDirective {
  @Input('for') messageFor: string
  private _shown = false

  constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef) {
    this.viewContainer.clear()
  }

  show(show: boolean) {
    if (show == this._shown) {
      return
    }

    this._shown = show

    if (show) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }
}
