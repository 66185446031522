import { routerlinks } from './routerlinks/routerlinks-nl'
import { Environment } from './environment.interface'
import { test } from './envs/test'
import { baseEnvironment } from './base.environment'

export const environment: Environment = {
  ...baseEnvironment,
  environmentName: test.environmentName,
  production: test.production,
  baseUrl: test.baseUrl,
  isDevelopmentMachine: test.isDevelopmentMachine,
  ng2ProductionMode: test.ng2ProductionMode,
  verboseDebugOutput: test.verboseDebugOutput,
  deployTestScreens: test.deployTestScreens,
  routerLinks: routerlinks,
  defaultLanguage: 'nl',
  firebase: test.firebase,
  intercomAppId: test.intercomAppId,
  apiUrl: test.apiUrl,
  clientId: test.clientId,
  clientSecret: test.clientSecret,
  kvkApiUrl: test.kvkApiUrl,
  billingServiceApiUrl: test.billingServiceApiUrl,
  stripeAntiCancellationCoupon: test.stripeAntiCancellationCoupon,
  taxServiceApiUrl: test.taxServiceApiUrl,
  bankServiceApiUrl: test.bankServiceApiUrl,
  segmentApiKey: test.segmentApiKey,
  baremetricsCancellationAccessToken: test.baremetricsCancellationAccessToken,
  versionWorkerApiUrl: test.versionWorkerApiUrl,
  userflowToken: test.userflowToken,
  paymentsServiceApiUrl: test.paymentsServiceApiUrl,
  agerasFinanceAppUrl: test.agerasFinanceAppUrl,
  integrationsServiceApiKey: test.integrationsServiceApiKey,
  integrationsServiceApiUrl: test.integrationsServiceApiUrl,
  billingSwitchDate: test.billingSwitchDate,
  basisUpsellPrice: test.basisUpsellPrice,
  plusUpsellPrice: test.plusUpsellPrice,
  completeUpsellPrice: test.completeUpsellPrice,
  swanBankingUrl: test.swanBankingUrl,
  laravelServiceApiUrl: test.laravelServiceApiUrl,
  recaptchaSiteKey: test.recaptchaSiteKey,
}
