import { EventEmitter, Injectable } from '@angular/core'
import { catchError, map, mergeMap, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { SecureHttp } from '../../core/remote/httpclient'
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs'
import { AdministrationService } from './administration.service'
import { Administration } from '../../domain/administration/administration.model'
import { InstitutionResponse, Provider } from '../../domain/bank/institution.model'
import { AccessTokenResponse } from '../../domain/bank/auth.model'
import { AiiaAccountResponse, AiiaAccountRichResponse } from '../../domain/bank/account.model'
import { HttpErrorResponse } from '@angular/common/http'
import { prod } from '../../../environments/envs/prod'
import { IntercomService } from '../external-services/intercom.service'
import { ToastrHelper } from '../helpers/toastr.helper'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { TranslateService } from '@ngx-translate/core'
import { Cacheable } from 'ts-cacheable'
import { BankAccountService } from './bankaccounts.service'
import { Bank } from '../../domain/bankAccount/administration-bank-account.model'
import { UserService } from '../user/user.service'

const cacheBusterObserver = new Subject<void>()

export type AiiaConsentRenewalResponse = {
  authUrl: string | null
  expiresAt: string
  status: string
}

@Injectable({
  providedIn: 'root',
})
export class BankService {
  bankCacheBusted = new EventEmitter<void>()

  constructor(
    private readonly _http: SecureHttp,
    private readonly _intercom: IntercomService,
    private readonly _administration: AdministrationService,
    private readonly _toastr: ToastrHelper,
    private readonly _routerService: TranslateRouteService,
    private readonly _translate: TranslateService,
    private readonly _banks: BankAccountService,
    private readonly _user: UserService,
  ) {
    this.countExpiredConsents()
    this._administration.onSwitchedAdministration.subscribe(() => this.clearCache())
  }

  public clearCache(): void {
    cacheBusterObserver.next()
    this.bankCacheBusted.emit()
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  aiiaAccounts(): Observable<AiiaAccountResponse[]> {
    return this._administration.defaultAdministration.pipe(
      switchMap((administration: Administration) => {
        return this._http.get<AiiaAccountResponse[]>(`${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/accounts`)
      }),
      catchError((err) => {
        console.error(err)

        return of([])
      }),
    )
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  aiiaAccount(id: string, consent: string): Observable<AiiaAccountRichResponse> {
    return this._administration.defaultAdministration.pipe(
      switchMap((administration: Administration) => {
        return this._http.get<AiiaAccountRichResponse>(`${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/${consent}/accounts/${id}`)
      }),
      catchError((err) => {
        console.error(err)

        return of(undefined)
      }),
    )
  }

  @Cacheable({ maxAge: 60 * 60 * 1000, cacheBusterObserver })
  getAllRichAiiaAccounts(): Observable<AiiaAccountRichResponse[]> {
    return this.aiiaAccounts().pipe(
      switchMap((accounts: AiiaAccountResponse[]) => forkJoin(accounts.map((account: AiiaAccountResponse) => this.aiiaAccount(account.id, account.consentId)))),
      startWith([]),
    )
  }

  /**
   * Used to show amount of expired consents on the dashboard.
   * This should be in line with the other data, since
   * both sources use 'this.aiiaAccounts()' to obtain.
   */
  countExpiredConsents(): Observable<number> {
    return this.aiiaAccounts().pipe(
      switchMap((accounts: AiiaAccountResponse[]) => forkJoin(accounts.map((account: AiiaAccountResponse) => this.aiiaAccount(account.id, account.consentId)))),
      map((combinedAccounts) => combinedAccounts.filter((account) => account.entity.connectionRequiresUpdate === true).length),
      tap((required) => this._intercom.updateIntercomFields({ aiiaconnectionUpdateRequired: required.toString() })),
      startWith(0),
    )
  }

  /**
   * Revoke Item. Should preferably not/never be used since it
   * changes the transaction IDs if the user later re-connects
   * and thus kills our ability to do transaction de-duplication.
   *
   * Can be used if we're terminating an administration for instance.
   */
  revoke(id: string, consent: string): Observable<null> {
    const administration = this._administration.defaultAdministration
    const account = this.aiiaAccount(id, consent)

    return forkJoin([account, administration]).pipe(
      switchMap(([account, administration]) => {
        return this._http.delete(`${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/auth/revoke-consent/${account.entity.consentId}`)
      }),
      tap(() => this.clearCache()),
      shareReplay(1),
      catchError((err) => {
        console.error(err)

        return of(undefined)
      }),
    )
  }

  /**
   * Insert logos/hex codes from
   * monolith backend database.
   */
  private _decorateWithBankPresentation(institutions: InstitutionResponse[], bank: Bank): InstitutionResponse {
    return {
      /**
       * Only take the first bit, so that 'regio bank' becomes 'regio',
       * and 'regio bank' (without bank) will match 'regiobank'.
       *
       * This also applies (and works) for all other banks, example;
       * - SNS Bank -> SNS -> matches SNS bank and/or SNSBank.
       */
      ...institutions.find(({ name }) => name.includes(bank.name.split(' ')[0])),
      /**
       * Mirror 'presentation'-object that Aiia supplies,
       * to keep in line with the current types we have.
       */
      presentation: {
        color: bank.primary_color_hex,
        logos: {
          large: {
            png: bank.light_logo_url,
          },
        },
      },
    }
  }

  public readonly currentBetaBanks: Bank[] = []

  /**
   * Get supported Aiia institutions (i.e. banks).
   * @see https://bank.test.tellow.nl/api/docs
   */
  @Cacheable()
  institutions(): Observable<InstitutionResponse[]> {
    return this._http.get<InstitutionResponse[]>(`${prod.bankServiceApiUrl}/v1/aiia/providers`).pipe(
      // 1. Process obtained providers.
      map((institutions: InstitutionResponse[]) =>
        // 1a. Only take Dutch banks.
        institutions.filter(({ countryCode }) => countryCode === 'NL'),
      ),
      mergeMap((institutions) =>
        // 2. Get all supported banks (from the database)
        combineLatest([this._banks.getSupportedBanks(), this._user.isTellowEmployee])
          // 3. Take out 'TEST' bank from test environment, and the Tellow/Swan bank from our own database.
          // 4. Remove any duplicates based on bank name.
          // 5. Enrich them with 'presentation' (logo, hex colors)
          //    this way, we don't have to worry about logo's anywhere else.
          .pipe(
            map(([banks, isEmployee]) =>
              banks
                .concat(isEmployee ? this.currentBetaBanks : [])
                .filter(({ name }) => (name as string) !== 'TEST' && (name as string) !== 'Tellow')
                .filter(({ name }, index, self) => self.findIndex((bank) => bank.name === name) === index)
                .map((bank) => this._decorateWithBankPresentation(institutions, bank)),
            ),
          ),
      ),
    )
  }

  /**
   * Get the AIIA connect link through our own API
   *
   * @see https://bank.test.tellow.nl/api/docs
   * @see https://docs.aiia.eu/#/auth/flow/
   */
  getAiiaConnectLink(providerId: string = Provider.Rabobank, consentId?: string): Observable<string | boolean> {
    return this._administration.defaultAdministration.pipe(
      switchMap((administration: Administration) => {
        const s = environment.routerLinks.settings
        const redirectUrl = this._routerService.translate(`${environment.baseUrl}${s.index}/${s.bank}/${s.bankAccountConnections}/callback`)
        const url = `${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/connect/link`

        return this._http.get<string>(url, {
          params: {
            redirectUrl,
            providerId,
            ...(consentId !== undefined ? { consentId } : {}),
          },
        })
      }),
      shareReplay(1),
      catchError((response) => {
        // The API will return a HTTP 301 or 302 with the link
        if (response.status == 301 || response.status == 302) {
          return of(response.error.link)
        } else {
          console.error(response)

          return of(false)
        }
      }),
    )
  }

  requestAiiaOauthTokens(code: string, consentId: string): Observable<AccessTokenResponse | boolean> {
    return this._administration.defaultAdministration.pipe(
      switchMap((administration: Administration) => {
        const s = environment.routerLinks.settings
        const redirectUrl = this._routerService.translate(`${environment.baseUrl}${s.index}/${s.bank}/${s.bankAccountConnections}/callback`)

        const url = `${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/auth/access-token`
        const body = {
          code: code,
          consentId: consentId,
          redirectUrl: redirectUrl,
        }

        return this._http.post<AccessTokenResponse>(url, body)
      }),
      shareReplay(1),
      catchError((err) => {
        console.error(err)

        return of(false)
      }),
    )
  }

  initiateManualSync(aiiaAccountId: string, consent: string): Observable<AiiaConsentRenewalResponse | HttpErrorResponse | boolean> {
    const administration = this._administration.defaultAdministration
    const account = this.aiiaAccount(aiiaAccountId, consent)

    return forkJoin([administration, account]).pipe(
      switchMap(([administration, account]) => {
        const s = environment.routerLinks.settings
        const redirectUrl = this._routerService.translate(`${environment.baseUrl}${s.index}/${s.bank}/${s.bankAccountConnections}`)

        const url = `${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/auth/update`
        const body = {
          consentId: account.entity.consentId,
          redirectUrl,
        }

        return this._http.post<AiiaConsentRenewalResponse>(url, body)
      }),
      shareReplay(1),
      catchError((err) => {
        console.error(err)

        return of(false)
      }),
    )
  }

  /**
   * Does the same as 'initiateManualSync' above,
   * but instead you can use a 'consentId'
   * when you already have obtained one.
   *
   * TODO: Refactor and (possibly) merge the two.
   * Note: Protected for internal use for now.
   */
  protected initiateManualSyncWithConsentId(consentId: string): Observable<AiiaConsentRenewalResponse | HttpErrorResponse | boolean> {
    return this._administration.defaultAdministration.pipe(
      switchMap((administration) => {
        const s = environment.routerLinks.settings
        const redirectUrl = this._routerService.translate(`${environment.baseUrl}${s.index}/${s.bank}/${s.bankAccountConnections}`)

        const url = `${environment.bankServiceApiUrl}/v1/aiia/${administration.id}/auth/update`
        const body = {
          consentId: consentId,
          redirectUrl,
        }

        return this._http.post<AiiaConsentRenewalResponse>(url, body)
      }),
      shareReplay(1),
      catchError((err) => {
        console.error(err)

        return of(false)
      }),
    )
  }

  /**
   * Currently unused, but still useful
   * to leave in for possible later use.
   */
  protected noticeForConsentExpiration(accounts: AiiaAccountResponse[]): void {
    Array.from(new Set(accounts.map((a) => a.consentId)))
      // For each consent, check update required, notify.
      .forEach((consent) => {
        const accountsForThisConsent = accounts.filter((account) => account.consentId === consent)
        const connectionRequiresUpdate = accountsForThisConsent.some((account) => account.connectionUpdateRequired)

        if (connectionRequiresUpdate) {
          const ibans = accountsForThisConsent.map(({ number }) => number?.iban?.slice(-4)).join(', ') ?? ''
          const title = this._translate.instant('ERRORS.SEVERITY.ACTION_REQUIRED')
          const body = this._translate.instant('MESSAGES.CONSENT_EXPIRED_TOAST', { ibans })

          void this._toastr.warning(body, title, { duration: 15000 })
        }
      })
  }

  /**
   * Renew consent on an existing Aiia account.
   * For internal use, hence the protection.
   */
  protected renewConsent(consentId: string): Observable<string> {
    return this.initiateManualSyncWithConsentId(consentId).pipe(
      map((res) => (res as AiiaConsentRenewalResponse)?.authUrl),
      take(1),
    )
  }
}
