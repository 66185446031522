import { CreateHotToastRef, HotToastService, ObservableMessages, ToastConfig } from '@ngneat/hot-toast'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

export type ToastSeverity = 'info' | 'success' | 'warning' | 'error' | 'action'
export interface ToastrDataType {
  title: string
  message: string
  severity?: ToastSeverity
}

@Injectable()
export class ToastrHelper {
  constructor(private _toastr: HotToastService) {}

  info(message: string, title: string = '', opts: Partial<ToastConfig> = {}): CreateHotToastRef<any> {
    return this._toastr.info(this._template(message, title), {
      ...this.infoConfig,
      ...opts,
    })
  }

  success(message: string, title: string = '', opts: Partial<ToastConfig> = {}): CreateHotToastRef<any> {
    return this._toastr.success(this._template(message, title), opts)
  }

  warning(message: string, title: string = '', opts: Partial<ToastConfig> = {}): CreateHotToastRef<any> {
    return this._toastr.warning(this._template(message, title), opts)
  }

  error(message: string, title: string = '', opts: Partial<ToastConfig> = {}): CreateHotToastRef<any> {
    return this._toastr.error(this._template(message, title), opts)
  }

  loading(message?: string, opts: Partial<ToastConfig> = {}): CreateHotToastRef<any> {
    return this._toastr.loading(message, {
      ...this.loadingConfig,
      ...opts,
    })
  }

  observe(args: ObservableMessages<any, any>): (source: Observable<any>) => Observable<any> {
    return this._toastr.observe(args)
  }

  private _template(message: string, title: string): string {
    return `<b>${title}</b><p>${message}</p>`
  }

  private infoConfig: Partial<ToastConfig> = {
    className: 'info',
  }

  private actionConfig: Partial<ToastConfig> = {
    className: 'action',
  }

  private loadingConfig: Partial<ToastConfig> = {
    className: 'loading',
  }
}
