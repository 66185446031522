import { of as observableOf } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FncModalComponent } from '../../ui-components/fnc-modal/fnc-modal.component'
import { SecureHttp } from '../../core/remote/httpclient'
import { TlwErrorModalComponent } from '../../ui-components/tlw-error-modal/tlw-error-modal.component'
import { globals } from '../../shared/config/globals'
import moment from 'moment'
import { PaymentService } from '../../logic/administration/payment.service'
import { AuthorizationService } from '../../logic/security/authorization/authorization.service'
import { Administration } from '../../domain/administration/administration.model'
import { User } from '../../domain/user/user.model'
import { UserService } from '../../logic/user/user.service'
import { AdministrationService } from '../../logic/administration/administration.service'

/**
 * @deprecated
 */
@Component({
  selector: 'paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss'],
})
export class PaywallComponent implements OnInit {
  get paid(): boolean {
    return this._paid
  }

  get timeForSnow(): boolean {
    return this._timeForSnow
  }

  get paying(): boolean {
    return this._paying
  }

  get userIsOwner(): boolean {
    return this._userIsOwner
  }

  get agreementsUrl(): string {
    return globals.urls.termsAndAgreement
  }

  get userName(): string {
    return this._user && this._user.first_name
  }

  get adminName(): string {
    return this._administration && this._administration.name
  }

  releaseConfetti: boolean
  newYear: boolean
  @ViewChild(FncModalComponent, { static: true }) modal: FncModalComponent
  @ViewChild(TlwErrorModalComponent, { static: true }) errorModal: TlwErrorModalComponent
  @ViewChild('payContent') payContent: ElementRef
  @ViewChild('confettiContent') confettiContent: ElementRef
  private _user: User
  private _administration: Administration
  private _paid: boolean
  private _timeForSnow: boolean
  private _paying: boolean
  private _userIsOwner: boolean

  /**
   * @deprecated
   * @param _http
   * @param _paymentService
   * @param _authService
   * @param _userService
   * @param _adminService
   */
  constructor(
    private _http: SecureHttp,
    private _paymentService: PaymentService,
    private _authService: AuthorizationService,
    private _userService: UserService,
    private _adminService: AdministrationService,
  ) {}

  ngOnInit() {
    this._http.onPaywallActionRequired.subscribe(() => this.open())

    this.newYear = new Date().getFullYear() > 2019

    this.modal.visibleChange.subscribe((visible) => {
      if (!visible) {
        this._http.paywallResultSubscriber.emit(this._paid)

        if (!this._paid) {
          this.errorModal.showError(<any>{ error_code: -2 })
        }
      }
    })

    // Let it snow in December
    this._timeForSnow = moment().month() == 11
  }

  close() {
    this.modal.close()
  }

  pay() {
    this._paying = true

    this._paymentService.payForUse().subscribe(
      () => {
        this._paid = true
        this._paying = false

        const height = this.payContent.nativeElement.getBoundingClientRect().height
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          this.confettiContent.nativeElement.style.height = `${height}px`

          // Tadaaaa!!!
          setTimeout(() => (this.releaseConfetti = true), globals.animations.speed.default + 1)
        })
      },
      (err) => {
        this.errorModal.showError(err)
        this._paying = false
      },
    )
  }

  private open() {
    this._paid = false

    this._userService.user.subscribe((user) => this.checkUserRole(user))

    this.modal.open()
  }

  private checkUserRole(user: User) {
    observableOf(user.latest_terms_accepted)
      .pipe(mergeMap((result) => (result ? observableOf(result) : this._userService.onUserAgreedToTerms)))
      .subscribe(() => {
        this._authService.isCurrentAdministrationOwner().subscribe((result) => (this._userIsOwner = result))

        this._userService.user.subscribe((u) => (this._user = u))
        this._adminService.defaultAdministration.subscribe((a) => (this._administration = a))
      })
  }
}
