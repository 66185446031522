import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { VerificationEmailModalSources } from '../../ui-components/tlw-email-verification-modal/tlw-email-verification-modal.component'
import { UserService } from './user.service'
import { BehaviorSubject, Observable, interval, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { User } from '../../domain/user/user.model'

const COUNTER_LENGTH = 60

@Injectable()
export class EmailService {
  protected counter$ = new BehaviorSubject<number>(0)

  get hasVerfiedEmail$(): Observable<boolean> {
    return this._user.user.pipe(map((user) => user.validated_email))
  }

  get getCounterCurrentValue$(): BehaviorSubject<number> {
    return this.counter$
  }

  get isCounterInProgress$(): Observable<boolean> {
    return this.counter$.pipe(map((counter) => counter > 0))
  }

  constructor(private readonly _router: Router, private readonly _user: UserService, private readonly _api: ApiGateway) {}

  public openEmailVerificationModal(context: VerificationEmailModalSources): void {
    void this._router.navigate(['/', { outlets: { modal: 'email-verification' } }], { state: { context } } as NavigationExtras)
  }

  requestVerificationEmail(): Observable<void> {
    return this._api.post<void>(endpoints.email.sendVerificationEmail, {})
  }

  checkForValidatedEmail(): Observable<boolean> {
    return this._api.get<User>(endpoints.user).pipe(map((user) => user.validated_email))
  }

  beginCounter(): void {
    interval(1000)
      .pipe(take(COUNTER_LENGTH))
      .subscribe({
        next: (currentValue) => {
          this.counter$.next(COUNTER_LENGTH - currentValue)
        },
        complete: () => {
          this.counter$.next(0)
        },
      })
  }
}
