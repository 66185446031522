import { Component, ViewChild } from '@angular/core'
import { FncAlertModalComponent } from '../fnc-modal/fnc-alert-modal/fnc-alert.modal.component'
import { TranslateService } from '@ngx-translate/core'
import { TellowError } from '../../core/remote/error.interface'

@Component({
  selector: 'tlw-error-modal',
  template: ` <fnc-alert-dialogue [content]="(errorCode ? 'ERRORS.' + errorCode : errorMessageKey) | translate"></fnc-alert-dialogue> `,
})
export class TlwErrorModalComponent {
  errorCode: number
  errorMessageKey: string

  @ViewChild(FncAlertModalComponent, { static: true }) alertModal: FncAlertModalComponent

  constructor(private readonly _translateService: TranslateService) {}

  showError(error: TellowError) {
    this.reset()

    if (!error) {
      this.errorMessageKey = 'ERRORS._UNKNOWN_ERROR'
      this.alertModal.open()

      return
    }

    if (error._isHandled) {
      return
    }

    error._isHandled = true

    // if(error.error_code === -2) {
    //   this.errorMessageKey = 'MAIN.PAYWALL_NOT_PAID';
    //   this.alertModal.open();
    //   return;
    // }

    // First check if this is a known error
    this._translateService.get(`ERRORS.${error.error_code}`).subscribe((t) => {
      if (t !== `ERRORS.${error.error_code}`) {
        this.errorCode = error.error_code
      } else {
        // Else print generic error message.
        this.errorMessageKey = 'ERRORS._UNKNOWN_ERROR'
      }

      this.alertModal.open()
    })
  }

  showCustomError(errorMessageKey: string) {
    this.reset()
    this.errorMessageKey = errorMessageKey
    this.alertModal.open()
  }

  close() {
    this.alertModal.close()
  }

  private reset() {
    this.errorCode = null
    this.errorMessageKey = null
  }
}
