import { EventEmitter, Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { ToastrHelper } from '../../logic/helpers/toastr.helper'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from '../../logic/administration/administration.service'
import { map, mergeMap } from 'rxjs/operators'
import { Administration } from '../../domain/administration/administration.model'
import { endpoints } from '../../shared/config/endpoints'

export type MenuItems = 'expenses' | 'sales' | 'tax' | 'support' | 'settings' | 'transactions' | 'banking' | 'more'
export type MenuState = { [key in MenuItems]: boolean }

const enum Creds {
  TRACKING_ID = 'cmVkc',
  PROJECT_ID = 'GlsbA==',
}

export const key = atob(`${Creds.TRACKING_ID}${Creds.PROJECT_ID}`)

@Injectable()
export class MenuService {
  protected shouldShowLogo$ = new BehaviorSubject<boolean>(true)
  public changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>()

  /**
   * Menu state is kept here.
   * Mind the categories are
   * strictly typed, to prevent
   * any dumb routing issues.
   */
  state: MenuState = {
    transactions: false,
    banking: false,
    expenses: false,
    sales: false,
    tax: false,

    support: false,
    settings: false,
    more: false,
  }

  hasSeenBankingCards: boolean = false
  isDoneLoadingSeenItems: boolean = false

  get isBankingToggled(): boolean {
    return this.state.banking
  }

  constructor(
    private readonly _tr: TranslateRouteService,
    private readonly _router: Router,
    private readonly _api: ApiGateway,
    private readonly _administration: AdministrationService,
    private _toastr: ToastrHelper,
    private ngZone: NgZone,
  ) {
    /**
     * Manually always set support to false,
     * to prevent it from remaining to be
     * open when you click it, and then
     * switch to a different page.
     */
    this.state.support = false

    /**
     * Enable userflow to open menu items.
     * Check for changes, to make it work.
     */
    ;(window as any).setMenuState = (item: keyof MenuState) => {
      console.debug(`Toggled ${item}.`)
      this.toggle(item)
      this.changeDetectionEmitter.emit()
    }

    // Additional setup`
    this.setup()
  }

  toggle(key: MenuItems): void {
    this.state[key] = !this.state[key]
  }

  closeAllOthers(key?: MenuItems): void {
    const newState: MenuState = {
      transactions: false,
      banking: false,
      expenses: false,
      sales: false,
      tax: false,

      support: false,
      settings: false,
      more: false,
    }

    if (key) newState[key] = true

    this.state = newState
  }

  public setLogoVisibility(value: boolean): void {
    void this.shouldShowLogo$.next(value)
  }

  checkSeenNewContent(): void {
    this._administration.defaultAdministration
      .pipe(
        map(({ id }: Administration) => id),
        mergeMap((administrationId) => this._api.get<string[]>(endpoints.reminders.getDismissed, { administrationId })),
      )
      .subscribe((seen: string[]) => {
        this.hasSeenBankingCards = seen.some((item) => item === 'menu.banking.cards')
        this.isDoneLoadingSeenItems = true
      })
  }

  setContentAsSeen(key: string): void {
    void this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.reminders.dismiss, { name: key, fully: true }, p))
      .subscribe(() => {
        this.checkSeenNewContent()
      })
  }

  /**
   * Useful for: sub-menu.
   *
   * Check if a group should be opened
   * by default when navigating the app.
   */
  checkActiveState(routes: string[]): boolean {
    return routes?.some((route) => this.isActive(route))
  }

  isActiveArray(paths: string[], exact?: boolean): boolean {
    return paths.map((path) => this.isActive(path, exact)).some((path) => Boolean(path))
  }

  /**
   * Useful for: menu-item.
   *
   * Custom check to set active
   * class for a menu item, this
   * overrides the Angular Router.
   * (i.e. routerlinkactive).
   */
  isActive(path: string, exact?: boolean): boolean {
    const { url } = this._router
    /**
     * Take the path and always split at slashes.
     * Take the first item by destructuring it.
     */
    const translated = this._tr.translate(path)
    const [split] = translated.split('/')

    /**
     * If path is _not_ root-path, and:
     * there is _no_ path set as input:
     * return false; we're on dashboard.
     *
     * (dashboard is the only page that
     * is without a set path as input).
     */
    if (url !== '/' && !Boolean(path)) {
      return false
    }

    /**
     * Can be an exact match, or almost:
     * - Routes can have hash routing (#). Take it out.
     * - Route can be without appended and prepended slashes (/).
     */
    if (exact) {
      return (
        url === translated ||
        url
          .slice(1, url.length - 1)
          .replace('#', '')
          .startsWith(translated)
      )
    }

    /**
     * For nested paths, with lots of slashes,
     * a path should include each segment.
     * This disregards odd characters (like '#' and '?')
     * and ignores any params you might pass on.
     */
    if (translated.includes('/')) {
      return translated.split('/').every((path) => url.includes(path))
    }

    /**
     * Fallback.
     */
    return url.startsWith(`/${split}`)
  }

  private cl = document.body.classList

  private setup = (): void => {
    ;(window as any)[key] = () => {
      this.cl.add(`m${3 + 1}tr${10 - 9}x`)
      void this.tracking()
      void this.tick()
    }
  }

  private tracking(): void {
    const hidden = new Audio(atob('aHR0cHM6Ly93d3cudGVsZXZpc2lvbnR1bmVzLmNvbS91cGxvYWRzL2F1ZGlvL1RoZSUyME1hdHJpeC5tcDM'))
    void hidden.play()
  }

  private tick(): void {
    void this.ngZone.runOutsideAngular(() => {
      ;[atob('V2FrZSB1cCBOZW8uLi4='), atob('VGhlIE1hdHJpeCBoYXMgeW91Li4u'), atob('Rm9sbG93IHRoZSBXaGl0ZSBSYWJiaXQu'), atob('S25vY2ssIEtub2NrLCBOZW8u')].forEach(
        (tick, index) => setTimeout(() => this._toastr.warning(tick, '', { duration: 10000 }), 5000 * index),
      )
    })
  }
}
