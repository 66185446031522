/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-var */
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import { User } from '../../domain/user/user.model'
import { UserService } from '../user/user.service'

export type DaisyconObject = {
  amount?: number
  description?: string
  transactionId?: string
  compensationCode?: string
}

@Injectable({
  providedIn: 'root',
})
export class DaisyconHelper {
  private _campaignId: number = 16329
  private _defaults: DaisyconObject = {
    amount: 0,
    transactionId: 'TRIAL-ID',
    description: 'User signed up for trial.',
  }

  /**
   * When no user / user id,
   * return null so we can move
   * on without the identifier.
   */
  get user$(): Observable<User | null> {
    return this._user.user.pipe(
      take(1),
      catchError(() => of(null)),
    )
  }

  constructor(private readonly _user: UserService) {
    this._loadScript()
  }

  /**
   * Nicer way to construct the imgsrc URI,
   * instead of just slamming it in the DOM.
   */
  construct(config?: DaisyconObject): Observable<string> {
    if (process.env.NODE_ENV === 'development') {
      return of('420-69-no-scope')
    }

    const baseUrl = 'https://jf79.net/t/'

    const amount = encodeURIComponent(String(config?.amount || this._defaults.amount))
    const description = encodeURIComponent(config?.description || this._defaults.description)
    const transactionId = encodeURIComponent(config?.transactionId || this._defaults.transactionId)
    const compensationCode = encodeURIComponent(config?.compensationCode)

    return this.user$.pipe(
      map(
        (user) =>
          `${baseUrl}?ci=${this._campaignId}&a=[${amount}]&ti=[${transactionId}]&pn=[${description}]${
            Boolean(config?.compensationCode) ? `&cc=${compensationCode}` : ''
          }${user ? `&e1=${user.id}` : ''}`,
      ),
    )
  }

  public tagFromService(config?: DaisyconObject): void {
    void this.construct(config).subscribe((source) => {
      const image = document.createElement('img')

      // Set 1px width/height from _global.scss
      image.classList.add('global-conversion-pixel')
      image.src = source

      document.body.appendChild(image)

      setTimeout(() => {
        document.body.removeChild(image)
      }, 3000)
    })
  }

  public tagCompensationFromService(compensationCode: string = 'tellowcompleet'): void {
    void this.tagFromService({ compensationCode })
  }

  /**
   * Script taken from
   * Daisycon admin panel.
   *
   * See documentation:
   * @see https://faq-advertiser.daisycon.com/hc/en-us/articles/206905459-Implement-the-conversion-pixel#Stap%201:%20Conversiepixel%20downloaden
   */
  private _loadScript(): void {
    if (process.env.NODE_ENV === 'development') console.debug('⏳ -> Daisycon pixel.')
    ;(function (d) {
      var a, b, c
      if (/comp|inter|loaded/.test(d.readyState)) {
        return _a()
      }
      d.addEventListener('DOMContentLoaded', _a)
      function _a() {
        setTimeout(function () {
          a = d.getElementsByTagName('img')
          for (b = 0; b < a.length; b++) {
            try {
              if (/[s|c]i=/i.exec(a[b].src) && (!a[b].offsetHeight || a[b].offsetHeight < 1)) {
                c = d.createElement('img')
                c.height = c.width = 1
                c.id = 'news'
                c.className = 'net'
                c.src =
                  '//' +
                  Math.round(+new Date() / 83000) +
                  '.' +
                  c.id +
                  'tat.' +
                  c.className +
                  '/ab/' +
                  a[b].src.substring(a[b].src.indexOf('?'), a[b].src.length) +
                  '&v3'
                d.body.appendChild(c)
              }
            } catch (e) {}
          }
        }, 100)
      }
    })(document)
  }
}
