import { Directive, ElementRef, forwardRef, Inject, Optional } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'

@Directive({
  selector: 'textarea',
  host: {
    '(blur)': 'firstClick()',
  },
})
export class TextAreaDirective extends BaseHtmlDirective {
  private _clicked: boolean

  constructor(@Optional() @Inject(forwardRef(() => FncInputContainerComponent)) private _parent: FncInputContainerComponent, element: ElementRef) {
    super(element)
  }

  firstClick() {
    if (this._clicked || !this._parent) {
      return
    }

    this._clicked = true

    this._parent.addInputNgClassesToContainer()
  }
}
