import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core'
import { FormGroupDirective, NgModel } from '@angular/forms'
import { Subscription } from 'rxjs'
import { LabelDirective } from '../html-directives/label.directive'
import { InputDirective } from '../html-directives/input.directive'
import { FncValidatorMessagesComponent } from '../fnc-validator-messages/fnc-validator-messages.component'
import { FormDirective } from '../html-directives/form.directive'
import { FncFormChildComponent } from '../fnc-form-child.component'
import { FncSelectComponent } from '../fnc-select/fnc-select.component'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { FncToggleComponent } from '../fnc-toggle/fnc-toggle.component'
import { FncDatepickerComponent } from '../fnc-datepicker/fnc-datepicker.component'
import { TextAreaDirective } from '../html-directives/textarea.directive'
import { FncRadioGroupComponent } from '../fnc-radio-group/fnc-radio-group.component'
import { FncSelectInputComponent } from '../fnc-select-input/fnc-select-input.component'
import { nanoid } from 'nanoid'
import { TlwSliderComponent } from '../tlw-slider/tlw-slider.component'

@Component({
  selector: 'fnc-input-container',
  template: ` <div
    #container
    class="container mdl-textfield mdl-js-textfield mdl-textfield--floating-label"
    [class.fixed-label]="fixedLabel !== null && fixedLabel !== false"
    [class.disabled]="disabled"
    [class.no-label]="!label"
    [class.no-top]="noTopMargin"
    [class.read-only]="readOnly"
  >
    <ng-container *ngIf="withCurrency !== undefined">
      <span icon>
        <tlw-icon icon="euro" library="custom" height="12"></tlw-icon>
      </span>
    </ng-container>
    <ng-content></ng-content>
  </div>`,
})
export class FncInputContainerComponent extends FncFormChildComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() noTopMargin: boolean = false

  get disabled(): boolean {
    return this._disabled || this._busy
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled

    if (this._disabled) {
      this.setInputFieldDisabled()
    } else {
      this.setInputFieldEnabled()
    }
  }

  get readOnly(): boolean {
    return this._readOnly
  }

  @Input() set readOnly(readOnly: boolean) {
    this._readOnly = readOnly

    // Update user input element. If available
    if (this.userInputElement) {
      this.setInputFieldReadOnly()
    }
  }

  // Container supports multiple input types
  get userInputElement(): BaseHtmlDirective {
    return this.input || this.textArea || this.select || this.selectInput || this.toggle || this.slider || this.date || this.radio
  }

  @ViewChild('container', { static: true }) container: ElementRef
  @ContentChild(LabelDirective, { static: false }) label: LabelDirective

  // Input types
  @ContentChild(InputDirective, { static: true }) input: InputDirective
  @ContentChild(TextAreaDirective, { static: true }) textArea: InputDirective
  @ContentChild(TlwSliderComponent, { static: true }) slider: TlwSliderComponent
  @ContentChild(FncSelectComponent, { static: true }) select: FncSelectComponent
  @ContentChild(FncSelectInputComponent, { static: true }) selectInput: FncSelectInputComponent
  @ContentChild(FncToggleComponent, { static: true }) toggle: FncToggleComponent
  @ContentChild(FncDatepickerComponent, { static: true }) date: FncDatepickerComponent
  @ContentChild(FncRadioGroupComponent, { static: true }) radio: FncRadioGroupComponent
  @ContentChild(NgModel, { static: true }) ngModel: NgModel
  @ContentChild(FncValidatorMessagesComponent, { static: true }) validatorMessages: FncValidatorMessagesComponent

  @Input('with-currency') withCurrency: boolean
  @Input() fixedLabel: boolean = false
  @Input() dirtyFromStart: boolean
  @Input() customClasses: any[]

  private _formBusySubscription: Subscription
  private _formDisabledSubscription: Subscription
  private _formSubmitSubscription: Subscription
  private _updateSubscription: Subscription
  private _selectFocusSubscription: Subscription
  private _busy: boolean
  private _disabled: boolean
  private _readOnly: boolean

  constructor(
    @Optional() @Inject(forwardRef(() => FormDirective)) public parentForm: FormDirective,
    @Optional() @Host() private _formgroup: FormGroupDirective,
    elRef: ElementRef,
  ) {
    super(elRef, parentForm)
  }

  ngOnInit(): void {
    if (this.parentForm) {
      this.setDisabledStateBasedOnFormState()

      this._formDisabledSubscription = this.parentForm.onDisabled.subscribe(() => this.setDisabledStateBasedOnFormState())
      this._formBusySubscription = this.parentForm.onBusy.subscribe(() => this.setDisabledStateBasedOnFormState())
      this._formSubmitSubscription = this.parentForm.onSubmit.subscribe(() => this.addInputNgClassesToContainer())
    }
  }

  ngAfterContentInit(): void {
    // Check if an id needs to be assigned to childs
    if (this.label && (!this.label.element.getAttribute('for') || !this.userInputElement.element.id)) {
      this.assignUniqueId()
    }

    this.assignClasses()

    // Monitor updates for validation purposes
    if ((this.ngModel || this.parentForm) && this.validatorMessages) {
      this.monitorModelUpdates()
    }
  }

  ngOnDestroy(): void {
    if (this._updateSubscription) {
      this._updateSubscription.unsubscribe()
    }

    if (this._formBusySubscription) {
      this._formBusySubscription.unsubscribe()
    }

    if (this._formDisabledSubscription) {
      this._formDisabledSubscription.unsubscribe()
    }

    if (this._formSubmitSubscription) {
      this._formSubmitSubscription.unsubscribe()
    }

    if (this._selectFocusSubscription) {
      this._selectFocusSubscription.unsubscribe()
    }
  }

  setDirty(dirty: boolean) {
    if (dirty) {
      this.container.nativeElement.classList.add('is-dirty')
    } else {
      this.container.nativeElement.classList.remove('is-dirty')
    }
  }

  @HostListener('mouseenter')
  mouseEnter() {
    if (!this.validatorMessages) {
      return
    }

    this.validatorMessages.show(true)
  }

  @HostListener('mouseleave')
  mouseLeave() {
    if (!this.validatorMessages) {
      return
    }

    this.validatorMessages.show(false)
  }

  addInputNgClassesToContainer() {
    this.container.nativeElement.classList.remove('has-placeholder')

    if (this.userInputElement) {
      setTimeout(() => {
        const current = Array.toArray(this.element.nativeElement.classList).filter((c) => !c.startsWith('ng-') && c !== 'is-dirty' && c !== 'is-focused')
        const future = Array.toArray(this.getNgClassesElement().classList).filter((c) => c.startsWith('ng-') || (c === 'is-dirty' && c === 'is-focused'))

        this.element.nativeElement.className = future.join(' ') + ' ' + current.join(' ')
      })
    }
  }

  private assignClasses() {
    if (this.userInputElement) {
      this.userInputElement.element.classList.add('mdl-textfield__input')
    }

    if (this.label) {
      this.label.element.classList.add('mdl-textfield__label')
    } else {
      this.element.nativeElement.classList.add('no-label')
    }

    if (this.slider) {
      this.element.nativeElement.classList.add('is-slider-container')
    }

    if (this.select) {
      this.element.nativeElement.classList.add('is-select-container')
    }

    if (this.selectInput) {
      this.element.nativeElement.classList.add('is-select-container')
    }

    if (this.radio) {
      this.element.nativeElement.classList.add('is-radio-container')
    }

    if (this.customClasses) {
      void this.customClasses.forEach((item) => {
        this.userInputElement.element.classList.add(item)
      })
    }

    componentHandler.upgradeElement(this.container.nativeElement)

    setTimeout(() => {
      if (this.ngModel && (this.ngModel.value || this.ngModel.value === 0)) {
        this.setDirty(true)
      }

      if (this.dirtyFromStart) {
        this.setDirty(true)
      }
    }, 0)
  }

  private assignUniqueId() {
    const generatedId = `__input__${nanoid()}`

    // Link the two together
    if (this.userInputElement) {
      this.userInputElement.element.id = generatedId

      if (this.label) {
        this.label.element.setAttribute('for', generatedId)
      }
    }
  }

  private monitorModelUpdates() {
    // activate validation
    this.element.nativeElement.classList.add('should-validate')

    // Switch between ngModel or FormGroup changes.
    const controller = this.ngModel ? this.ngModel.update : this._formgroup.form.valueChanges
    this._updateSubscription = controller.subscribe(() => this.addInputNgClassesToContainer())

    if (this.select) {
      this._selectFocusSubscription = this.select.onFocusStateChanged.subscribe(() => this.addInputNgClassesToContainer())
    }
  }

  private setDisabledStateBasedOnFormState() {
    if (this.readOnly) {
      return
    }

    // TODO: Override element importance
    const busy = this.parentForm.busy
    const disabled = this._disabled || this.parentForm.disabled

    const dis = busy || disabled

    if (dis) {
      this.setInputFieldDisabled()
    } else {
      this.setInputFieldEnabled()
    }
  }

  private getNgClassesElement(): HTMLElement {
    if (this.select) {
      return this.select.selectElement.element
    }

    if (this.selectInput) {
      return this.selectInput.selectElement.element
    }

    if (this.date) {
      return this.date.datePickerEl.nativeElement
    }

    return this.userInputElement.element
  }

  private setInputFieldReadOnly() {
    if (this.readOnly) {
      this.setInputFieldDisabled()
    } else {
      this.setInputFieldEnabled()
    }
  }

  private setInputFieldDisabled() {
    if (!this.userInputElement) {
      return
    }

    this.element.nativeElement.classList.add('disabled')
    this.userInputElement.element.setAttribute('disabled', 'disabled')
  }

  private setInputFieldEnabled() {
    if (!this.userInputElement) {
      return
    }

    // If an input in this `fnc-input-container` has an input with `readonly`.
    if (this.userInputElement.element.hasAttribute('readonly')) {
      this.userInputElement.element.classList.add('read-only')
    }

    this.element.nativeElement.classList.remove('disabled')
    this.userInputElement.element.removeAttribute('disabled')
  }
}
