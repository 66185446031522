import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'
import { globals } from '../../shared/config/globals'

@Pipe({
  name: 'fncDate',
})
export class FncDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value
    }

    return moment(value).format((args && args.format) || globals.displayDateFormat)
  }
}
