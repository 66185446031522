/**
 * @see https://github.com/hellotellow/payments-service/blob/develop/src/app/types/opp/merchant.type.ts
 * @see https://docs.onlinepaymentplatform.com/#object-definition-merchant
 */

/** @see https://docs.onlinepaymentplatform.com/#object-definition-merchant */
export enum Status {
  NEW = 'new',
  PENDING = 'pending',
  LIVE = 'live',
  SUSPENDED = 'suspended',
  TERMINATED = 'terminated',
  BLOCKED = 'blocked',
}

/** @see https://docs.onlinepaymentplatform.com/#object-definition-merchant */
export enum MerchantType {
  BUSINESS = 'business',
  CONSUMER = 'consumer',
}

/** @see https://docs.onlinepaymentplatform.com/#object-definition-compliance */
export enum ComplianceStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  VERIFIED = 'verified',
}

/** @see https://docs.onlinepaymentplatform.com/#bank-account */
export enum BankStatus {
  NEW = 'new',
  PENDING = 'pending',
  APPROVED = 'approved',
  DISAPPROVED = 'disapproved',
}

/** @see https://docs.onlinepaymentplatform.com/#compliance-status */
export enum ComplianceType {
  BANK = 'bank_account.verification.required',
  CONTACT = 'contact.verification.required',
  PHONE = 'contact.phonenumber.verification.required',
  UBO = 'ubo.verification.required',
}

/** @see https://docs.onlinepaymentplatform.com/#object-definition-compliance */
export enum ComplianceRequirements {
  BANK = 'bank_account.required',
  PHONE = 'contact.phonenumber.required',
  COC = 'coc_extract.required',
  STRUCTURE = 'organization_structure.required',
  UBO = 'ubo.required',
}

/**
 * Combination of above two types (ComplianceType + ComplianceRequirements).
 * They serve different purposes, but are returned in a single response.
 */
export type RawComplianceType = ComplianceType | ComplianceRequirements

/**
 * MerchantDTO as obtained from
 * the backend repository.
 */
export interface MerchantDTO {
  country: string
  coc_nr: string
  type: MerchantType
  emailaddress: string
}

export interface CreateMerchantDTO extends MerchantDTO {
  locale?: 'nl' | 'en' | 'fr' | 'de'
  return_url: string
}

export interface CreateBankAccountDTO {
  return_url: string
}

/**
 * These are included in various
 * backend responses, to base off.
 */
export interface BaseDTO {
  createdAt: string
  deletedAt: string
  updatedAt: string

  id: string
  administrationId: number
}

export interface PaymentLinkResponse extends BaseDTO {
  invoiceLinkId: string
  invoiceId: number
  link: string
}

/** @see https://docs.onlinepaymentplatform.com/#compliance */
export type MerchantCompliance = 'unverified' | 'pending' | 'verified'

export interface MerchantResponse extends BaseDTO {
  oppMerchantId: string
  oppMerchantStatus: Status
  oppMerchantComplianceStatus: MerchantCompliance
}

export interface BankAccountResponse extends BaseDTO {
  oppVerificationUrl: string
  oppBankAccountId: string
  oppMerchantId: string
  oppStatus: BankStatus
}

/**
 * 'Raw' as this is directly obtained
 * from OPP, and left unaltered.
 *
 * @see https://docs.onlinepaymentplatform.com/#compliance-requirements
 */
export interface RawOppRequirement {
  type: RawComplianceType
  status: ComplianceStatus
  object_type: ComplianceRequirements
  object_uid: string
  object_url: string
  object_redirect_url: string
}

/**
 * 'Raw' as this is directly obtained
 * from OPP, and left unaltered.
 *
 * @see https://docs.onlinepaymentplatform.com/#object-definition-compliance
 */
export interface RawOppCompliance {
  level: number
  status: ComplianceStatus
  overview_url: string
  requirements: RawOppRequirement[]
}

/**
 * 'Raw' as this is directly obtained
 * from OPP, and left unaltered.
 *
 * @see https://docs.onlinepaymentplatform.com/#object-definition-merchant
 */
export interface RawOppMerchant {
  // Static strings;
  // These will/should not change.
  object: 'merchant'
  type: 'business'
  country: 'nld'

  // Types
  livemode: false
  uid: string
  created: number
  updated: number
  status: Status
  compliance: RawOppCompliance
  coc_nr: string
  name: null
  phone: string
  vat_nr: null
  sector: null
  notify_url: string
  return_url: string

  // Unused, but included in
  // response you will get.
  addresses: []
  trading_names: []
  contacts: []
  profiles: []
  payment_methods: []
  metadata: []
}

/**
 * The same as 'MerchantResponse' but
 * with the full object included.
 */
export interface RichMerchantResponse extends MerchantResponse {
  oppMerchant: RawOppMerchant
  bankAccount?: BankAccountResponse
}

/**
 * Payment methods that
 * are allowed by Tellow.
 * @see https://docs.onlinepaymentplatform.com/#payment-methods
 */
type OppPaymentMethods = 'ideal' | 'bcmc' | 'sepa' | 'paypal-pc' | 'creditcard' | 'sofort-banking' | 'mybank' | 'giropay' | 'mandate' | 'multi'
type CustomPaymentMethods = 'bnpl'

export type PaymentMethods = OppPaymentMethods & CustomPaymentMethods
export type PaymentMethodResponse = { [key in PaymentMethods]: boolean }

/** Errors */
export enum MerchantErrors {
  NoAccount,
}
