import { AfterViewInit, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core'

@Component({
  selector: 'fnc-icon',
  styleUrls: ['./fnc-icon.component.scss'],
  template: `
    <div #icon1 icon [class]="icon1class" aria-hidden="true"></div>
    <div #icon2 icon [class]="icon2class" aria-hidden="true"></div>
  `,
})
export class FncIconComponent implements AfterViewInit {
  @Input() icon: string

  @HostBinding('attr.disabled') @Input() disabled: boolean

  @ViewChild('icon1', { static: true }) icon1: ElementRef
  @ViewChild('icon2', { static: true }) icon2: ElementRef

  constructor(public _el: ElementRef) {}

  @Input() set active(active: boolean) {
    if (active) {
      this._el.nativeElement.classList.add('active')
    } else {
      this._el.nativeElement.classList.remove('active')
    }
  }

  get icon1class() {
    return `icon-${this.icon}-b`
  }

  get icon2class() {
    if (this.disabled) {
      return this.icon1class
    }

    return `icon-${this.icon}-a`
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.icon1.nativeElement.style.display = 'none'
      this.icon1.nativeElement.offsetHeight
      this.icon1.nativeElement.style.display = ''

      this.icon2.nativeElement.style.display = 'none'
      this.icon2.nativeElement.offsetHeight
      this.icon2.nativeElement.style.display = ''
    }, 1)
  }
}
