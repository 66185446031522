import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { EventEmitter, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ApiGateway } from '../remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { Token } from '../../domain/user/token.model'
import { SecurityService } from './security.service'
import { environment } from '../../../environments/environment'
import { TranslateRouteService } from '../logic/i18n/translate-route.service'
import { DeviceService } from '../../logic/helpers/device.service'
import { AdministrationService } from '../../logic/administration/administration.service'
import { globalCacheBusterNotifier } from 'ts-cacheable'

@Injectable()
export class LoginService {
  onLoggedIn = new EventEmitter<void>()
  onLoggedOut = new EventEmitter<void>()

  constructor(
    private _api: ApiGateway,
    private _security: SecurityService,
    private _deviceService: DeviceService,
    private _router: Router,
    private _translateRouter: TranslateRouteService, // private _ngZone: NgZone,
  ) {
    // Prevent Protractor waiting forever for NgZone to cool down.
    // this._ngZone.runOutsideAngular(() => {
    //   // Check if login is valid.
    //   setInterval(() => {
    //     if (this._security.hasToken && !this._security.hasValidToken && !this._security.hasValidRefreshToken) {
    //       this._ngZone.run(() => {
    //         this.signOut()
    //       })
    //     }
    //   }, 30000)
    // })
  }

  upgradeLoginForActivatedDevice(): Observable<{}> {
    const body = {
      device_id: this._deviceService.getDeviceFingerPrint(),
      token: this._security.token.access_token,
      grant_type: 'https://grants.tellow.nl/web',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }

    return this._api.post(endpoints.login.token, body).pipe(
      // Save on success
      map((token: Token) => {
        this._security.saveToken(token)

        return token
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((error) => observableThrowError('error_upgrade_login')),
    )
  }

  routeAfterLogin(administrationService: AdministrationService, redirectUrl?: string): void {
    administrationService.shouldShowOnboardingWizard.subscribe((result) => {
      if (result) {
        // Always send people to the wizard
        void this._translateRouter.navigate('/onboarding/wizard')
      } else {
        if (redirectUrl) {
          void this._router.navigateByUrl(redirectUrl)
        } else {
          void this._router.navigate([''])
        }
      }
    })
  }

  signOut(): void {
    this._api.delete(endpoints.login.logout).subscribe(
      /**
       * Mind that onLoggedOut has to be
       * called _before_ clear and redirect.
       * Otherwise, redirect causes the emitter
       * to not be called and executed at all.
       */
      () => {
        this.onLoggedOut.emit()
        this.clearAndRedirect()
      },
      () => {
        this.onLoggedOut.emit()
        this.clearAndRedirect()
      },
    )
  }

  private clearAndRedirect(): void {
    this._security.clearToken('LoginService')
    this._security.clearSupportToken()
    globalCacheBusterNotifier.next()

    void this._translateRouter.navigate('/login')
  }
}
