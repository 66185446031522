import { ModuleWithProviders, NgModule } from '@angular/core'
import { AccountingService } from './accounting/accounting.service'
import { AdministrationService } from './administration/administration.service'
import { TaskService } from './administration/task.service'
import { UserService } from './user/user.service'
import { ContactsService } from './administration/contacts.service'
import { BankAccountService } from './administration/bankaccounts.service'
import { DocumentService } from './documents/document.service'
import { PdfService } from './documents/pdf.service'
import { InvoiceService } from './invoices/invoice.service'
import { QuotesService } from './quotes/quotes.service'
import { OnboardingService } from './onboarding/onboarding.service'
import { TransactionService } from './transactions/transaction.service'
import { ExpenseService } from './expenses/expense.service'
import { VatService } from './vat/vat.service'
import { StatsService } from './dashboard/stats.service'
import { PurchaseInvoiceService } from './invoices/purchase-invoice.service'
import { AdministrationGuard } from './security/guards/administration.guard'
import { FirebaseCoreService } from './firebase/firebase-core.service'
import { FirebasePushMessageService } from './firebase/firebase-push-message.service'
import { InvoiceNumberBuilder } from './invoices/InvoiceNumberBuilder'
import { SessionService } from './user/session.service'
import { PeriodsService } from './administration/periods.service'
import { DeviceService } from './helpers/device.service'
import { CustomInvoiceTemplateService } from './invoices/custom-invoice-template.service'
import { CustomQuoteTemplateService } from './quotes/custom-quote-template.service'
import { AdministrationNotificationService } from './firebase/administration-notifications.service'
import { TermsAndAgreementsGuard } from './security/guards/terms-agreements.guard'
import { TransactionJustificationService } from './justification/transaction-justification.service'
import { InvoiceJustificationService } from './justification/invoice-justification.service'
import { JustificationService } from './justification/justification.service'
import { AuthorizationService } from './security/authorization/authorization.service'
import { MasterDetailOrchestrator } from './ui-logic/master-detail.orchestrator'
import { DocumentJustificationService } from './justification/document-justification.service'
import { OwnerGuard } from './security/guards/owner.guard'
import { StartBalanceGuard } from './security/guards/start-balance.guard'
import { RemovableBookingHelper } from './accounting/removable-booking.helper'
import { ExportService } from './administration/export.service'
import { PaymentService } from './administration/payment.service'
import { BrowserHelper } from './helpers/browser.helper'
import { SuppletionService } from './vat/suppletion.service'
import { IntercomService } from './external-services/intercom.service'
import { VatJustificationService } from './justification/vat-justification.service'
import { FilterService } from './filters/filter.service'
import { PrintService } from './helpers/print.service'
import { NotificationService } from './notifications/notification.service'
import { UserAdministrationGuard } from './security/guards/user-administration.guard'
import { SentryErrorHandlerService } from './external-services/sentry-error-handler.service'
import { CompanyService } from './companies/company.service'
import { SegmentHelper } from './external-services/segment.helper'
import { OutbrainHelper } from './external-services/outbrain.helper'
import { CloudflareWorkerHelper } from './external-services/cloudflare.helper'
import { KeyboardService } from './helpers/keyboard.helper'
import { AdblockerHelper } from './helpers/adblocker.helper'
import { DaisyconHelper } from './external-services/daisycon.helper'
import { ToastrHelper } from './helpers/toastr.helper'
import { FinanceService } from './finance/finance.service'
import { TransactionHelper } from './transactions/transaction.helper'
import { ListHelper } from './helpers/list.helper'
import { ScriptService } from './helpers/script.service'
import { CalculationHelper } from './vat/calculation.helper'
import { PaymentsService } from './payments/payments.service'
import { LaravelService } from './banking/laravel.service'
import { TransferService } from './banking/transfer.service'
import { ProductsService } from './product/product.service'
import { EmailService } from './user/email.service'
import { DismissedNotificationService } from './notifications/dismissed-notifications.service'
import { PasskeysService } from './security/passkeys.service'

@NgModule()
export class LogicModule {
  static forRoot(): ModuleWithProviders<LogicModule> {
    return {
      ngModule: LogicModule,
      providers: [
        AdministrationService,
        AccountingService,
        TaskService,
        UserService,
        ContactsService,
        CompanyService,
        BankAccountService,
        DocumentService,
        PdfService,
        OnboardingService,
        TransactionService,
        ExpenseService,
        VatService,
        StatsService,
        PurchaseInvoiceService,
        SessionService,
        PeriodsService,
        DeviceService,
        PrintService,
        TransactionJustificationService,
        InvoiceJustificationService,
        DocumentJustificationService,
        VatJustificationService,
        JustificationService,
        AuthorizationService,
        ExportService,
        PaymentService,
        SuppletionService,
        FilterService,
        NotificationService,
        DismissedNotificationService,
        CustomInvoiceTemplateService,
        CustomQuoteTemplateService,
        InvoiceService,
        PaymentsService,
        FirebaseCoreService,
        FirebasePushMessageService,
        CloudflareWorkerHelper,
        AdministrationNotificationService,
        AdministrationGuard,
        TermsAndAgreementsGuard,
        OwnerGuard,
        StartBalanceGuard,
        UserAdministrationGuard,
        MasterDetailOrchestrator,
        BrowserHelper,
        IntercomService,
        ScriptService,
        RemovableBookingHelper,
        InvoiceNumberBuilder,
        SegmentHelper,
        ToastrHelper,
        SentryErrorHandlerService,
        QuotesService,
        OutbrainHelper,
        KeyboardService,
        AdblockerHelper,
        DaisyconHelper,
        FinanceService,
        ListHelper,
        TransactionHelper,
        CalculationHelper,
        LaravelService,
        TransferService,
        ProductsService,
        EmailService,
        PasskeysService,
      ],
    }
  }
}
