import { RouterLinks } from './routerlinks'

export const routerlinks: RouterLinks = {
  onboarding: {
    index: 'aanmelden',
    confirmData: 'verifieer-gegevens',
    correctPhoneNumber: 'corrigeer-telefoonnummer',
    verificationCode: 'verificatie-code-invullen',
    phoneNumberReceived: 'telefoonnummer-ontvangen',
    wizard: 'wizard',
    products: 'producten',
    invitedUserOnboarding: 'uitgenodige-gebruiker-onboarding',
    invoicesAfterStartIntro: 'facturen-na-start',
    startBalance: 'beginbalans',
    unknownToken: 'onbekende-sessie',
  },
  login: {
    index: 'inloggen',
    register: 'registreer',
    termsAndAgreement: 'algemene-voorwaarden',
    oneTimePassword: 'eenmalig-wachtwoord',
    magicLink: 'magic-token',
    passkey: 'passkey',
  },
  register: {
    index: 'registreer',
  },
  emailVerification: {
    index: 'email-verificatie',
  },
  main: {
    account: 'mijn-account',
    pageNotFound: 'pagina-niet-gevonden',
    editEmailaddress: 'wijzig-emailadres',
    user: 'nieuwe-gebruiker',
    paywall: 'betaalmuur',
    requiredFields: 'verplichte-velden',
  },
  sending: {
    index: 'versturen',
    invoice: 'factuur',
    quote: 'offerte',
  },
  invoices: {
    index: 'facturen',
    selectCustomer: 'selecteer-klant',
    requiredFields: 'verplichte-velden',
    createInvoice: 'maak-een-factuur-aan',
    editInvoice: 'pas-factuur-aan',
    sendInvoice: 'verstuur-factuur',
  },
  quotes: {
    index: 'offerte',
    createQuote: 'offerte-aanmaken',
    requiredFields: 'verplichte-velden',
    sendQuote: 'verstuur-offerte',
    editQuote: 'offerte-aanpassen',
  },
  payments: {
    index: 'betalingen',
    onboarding: 'start-met-betalingen',
  },
  documents: {
    index: 'bestanden',
    annotation: 'annotatie',
    addDocument: 'bestand-toevoegen',
    requiredFields: 'verplichte-velden',
  },
  transactions: {
    index: 'transacties',
    import: 'importeer',
    importInfo: 'importeer-informatie',
  },
  transferMoney: {
    index: 'overschrijven',
  },
  finance: {
    index: 'leningen',
  },
  banking: {
    index: 'bankieren',
    signup: 'aanmelden',
    returnFromSwan: 'return',
    topup: 'opwaarderen',
  },
  templating: {
    index: 'sjablonen',
    select: 'kiezen',
    customize: 'aanpassen',
  },
  vat: {
    index: 'btw',
    declarations: 'aangiften',
    submitDeclaration: 'aangifte-indienen',
    suppletion: 'suppletie',
    createSuppletion: 'maak-suppletie',
    incomeTax: 'inkomstenbelasting',
  },
  contacts: {
    index: 'adresboek',
    select: 'toevoegen',
    create: 'contact-aanmaken',
    edit: 'bewerken',
    person: 'persoon',
    company: {
      index: 'bedrijf',
      person: 'persoon',
    },
  },
  expenses: {
    index: 'uitgaven',
  },
  settings: {
    index: 'extra',
    account: 'account',
    bank: 'mijn-banken',
    bookkeeping: 'boekhouding',
    securitySettings: 'beveiliging',
    activeSessions: 'actieve-sessies',
    referralProgram: 'vertel-een-vriend',
    companyInfo: 'bedrijfsgegevens',
    administrationSettings: 'administratieinstellingen',
    faq: 'faq',
    smartMailbox: 'inbox',
    invoiceSettings: 'factuur-instellingen',
    billingSettings: 'abonnement',

    paymentRequestSettings: 'betaalverzoeken',

    bankLinkNewAccount: 'nieuwe-rekening',
    bankAccountConnections: 'banken',
    bankAccountSettings: 'bankinstellingen',
    bankPrivacyAndSecurity: 'privacy-en-beveiliging',

    users: 'mijn-gebruikers',
    newUser: 'nieuwe-gebruiker',
    quoteSettings: 'offerte-instellingen',
    fiscalYears: 'fiscale-jaren',
    reconcilliation: {
      index: 'reconcilliatie',
      invoice: 'factuur',
      transaction: 'transactie',
      vat: 'btw',
      suppletion: 'suppletie',
    },
    banking: 'bankieren',
    cardSettings: 'kaart-instellingen',
    bankAccountDetails: 'rekeninggegevens',
    purchaseInvoices: {
      index: 'inkoopfacturen',
      createPurchaseInvoice: 'inkoopfactuur-aanmaken',
      editPurchaseInvoice: 'pas-inkoopfactuur-aan',
    },
    generalJournalEntry: {
      index: 'memoriaal-boekingen',
      editJournalEntry: 'boeking-aanpassen',
      createJournalEntry: 'nieuwe-boeking',
    },
    privateUse: 'prive-gebruik',
    newPrivateUseBooking: 'nieuw',
  },
  help: {
    request: 'verzoek',
  },
  reporting: {
    index: 'rapportage',
    profitLoss: 'winst-verlies',
    currentBalance: 'actuele-balans',
    export: 'exporteren',
    columnBalance: 'kolommenbalans',
    mutations: 'mutatiekaart',
    journals: 'journaalposten',
  },
  bank: {
    oauth: 'oauth',
  },
}
