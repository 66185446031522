import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, map, mergeMap, publishReplay, refCount, startWith, take, tap } from 'rxjs/operators'
import { Cacheable } from 'ts-cacheable'
import { environment } from '../../../environments/environment'
import { LoginService } from '../../core/security/login.service'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationService } from '../administration/administration.service'
import { SwanAccount } from '../../domain/swan/account.model'
import { SwanOnboarding } from '../../domain/swan/onboarding.model'
import { FirebaseHelper } from '../external-services/firebase.helper'
import { SwanCard } from '../../domain/swan/card.model'
import { LaravelClient } from '../../core/remote/laravelClient'
import { ActivationState } from '../../domain/swan/activation-state.model'
import { DismissedNotificationService } from '../notifications/dismissed-notifications.service'

const cacheBusterObserver = new Subject<void>()

enum SwanErrors {
  NoOnboarding,
  NoAccount,
  NoCards,
}

@Injectable()
export class LaravelService {
  /*
   * Banking feature-flag.
   */
  public isBankingEnabled$: Observable<boolean> = of(true)
  // combineLatest([
  //   this._firebase.isInvitedToTellowBank(),
  //   this._administrationService.defaultAdministration,
  //   // this._user.user.pipe(map((user) => user?.is_beta)),
  // ]).pipe(
  //   map(([hasFeatureFlag, { legal_form } /*, isBeta */]) => legal_form === LegalFormType.TYPE_SOLE_PROPRIETORSHIP && hasFeatureFlag /* || isBeta  || false */),
  //   startWith(false),
  // )

  /*
   * Open Bank notification feature-flag.
   */
  public shouldSeeOpenBankNotification$: Observable<boolean> = this._firebase.isShownOpenBankNotification(false)

  /*
   * Order Physical Card notification feature-flag.
   */
  public shouldSeeOrderPhysicalCardNotification$: Observable<boolean> = this._firebase.isShownOrderPhysicalCardNotification(false)

  private get _onboarding$(): Observable<SwanOnboarding | null> {
    return this._getOnboarding$().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return throwError(SwanErrors.NoOnboarding)
        }

        return of(null)
      }),
    )
  }
  /** @deprecated should be using `get _accounts$` */
  private get _account$(): Observable<SwanAccount | null> {
    return this._getAccount$().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return throwError(SwanErrors.NoAccount)
        }

        return of(null)
      }),
    )
  }

  private get _accounts$(): Observable<SwanAccount[] | null> {
    return this._getAccounts$().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return throwError(SwanErrors.NoAccount)
        }

        return of(null)
      }),
    )
  }

  private get _cards$(): Observable<SwanCard[]> {
    return this._getCards$().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return throwError(SwanErrors.NoCards)
        }

        return of([])
      }),
    )
  }

  public getOnboarding$: Observable<SwanOnboarding | null> = this._onboarding$.pipe(
    map((onboarding) => onboarding),
    catchError(() => of(null)),
    startWith(null),
  )

  public getOnboardingStatus$: Observable<string> = this._onboarding$.pipe(
    map((onboarding) => (onboarding.status === null ? 'none' : onboarding.status)),
    catchError(() => of('none')),
    startWith('none'),
  )

  public hasBankAccount$: Observable<boolean> = this._account$.pipe(
    map((account) => account !== null),
    catchError(() => of(false)),
    startWith(false),
  )

  public hasActiveBankAccount$: Observable<boolean> = this._account$.pipe(
    map((account) => account && account.is_active),
    catchError(() => of(false)),
    startWith(false),
  )

  public isAccountSuspended$: Observable<boolean> = this._account$.pipe(
    map((account) => account !== null && account.status === 'Suspended'),
    catchError(() => of(false)),
    startWith(false),
  )

  public isAccountClosed$: Observable<boolean> = this._account$.pipe(
    map((account) => account !== null && account.status === 'Closed'),
    catchError(() => of(false)),
    startWith(false),
  )

  public isAccountClosedOrClosing$: Observable<boolean> = this._account$.pipe(
    map((account) => account !== null && (account.status === 'Closed' || account.status === 'Closing')),
    catchError(() => of(false)),
    startWith(false),
  )

  public getBankAccount$: Observable<SwanAccount | null> = this._account$.pipe(
    map((account) => account),
    catchError(() => of(null)),
    startWith(null),
  )

  public getBankAccountBalance$: Observable<number | null> = this._account$.pipe(
    map((account) => (account === null ? null : account.available_balance)),
    catchError(() => of(null)),
  )

  public hasIBAN$: Observable<boolean> = this._account$.pipe(
    map((account) => account !== null && account.iban !== '' && account.iban !== null),
    catchError(() => of(false)),
    startWith(false),
  )

  public hasCards$: Observable<boolean> = this._cards$.pipe(
    map((cards) => cards.length > 0),
    catchError(() => of(false)),
    startWith(false),
  )

  public getCards$: Observable<SwanCard[]> = this._cards$.pipe(
    map((cards) => cards),
    catchError(() => of([])),
    startWith([]),
  )

  private get _activationStates$(): Observable<string[]> {
    return this._getActivationStates$().pipe(catchError(() => of([])))
  }

  public getActivationStates$: Observable<string[]> = forkJoin([this._activationStates$, this._getDismissedActivationStates$()]).pipe(
    map(([activationStates, dismissedActivationStates]) => activationStates.filter((state) => !dismissedActivationStates.includes(state))),
    publishReplay(1, 3000),
    refCount(),
    take(1),
  )

  public hasActivationState$: Observable<boolean> = this.getActivationStates$.pipe(map((activationState) => activationState.length > 0))

  constructor(
    private readonly _http: LaravelClient,
    private readonly _administrationService: AdministrationService,
    private readonly _login: LoginService,
    private readonly _firebase: FirebaseHelper,
    private readonly _dismissedNotifications: DismissedNotificationService,
  ) {
    this._administrationService.onSwitchedAdministration.subscribe(() => this.clearCache())
    this._login.onLoggedOut.subscribe(() => this.clearCache())
  }

  /**
   * Clear all cache related to this
   * laravel service; for instance on logout
   * or administration change.
   */
  public clearCache(): void {
    void cacheBusterObserver.next()
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  private _getOnboarding$(): Observable<SwanOnboarding | null> {
    return this._administrationService.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.get<HttpResponse<SwanOnboarding>>(`${environment.laravelServiceApiUrl}/swan/${id}/onboarding`, { observe: 'response' })),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  /** @deprecated this API will cease to exist, and is replaced with `get accounts` */
  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  private _getAccount$(): Observable<SwanAccount | null> {
    return this._administrationService.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.get<HttpResponse<SwanAccount>>(`${environment.laravelServiceApiUrl}/swan/${id}`, { observe: 'response' })),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  private _getAccounts$(): Observable<SwanAccount[] | null> {
    return this._administrationService.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.get<HttpResponse<SwanAccount[]>>(`${environment.laravelServiceApiUrl}/swan/${id}/accounts`, { observe: 'response' })),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  private _getCards$(): Observable<SwanCard[] | null> {
    const account = this._account$
    const administration = this._administrationService.defaultAdministration

    return combineLatest([account, administration]).pipe(
      map(([account, administration]: [SwanAccount | null, Administration]) => {
        if (!account) throwError(SwanErrors.NoAccount)

        return [account.id, administration.id]
      }),
      mergeMap(([accountId, administrationId]) =>
        this._http.get<HttpResponse<SwanCard[]>>(`${environment.laravelServiceApiUrl}/swan/${administrationId}/accounts/${accountId}/cards`, {
          observe: 'response',
        }),
      ),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  closeSwanAccount(redirectUrl: string): Observable<{ consent_url: string }> {
    const account = this._account$
    const administration = this._administrationService.defaultAdministration

    return combineLatest([account, administration]).pipe(
      map(([account, administration]: [SwanAccount | null, Administration]) => {
        if (!account) throwError(SwanErrors.NoAccount)

        return [account.id, administration.id]
      }),
      mergeMap(([accountId, administrationId]) =>
        this._http.post<{ consent_url: string }>(
          `${environment.laravelServiceApiUrl}/swan/${administrationId}/accounts/${accountId}/close?redirect_url=${redirectUrl}`,
          null,
        ),
      ),
      tap(() => this.clearCache()),
    )
  }

  setupIddMandate(): Observable<SwanAccount> {
    const account = this._account$
    const administration = this._administrationService.defaultAdministration

    return combineLatest([account, administration]).pipe(
      map(([account, administration]: [SwanAccount | null, Administration]) => {
        if (!account) throwError(SwanErrors.NoAccount)

        return [account.id, administration.id]
      }),
      mergeMap(([accountId, administrationId]) =>
        this._http.post<SwanAccount>(
          `${environment.laravelServiceApiUrl}/swan/${administrationId}/accounts/${accountId}/setup-internal-direct-debit-mandate`,
          null,
        ),
      ),
      tap(() => this.clearCache()),
    )
  }

  private _getActivationStates$(): Observable<string[]> {
    const account = this._account$
    const administration = this._administrationService.defaultAdministration

    return combineLatest([account, administration]).pipe(
      map(([account, administration]: [SwanAccount | null, Administration]) => {
        if (!account) throwError(SwanErrors.NoAccount)

        return [account.id, administration.id]
      }),
      mergeMap(([accountId, administrationId]) =>
        this._http.get<HttpResponse<string[]>>(`${environment.laravelServiceApiUrl}/swan/${administrationId}/accounts/${accountId}/activate-state`, {
          observe: 'response',
        }),
      ),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  private _getDismissedActivationStates$(): Observable<string[]> {
    const activationStates = Object.values(ActivationState).map((state) => state.toString())

    return this._dismissedNotifications
      .getDismissedNotifications$()
      .pipe(map((notifications) => notifications.filter((notification) => activationStates.includes(notification))))
  }
}
