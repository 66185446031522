import { Observable, of as observableOf, of, Subject } from 'rxjs'
import { catchError, filter, first, map, mergeMap, publishLast, publishReplay, refCount, switchMap, tap, toArray } from 'rxjs/operators'
import { EventEmitter, Injectable } from '@angular/core'
import moment from 'moment'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationSettings, Prediction, Predictions } from '../../domain/administration/administration-settings.model'
import { VatPeriodDisplayItem } from '../../domain/administration/vat-period.model'
import { Account } from '../../domain/administration/account.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { LoginService } from '../../core/security/login.service'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationListItem } from '../../domain/administration/administration-list-item.model'
import { DeviceService } from '../helpers/device.service'
import { User } from '../../domain/user/user.model'
import { AccountTypes } from '../../domain/administration/account-types.constants'
import { Cacheable } from 'ts-cacheable'
import { ToastrHelper } from '../helpers/toastr.helper'
import { TellowError } from '../../core/remote/error.interface'
import { TranslateService } from '@ngx-translate/core'
import { PaginatedResponse } from '../../domain/helpers/pagination.model'
import { TranslatePersist } from '../../core/logic/i18n/translate-persist.service'
import { NavigationExtras, Router } from '@angular/router'
import { OnboardingModalSources } from '../../ui-components/tlw-onboarding-modal/tlw-onboarding-modal.component'
import { VatRate } from '../../domain/administration/vat-rate.model'
import { globals } from '../../shared/config/globals'
import { format } from 'date-fns'

const cacheBusterObserver = new Subject<void>()
const cacheBusterObserverSettings = new Subject<void>()
const cacheBusterObserverVatRates = new Subject<void>()

// TODO: Paginated false. Use offset instead.
@Injectable()
export class AdministrationService {
  get userAdministrationList(): Observable<AdministrationListItem[]> {
    if (this._userAdministrationList) {
      return this._userAdministrationList
    }

    this._userAdministrationList = this._api
      .get<AdministrationListItem[]>(endpoints.administration.administration, {
        paginated: true,
        limit: 20,
      })
      .pipe(
        map((r: any) => r.rows),
        publishReplay(1),
        refCount(),
      )

    if (this.onAdministrationNameChange) {
      this.onAdministrationNameChange.emit()
    }

    return this._userAdministrationList
  }

  get defaultAdministrationSettings(): Observable<AdministrationSettings> {
    return this._defaultAdministrationSettings()
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver: cacheBusterObserverSettings })
  private _defaultAdministrationSettings(): Observable<AdministrationSettings> {
    return this.defaultAdministration.pipe(
      filter((admin) => admin !== null),
      mergeMap((administration) =>
        this._api.get<AdministrationSettings>(endpoints.administration.settings, {
          administrationId: administration.id,
        }),
      ),

      /**
       * Automatically set the users preferred language
       * when fetching the administration settings.
       * This happens here, to have the most DRY
       * code possible.
       */
      tap(({ language }) => this._languagePersist.setPreferredLanguageAfterFetchingSettings(language)),
    )
  }

  get vatRatePeriods(): Observable<VatPeriodDisplayItem[]> {
    return this._vatRatePeriods()
  }

  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver: cacheBusterObserverVatRates })
  private _vatRatePeriods(): Observable<VatPeriodDisplayItem[]> {
    return this.defaultAdministration.pipe(
      // Convert single result in observable array for filtering and mapping
      switchMap((r: Administration) => r.vat_rates),
      map((rate: VatRate) => {
        const period = rate.vat_periods.firstOrUndefined()

        return {
          id: rate.id,
          label: `${period.percentage}%`,
          description: rate.description,
          percentage: period.percentage,
          sale: rate.sale,
          relayed: rate.relayed,
          in_eu: rate.in_eu,
          exempt: rate.exempt,
          domestic: rate.domestic,
          limits_invoice_to_same: rate.limits_invoice_to_same,
          limits_invoice_to_similar: rate.limits_invoice_to_similar,
          start_date: period.date_start,
          end_date: period.date_end,
          tag: rate.tag,
          balance_name: rate.balance_name,
          human_readable_name: rate.human_readable_name,
        }
      }),
      toArray(),
      /**
       * Not sure why this is in the code, and I really
       * like how nobody ever took the effort to write
       * down why the fuck this was added. Thanks.
       * Commenting it out until something breaks,
       * I guess. Will return this on error instead.
       * - xoxo Albert.
       */
      // tap((rates) => {
      //   const firstEmptyRate = {
      //     id: 0,
      //     label: '',
      //     description: '',
      //     percentage: null,
      //     sale: null,
      //     relayed: null,
      //     in_eu: null,
      //     exempt: null,
      //     domestic: null,
      //     limits_invoice_to_same: false,
      //     limits_invoice_to_similar: false,
      //     start_date: '1970-01-01',
      //     end_date: null,
      //     tag: '',
      //     balance_name: '',
      //     human_readable_name: '',
      //   }

      //   rates.unshift(firstEmptyRate)

      //   return rates
      // }),
      catchError((error) => {
        console.error(error)

        return of([
          {
            id: 0,
            label: '',
            description: '',
            percentage: null,
            sale: null,
            relayed: null,
            in_eu: null,
            exempt: null,
            domestic: null,
            limits_invoice_to_same: false,
            limits_invoice_to_similar: false,
            start_date: '1970-01-01',
            end_date: null,
            tag: '',
            balance_name: '',
            human_readable_name: '',
          } as VatPeriodDisplayItem,
        ])
      }),
    )
  }

  get userHasNoAdministration(): Observable<boolean> {
    return this.userAdministrationList.pipe(map((r) => r.length === 0))
  }

  get userHasMultipleAdministrations(): Observable<boolean> {
    return this.userAdministrationList.pipe(map((r) => r.length > 1))
  }

  get administrationAccounts(): Observable<Account[]> {
    if (this._accounts) {
      return this._accounts
    }

    this._accounts = this.defaultAdministration.pipe(
      mergeMap((administration) => this._api.get<Account[]>(endpoints.administration.accounts, { administrationId: administration.id })),
      map((accounts: Account[]) => {
        const balanceAccounts = accounts.filter((a) => a.type === AccountTypes.BALANCE),
          profitLossAccounts = accounts.filter((a) => a.type === AccountTypes.PROFIT_LOSS)

        balanceAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.BALANCE'))
        profitLossAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.PROFIT_LOSS'))

        return [...balanceAccounts, ...profitLossAccounts]
      }),
      publishReplay(1),
      refCount(),
    )

    return this._accounts
  }

  /**
   * Temporary function to get predictions for transactions.
   * Function should be deprecated when we decide to pursue this mechanism;
   * in favor of a better, more pure, function.
   *
   * @param id of given transaction.
   */
  getPrediction(id: number): Observable<Predictions> {
    this._predictions = this.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<Predictions>(endpoints.transactions.prediction, {
          administrationId: administration.id,
          transactionId: id,
        }),
      ),
      map((predictions) => {
        // Add the RGS name to the prediction object.
        const enrichedPredictions = Object.values(predictions.predictions).reduce((acc: Prediction[], curr: Prediction) => {
          this.handpickedAdministrationAccounts('user_annotation').subscribe((accounts) => {
            accounts
              .filter((accnt: Account) => curr.number === accnt.number)
              .map((result: Account) =>
                acc.push({
                  probability: curr.probability,
                  number: curr.number,
                  id: curr.account_id,
                  name: result.name,
                }),
              )
          })

          return acc
        }, [])

        return {
          id: predictions.id,
          predictions: enrichedPredictions,
        }
      }),
      publishReplay(1),
      refCount(),
    )

    return this._predictions
  }

  /**
   * Get specific array of RGS accounts.
   * Useful for when you need to fetch a select few;
   * for instance handpicked by your product manager. ;-)
   *
   * @param {string[]} selection of RGS account numbers (not ids).
   */
  getSpecificAccountsByNumber(selection: string[]): Observable<Account[]> {
    return this.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<Account[]>(endpoints.administration.annotationAccounts, {
          administrationId: administration.id,
          // type: 'user_annotation',
        }),
      ),
      map((accounts: Account[]) => accounts.filter((account) => selection.includes(account.number))),
      publishReplay(1),
      refCount(),
    )
  }

  /**
   * Process accounts that previously have been fetched,
   * and need to be split into 'balance' and 'profit/loss.
   * Used in, for instance, the 'annotation' logic.
   *
   * @param {Account[]} accounts previously fetched.
   */
  processSpecificAccounts(accounts: Account[]): Account[] {
    const balanceAccounts = accounts.filter((a) => a.type === AccountTypes.BALANCE)
    const profitLossAccounts = accounts.filter((a) => a.type === AccountTypes.PROFIT_LOSS)

    balanceAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.BALANCE'))
    profitLossAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.PROFIT_LOSS'))

    return [...balanceAccounts, ...profitLossAccounts]
  }

  /**
   * Essentially the same as above two methods, however:
   * In this case, the _backend_ selects the accounts.
   * Used in 'tx-initial-booking'.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  handpickedAdministrationAccounts(type?: 'user_annotation'): Observable<Account[]> {
    if (this._handpickedAccounts) {
      return this._handpickedAccounts
    }

    this._handpickedAccounts = this.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<Account[]>(endpoints.administration.annotationAccounts, {
          administrationId: administration.id,
          ...(type && { type }),
        }),
      ),
      map((accounts: Account[]) => {
        const balanceAccounts = accounts.filter((a) => a.type === AccountTypes.BALANCE)
        const profitLossAccounts = accounts.filter((a) => a.type === AccountTypes.PROFIT_LOSS)

        balanceAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.BALANCE'))
        profitLossAccounts.unshift(AdministrationService.getOptionTitle('OPTION_TITLE', 'ACCOUNTING.PROFIT_LOSS'))

        return [...balanceAccounts, ...profitLossAccounts]
      }),
      publishReplay(1),
      refCount(),
    )

    return this._handpickedAccounts
  }

  get hasAdministration(): Observable<boolean> {
    return this.defaultAdministration.pipe(map((admin) => admin !== null))
  }

  get shouldShowOnboardingWizard(): Observable<boolean> {
    return this.hasAdministration.pipe(
      switchMap((result) => {
        if (!result) {
          return observableOf(true)
        }

        return this.defaultAdministrationSettings.pipe(map((settings) => settings == null))
      }),
    )
  }

  get defaultAdministration(): Observable<Administration> {
    if (this._defaultAdministrationEntry) {
      return this._defaultAdministrationEntry
    }

    let fetchAdministrationFunc: () => Observable<Administration>

    if (this.defaultAdministrationId && this.defaultAdministrationId > 0) {
      fetchAdministrationFunc = () =>
        this._api.get<Administration>(endpoints.administration.administration, { administrationId: this.defaultAdministrationId }, null, null, true).pipe(
          // Fallback
          catchError(() => {
            this.setDefaultAdministrationId(null)

            return this.loadFirstAdministration()
          }),
        )
    } else {
      // Load first administration that a user is entitled to.
      fetchAdministrationFunc = () => this.loadFirstAdministration()
    }

    this._defaultAdministrationEntry = this._deviceService
      .checkDevice()
      .pipe(
        mergeMap((result) => {
          if (result) {
            return fetchAdministrationFunc()
          }

          return observableOf(null)
        }),
      )
      .pipe(publishLast(), refCount())

    if (this.onAdministrationNameChange) {
      this.onAdministrationNameChange.emit()
    }

    return this._defaultAdministrationEntry
  }

  get vatAllRatePeriods(): Observable<VatPeriodDisplayItem[]> {
    return this.vatRatePeriods.pipe(
      switchMap((periods) => periods),
      toArray(),
    )
  }

  get vatSaleRatePeriods(): Observable<VatPeriodDisplayItem[]> {
    return this.vatRatePeriods.pipe(
      switchMap((periods) => periods),
      filter((vrp) => vrp.sale),
      toArray(),
    )
  }

  get vatSaleNoVatPeriod(): Observable<VatPeriodDisplayItem> {
    return this._vatSaleNoVatPeriod()
  }

  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  private _vatSaleNoVatPeriod(): Observable<VatPeriodDisplayItem> {
    return this.vatRatePeriods.pipe(
      switchMap((periods) => periods),
      first((vatRatePeriod) => !vatRatePeriod.relayed && vatRatePeriod.sale && !vatRatePeriod.exempt && vatRatePeriod.domestic && !vatRatePeriod.percentage),
    )
  }

  get vatPurchaseRatePeriods(): Observable<VatPeriodDisplayItem[]> {
    return this.vatRatePeriods.pipe(
      switchMap((periods) => periods),
      filter((vrp) => !vrp.sale),
      toArray(),
    )
  }

  private get defaultAdministrationId(): number {
    const id = localStorage.getItem(AdministrationService.administrationId)

    return id ? parseInt(id) : -1
  }

  private static administrationId = 'default_administration_id'

  onAdministrationUpdate = new EventEmitter<void>()
  onSwitchedAdministration = new EventEmitter<void>()
  onAdministrationNameChange = new EventEmitter<void>()
  onAdministrationUpdated = new EventEmitter<void>()

  private _userAdministrationList: Observable<AdministrationListItem[]>
  private _defaultAdministrationEntry: Observable<Administration>
  private _accounts: Observable<Account[]>
  private _predictions: Observable<Predictions>
  private _handpickedAccounts: Observable<Account[]>

  /**
   * Sets a static option title and returns according to the Account model
   * @param type
   * @param name
   */
  static getOptionTitle(type: string, name: string): Account {
    return {
      id: -1, // Used for selecting the title in the greater array
      code: type,
      description: type,
      number: '',
      debit: true,
      priority: -1,
      type: type,
      name: name,
      account_name: name,
      user_updateable: true,
      booking_allowed: true,
    }
  }

  constructor(
    private readonly _api: ApiGateway,
    private readonly _deviceService: DeviceService,
    private readonly _toastr: ToastrHelper,
    private readonly _translate: TranslateService,
    private readonly _languagePersist: TranslatePersist,
    private readonly _router: Router,
    _login: LoginService,
  ) {
    // Clear cache on (re)login
    void _login.onLoggedIn.subscribe(() => this.clearCache())
    void _login.onLoggedOut.subscribe(() => this.clearCache())

    /**
     * Whenever the cache is busted,
     * call a few basic methods to
     * assure they fetch new data.
     */
    void this.onAdministrationUpdate
      .asObservable()
      .pipe(
        mergeMap(() => this.defaultAdministrationSettings),
        mergeMap(() => this.getUsersForAdministration()),
        mergeMap(() => this.defaultAdministration),
      )
      .subscribe(() => {
        void console.log('👷 Rehydrated administration cache.')
        this.onAdministrationUpdated.emit()
      })
  }

  clearCachedSettings(): void {
    void cacheBusterObserverSettings.next()
  }

  addRequiredInformation(): Observable<AdministrationSettings> {
    return this.defaultAdministrationSettings.pipe(
      tap((settings) => {
        settings.missing_context_answers.remove(settings)
      }),
    )
  }

  clearCache(): void {
    this._accounts = null
    this._defaultAdministrationEntry = null
    this._userAdministrationList = null

    cacheBusterObserver.next()
    cacheBusterObserverSettings.next()
    cacheBusterObserverVatRates.next()
  }

  clearUserCache(): void {
    cacheBusterObserver.next()
  }

  clearSettingsCache(): void {
    cacheBusterObserverSettings.next()
    cacheBusterObserverVatRates.next()
    this._defaultAdministrationEntry = null
  }

  clearDefaultAdministrationCache(): void {
    this._defaultAdministrationEntry = null
    cacheBusterObserverSettings.next()
  }

  setDefaultAdministrationId(id: number, forceCacheClearance?: boolean) {
    const previous = this.defaultAdministrationId
    localStorage.setItem(AdministrationService.administrationId, id ? id.toString() : null)

    if (forceCacheClearance || (id && previous && previous != id)) {
      this.clearCache()
      this.onSwitchedAdministration.emit()
    }
  }

  // TODO: Infinite scroll search object
  searchAdministrations(term?: string): Observable<AdministrationListItem[]> {
    const searchParams: any = {
      paginated: true,
      limit: 50,
    }

    if (term) {
      searchParams.search = term
    }

    return this._api.get<AdministrationListItem>(endpoints.administration.administration, searchParams).pipe(
      // TODO: paginated
      map((r: any) => r.rows),
    )
  }

  makeApiCallForDefaultAdministration<T>(call: (params?: any, object?: any) => Observable<T>, params?: any, object?: any): Observable<T> {
    return this.defaultAdministration.pipe(
      mergeMap((administration) => {
        if (!params) {
          params = <any>{}
        }

        params.administrationId = administration.id

        return call(params, object)
      }),
    )
  }

  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  getUsersForAdministration(): Observable<User[]> {
    return this.makeApiCallForDefaultAdministration((p) => this._api.get<User[]>(endpoints.administration.users, p, null)).pipe(publishReplay(1), refCount())
  }

  isYearStartYear(year?: number): Observable<boolean> {
    if (!year) {
      year = moment().year()
    }

    return this.defaultAdministrationSettings.pipe(map((settings) => moment(settings.start_date).year() == year))
  }

  isYearStartYearAndStartDateIsJanuaryFirst(year?: number): Observable<boolean> {
    if (!year) {
      year = moment().year()
    }

    return this.isYearStartYear(year).pipe(
      mergeMap((result) => {
        if (!result) {
          return observableOf(false)
        }

        return this.defaultAdministrationSettings.pipe(map((settings) => moment(settings.start_date).isSame(moment(`01-01-${year}`).startOf('year'), 'day')))
      }),
    )
  }

  sendPaymentRequest(data: any): Observable<any> {
    return this.makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.administration.paymentRequest, data, p, {}, true))
  }

  generateSmartMailbox(): Observable<string> {
    return this.defaultAdministration.pipe(
      mergeMap((a) =>
        this._api.post<{ smart_box_email: string }>(
          endpoints.administration.generateSmartMailbox,
          null,
          {
            administrationId: a.id,
          },
          {},
          true,
        ),
      ),
      map(({ smart_box_email }) => {
        this.clearSettingsCache()
        this.onAdministrationUpdated.emit()

        return smart_box_email
      }),
    )
  }

  setNewAdministrationName(newAdministrationName: string, administrationId: number): Observable<Administration> {
    return this._api.patch<Administration>(endpoints.administration.administration, { name: newAdministrationName }, ['name'], {
      administrationId: administrationId,
    })
  }

  setNewCompanyLogo(file: File): Observable<any> {
    const loading: string = this._translate.instant('INVOICES.UPLOADING_LOGO')
    const success: string = this._translate.instant('INVOICES.UPLOAD_LOGO_SUCCESS')
    const unexpectedError: string = this._translate.instant('INVOICES.UPLOAD_LOGO_FAILED')

    return this.defaultAdministration.pipe(
      this._toastr.observe({
        loading,
        success: () => success,
        error: (err: TellowError) => {
          switch (err.error_code) {
            default:
              return unexpectedError
          }
        },
      }),
      mergeMap((administration) =>
        this._api.postMultipart(
          endpoints.administration.logo,
          { logo: file },
          {
            administrationId: administration.id,
          },
        ),
      ),
      // Re-fetch existing settings.
      tap(() => this.clearSettingsCache()),
    )
  }

  setTaxBucketStartDate(unset = false): Observable<AdministrationSettings> {
    const date = unset ? null : format(new Date(), globals.fnsDateFormat)

    return this.defaultAdministration.pipe(
      mergeMap((a) =>
        this._api.patch(endpoints.administration.settings, { tax_bucket_start_date: date }, ['tax_bucket_start_date'], {
          administrationId: a.id,
        }),
      ),
      mergeMap(() => {
        this.clearSettingsCache()
        this.onAdministrationUpdated.emit()

        return this.defaultAdministrationSettings
      }),
    )
  }

  public openOnboardingModal(context: OnboardingModalSources, onCompletionRoute?: string, onSubmit: boolean = false): void {
    void this._router.navigate(['/', { outlets: { modal: 'onboarding' } }], { state: { context, onCompletionRoute, onSubmit } } as NavigationExtras)
  }

  private loadFirstAdministration(): Observable<Administration> {
    return this._api
      .get<PaginatedResponse<AdministrationListItem>>(endpoints.administration.administration, {
        paginated: true,
        limit: 1,
      })
      .pipe(
        // TODO: paginated
        map((r) => r.rows[0] as AdministrationListItem),
        mergeMap((a) => {
          if (a) {
            return this._api.get<Administration>(endpoints.administration.administration, { administrationId: a.id }, null, null, true)
          }

          return observableOf(null)
        }),
      )
  }
}
