import { Account } from './account.model'

export interface AdministrationSettings {
  id?: number
  account_template_id?: number
  coc_number?: string
  reason_no_coc?: string
  start_date?: string
  mutations_before_start_date?: boolean
  invoices_after_start_date?: boolean
  has_car?: boolean
  car_license_plate?: string
  email_address?: string
  has_real_estate?: boolean
  has_mileage_registration?: boolean
  vat_number?: string
  vat_declaration_period?: string
  vat_period_offset?: string
  has_vat: boolean
  has_kor?: boolean
  booking_period?: string
  quote_template?: string
  invoice_template?: string
  invoice_number_index?: number
  company_name?: string
  address_street?: string
  address_number?: string
  address_number_addition?: string
  address_postal_code?: string
  address_city?: string
  address_country?: string
  iban?: string
  recipient_name?: string
  recipient_second_name?: string
  last_name?: string
  bic?: string
  website?: string
  phone_number?: string
  mobile_number?: string
  legal_form?: LegalFormType
  tax_bucket_start_date: string | null
  generated_template?: boolean
  start_balance_filled?: boolean
  missing_context_answers?: string[]
  logo_url?: string
  main_color?: string
  secondary_color: string
  font?: string
  account_template?: AccountTemplate
  default_accounts?: DefaultAccounts
  push_enabled: boolean
  sbi?: string[]
  requires_start_balance: boolean
  default_quote_email_body: string
  default_quote_footer: string
  quote_expiry_delta: number
  quote_number_index?: number
  default_invoice_template: number
  default_quote_template: number
  invoice_expiry_delta: number
  default_invoice_email_body: string
  default_invoice_footer: string
  company_info_dossier_created_at?: string | null
  invoices_after_start_date_added?: boolean
  invoice_numbering?: 'YEARLY' | 'MONTHLY'
  language?: 'nl' | 'en'
  smart_box_email?: string | null
}

export class LegalFormType {
  static TYPE_BV = 'BV'
  static TYPE_SOLE_PROPRIETORSHIP = 'SOLE_PROP'
  static TYPE_FOUNDATION = 'FOUNDATION'
  static TYPE_GENERAL_PARTNERSHIP = 'GP'
  static TYPE_BV_CODE = 41
  static TYPE_SOLE_PROPRIETORSHIP_CODE = 1
}

export interface AccountTemplate {
  id: number
  name: string
}

export interface DefaultAccounts {
  balance_net_result_account: Account
  bank_account: Account
  costs_account: Account
  creditor_account: Account
  debtor_account: Account
  justify_account: Account
  payment_differences_account: Account
  pre_vat_account: Account
  private_credit_account: Account
  private_debit_account: Account
  vat_paid_account: Account
  vat_to_pay_account: Account
}

export interface BillingSettings {
  id: number
  account_type: string
  created_at: Date
  subscription_start_date: Date
  trial_expiration_date: Date
  payment_collection_consent: boolean
  payment_collection_status: string
}

export interface Predictions {
  id: string
  predictions: Prediction[]
}

export interface Prediction {
  probability: number
  number: string
  id?: number
  account_id?: number
  name?: string
}

export interface AccountsWithPredictions {
  accounts: Account[]
  predictions: Predictions
}
