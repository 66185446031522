import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core'
import { NgForm, FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { TranslatePersist } from '../../core/logic/i18n/translate-persist.service'
import { indicate } from '../../core/operators/indicate'
import { ApiGateway } from '../../core/remote/api.gateway'
import { Question } from '../../domain/onboarding/question.model'
import { AdministrationService } from '../../logic/administration/administration.service'
import { SegmentHelper } from '../../logic/external-services/segment.helper'
import { ToastrHelper } from '../../logic/helpers/toastr.helper'
import { InvoiceService } from '../../logic/invoices/invoice.service'
import { endpoints } from '../../shared/config/endpoints'
import { TlwAuxilaryModalComponent } from '../tlw-auxilary-modal/tlw-auxilary-modal.component'
import FieldControl from './fieldcontrol.extension'

export type OpenMethodProperties = {
  context: OnboardingModalSources
  args?: any[]
}

export enum OnboardingModalSources {
  Home = 'home',
  Quote = 'quote',
  Document = 'document',
  Loans = 'loans',
  SmartMailBox = 'smart-mailbox',
  Invoice = 'invoice',
}

export type QuestionKeys = 'bedrijfsnaam' | 'plaats' | 'kvk' | 'btw' | 'land' | 'huisnummer' | 'postcode' | 'straat' | 'telefoonnummer' | 'sbi'
export type CocKeys = 'city' | 'coc_number' | 'company_name' | 'country' | 'house_number' | 'location_number' | 'postcode' | 'street_name' | 'sbi' | 'sbi_codes'

type SelectedCompany = { [key in CocKeys]: string | number }

@Component({
  selector: 'tlw-onboarding-modal',
  templateUrl: 'tlw-onboarding-modal.component.html',
  styleUrls: ['tlw-onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgForm],
})
export class TlwOnboardingModalComponent implements OnInit {
  @ViewChild(TlwAuxilaryModalComponent) auxilary: TlwAuxilaryModalComponent

  protected moreAboutInvoiceDetailsUrl: { [key in 'en' | 'nl']: string } = {
    nl: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/administratie_bijhouden/facturen_maken/factuureisen/',
    en: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontenten/belastingdienst/business/vat/vat_in_the_netherlands/vat_administration/invoice_requirements',
  }

  protected companyName: string = ''
  protected isFormVisible: boolean = false
  protected context: `${OnboardingModalSources}` = this.getAuxilaryContext ?? OnboardingModalSources.Invoice

  protected form: FormGroup = new FormGroup({})

  protected saving$ = new BehaviorSubject<boolean>(false)
  protected loading$ = new BehaviorSubject<boolean>(true)

  get getEntityName(): string {
    return this._translate.instant(`MAIN.${this.context.toUpperCase()}`)
  }

  /**
   * Auxilary context is stored as a state
   * through the Angular router. We can
   * obtain it from the ViewChild.
   */
  get getAuxilaryContext(): OnboardingModalSources {
    return this.auxilary?.state?.context
  }

  get getAuxilaryCompletionRoute(): string {
    return this.auxilary?.state?.onCompletionRoute
  }

  get getAuxilarySubmit(): string {
    return this.auxilary?.state?.onSubmit
  }

  /**
   * Take all the non-optional items from above getter (items).
   * Add 'addition' ('toevoegsel' in Dutch), since this is an
   * exception in the layout, which should be added regardless.
   */
  get nonOptionalFields() {
    return (
      Object.keys(this.form.value)
        // ...and return the controller.
        .map((key) => this.form.get(key))
        // Only take ones with required validator.
        .filter((item) => item.validator)
    )
  }

  /**
   * See explanation above for non-optional items.
   */
  get optionalFields() {
    return (
      Object.keys(this.form.value)
        // Filter out the right values
        .filter((key) => !key.includes('toevoegsel'))
        // ...and return the controller.
        .map((key) => this.form.get(key))
        // Only take ones with required validator.
        .filter((item) => !item.validator)
    )
  }

  constructor(
    private readonly _invoice: InvoiceService,
    private readonly _segment: SegmentHelper,
    private readonly _administration: AdministrationService,
    private readonly _translate: TranslateService,
    private readonly _toastr: ToastrHelper,
    private readonly _api: ApiGateway,
    public i18n: TranslatePersist,
  ) {}

  /**
   * Lifecycle method.
   */
  ngOnInit() {
    void this.track('Form')

    void this._invoice
      .getInvoiceQuestions('invoice')
      .pipe(
        indicate(this.loading$),
        map(([data]) => data.questions),
      )
      .subscribe((questions: Question[]) => {
        void questions
          // Take out unused fields.
          .filter(({ title }) => /^((?!(land|bic)).)*$/gi.test(title))
          // Remove spaces, '(optioneel)', and anything after a dash.
          .map((item) => ({ ...item, title: item.title.replace(/(\s+|\(optioneel\)|-.*)/g, '').toLowerCase() }))
          // Add controls to the 'fieldcontrol' (see .extension.ts in folder view).
          .forEach(({ type, field, title, answer, required, pattern }) => {
            void this.form.addControl(title, new FieldControl(answer ?? null, { type, title, field, pattern, required }))
          })
      })
  }

  /**
   * Enable saving from the template.
   */
  onSaveHandler(): void {
    void this.checkValidity(() => this.performSaveRequest())
  }

  /**
   * Check if form is valid, otherwise
   * do not save and mark invalid fields.
   */
  private checkValidity(callback: () => void): void {
    if (this.form.valid === false) {
      this.form.markAllAsTouched()
    } else {
      void callback()
    }
  }

  /**
   * Save the data to the backend.
   */
  private performSaveRequest(): void {
    /**
     * Convert the 'field' value back the the random UUID
     * initially used by the backend. 'getAsField' is a method
     * in 'FieldControl' that eases this process.
     */
    const answers = Object.values(this.form.controls).reduce((acc, value: FieldControl) => ({ ...acc, ...value.getAsField }), {})

    // Get all i18n keys.
    const loading: string = this._translate.instant('COMPONENTS.ONBOARDING_MODAL.PROCESSING')
    const success: string = this._translate.instant('COMPONENTS.ONBOARDING_MODAL.SUCCESS')
    const unexpectedError: string = this._translate.instant('COMPONENTS.ONBOARDING_MODAL.ERROR')

    /**
     * Make the call.
     */
    void this._administration
      .makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.administration.complement, { context: this.context, answers }, p))
      .pipe(
        indicate(this.saving$),
        this._toastr.observe({
          loading,
          success: () => success,
          error: () => unexpectedError,
        }),
        tap(() => this.track('Submitted')),
        tap(() => this._administration.clearCache()),
        tap(() => this._administration.onAdministrationUpdate.emit()),
      )
      .subscribe(() => {
        this.auxilary.close()
      })
  }

  onSelectCompany(value: SelectedCompany): void {
    void this.selectCompany(value)
    this.isFormVisible = true
  }

  /**
   * This functions runs when you pick a company
   * from the 'tlw-company-seacher' component.
   */
  protected selectCompany(value: SelectedCompany): void {
    void Object.entries(value)
      .filter(([key]) => Boolean(this.convertCocKeys(key)))
      .map(([key, value]) => {
        void this.form.controls[this.convertCocKeys(key)].setValue(value)
      })
  }

  /**
   * Submit tracking code to Segment.
   * Small helper to make code more DRY.
   */
  private track(title: 'Form' | 'Submitted'): void {
    return this._segment.track(`Required Fields - ${title}`, {
      location: this.context,
    })
  }

  /**
   * Convert keys from the KVK (CoC) API
   * to align with our custom keys.
   */
  private convertCocKeys(key: string): QuestionKeys {
    switch (key) {
      case 'city':
        return 'plaats'
      case 'coc_number':
        return 'kvk'
      case 'company_name':
        return 'bedrijfsnaam'
      case 'house_number':
        return 'huisnummer'
      case 'postcode':
        return 'postcode'
      case 'street_name':
        return 'straat'
      case 'sbi_codes':
        return 'sbi'
      default:
        break
    }
  }

  /**
   * Method for Angular to keep track
   * of rendered items in for-loop.
   * (in the template, 'ngFor').
   */
  protected trackByFn(index: number, item: Question): string {
    return item.title
  }
}
