import { Observable } from 'rxjs'
import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'

/**
 * @deprecated
 * Used to be used for Rabobank 'track'-users.
 * This is old and unused, so should not be used.
 */
@Injectable()
export class AdministrationGuard implements CanActivate {
  canActivate(): boolean | Observable<boolean> {
    return true
  }
}
