import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, forwardRef, Inject, Input, Optional, Output, ViewChild } from '@angular/core'
import { OptionDirective } from '../html-directives/option.directive'
import { SelectDirective } from '../html-directives/select.directive'
import { NgModel } from '@angular/forms'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { SelectOption } from '../fnc-select/fnc-select.component'
import { OptionLabelValue } from '../../domain/onboarding/question.model'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'fnc-select-input',
  styleUrls: ['./fnc-select-input.component.scss'],
  host: {
    '(click)': 'toggleActive($event)',
    '(document:click)': 'setInactive($event)',
  },
  template: `
    <ng-content></ng-content>
    <div *ngIf="select.model || value">
      <fnc-input-container>
        <div style="display: inline-flex; width: 100%;">
          <input [maxlength]="inputMaxlength" type="text" [(ngModel)]="value" #input />
          <fnc-icon (click)="clearModel($event)" [icon]="'close'" style="float: right; cursor: pointer"></fnc-icon>
        </div>
      </fnc-input-container>
    </div>
    <div class="fnc-select-container" [class.active]="active">
      <div class="fnc-select-value" *ngIf="!select.model && !value">
        <div class="fnc-select-value-inner">
          {{ selectedLabel }}
        </div>
      </div>
      <div #list class="list" *ngIf="!select.model && !value">
        <div class="search-container row" *ngIf="enableSearch" (click)="$event.stopPropagation(); $event.preventDefault()">
          <div class="columns">
            <fnc-input-container>
              <input type="text" [ngModel]="searchString" (ngModelChange)="search($event)" />
              <label>{{ 'MAIN.SEARCH' | translate }}</label>
            </fnc-input-container>
          </div>
        </div>
        <ul>
          <li *ngFor="let option of options; let i = index" (click)="$event.stopPropagation(); optionClicked(i)" [class.checked]="option.checked">
            {{ (option.text | translate) || option.text }}
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class FncSelectInputComponent extends BaseHtmlDirective implements AfterContentInit {
  get value(): string {
    return this._value
  }

  set value(val: string) {
    this._value = val
    // update model dan ook ff
    if (this.ngModel && this.ngModel.model) {
      this.ngModel.model.value = this._value
      // this.ngModel.model.label = this._parent.label.element.textContent;
    }
  }

  get selectedLabel() {
    if (!this.options || !this.options.length) {
      return
    }

    const first = this.options.firstOrUndefined((o) => o.checked)

    // show Select value if value selected. Otherwise display placeholder. If placeholder is
    if (first) {
      return first.text
    }

    if (this.active) {
      return this.selectElement.placeholder
    }

    return ''
  }

  get ngModel(): NgModel {
    return this.select
  }

  set ngModel(val: NgModel) {
    this.select = val
  }

  searchString: string
  active = false
  options: SelectOption[]

  @Input() integer: boolean
  @Input() enableSearch: boolean
  @Input() items: any[]

  @ViewChild('list') list: ElementRef
  @ViewChild('input') input: ElementRef

  @ContentChild(NgModel) select: NgModel
  @ContentChild(SelectDirective) selectElement: SelectDirective
  @Output() onFocusStateChanged = new EventEmitter<boolean>()

  @Output() onDelete = new EventEmitter<any>()
  @Output() onSelect = new EventEmitter<any>()

  private removeClassTimeOut: NodeJS.Timeout
  private _options: SelectOption[]

  private _value: string
  inputMaxlength: number

  constructor(
    private readonly _el: ElementRef,
    @Optional() @Inject(forwardRef(() => FncInputContainerComponent)) private readonly _parent: FncInputContainerComponent,
    private readonly _translate: TranslateService,
  ) {
    super(_el)
  }

  ngAfterContentInit() {
    this.changeOptions()
    this.selectElement.options.changes.subscribe(() => this.changeOptions())

    this.valueChanged()

    if (this.select) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.select.valueChanges.subscribe((value) => this.valueChanged())
    }

    if (this.ngModel.model) {
      this.value = this.ngModel.model['value']

      setTimeout(() => {
        this._parent.label.element.textContent = this.ngModel.model['label']
        this._parent.container.nativeElement.classList.add('is-dirty')
      }, 10)
    }
  }

  setValueAndLabel(labelAndValue: OptionLabelValue) {
    if (labelAndValue) {
      // this.ngModel.model = labelAndValue;
      setTimeout(() => {
        this.value = labelAndValue.value

        setTimeout(() => {
          // this.value = labelAndValue.value;
          this._parent.label.element.textContent = labelAndValue.label
          this._parent.container.nativeElement.classList.add('is-dirty')
          this.onSelect.emit()
        }, 10)
      }, 5)
    }
  }

  optionClicked(index: number) {
    if (this.select) {
      let value = this.options[index].value
      const maxlength = this.items[index].maxlength

      this.inputMaxlength = maxlength

      if (this.integer) {
        value = parseInt(value)
      }

      this.select.control.setValue({
        label: this.translateLabelReverse(value, this.items[index]?.label),
        // value: this._value,
        value,
        maxlength: maxlength,
      })
      this.updateLabel(this.translateLabelReverse(value, this.items[index]?.label))
      this.value = ''
    }

    setTimeout(() => this.setInactive(), 100)
  }

  toggleActive(evt: any) {
    evt.preventDefault()
    evt.cancelBubble = true
    evt.stopPropagation()

    this.active = !this.active

    if (this.active) {
      this.setActive()
      clearTimeout(this.removeClassTimeOut)
    } else {
      this.setInactive()
    }

    this._el.nativeElement.focus()

    return false
  }

  // Body clicked. Or dismissed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setInactive(evt?: any) {
    this.active = false

    if (this._parent && this.select && (this.select.control.value == undefined || this.select.control.value == '')) {
      this._parent.container.nativeElement.classList.remove('is-focused')
    }

    if (this.list) {
      this.list.nativeElement.style.height = '0'
      this._el.nativeElement.style.zIndex = null

      // Only remove class after animation is done.
      this.removeClassTimeOut = setTimeout(() => {
        this.list.nativeElement.classList.remove('visible')
      }, 300)
    }

    this.onFocusStateChanged.emit(false)

    this.searchString = null
    this.options = this._options
  }

  search(searchString: string) {
    this.searchString = searchString
    this.performSearch()
  }

  getValueAndLabel(notTranslate?: boolean) {
    if (!this._value) {
      return
    }

    if (notTranslate) {
      return { label: this._parent.label.element.textContent, value: this._value }
    }

    return { value: this._value, label: this.translateLabel(this._parent.label.element.textContent) }
  }

  clearModel(evt: any) {
    evt.preventDefault()
    evt.stopPropagation()
    const prevValues = { label: this._parent.label.element.textContent, value: this.translateLabel(this._parent.label.element.textContent) }
    this.selectElement.placeholder = null
    this.select.reset()
    this.value = null
    this._parent.label.element.textContent = 'Overige informatie regels (optioneel)'
    this.onDelete.emit(prevValues)
    this.setInactive()
  }

  reset() {
    this.select.reset()
    this.value = null
    this._parent.label.element.textContent = 'Overige informatie regels (optioneel)'
    this.setInactive()
  }

  private updateLabel(labelValue: string) {
    setTimeout(() => {
      // this.value = labelAndValue.value;
      this._parent.label.element.textContent = labelValue
      this._parent.container.nativeElement.classList.add('is-dirty')
      this.onSelect.emit()
    }, 10)
  }

  private changeOptions() {
    this._options = this.selectElement.options.toArray().map((o: OptionDirective) => {
      const value = o.value && o.value.contains(': ') ? o.value.split(': ')[1] : o.value

      return new SelectOption(value, o.text, this.select && this.select.control.value == o.value)
    })

    this.performSearch()
  }

  private translateLabel(label: string) {
    if (['Projectomschrijving', 'Project description'].includes(label)) {
      return 'description'
    }

    if (['Kostenplaats', 'Cost center'].includes(label)) {
      return 'expense'
    }

    if (['Kostensoort', 'Cost type'].includes(label)) {
      return 'cost_type'
    }

    if (['Projectnaam', 'Project name'].includes(label)) {
      return 'project_name'
    }

    return label
  }

  private translateLabelReverse(label: string, key?: string) {
    if (key) return this._translate.instant(key)

    if (label === 'description') {
      return 'Projectomschrijving'
    }

    if (label === 'expense') {
      return 'Kostenplaats'
    }

    if (label === 'cost_type') {
      return 'Kostensoort'
    }

    if (label === 'project_name') {
      return 'Projectnaam'
    }
  }

  private valueChanged() {
    if (!this.select) {
      return
    }

    if (this.select.value) {
      let optionLabel = ''
      this.options.forEach((option) => {
        if (option.value === this.select.value) {
          optionLabel = option.text
        }
      })

      this._parent.label.element.textContent = optionLabel
    }

    this.options.forEach((o) => (o.checked = o.value == this.select.control.value))
    this._options.forEach((o) => (o.checked = o.value == this.select.control.value))

    if (this._parent.container && this.select.control.value !== null && this.select.control.value !== undefined) {
      this._parent.container.nativeElement.classList.add('is-dirty')
    }

    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus()
      }
    }, 1)

    setTimeout(() => {
      this.onSelect.emit(this.select.model)
    }, 10)
  }

  private setActive() {
    if (!this.list) {
      return
    }

    this._el.nativeElement.style.zIndex = '4'
    if (this._parent) {
      this._parent.container.nativeElement.classList.add('is-focused')
    }

    // let it grow. So we can get a measure of the actual height.
    this.list.nativeElement.style.height = 'auto'

    // Now measure the height.
    const height = this.list.nativeElement.getBoundingClientRect().height

    // Revert back to 0 height so we can animate to the actual height.
    this.list.nativeElement.style.height = '0'

    // Set animatable class.
    this.list.nativeElement.classList.add('visible')

    // Now set the actual height for animation.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    setTimeout(() => (this.list.nativeElement.style.height = `${height}px`), 0)

    this.onFocusStateChanged.emit(true)
  }

  private performSearch() {
    if (!this.searchString || !this._options.length) {
      this.options = this._options

      return
    }

    const search = this.searchString.toLowerCase()

    this.options = this._options.filter((o) => o.text.toLowerCase().contains(search))
  }
}
