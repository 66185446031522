import { mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AdministrationService } from './administration.service'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { Task } from '../../domain/task/task.model'

@Injectable()
export class TaskService {
  constructor(private readonly _administration: AdministrationService, private readonly _api: ApiGateway) {}

  getTask(id: number): Observable<Task> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => this._api.get<Task>(endpoints.tasks.tasks, { administrationId: administration.id, id: id })),
    )
  }
}
