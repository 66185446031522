import { EventEmitter, Injectable, Injector } from '@angular/core'
import { catchError, map, mergeMap, refCount, shareReplay, startWith, switchMap, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { SubscriptionResponse, Plan, TellowPlans, Features, PlanData } from '../../domain/billing/plan.model'
import { BillingDetails, GetDataBody, GetDataModel } from '../../domain/billing/billing-details.model'
import { SecureHttp } from '../../core/remote/httpclient'
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs'
import { UserService } from '../user/user.service'
import { AdministrationService } from './administration.service'
import { User } from '../../domain/user/user.model'
import { Administration } from '../../domain/administration/administration.model'
import { PortalRequest, PortalResponse } from '../../domain/billing/portal.model'
import moment from 'moment'
import { SegmentHelper } from '../external-services/segment.helper'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { SecurityService } from '../../core/security/security.service'
import { Router } from '@angular/router'
import { routerlinks } from '../../../environments/routerlinks/routerlinks-nl'
import { Cacheable } from 'ts-cacheable'
import { ToastrHelper } from '../helpers/toastr.helper'
import { TranslateService } from '@ngx-translate/core'
import { LegalFormType } from '../../domain/administration/administration-settings.model'
import { LoginService } from '../../core/security/login.service'

const cacheBusterObserver = new Subject<void>()

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private readonly _administration: AdministrationService,
    private readonly _router: TranslateRouteService,
    private readonly _translate: TranslateService,
    private readonly _security: SecurityService,
    private readonly _toastr: ToastrHelper,
    private readonly _login: LoginService,
    private readonly _injector: Injector,
    private readonly _user: UserService,
    private readonly _http: SecureHttp,
    private readonly _paywall: Router,
  ) {
    this._administration.onSwitchedAdministration.subscribe(() => this.clearCache())
    this._login.onLoggedOut.subscribe(() => this.clearCache())

    /**
     * Whenever the cache is busted,
     * call a few basic methods to
     * assure they fetch new data.
     */
    void this.onBillingUpdate
      .pipe(
        mergeMap(() => this.getSubscriptionData()),
        mergeMap(() => this.getInfo()),
      )
      .subscribe(() => {
        void console.log('👷 Rehydrated billing cache.')
      })
  }

  /**
   * Emitter to let other components know we need to act.
   * Example given: we might want to clear certain caches.
   */
  public onBillingUpdate = new EventEmitter<void>()

  /**
   * Check whether user can make quotes
   * according to the billing service.
   *
   *       Important:
   *  1. - Force refresh when data is renewed.
   *  2. - Start with getInfo to fetch initial
   *       billing data, else you will get undefined.
   */
  public canMakeQuotes$: Observable<boolean | undefined> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => info?.data?.features?.canMakeQuotes ?? false),
  )

  /**
   * Use `canMakeQuotes$` to decide whether
   * user can route to quote domain or not.
   */
  public canRouteToQuotes$: Observable<boolean | Error | undefined> = this.canMakeQuotes$.pipe(
    map((isAbleToRouteToQuotes) => {
      if (!isAbleToRouteToQuotes) {
        throw new Error('Not allowed to route to quotes module.')
      }

      return true
    }),
  )

  /**
   * Use `canUseSmartPay$` to decide whether
   * user can use the Smart Pay button in Expenses.
   */
  public canUseSmartPay$: Observable<boolean> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => info?.data?.features?.canUseSmartPay ?? false),
  )

  /**
   * Use `canUseSmartBoxEmail$` to decide whether
   * user can use the Smart Mail box for expenses.
   */
  public canUseSmartBoxEmail$: Observable<boolean> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => info?.data?.features?.canUseSmartBoxEmail ?? false),
  )

  /**
   * Use `canUseTaxBucket$` to decide whether
   * user can start using the Tax Bucket feature.
   */
  public canUseTaxBucket$: Observable<boolean> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => info?.data?.features?.canUseTaxBucket ?? false),
  )

  /**
   * Use `canUseStandardProducts$` to decide whether
   * user can start using the Standard Products feature.
   */
  public canUseStandardProducts$: Observable<boolean> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => info?.data?.features?.canUseStandardProducts ?? false),
  )

  /**
   * Use `numberOfAllowedTransactionPerMonth$` to decide the number of transactions
   * user can interact with in a month.
   */
  public numberOfAllowedTransactionPerMonth$: Observable<number> = this.onBillingUpdate.asObservable().pipe(
    startWith(() => this.getInfo()),
    mergeMap(() => this.getInfo()),
    map((info) => {
      return info && info?.data?.features?.numberOfAllowedTransactionPerMonth !== 0 ? info?.data?.features?.numberOfAllowedTransactionPerMonth : Infinity
    }),
  )

  /**
   * Clear all cache related to this
   * billing service; for instance
   * when you are changing plans.
   */
  public clearCache(): void {
    void cacheBusterObserver.next()
  }

  isBankingPlan(plan?: PlanData): boolean {
    return plan?.planId.contains('banking') ?? false
  }

  /**
   * Goes to paywall with a preselected plan or automatically upgrades the user to a selected plan.
   *
   * @param {string} location from which the Upgrade has been selected - used for tracking
   * @param {number} tier to which the user should be upgraded (Defaults to 2 => Plus as most limitations happen between Basis - Plus)
   * @param {function} nextStep function to be called after the upgrade (Defaults to redirect to billing settings)
   */
  goToPaywallOrUpgrade(location: string, tier: number = 2, nextStep?: () => void): void {
    const { index, account, billingSettings } = environment.routerLinks.settings
    const segment = this._injector.get(SegmentHelper)

    const loadingKey = this._translate.instant('MESSAGES.YOU_ARE_BEING_UPGRADED')
    const successKey = this._translate.instant('SETTINGS.ADMINISTRATION_BILLING_SCREEN.PLAN_HAS_BEEN_CHANGED')
    const errorKey = this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG')

    void combineLatest([this.isOn(TellowPlans.Gratis), this.getInfo(), this._administration.defaultAdministration, this.getPlans()])
      .pipe(
        this._toastr.observe({
          error: () => errorKey,
        }),
        tap(() =>
          segment.track('Upgrade - Selected', {
            location: location,
          }),
        ),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tap(([isOnFreemium, info, { legal_form }, plans]: [boolean, SubscriptionResponse, Administration, Plan]) => {
          if (isOnFreemium && !this.isBankingPlan(info?.data?.plan)) {
            throw new Error('User is on freemium with no Tellow banking account.')
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tap(([isOnFreemium, info, { legal_form }, plans]: [boolean, SubscriptionResponse, Administration, Plan]) => {
          // If on Apple or Not SOLE_PROP and upgrading to Compleete -> Upgrade cannot happen thus redirect to abonnement settings where error will be clearly presented
          if (info.type === 'apple' || (legal_form !== LegalFormType.TYPE_SOLE_PROPRIETORSHIP && tier === 3)) {
            const settings = environment.routerLinks.settings
            void this._router.navigate(`/${settings.index}/${settings.account}/${settings.billingSettings}`)

            return
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mergeMap(([isOnFreemium, info, administration, plans]: [boolean, SubscriptionResponse, Administration, Plan]) => {
          const currentlyLockedToMonths: number = info.data.plan.lockUserToThisPlanForMonths
          const planToUpgradeTo: string =
            tier === 3
              ? plans.data.find((plan) => plan.tier === tier).stripePlanId
              : plans.data.find((plan) => plan.lockUserToThisPlanForMonths === currentlyLockedToMonths && plan.tier === tier).stripePlanId

          this._toastr.loading(loadingKey)

          return this.changePlan(planToUpgradeTo)
        }),
        tap(() => this._user.onIdentifyUser.emit()),
      )
      .subscribe(
        () => {
          this._toastr.success(successKey)

          if (nextStep) {
            void nextStep()
          } else {
            void this._router.navigate(`/${index}/${account}/${billingSettings}`)
          }
        },
        () => {
          this._security.showUserPaywall()
          this._security.saveUrlAfterPaywall(this._paywall.url)

          // Navigate to Paywall
          void this._paywall.navigate([routerlinks.main.paywall], {
            queryParams: {
              tier: tier,
              yearly: tier === 3,
            },
          })
        },
      )
  }

  /**
   * Get billing information.
   */
  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  getInfo(skipPaywall: boolean = false): Observable<SubscriptionResponse | any> {
    return this.getSubscriptionData().pipe(
      switchMap((data: GetDataModel) =>
        this._http.post<SubscriptionResponse>(`${environment.billingServiceApiUrl}/v1/subscriptions/${data.administrationId}`, data.body, {
          skipPaywallFlow: skipPaywall,
        }),
      ),
    )
  }

  /**
   * Get billing portal URL. Keep in mind
   * URL is linked to a Stripe session which
   * is not valid indefinitely.
   *
   * @see https://billing.test.tellow.nl/api/docs
   * @see https://stripe.com/docs/billing/subscriptions/integrating-customer-portal#redirect
   */
  billingPortalUrl(returnUrl: string): Observable<PortalResponse | undefined> {
    const data: PortalRequest = {
      returnUrl,
      createdAt: moment().toISOString(),
    }

    const administrationId: Observable<number> = this.getAdministrationId()

    return administrationId.pipe(
      switchMap((administrationId: number) => {
        return this._http.post<PortalResponse>(`${environment.billingServiceApiUrl}/v1/stripe/portal/${administrationId}`, data)
      }),
      shareReplay(1),
      refCount(),
      catchError((err) => {
        console.error(err)

        return of(undefined)
      }),
    )
  }

  /**
   * Get all possible plans.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  getPlans(): Observable<Plan | undefined> {
    return this.getInfo().pipe(
      mergeMap((info: SubscriptionResponse) => {
        const isOnABankingPlan = this.isBankingPlan(info?.data?.plan)

        return this._http.get<Plan>(`${environment.billingServiceApiUrl}/v1/plans${isOnABankingPlan ? '?showBankingPlans=true' : ''}`)
      }),
    )
  }

  /**
   * Create Stripe subscription (by id).
   *
   * @param id of users administration.
   * @param planId plan user wants to subscribe to.
   * @param details that needs to be passed to the API.
   */
  createSubscription(id: number, planId: string, details: BillingDetails): Observable<any> {
    if (!details) {
      throw new Error('No details given.')
    }

    if (!planId) {
      throw new Error('No plan selected.')
    }

    return this._http
      .post<BillingDetails>(`${environment.billingServiceApiUrl}/v1/stripe/subscriptions/${id}`, { ...this.filterEmptyValues(details), planId })
      .pipe(
        tap(() => {
          this.clearCache()
          this.onBillingUpdate.emit()
        }),
      )
  }

  /**
   * Apply a Stripe promotion code.
   */
  applyCoupon(promotionCode: string): any {
    return this.getAdministrationId().pipe(
      switchMap((id: number) => {
        return this._http.post<any>(`${environment.billingServiceApiUrl}/v1/stripe/subscriptions/${id}/promotion-code`, {
          coupon: promotionCode,
        })
      }),
    )
  }

  /**
   * Cancel Stripe subscription. Idempotent request.
   * Marks the subscription to cancel at period end.
   * Can result in a grace period since we do not
   * prorate.
   */
  cancelSubscription(): any {
    return this.getAdministrationId().pipe(
      switchMap((id: number) => {
        return this._http.delete<any>(`${environment.billingServiceApiUrl}/v1/stripe/subscriptions/${id}`)
      }),
      tap(() => this.clearCache()),
    )
  }

  /**
   * Change Stripe plan for this user.
   */
  changePlan(planId: string): Observable<any | SubscriptionResponse> {
    const administrationId: Observable<number> = this.getAdministrationId()
    const p = { planId: planId }
    const input = Object.assign(p)
    const body = this.filterEmptyValues(input)

    return administrationId.pipe(
      switchMap((id: number) => {
        return this._http.post<any>(`${environment.billingServiceApiUrl}/v1/stripe/subscriptions/${id}/change-plan`, body)
      }),
      // Clear out all existing cache.
      tap(() => this.clearCache()),
      tap(() => this.onBillingUpdate.emit()),
    )
  }

  /**
   * Function to see if feature are enabled for this user.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  getEnabledFeatures(): Observable<Features> {
    return this.getInfo().pipe(map(({ data }) => data.features))
  }

  /**
   * 'isOn'-plan helper method.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  isOn(plan: TellowPlans): Observable<boolean> {
    return this.getInfo().pipe(map(({ data }) => data?.plan?.name === plan || false))
  }

  /**
   * Check if user is on a certain plan,
   * and has started before a certain date.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  isLegacyUser(): Observable<boolean> {
    return forkJoin([this._user.user, this.getInfo()]).pipe(
      map(
        ([user, billing]) => billing.data?.plan?.tier > 1 || (billing.data?.plan?.tier > 0 && moment(user.created_at).isBefore(environment.billingSwitchDate)),
      ),
    )
  }

  /**
   * Get administration ID.
   */
  @Cacheable()
  private getAdministrationId(): Observable<number> {
    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => {
        return administration.id
      }),
    )
  }

  /**
   * Get subscription data.
   */
  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  private getSubscriptionData(): Observable<GetDataModel> {
    const user = this._user.user
    const administration = this._administration.defaultAdministration

    return forkJoin([user, administration]).pipe(
      map(([user, administration]: [User, Administration]) => {
        // Filter role or return empty string
        const filterUnknown = (input: string) => (input === 'UNKNOWN' || input === null ? '' : input)

        const origin = filterUnknown(UserService.getUserTypeName(user.origin_id))
        const ownerOrigin = filterUnknown(UserService.getUserTypeName(administration.owner_origin_id))

        // Construct body for request
        const body: GetDataBody = {
          user: {
            id: user.id,
            role: user.role,
            origin,
            isBeta: user.is_beta,
          },
          owner: {
            origin: ownerOrigin,
          },
          app: {
            platform: 'web',
            version: environment.version,
          },
          createdAt: user.created_at,
        }

        return {
          body,
          administrationId: administration.id,
        }
      }),
    )
  }

  // Filter out coupon if not needed
  private filterEmptyValues = (input: object) =>
    Object.keys(input)
      .filter((k) => input[k] !== '') // Remove empty string.
      .reduce(
        (newObj, k) =>
          typeof input[k] === 'object'
            ? { ...newObj, [k]: this.filterEmptyValues(input[k]) } // Recurse.
            : { ...newObj, [k]: input[k] }, // Copy value.
        {},
      )
}
