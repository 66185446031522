import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { RouterLinks } from '../../../../environments/routerlinks/routerlinks'
import { environment } from '../../../../environments/environment'
import { TellowContext } from '../../../domain/tellow/tellow-context'

@Injectable()
export class TranslateRouteService {
  private links: RouterLinks = environment.routerLinks

  constructor(private readonly _router: Router, private ngZone: NgZone) {
    this.setupUserflowRouter()
  }

  translate(route: string): string {
    let result = ''
    const originalValue = route

    if (originalValue.startsWith('/')) {
      route = route.substr(1)
    }

    const urlParts = route.split('/')

    // Finished
    if (!urlParts.length) {
      return result
    }

    let lastTranslateEntry: any

    urlParts.forEach((part) => {
      result += '/'

      // Resolve part to translation or variable
      if (!lastTranslateEntry && typeof this.links[part] == 'object') {
        lastTranslateEntry = this.links[part]
        result += lastTranslateEntry.index
      } else if (lastTranslateEntry && typeof lastTranslateEntry[part] == 'object') {
        lastTranslateEntry = lastTranslateEntry[part]
        result += lastTranslateEntry.index
      } else if (lastTranslateEntry && typeof lastTranslateEntry[part] == 'string') {
        result += lastTranslateEntry[part]
      } else {
        result += part
      }
    })

    // Fix path
    if (!originalValue.startsWith('/') && result.startsWith('/')) {
      result = result.substr(1)
    }

    return result
  }

  navigate(route: string, parameters?: any): Promise<boolean> {
    const translatedRoute = route == '/' ? '/' : this.translate(route)
    const routeArray = [translatedRoute]

    if (parameters) {
      routeArray.push(parameters)
    }

    return void this._router.navigate(routeArray)
  }

  /**
   * In theory the same method as 'navigate' (above),
   * but with queryParams added on the router.
   */
  navigateWithQueryParam(route: string, parameters?: any, query?: { [key: string]: string }): void {
    const translatedRoute = route == '/' ? '/' : this.translate(route)
    const routeArray = [translatedRoute]

    if (parameters) {
      routeArray.push(parameters)
    }

    void this._router.navigate(routeArray, { queryParams: query })
  }

  navigateWithContext(route: string, context: TellowContext, parameters?: any): void {
    const p: any = parameters || {}
    p.context = context.context

    void this.navigate(route, p)
  }

  navigateWithContextAndCustomBackRoute(route: string, context: TellowContext, back: string, parameters?: any): void {
    const p: any = parameters || {}
    p.context = context.context

    if (back) {
      p.back = back
    }

    void this.navigate(route, p)
  }

  private setupUserflowRouter(): void {
    /**
     * Enable userflow to route.
     * Assure that the link exists in the app.
     */
    ;(window as any).nav = (route: string) => {
      const links = Object.values(environment.routerLinks)
      const string = route.replace('/', '')

      if (links.any(({ index }) => string.startsWith(index))) {
        void this.ngZone.run(() => {
          void this.navigate(route)
        })
      } else {
        throw new Error('Page does not exist.')
      }
    }

    /**
     *  Enable Userflow to open auxilary modals.
     */
    ;(window as any).aux = (modal: 'onboarding') => {
      if (['onboarding'].includes(modal)) {
        void this.ngZone.run(() => {
          void this._router.navigate(['./', { outlets: { modal } }])
        })
      } else {
        throw new Error('Auxilary does not exist.')
      }
    }
  }
}
