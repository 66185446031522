import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core'
import { MenuItems } from '../menu.service'
import { TlwMenuItemComponent } from '../menu-item/menu-item.component'
import { MenuService } from '../menu.service'

@Component({
  selector: 'sub-menu',
  template: `<div [hidden]="!menu.state[name]">
    <ng-content></ng-content>
  </div> `,
})
export class TlwSubMenuComponent implements OnInit {
  @ViewChildren(TlwMenuItemComponent)
  menuItems: QueryList<TlwMenuItemComponent>

  @Input() name: MenuItems
  @Input() paths: string[]

  constructor(public menu: MenuService) {}

  ngOnInit() {
    this.menu.state[this.name] = this.menu.checkActiveState(this.paths)
  }
}
