import { Directive, ElementRef } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'

@Directive({
  selector: 'option',
})
export class OptionDirective extends BaseHtmlDirective {
  constructor(element: ElementRef) {
    super(element)
  }

  get value() {
    return (<HTMLOptionElement>this.element).value
  }

  get text() {
    return (<HTMLOptionElement>this.element).text
  }
}
