import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fncCurrency',
})
export class FncCurrencyPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `€${value}`.replace('.', ',')
  }
}
