import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgxMaskModule } from 'ngx-mask'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { FncButtonComponent } from './fnc-button/fnc-button.component'
import { FncSliderComponent } from './fnc-slider/fnc-slider.component'
import { FncInputContainerComponent } from './fnc-input-container/fnc-input-container.component'
import { InputDirective } from './html-directives/input.directive'
import { LabelDirective } from './html-directives/label.directive'
import { FncCodeEntryComponent } from './fnc-code-entry/fnc-code-entry.component'
import { FncActiveButtonSwitchComponent } from './fnc-active-element-switch/fnc-active-button-switch.component'
import { FncValidatorMessagesComponent } from './fnc-validator-messages/fnc-validator-messages.component'
import { FncValidatorMessageForDirective } from './fnc-validator-messages/fnc-validator-message-for.directive'
import { FormDirective } from './html-directives/form.directive'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FncSelectComponent } from './fnc-select/fnc-select.component'
import { SelectDirective } from './html-directives/select.directive'
import { OptionDirective } from './html-directives/option.directive'
import { FncToggleComponent } from './fnc-toggle/fnc-toggle.component'
import { FncDatepickerComponent } from './fnc-datepicker/fnc-datepicker.component'
import { InputCurrencyDirective } from './special-directives/input-currency/input-currency.directive'
import { InputContainerDirective } from './html-directives/input-container.directive'
import { FncIconComponent } from './fnc-icon/fnc-icon.component'
import { FncInputActionComponent } from './fnc-input-action/fnc-input-action.component'
import { FncLabelValueComponent } from './fnc-label-value/fnc-label-value.component'
import { FncFontPickerComponent } from './fnc-font-picker/fnc-font-picker.component'
import { TextAreaDirective } from './html-directives/textarea.directive'
import { FncFormMessagesComponent } from './fnc-form-messages/fnc-form-messages.component'
import { FncInfiniteScrollerComponent } from './fnc-infinite-scroller/fnc-infinite-scroller.component'
import { FncBackButtonComponent } from './fnc-back-button/fnc-back-button.component'
import { FncFileDropComponent } from './fnc-file-drop/fnc-file-drop.component'
import { FncRadioGroupComponent } from './fnc-radio-group/fnc-radio-group.component'
import { FncRadioComponent } from './fnc-radio/fnc-radio.component'
import { FncTableRowActionsComponent } from './fnc-table-row-actions/fnc-table-row-actions.component'
import { FncTableRowDirective } from './special-directives/table/fnc-table-row'
import { FncLoaderComponent } from './fnc-loader/fnc-loader.component'
import { TxLabelPipe } from './pipes/tx-label.pipe'
import { TxDatePipe } from './pipes/tx-date.pipe'
import { DocumentStatePipe } from './pipes/document-state.pipe'
import { FncTableComponent } from './fnc-table/fnc-table.component'
import { VatFormatterDirective } from './special-directives/vat-formatter.directive'
import { TranslateModule } from '@ngx-translate/core'
import { SnapToEdgeDirective } from './special-directives/snap/snap-to-edge.directive'
import { SnapManagerDirective } from './special-directives/snap/snap-manager.directive'
import { FncDateRangeSelectorComponent } from './fnc-date-range-selector/fnc-date-range-selector.component'
import { FncPeriodSelectorComponent } from './fnc-period-selector/fnc-period-selector.component'
import { LogicModule } from '../logic/logic.module'
import { FncImageMarkerComponent } from './fnc-image-marker/fnc-image-marker.component'
import { FncActionIconComponent } from './fnc-action-icon/fnc-action-icon.component'
import { JustificationLabelPipe } from './pipes/justification-label.pipe'
import { VatPeriodeNamePipe } from './pipes/vat-periode-name.pipe'
import { TlwFloatingButtonBarComponent } from './tlw-floating-button-bar/tlw-floating-button-bar.component'
import { FncModalComponent } from './fnc-modal/fnc-modal.component'
import { FncConfirmModalComponent } from './fnc-modal/fnc-confirm-modal/fnc-confirm-modal.component'
import { FncAlertModalComponent } from './fnc-modal/fnc-alert-modal/fnc-alert.modal.component'
import { FncLoadingModalComponent } from './fnc-modal/fnc-loading-modal/fnc-loading-modal.component'
import { ModalButtons } from './fnc-modal/directive/modal-directive'
import { ShowForOwnerDirective } from './special-directives/authorization/show-for-owner.directive'
import { TlwIconLabelComponent } from './tlw-icon-label/tlw-icon-label.component'
import { TlwIconLinkButton } from './tlw-icon-link-button/tlw-icon-link-button.component'
import { TlwFileDropComponent } from './tlw-file-drop/tlw-file-drop.component'
import { TlwSubMenuComponent } from './tlw-sub-menu/tlw-sub-menu.component'
import { TlwAmountPickerComponent } from './tlw-amountpicker/tlw-amountpicker.component'
import { TlwAmountRangeSelectorComponent } from './tlw-amount-range-selector/tlw-amount-range-selector.component'
import { TlwColorPickerComponent } from './tlw-color-picker/tlw-color-picker.component'
import { TlwIconSphereComponent } from './tlw-icon-sphere/tlw-icon-sphere.component'
import { ValidateOpenYearDirective } from './validators/directives/validate-open-year.directive'
import { TlwErrorModalComponent } from './tlw-error-modal/tlw-error-modal.component'
import { HideForReadonlyDirective } from './special-directives/authorization/hide-for-readonly.directive'
import { ConfettiComponent } from './fun/confetti/confetti.component'
import { TlwListHeaderComponent } from './tlw-list-header/tlw-list-header.component'
import { FncSelectInputComponent } from './fnc-select-input/fnc-select-input.component'
import { FncCurrencyInputComponent } from './fnc-currency-input/fnc-currency-input.component'
import { TlwMessageboxComponent } from './tlw-messagebox/tlw-messagebox.component'
import { FncLargeInputContainerComponent } from './fnc-large-input-container/fnc-large-input-container.component'
import { FncVideoComponent } from './fnc-video/fnc-video.component'
import { FncCheckboxComponent } from './fnc-checkbox/fnc-checkbox.component'
import { TlwCompanySearcherComponent } from './tlw-company-searcher/tlw-company-searcher.component'
import { TlwIbanComponent } from './tlw-iban/tlw-iban.component'
import { TlwIconComponent } from './tlw-icon/tlw-icon.component'
import { TlwTagInputComponent } from './tlw-tag-input/tlw-tag-input.component'
import { TlwBarComponent } from './tlw-bar/tlw-bar.component'
import { TlwToggleComponent } from './tlw-toggle/tlw-toggle.component'
import { TlwDropdownComponent } from './tlw-dropdown/tlw-dropdown.component'
import { TlwExpandableButtonComponent } from './tlw-expandable-button/tlw-expandable-button.component'
import { TlwContactModal } from './fnc-modal/tlw-contact-modal/tlw-contact-modal.component'
import { HttpClientModule } from '@angular/common/http'
import { TlwParsingModalComponent } from './fnc-modal/tlw-parsing-modal/tlw-parsing-modal.component'
import { SpinnerComponent } from './tlw-company-searcher/spinner/spinner.component'
import { ParsingStepComponent } from './fnc-modal/tlw-parsing-modal/step/step.component'
import { CoreModule } from '../core/core.module'
import { ShowForEmployeeDirective } from './special-directives/admin/show-for-employee.directive'
import { TlwEmployeeZoneComponent } from './tlw-employee-zone/tlw-employee-zone.component'
import { TlwSliderComponent } from './tlw-slider/tlw-slider.component'
import { TlwCarouselComponent } from './tlw-carousel/tlw-carousel.component'
import { TlwListComponent } from './tlw-list/tlw-list.component'
import { ShowForPlanDirective } from './special-directives/authorization/show-for.directive'
import { TlwButtonComponent } from './tlw-button/tlw-button.component'
import { TlwInputContainerComponent } from './tlw-input-container/tlw-input-container.component'
import { TlwValidatorMessagesComponent } from './tlw-validator-messages/tlw-validator-messages.component'
import { TlwValidatorForDirective } from './tlw-validator-messages/tlw-validator-for.directive'
import { TlwRadioGroupComponent } from './tlw-radio-group/tlw-radio-group.component'
import { TlwRadioComponent } from './tlw-radio/tlw-radio.component'
import { ShowForTellowBookkeeper } from './special-directives/authorization/show-for-tellow-bookkeeper.directive'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { TlwBillingCardComponent } from './tlw-billing-card/tlw-billing-card.component'
import { TlwLockUpsellComponent } from './tlw-lock-upsell/tlw-lock-upsell.component'
import { TlwLockCounterComponent } from './tlw-lock-counter/tlw-lock-counter.component'
import { TlwTimeline } from './tlw-timeline/tlw-timeline.component'
import { TlwStateArrow } from './tlw-state-arrow/tlw-state-arrow.component'
import { FncLabelContainerComponent } from './fnc-label-container/fnc-label-container.component'
import { TlwTopMenuComponent } from './tlw-top-menu/tlw-top-menu.component'
import { TlwHomeBankingCardComponent } from './tlw-banking-card/tlw-banking-card.component'
import { FncTableGroupDirective } from './special-directives/table/fnc-table-group'
import { FncTableGroupTitleDirective } from './special-directives/table/fnc-table-group-title'
import { TlwBankingStateComponent } from './tlw-banking-state/tlw-banking-state.component'
import { provideTippyConfig, tooltipVariation, popperVariation, TippyDirective } from '@ngneat/helipopper'
import { TippyProps } from '@ngneat/helipopper/lib/tippy.types'
import { TlwAnnotationLineComponent } from './tlw-annotation-line/tlw-annotation-line.component'
import { TlwTotalsComponent } from './tlw-totals/tlw-totals.component'
import { TlwDocumentStatus } from './tlw-document-status/tlw-document-status.component'
import { TlwUpsellCardComponent } from './tlw-upsell-card/tlw-upsell-card.component'
import { TlwFullScreenUpsellModalComponent } from './tlw-full-screen-upsell-modal/tlw-full-screen-upsell-modal.component'
import { TlwSelectComponent } from './tlw-select/tlw-select.component'
import { TlwPillComponent } from './tlw-pill/tlw-pill.component'
import { TlwFullScreenLoaderComponent } from './tlw-full-screen-loader/tlw-full-screen-loader.component'
import { ShowForUser } from './special-directives/authorization/show-for-user.directive'
import { PaymentMethodPipe } from './pipes/payment-methods.pipe'
import { TlwCreateEntityDetailsComponent } from './tlw-create-entity-details/tlw-create-entity-details.component'
import { TlwCreateEntityFooterComponent } from './tlw-create-entity-footer/tlw-create-entity-footer.component'
import { TlwCreateEntityHeaderComponent } from './tlw-create-entity-header/tlw-create-entity-header.component'
import { TlwCreateEntityLinesComponent } from './tlw-create-entity-lines/tlw-create-entity-lines.component'
import { TlwDiscountFieldComponent } from './tlw-discount-field/tlw-discount-field.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TlwCreateEntityPreHeaderComponent } from './tlw-create-entity-pre-header/tlw-create-entity-pre-header.component'
import { CapitalPipe } from './pipes/capital.pipe'
import { ShowForBetaDirective } from './special-directives/authorization/show-for-beta.directive'
import { TlwQuantityUnitsInput } from './tlw-quantity-units-input/tlw-quantity-units-input.component'
import { TlwBannerComponent } from './tlw-banner/tlw-banner.component'
import { TlwAmountWithVatDropdownComponent } from './tlw-amount-with-vat-dropdown/tlw-amount-with-vat-dropdown.component'
import { TlwPayoutModalComponent } from './tlw-payout-modal/tlw-payout-modal.component'
import { TlwPopperComponent } from './tlw-popper/tlw-popper.component'
import { TlwSlideToOpenComponent } from './tlw-slide-to-open/tlw-slide-to-open.component'
import { TlwSmartBannerComponent } from './tlw-smart-banner/tlw-smart-banner.component'
import { TlwOnboardingModalComponent } from './tlw-onboarding-modal/tlw-onboarding-modal.component'
import { TlwAuxilaryModalComponent } from './tlw-auxilary-modal/tlw-auxilary-modal.component'
import { TlwNotificationComponent } from './tlw-notification/tlw-notification.component'
import { TlwBankingCardButtonsComponent } from './tlw-banking-card/tlw-banking-card-buttons/tlw-banking-card-buttons.component'
import { TlwBankingUpsellModalComponent } from './tlw-banking-upsell-modal/tlw-banking-upsell-modal.component'
import { TlwTaxBucketCardComponent } from './tlw-tax-bucket-card/tlw-tax-bucket-card.component'
import { TlwStandardProductModalComponent } from './tlw-standard-product-modal/tlw-standard-product-modal.component'
import { TlwEntityLineDescriptionComponent } from './tlw-create-entity-lines/tlw-entity-line-description/tlw-entity-line-description.component'
import { TlwEmailVerificationModalComponent } from './tlw-email-verification-modal/tlw-email-verification-modal.component'
import { EmailHintComponent } from './email-hint/email-hint.component'
import { FncEmailValidationInputComponent } from './fnc-email-validation-input/fnc-email-validation-input.component'

const sharedPopperProps: Partial<TippyProps> = {
  ...popperVariation,
  arrow: false,
  offset: [2, 6],
  placement: 'bottom-end',
  animation: 'shift-away',
  hideOnClick: true,
  theme: 'tlw',
}

const sharedTooltipProps: Partial<TippyProps> = {
  ...tooltipVariation,
  arrow: false,
  offset: [0, 6],
  placement: 'right',
  animation: 'ease-in-out',
  hideOnClick: true,
  theme: 'tlw',
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: sharedTooltipProps,
        popper: sharedPopperProps,
      },
    }),
  ],
  imports: [
    CommonModule,
    DragDropModule,
    CoreModule, // required for `tooltip`.
    FormsModule,
    TranslateModule,
    LogicModule,
    HttpClientModule, // required for `tlw-icon`.
    NgxMaskModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    TippyDirective,
  ],
  declarations: [
    TlwAuxilaryModalComponent,
    FncButtonComponent,
    FncBackButtonComponent,
    FncSliderComponent,
    FncInputContainerComponent,
    FncSelectComponent,
    FncCodeEntryComponent,
    FncActiveButtonSwitchComponent,
    FncValidatorMessagesComponent,
    FncFormMessagesComponent,
    FncToggleComponent,
    FncDatepickerComponent,
    FncModalComponent,
    TlwContactModal,
    FncIconComponent,
    FncInputActionComponent,
    FncLabelValueComponent,
    FncIconComponent,
    FncFontPickerComponent,
    FncInfiniteScrollerComponent,
    FncFileDropComponent,
    FncRadioGroupComponent,
    FncRadioComponent,
    FncTableRowActionsComponent,
    FncLoaderComponent,
    FncTableComponent,
    FncDateRangeSelectorComponent,
    FncPeriodSelectorComponent,
    FncImageMarkerComponent,
    FncModalComponent,
    FncConfirmModalComponent,
    FncAlertModalComponent,
    FncLoadingModalComponent,
    FncActionIconComponent,
    FncSelectInputComponent,
    FncLargeInputContainerComponent,
    FncVideoComponent,
    FncCheckboxComponent,
    FncEmailValidationInputComponent,
    TlwCompanySearcherComponent,
    TlwIconLinkButton,
    TlwIconLabelComponent,
    TlwFileDropComponent,
    TlwAmountPickerComponent,
    TlwAmountRangeSelectorComponent,
    TlwIconSphereComponent,
    TlwColorPickerComponent,
    TlwErrorModalComponent,
    TlwFloatingButtonBarComponent,
    TlwSubMenuComponent,
    TlwTopMenuComponent,
    ConfettiComponent,
    TlwListHeaderComponent,
    TlwMessageboxComponent,
    TlwIbanComponent,
    TlwIconComponent,
    TlwTagInputComponent,
    TlwToggleComponent,
    TlwBarComponent,
    TlwDropdownComponent,
    TlwExpandableButtonComponent,
    TlwParsingModalComponent,
    SpinnerComponent,
    ParsingStepComponent,
    TlwEmployeeZoneComponent,
    TlwSliderComponent,
    TlwCarouselComponent,
    TlwListComponent,
    FncCurrencyInputComponent,
    TlwButtonComponent,
    TlwInputContainerComponent,
    TlwValidatorMessagesComponent,
    TlwRadioGroupComponent,
    TlwRadioComponent,
    ProgressBarComponent,
    TlwTimeline,
    TlwBillingCardComponent,
    TlwStateArrow,
    TlwHomeBankingCardComponent,
    TlwBankingCardButtonsComponent,
    TlwBankingStateComponent,
    TlwUpsellCardComponent,
    TlwFullScreenUpsellModalComponent,
    TlwFullScreenLoaderComponent,
    TlwSelectComponent,
    TlwPillComponent,
    TlwQuantityUnitsInput,
    TlwBannerComponent,
    TlwPopperComponent,
    TlwSlideToOpenComponent,
    TlwSmartBannerComponent,
    TlwOnboardingModalComponent,
    TlwNotificationComponent,
    TlwBankingUpsellModalComponent,
    TlwTaxBucketCardComponent,
    TlwStandardProductModalComponent,
    TlwEntityLineDescriptionComponent,
    TlwEmailVerificationModalComponent,
    // Directives
    ShowForBetaDirective,
    InputDirective,
    TextAreaDirective,
    SelectDirective,
    OptionDirective,
    LabelDirective,
    InputContainerDirective,
    FormDirective,
    FncValidatorMessageForDirective,
    InputCurrencyDirective,
    FncTableGroupTitleDirective,
    FncTableGroupDirective,
    FncTableRowDirective,
    VatFormatterDirective,
    SnapToEdgeDirective,
    SnapManagerDirective,
    ValidateOpenYearDirective,
    ShowForOwnerDirective,
    HideForReadonlyDirective,
    ShowForEmployeeDirective,
    ShowForPlanDirective,
    ShowForUser,
    TlwValidatorForDirective,
    ShowForTellowBookkeeper,
    ModalButtons,
    TlwLockUpsellComponent,
    TlwLockCounterComponent,
    FncLabelContainerComponent,
    TlwAnnotationLineComponent,
    TlwTotalsComponent,
    TlwDocumentStatus,
    TlwCreateEntityDetailsComponent,
    TlwCreateEntityFooterComponent,
    TlwCreateEntityHeaderComponent,
    TlwCreateEntityLinesComponent,
    TlwDiscountFieldComponent,
    TlwCreateEntityPreHeaderComponent,
    TlwAmountWithVatDropdownComponent,
    TlwPayoutModalComponent,
    // TODO: Move pipes to view module
    PaymentMethodPipe,
    TxLabelPipe,
    TxDatePipe,
    DocumentStatePipe,
    JustificationLabelPipe,
    VatPeriodeNamePipe,
    CapitalPipe,
    EmailHintComponent,
  ],
  exports: [
    TlwAuxilaryModalComponent,
    FncButtonComponent,
    FncBackButtonComponent,
    FncSliderComponent,
    FncInputContainerComponent,
    FncSelectComponent,
    FncCodeEntryComponent,
    FncActiveButtonSwitchComponent,
    FncValidatorMessagesComponent,
    FncFormMessagesComponent,
    FncToggleComponent,
    FncDatepickerComponent,
    FncModalComponent,
    FncIconComponent,
    FncInputActionComponent,
    FncLabelValueComponent,
    FncFontPickerComponent,
    FncInfiniteScrollerComponent,
    FncFileDropComponent,
    FncRadioGroupComponent,
    FncRadioComponent,
    FncTableRowActionsComponent,
    FncLoaderComponent,
    FncTableComponent,
    FncDateRangeSelectorComponent,
    FncPeriodSelectorComponent,
    FncImageMarkerComponent,
    FncActionIconComponent,
    FncModalComponent,
    TlwContactModal,
    FncConfirmModalComponent,
    FncAlertModalComponent,
    FncLoadingModalComponent,
    FncSelectInputComponent,
    FncLargeInputContainerComponent,
    FncVideoComponent,
    FncCheckboxComponent,
    FncEmailValidationInputComponent,
    TlwCompanySearcherComponent,
    TlwFloatingButtonBarComponent,
    TlwSubMenuComponent,
    TlwTopMenuComponent,
    TlwIconLinkButton,
    TlwIconLabelComponent,
    TlwFileDropComponent,
    TlwAmountPickerComponent,
    TlwAmountRangeSelectorComponent,
    TlwIconSphereComponent,
    TlwColorPickerComponent,
    TlwErrorModalComponent,
    TlwToggleComponent,
    ConfettiComponent,
    TlwListHeaderComponent,
    TlwMessageboxComponent,
    TlwIbanComponent,
    TlwIconComponent,
    TlwTagInputComponent,
    TlwBarComponent,
    TlwDropdownComponent,
    TlwExpandableButtonComponent,
    TlwParsingModalComponent,
    SpinnerComponent,
    ParsingStepComponent,
    TlwEmployeeZoneComponent,
    TlwSliderComponent,
    TlwCarouselComponent,
    TlwListComponent,
    TlwButtonComponent,
    TlwInputContainerComponent,
    TlwValidatorMessagesComponent,
    TlwRadioGroupComponent,
    TlwRadioComponent,
    ProgressBarComponent,
    TlwTimeline,
    TlwBillingCardComponent,
    TlwStateArrow,
    TlwHomeBankingCardComponent,
    TlwBankingCardButtonsComponent,
    TlwBankingStateComponent,
    FncLabelContainerComponent,
    TlwAnnotationLineComponent,
    TlwTotalsComponent,
    TlwDocumentStatus,
    TlwUpsellCardComponent,
    TlwFullScreenUpsellModalComponent,
    TlwFullScreenLoaderComponent,
    TlwSelectComponent,
    TlwPillComponent,
    TlwQuantityUnitsInput,
    TlwBannerComponent,
    TlwPopperComponent,
    TlwSlideToOpenComponent,
    TlwSmartBannerComponent,
    TlwOnboardingModalComponent,
    TlwNotificationComponent,
    TlwBankingUpsellModalComponent,
    TlwTaxBucketCardComponent,
    TlwStandardProductModalComponent,
    TlwEmailVerificationModalComponent,
    // Directives
    ShowForBetaDirective,
    InputDirective,
    TextAreaDirective,
    SelectDirective,
    OptionDirective,
    LabelDirective,
    InputContainerDirective,
    FormDirective,
    FncValidatorMessageForDirective,
    InputCurrencyDirective,
    FncTableGroupTitleDirective,
    FncTableGroupDirective,
    FncTableRowDirective,
    VatFormatterDirective,
    SnapToEdgeDirective,
    SnapManagerDirective,
    ValidateOpenYearDirective,
    ModalButtons,
    FncCurrencyInputComponent,
    ShowForOwnerDirective,
    HideForReadonlyDirective,
    ShowForEmployeeDirective,
    ShowForPlanDirective,
    ShowForUser,
    TlwValidatorForDirective,
    ShowForTellowBookkeeper,
    TlwLockUpsellComponent,
    TlwLockCounterComponent,
    TlwCreateEntityDetailsComponent,
    TlwCreateEntityFooterComponent,
    TlwCreateEntityHeaderComponent,
    TlwCreateEntityLinesComponent,
    TlwDiscountFieldComponent,
    TlwCreateEntityPreHeaderComponent,
    TlwAmountWithVatDropdownComponent,
    TlwPayoutModalComponent,
    // TODO: Move pipes to view module
    PaymentMethodPipe,
    TxLabelPipe,
    TxDatePipe,
    DocumentStatePipe,
    JustificationLabelPipe,
    VatPeriodeNamePipe,
    CapitalPipe,
    EmailHintComponent,
  ],
})
export class UiComponentsModule {}
