import { PreloadingStrategy, Route } from '@angular/router'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'

/**
 * Returns boolean whether or not route should
 * be preloaded based on internet connection.
 */
export function shouldPreload(route: Route): boolean {
  // Get NetworkInformation object
  const conn = (navigator as any).connection

  if (conn && route) {
    // Save-Data mode
    if (conn.saveData) {
      return false
    }

    // 'slow-2g', '2g', '3g', or '4g'
    const effectiveType = conn.effectiveType || ''

    // 2G and 3G network
    if (effectiveType.includes('2g') || effectiveType.includes('3g')) {
      return false
    }
  }

  return true
}

@Injectable({
  providedIn: 'root',
})
export class NetworkAwarePreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (shouldPreload(route)) {
      return load()
    }

    return EMPTY
  }
}
