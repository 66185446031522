import { Injectable } from '@angular/core'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

export type ConvertedPdfType = {
  pages: number
  image: string
}

@Injectable()
export class PdfService {
  private pdfJs = PDFJS

  constructor() {
    /**
     * Bear in mind that the version of 'pdfjs-dist' is tightly
     * coupled to the worker script loaded from the source
     * referenced below. Compiled releases for PDF.JS can
     * be fetched from the following repository:
     *
     * @see https://github.com/mozilla/pdf.js/releases
     *
     * Please note that we HAVE to use the 'legacy'
     * release, because as of this moment of typing,
     * we use Angular 11, which uses Webpack 4, meaning
     * modern features like optional chaining break
     * our import of PDF.JS otherwise.
     */
    this.pdfJs.GlobalWorkerOptions.workerSrc = 'assets/scripts/pdfjs-2.9.359/pdf.worker.js?v=' + environment.version
  }

  /**
   * Does as described.
   * @param {Blob} pdfFile to convert
   * @param {number} resolution to scale up the image.
   */
  pdfToImage(pdfFile: Blob, resolution: number = 1.5, pageNumber: number = 1): Observable<ConvertedPdfType> {
    let canvas: HTMLCanvasElement = document.createElement('canvas')

    return new Observable((observer) => {
      let arrayBuffer
      const fileReader = new FileReader()

      fileReader.onload = () => {
        arrayBuffer = fileReader.result

        const loadingTask = this.pdfJs.getDocument({ data: arrayBuffer, disableFontFace: false })
        loadingTask.promise.then(
          function (pdf) {
            void pdf.getPage(pageNumber).then(function (page) {
              const scale = resolution
              const viewport = page.getViewport({ scale: scale })

              // Prepare canvas using PDF page dimensions
              const context = canvas.getContext('2d')
              canvas.height = viewport.height
              canvas.width = viewport.width

              // Render PDF page into canvas context
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              }

              const renderTask = page.render(renderContext)
              void renderTask.promise.then(() => {
                observer.next({ pages: pdf.numPages, image: canvas.toDataURL('image/jpeg') })
                observer.complete()
                canvas = null
              })
            })
          },
          function (reason) {
            // PDF loading error
            observer.error(reason)
            observer.complete()
            canvas = null
          },
        )
      }
      fileReader.readAsArrayBuffer(pdfFile)
    })
  }
}
