import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

export interface ConfirmOnLeaveComponent {
  canLeave(): Observable<boolean> | boolean
}

export class ConfirmLeaveOnChangeGuard implements CanDeactivate<ConfirmOnLeaveComponent> {
  canDeactivate(
    component: ConfirmOnLeaveComponent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentRoute: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentState: RouterStateSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nextState?: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return component.canLeave()
  }
}
