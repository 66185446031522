import { AfterContentInit, Component, ContentChild, EventEmitter, OnInit, Output } from '@angular/core'
import { FncIconComponent } from '../fnc-icon/fnc-icon.component'
import { InputDirective } from '../html-directives/input.directive'

@Component({
  selector: 'fnc-input-action',
  styleUrls: ['./fnc-input-action.component.scss'],
  template: '<ng-content></ng-content>',
})
export class FncInputActionComponent implements OnInit, AfterContentInit {
  @ContentChild(FncIconComponent) icon: FncIconComponent
  @ContentChild(InputDirective) input: InputDirective

  @Output() action = new EventEmitter<string>()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {}

  ngAfterContentInit(): void {
    if (!this.icon) {
      return
    }

    this.icon._el.nativeElement.addEventListener('click', () => {
      this.action.emit((<HTMLInputElement>this.input.element).value)
    })
  }
}
