import { Injectable } from '@angular/core'
import { PublicKeyCredentialCreationOptionsJSON, RegistrationResponseJSON } from '@simplewebauthn/types'
import { Observable } from 'rxjs'
import { Passkey } from '../../domain/security/passkey.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'

export type RequestPasskeyRegistrationResponseDto = {
  registration_options: PublicKeyCredentialCreationOptionsJSON
  token: string
}

@Injectable()
export class PasskeysService {
  constructor(private readonly _api: ApiGateway) {}

  getPasskeys(): Observable<Passkey[] | null> {
    return this._api.get<Passkey[] | null>(endpoints.passkey.getPasskeys)
  }

  deletePasskey(passkeyId: string): Observable<null> {
    return this._api.delete<null>(endpoints.passkey.deletePasskey, { passkeyId })
  }

  generateRegistrationOptions(): Observable<RequestPasskeyRegistrationResponseDto> {
    return this._api.post<RequestPasskeyRegistrationResponseDto>(endpoints.passkey.generateRegistrationOptions, {})
  }

  registerPasskey(signedRegistration: RegistrationResponseJSON, token: string): Observable<Passkey> {
    return this._api.post<Passkey>(endpoints.passkey.registerPasskey, {
      signed_registration: signedRegistration,
      token,
    })
  }
}
