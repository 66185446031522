export const endpoints = {
  diary: '/v1/administration/:adminstrationId/diary',
  user: '/v1/user/:userId',
  terms: '/v1/terms',
  userAdministration: '/v1/user/administrations',
  subscriptions: '/v2/sign-up',

  device: {
    device: '/v2/device',
    sendValidationCode: '/v1/device/activate/send',
    validateCode: '/v1/device/activate/validate',

    allDevices: '/v1/user/:id/devices',
    logout: '/v1/logout-device/:deviceId',
    logoutAll: '/v1/logout-all-devices',
  },

  onboarding: {
    prospect: '/v1/activate/prospect',
    validateProspect: '/v1/activate/validate',
    activate: '/v1/activate',
    activateInvitee: '/v1/activate/invitee',

    validateToken: '/v1/activate/validate-token',
    validateActivationCode: '/v1/activate/validate-code',
    incorrectInfo: '/v1/activate/info-incorrect',

    wizardQuestions: '/v1/onboarding/wizard-questions',
    requestToken: '/v2/activation/request-token',
    register: '/v2/activation/register',
    onboardingQuestions: '/v2/activation/onboarding',

    userRegistration: '/v2/registration/user',
    emailVerification: '/v2/email/validate',
    companyRegistration: '/v2/registration/administration',
    administrationRegistration: '/v2/registration/register',

    tokenVerification: '/v2/registration/token-verification',
  },

  login: {
    logout: '/v1/logout',
    token: '/oauth/v2/token',
    jwt: '/v1/user/jwt',

    sendChangeEmailVerificationCode: '/v1/user/:id/change-email/send-sms',
    validateChangeEmailVerificationCode: '/v1/user/:id/change-email/validate-code',
    verifyNewEmailAddress: '/v1/user/:id/change-email/verify-email',
    changeEmailAddress: '/v1/user/:id/change-email',

    sendMagicLink: '/v2/login/magic-token',
    validationMagicToken: '/v2/login/token-validation',
    checkOneTimePassword: '/v2/login/otp',
  },

  passkey: {
    generateRegistrationOptions: '/v2/passkey/registration/generate-options',
    registerPasskey: '/v2/passkey/registration/verify',
    generateAuthenticationOptions: '/v2/passkey/authentication/generate-options',
    validateAuthentication: '/v2/passkey/authentication/verify',
    getPasskeys: '/v2/passkey/list',
    deletePasskey: '/v2/passkey/:passkeyId',
  },

  email: {
    // Public
    verifyEmail: '/v2/verification/verify/email',
    resendVerificationEmail: '/v2/verification/renew',

    // Private
    sendVerificationEmail: '/v2/user/send-email-verification',
  },

  administration: {
    administration: '/v1/administration/:administrationId',

    settings: '/v1/administration/:administrationId/settings',
    accounts: '/v1/administration/:administrationId/account',
    annotationAccounts: '/v1/administration/:administrationId/account?type=:type',
    startBalance: '/v1/administration/:administrationId/start-balance',
    expenses: '/v1/administration/:administrationId/expenses',
    logo: '/v1/administration/:administrationId/logo',

    users: '/v1/administration/:administrationId/users',
    revokeAccess: '/v1/administration/:administrationId/users/:userId',
    invite: '/v1/administration/:administrationId/users/invite',
    modifyAccess: '/v1/administration/:administrationId/users/access',
    addAccess: '/v1/administration/:administrationId/modify-access',
    promote: '/v1/administration/:administrationId/users/promote-self',
    demote: '/v1/administration/:administrationId/users/demote-self',

    // enableSubscription: '/v1/administration/:administrationId/enable-subscription',
    subscription: '/v2/administration/:administrationId/subscription', // @deprecated
    enableSubscription: '/v2/administration/:administrationId/subscription/enable', // @deprecated
    upgradeSubscription: '/v2/administration/:administrationId/subscription/payment', // @deprecated
    answerQuestions: '/v1/administration/:administrationId/answer-questions',

    complement: '/v2/administration/:administrationId/complement/:context',
    onboardingQuestions: '/v2/activation/onboarding',
    paymentRequest: '/v2/administration/:administrationId/payment-request',

    bankAccountAdd: '/v2/administration/:administrationId/bank-accounts',
    banks: '/v2/banks/:bankId',
    bankTypes: '/v2/banks/:bankId/import/supported-types',

    plans: '/v1/plans',
    generateSmartMailbox: '/v1/administration/:administrationId/smart_box_email',
  },

  invoices: {
    invoices: '/v1/administration/:administrationId/invoice/:invoiceId',
    duplicate: '/v2/administration/:administrationId/invoices/:invoiceId/duplicate',
    listInvoices: '/v2/administration/:administrationId/invoices',
    metadata: '/v2/administration/:administrationId/invoices/metadata',
    purchaseInvoice: '/v1/administration/:administrationId/invoice/purchase/:documentId',

    invoiceJournalEntries: '/v1//administration/:administrationId/invoice/:invoiceId/journal-entries',

    templates: '/v1/invoice-template',
    customTemplates: '/v1/administration/:administrationId/template/:id',

    preview: '/v1/administration/:administrationId/invoice/preview',
    view: '/v1/administration/:administrationId/invoice/:invoiceId/view',
    setStatus: '/v1/administration/:administrationId/invoice/:invoiceId/status',
    send: '/v1/administration/:administrationId/invoice/:invoiceId/send',
    credit: '/v1/administration/:administrationId/invoice/:invoiceId/credit',
    rebook: '/v1/administration/:administrationId/invoice/:invoiceId/rebook',
  },

  quotes: {
    quotes: '/v1/administration/:administrationId/quote/:quoteId',
    createQuote: '/v1/administration/:administrationId/quote',
    pdf: '/v1/administration/:administrationId/quote/:quoteId/pdf',
    send: '/v1/administration/:administrationId/quote/:quoteId/send',
    setStatus: '/v1/administration/:administrationId/quote/:quoteId/status',
    promoteIntoInvoice: '/v1/administration/:administrationId/quote/:quoteId/promote',
    customTemplates: '/v1/administration/:administrationId/quote-template/:id',
    templates: '/v1/quote-template',
  },

  products: {
    products: '/v1/administration/:administrationId/products',
    product: '/v1/administration/:administrationId/products/:productId',
  },

  documents: {
    // Mind the V2.
    documentList: '/v2/administration/:administrationId/documents',

    // V1 endpoints
    document: '/v1/administration/:administrationId/invoice/purchase/draft',
    documents: '/v1/administration/:administrationId/document/:documentId',
    annotate: '/v1/administration/:administrationId/invoice/purchase/:documentId',
    download: '/v1/administration/:administrationId/document/:documentId/download',
  },

  finance: {
    getLoansData: '/api/agerasfinance/loan/v2/get-estimates',
    applyForLoan: '/api/agerasfinance/loan/v2/apply',

    getCreditLineData: '/api/agerasfinance/credit-line/v2/get-estimates',
    applyForCreditLine: '/api/agerasfinance/credit-line/v2/apply',

    financingEstimate: '/api/agerasfinance/financing/v2/get-estimate',
    applyForFinancing: '/api/agerasfinance/financing/v2/approve-estimate',

    getCustomer: '/api/agerasfinance/customer/v2/get-customer',
    getCustomerToken: '/api/agerasfinance/customer/v2/get-customer-token',
  },

  transactions: {
    transactions: '/v1/administration/:administrationId/bank-account/:bankAccountId/transactions/list',
    transaction: '/v1/administration/:administrationId/bank-account-transactions/:id',

    prediction: '/v2/administration/:administrationId/transactions/:transactionId/prediction',
    selected: '/v2/administration/:administrationId/transactions/:transactionId/selected',

    transactionJournalEntries: '/v1/administration/:administrationId/bank-account/:bankAccountId/transactions/:id/journal-entries',

    justify: '/v1/administration/:administrationId/bank-account/:bankAccountId/transactions/:id/document',
    justifyWithoutFile: '/v1/administration/:administrationId/bank-account/:bankAccountId/transactions/:id/justify',
    justifyWithVat: '/v1/administration/:administrationId/bank-account/:bankAccountId/transactions/:transactionId/vatdeclaration/:vatDeclarationId/justify',

    acceptProposal: '/v1/administration/:administrationId/proposal/:taskId/accept',
    rejectProposal: '/v1/administration/:administrationId/proposal/:taskId/reject',

    import: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/import',
    importTypes: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/import/supported-types',

    remove: '/v2/administration/:administrationId/transactions/:transactionId',

    dateLastTransaction: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/date',

    allTransactions: '/v2/administration/:administrationId/transactions/list',
  },

  notifications: {
    notifications: '/v2/administration/:administrationId/notifications/:notificationId',
    notificationRead: '/v2/administration/:administrationId/notifications/:notificationId/read',
    notificationReadAll: '/v2/administration/:administrationId/notifications/all/read',
  },

  contacts: {
    contacts: '/v1/administration/:administrationId/contact/:contactId',
    companies: '/v2/administration/:administrationId/companies/:id',
    persons: '/v2/administration/:administrationId/persons/:id',
    suppliers: '/v1/administration/:administrationId/contact/suppliers',

    addBankAccount: {
      company: '/v1/administration/:administrationId/company/:companyId/bank-account',
      person: '/v1/administration/:administrationId/person/:personId/bank-account',
    },

    updateBankAccount: {
      company: '/v1/administration/:administrationId/company/:companyId/bank-account/:bankAccountId',
      person: '/v1/administration/:administrationId/person/:personId/bank-account/:bankAccountId',
    },
  },

  bankAccounts: {
    administration: '/v2/administration/:administrationId/bank-accounts',
    account: '/v2/administration/:administrationId/bank-accounts/:bankAccountId',
    order: '/v2/administration/:administrationId/bank-accounts/order',
    addPsd2BankAccount: '/v2/administration/:administrationId/bank-accounts/psd2',
    promoteToPsd2: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/promote-to-psd2',
    togglePsd2Active: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/toggle-psd2-active',
  },

  tasks: {
    tasks: '/v1/administration/:administrationId/task/:id',
  },

  vat: {
    years: '/v1/administration/:administrationId/vat-declaration/years',
    periods: '/v1/administration/:administrationId/vat-declaration/:year/periods',

    declaration: '/v1/administration/:administrationId/vat-declaration/:id',
    markAsChecked: '/v1/administration/:administrationId/vat-declaration/:id/markAsChecked',
    submitDeclaration: '/v2/administration/:administrationId/vat-declaration/:id/submit',
    declarationDateRange: '/v1/administration/:administrationId/vat-declaration/:startDate,:endDate/report',

    declarationDifference: '/v1/administration/:administrationId/vat-declaration/:id/compare',
    journalEntries: '/v1/administration/:administrationId/vat-declaration/:year/periods/:id/journal-entries',

    suppletion: '/v1/administration/:administrationId/suppletion/:id/:action',
    generateSuppletion: '/v1/administration/:administrationId/suppletion/generate/:fiscalYearId',

    privateUse: '/v1/administration/:administrationId/private-usages/:action',

    // tax service endpoints (so not default backend API)
    listSubmittedDeclarations: '/v1/belastingdienst/tax-return/:administrationId/turnover?remoteId=:id',
  },

  dashboard: {
    stats: '/v2/administration/:administrationId/stats',
    vatStats: '/v2/administration/:administrationId/stats/vat',
  },

  accounting: {
    columnBalance: '/v1/administration/:administrationId/column-balance/',
    mutations: '/v1/administration/:administrationId/mutations/',
    journals: '/v1/administration/:administrationId/journal/:id',
    journalEntry: '/v1/administration/:administrationId/journal/:journalId/journal-entry/:journalEntryId',
    journalEntryDetails: '/v1/administration/:administrationId/journal/:journalId/journal-entry/:id/detail',
    journalEntryLines: '/v1/administration/:administrationId/journal/:id/journal-entry/all',
    startBalanceAccounts: '/v1/administration/:administrationId/start-balance/accounts',
    startBalanceProfitLossAccounts: '/v1/administration/:administrationId/start-balance/profit-loss/accounts',

    fiscalYears: '/v1/administration/:administrationId/fiscal-year/:id/:action',

    removePurchaseInvoice: '/v1/administration/:administrationId/invoice/purchase/:invoiceId',
    updatePurchaseInvoice: '/v1/administration/:administrationId/invoice/purchase/:invoiceId/update',
    finalizePurchaseInvoice: '/v1/administration/:administrationId/invoice/purchase/:invoiceId/finalize',

    bookInvoicePaymentDifference: '/v1/administration/:administrationId/invoice/:invoiceId/payment-difference',
    bookInvoicePrivatePayment: '/v1/administration/:administrationId/invoice/:invoiceId/private-payment',
    bookInvoiceCustomPayment: '/v1/administration/:administrationId/invoice/:invoiceId/payment',

    txReconcilableInvoices: '/v1/administration/:administrationId/transaction/:transactionId/justification/reconcilable-invoices',
  },

  bookings: {
    removeJustification: '/v1/administration/:administrationId/booking/justification/:invoiceId,:transactionId',
    bookTxWithInvoice: '/v1/administration/:administrationId/booking/transaction/:transactionId/invoice/:invoiceId',
  },

  justifications: {
    transaction: '/v1/administration/:administrationId/transaction/:transactionId/justification/:justificationId',
    invoice: '/v1/administration/:administrationId/invoice/:invoiceId/justification/:justificationId',
    document: '/v1/administration/:administrationId/document/:documentId/justification/:justificationId',
    vat: '/v1/administration/:administrationId/vat-declaration/:vatDeclarationId/justification/:justificationId',
    suppletion: '/v1/administration/:administrationId/suppletion/:suppletionId/justification/:justificationId',

    // Accept pending justifications
    acceptInvoiceJustifications: '/v1/administration/:administrationId/invoice/:invoiceId/justification/accept',
    acceptTransactionJustifications: '/v1/administration/:administrationId/transaction/:transactionId/justification/accept',

    // Update justifcation
    justification: '/v1/administration/:administrationId/justification/:justificationId',
  },

  role: {
    roles: '/v1/roles',
  },

  test: {
    createProspect: '/v1/user/prospect',
    getOrigins: '/v1/support/user-origins',
    transactionsImport: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/import',
    transactionsImportTypes: '/v2/administration/:administrationId/bank-accounts/:bankAccountId/import/supported-types',
  },

  export: {
    csv: '/v1/administration/:administrationId/export/csv',
    auditFile: '/v1/administration/:administrationId/export/auditfile/:fiscalYearId',
  },

  shortUrl: {
    create: '/v1/short-url/create',
  },

  prospect: {
    activation: '/v2/activation/prospect/:token/',
    validate: '/v2/activation/prospect/:token/validate',
    validateCode: '/v2/activation/prospect/:token/validate-code',
    activate: '/v2/activation/prospect/:token/activate',
  },

  modules: {
    subscriptions: '/v2/subscriptions',
    subscribe: '/v2/subscriptions/subscribe',
    unsubscribe: '/v2/subscriptions/unsubscribe',
  },

  /**
   * Not that these are for the _tax_ service.
   * They are not intended for the 'regular' API.
   */
  ib: {
    list: '/v1/documents/:administrationId',
    create: '/v1/documents/:administrationId/upload',
    update: '/v1/documents/:administrationId/:id',
    delete: '/v1/documents/:administrationId/:id',
  },

  reminders: {
    getDismissed: '/v2/administration/:administrationId/reminders/dismissed',
    dismiss: '/v2/administration/:administrationId/reminders/dismiss',
  },
}
