import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'
import { NgForm } from '@angular/forms'

@Directive({
  selector: 'form',
  host: {
    '(ngSubmit)': 'submitted()',
  },
  exportAs: 'formDirective',
})
export class FormDirective extends BaseHtmlDirective implements OnInit {
  get disabled(): boolean {
    return this._disabled
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled
    this.onDisabled.emit(disabled)
  }

  get busy() {
    return this._busy
  }

  @Input()
  set busy(busy: boolean) {
    this._busy = busy
    this.onBusy.emit(busy)
  }

  get ngForm() {
    return this._form
  }

  get valid() {
    return this._form.valid
  }

  @Output()
  onBusy = new EventEmitter<boolean>()
  @Output()
  onDisabled = new EventEmitter<boolean>()
  @Output()
  onValid = new EventEmitter<boolean>()
  @Output()
  onSubmit = new EventEmitter<void>()
  @Output()
  statusChanged = new EventEmitter<any>()
  @Output()
  valueChanged = new EventEmitter<any>()

  status: string

  private _disabled = false
  private _busy = false

  constructor(element: ElementRef, private _form: NgForm) {
    super(element)
  }

  ngOnInit() {
    if (this._form) {
      // When a value changes, let the parent know.
      this._form.valueChanges.subscribe((e) => {
        this.valueChanged.emit(e)
      })

      // Set form status on parent component.
      this._form.statusChanges.subscribe((status) => {
        this.status = status
        this.statusChanged.emit({ form: this, status: status })
      })

      this.status = 'INVALID'
      this.statusChanged.emit({ form: this, status: 'INVALID' })
    }
  }

  submitted() {
    // Fix for generated forms with *ngFor
    this.element.classList.add('ng-submitted')
    this.onSubmit.emit()
  }
}
