import { Observable } from 'rxjs'
import { NgZone } from '@angular/core'

/**
 *
 */
export default class ResizeObservable extends Observable<ResizeObserverEntry[]> {
  constructor(elem: HTMLElement, ngZone: NgZone) {
    super((subscriber) => {
      const ro = new ResizeObserver((entries) => {
        ngZone.run(() => subscriber.next(entries))
      })

      // Observe one or multiple elements
      ro.observe(elem)

      return function unsubscribe() {
        ro.unobserve(elem)
        ro.disconnect()
      }
    })
  }
}
