import { Directive, ElementRef } from '@angular/core'
import { BaseHtmlDirective } from './basehtml.directive'

@Directive({
  selector: 'label',
})
export class LabelDirective extends BaseHtmlDirective {
  constructor(element: ElementRef) {
    super(element)
  }
}
