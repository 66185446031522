import { Component, Input } from '@angular/core'
import { UserLockType } from '../../../domain/user/user-lock-type.enum'

@Component({
  selector: 'locked-out-page',
  styleUrls: ['./locked-out-page.component.scss'],
  template: `
    <div class="full-screen-wrapper">
      <svg height="32" viewBox="0 0 155 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.1285 0V5.16926H59.6632V25.479H65.5588V5.16926H73.0906V0H52.1285Z" fill="#002E33" />
        <path
          d="M85.4629 9.53555C83.8183 7.78962 81.7134 6.91528 79.0575 6.91528C76.2453 6.91528 73.9622 7.78962 72.134 9.57392C70.3059 11.3582 69.3932 13.6496 69.3932 16.4535C69.3932 19.2574 70.3032 21.5131 72.134 23.3056C73.8683 25.0453 76.2283 26.0159 78.6847 25.9999C82.5219 25.9999 85.3313 24.4705 87.0745 21.3788L83.0975 19.0737C82.0158 20.5739 80.6161 21.3258 78.8985 21.3295C76.6784 21.3295 74.8585 19.981 74.5679 17.7992H87.9269V15.9437C87.9269 13.3947 87.0909 11.2486 85.4601 9.53829M74.7982 13.9785C75.382 12.1942 76.947 11.1389 78.9478 11.1389C81.0227 11.1389 82.5877 12.1942 83.0235 13.9785H74.7982Z"
          fill="#002E33"
        />
        <path d="M96.388 0H90.9282V25.479H96.388V0Z" fill="#002E33" />
        <path d="M105.315 0H99.8551V25.479H105.315V0Z" fill="#002E33" />
        <path
          d="M122.138 7.8964C118.55 6.1291 114.24 6.78872 111.344 9.54839C108.448 12.3081 107.582 16.5814 109.175 20.2508C110.767 23.9202 114.481 26.2061 118.474 25.9756C122.468 25.745 125.893 23.0472 127.053 19.2189C128.402 14.7509 126.323 9.96248 122.138 7.8964ZM121.971 20.466C121.991 20.5356 121.971 20.6104 121.92 20.6615C121.869 20.7126 121.794 20.7322 121.725 20.7127L120.784 20.4386C120.351 20.324 119.893 20.3519 119.477 20.5181C117.625 21.2192 115.532 20.582 114.385 18.9674C113.238 17.3528 113.325 15.1674 114.596 13.6488C115.868 12.1302 118.004 11.6608 119.795 12.5065C121.314 13.2514 122.277 14.7948 122.278 16.4863C122.291 17.1113 122.157 17.7306 121.889 18.2952C121.675 18.737 121.629 19.2418 121.76 19.715L121.971 20.466Z"
          fill="#002E33"
        />
        <path
          d="M149.218 7.42773L146.812 17.6923L144.046 7.42773H138.806L136.037 17.6923L133.636 7.42773H128.179L133.236 25.4818H138.512L141.426 14.4882L144.339 25.4818H149.615L154.672 7.42773H149.218Z"
          fill="#002E33"
        />
        <path
          d="M25.9879 0H17.4821C17.4821 3.60742 18.2181 7.03387 19.5452 10.1587H14.2246C11.0515 14.5986 5.86357 17.4942 0 17.4942V26C9.09698 26 17.0961 21.3309 21.7411 14.2487C26.3861 21.3188 34.3851 26 43.4701 26V17.4942C33.8181 17.4821 25.9879 9.65197 25.9879 0Z"
          fill="#002E33"
        />
      </svg>
      <div class="info-container" [ngClass]="{ red: isUserBlocked, yellow: didntAcceptInvite }">
        <div class="icon-container">
          <svg width="149" height="149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff" d="M61 39h25v78H61z" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M61.7 30.4a14.44 14.44 0 0 1 25.38 0l40.63 73.46c5.46 9.87-1.55 22.06-12.68 22.06H33.76c-11.15 0-18.16-12.19-12.7-22.06L61.7 30.4Zm19.97 73.31c0 1.97-.77 3.85-2.13 5.24a7.22 7.22 0 0 1-10.3 0 7.47 7.47 0 0 1 0-10.47 7.22 7.22 0 0 1 10.3 0 7.47 7.47 0 0 1 2.13 5.23Zm-7.28-59.23c-1.93 0-3.79.78-5.15 2.17a7.47 7.47 0 0 0-2.14 5.23V74.1c0 1.96.77 3.84 2.14 5.23a7.22 7.22 0 0 0 10.3 0 7.47 7.47 0 0 0 2.13-5.23V51.88c0-1.96-.77-3.84-2.13-5.23a7.22 7.22 0 0 0-5.15-2.17Z"
            />
          </svg>
        </div>

        <ng-container *ngIf="isUserBlocked">
          <h2>{{ (hasValidUrl ? 'MAIN.LOCKED_OUT_SCREEN.WITH_URL.TITLE' : 'MAIN.LOCKED_OUT_SCREEN.WITHOUT_URL.TITLE') | translate }}</h2>
          <p>{{ (hasValidUrl ? 'MAIN.LOCKED_OUT_SCREEN.WITH_URL.SUBTITLE' : 'MAIN.LOCKED_OUT_SCREEN.WITHOUT_URL.SUBTITLE') | translate }}</p>
          <fnc-button *ngIf="hasValidUrl" (fncClick)="goToUrl()" big purple>{{ 'MAIN.LOCKED_OUT_SCREEN.WITH_URL.PAY_NOW' | translate }}</fnc-button>
          <p *ngIf="!hasValidUrl" class="email-title">{{ 'MAIN.LOCKED_OUT_SCREEN.WITHOUT_URL.EMAIL_TITLE' | translate }}</p>
          <p class="email-subtitle">
            {{ (hasValidUrl ? 'MAIN.LOCKED_OUT_SCREEN.WITH_URL.EMAIL_SUBTITLE' : 'MAIN.LOCKED_OUT_SCREEN.WITHOUT_URL.EMAIL_SUBTITLE') | translate }}
          </p>
        </ng-container>

        <p class="email-title" *ngIf="didntAcceptInvite">{{ 'MAIN.LOCKED_OUT_SCREEN.INVITE_NOT_ACCEPTED' | translate }}</p>
      </div>
    </div>
  `,
})
export class LockedOutPageComponent {
  @Input() blockUrl: string
  @Input() lockType: UserLockType

  get isUserBlocked(): boolean {
    return this.lockType === UserLockType.USER_BLOCKED
  }

  get didntAcceptInvite(): boolean {
    return this.lockType === UserLockType.INVITE_NOT_ACCEPTED
  }

  get hasValidUrl(): boolean {
    return !!this.blockUrl
  }

  goToUrl(): void {
    window.open(this.blockUrl, '_blank')
  }
}
