import { EventEmitter } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Token } from '../../domain/user/token.model'
import { uuid4 } from '@sentry/utils'

export class SecurityService {
  public static support = '820b3818-92372b182ee1'
  public static supportQueryParamValue = 'cc6a394a'

  private static refreshExpiryInSeconds = 604800 // On current production server.
  private static token = 'token'
  private static x_device = 'X-Device-UID'
  private _x_device_id_value: string
  private _support_token_value: string
  private _showPaywall: boolean
  private _isExistingUser: boolean

  public static TokenSaved = new EventEmitter<void>()
  public static TokenCleared = new EventEmitter<void>()

  get token(): Token {
    return SecurityService.getTokenFromLocalStorage()
  }

  set token(token: Token) {
    token.__timeStampAdded = new Date().getTime()

    try {
      // Serialize and encode data.
      localStorage.setItem(SecurityService.token, window.btoa(JSON.stringify(token)))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log('Private mode or no local storage available. Use in memory storage')
    }
  }

  get hasSupportToken(): boolean {
    return !!this.supportToken
  }

  get supportToken(): string {
    return localStorage.getItem(SecurityService.support) ?? this._support_token_value
  }

  setSupportToken(): string {
    this._support_token_value = uuid4()

    try {
      localStorage.setItem(SecurityService.support, this._support_token_value)
    } catch (error) {
      console.log('Private mode or no local storage available. Use in memory storage')
    }

    return this._support_token_value
  }

  clearSupportToken(): void {
    this._support_token_value = null
    localStorage.removeItem(SecurityService.support)
  }

  get hasDeviceID(): boolean {
    return !!this.deviceID
  }

  get deviceID(): string {
    return localStorage.getItem(SecurityService.x_device) ?? this._x_device_id_value
  }

  setDeviceID(): string {
    this._x_device_id_value = uuid4()

    try {
      localStorage.setItem(SecurityService.x_device, this._x_device_id_value)
    } catch (error) {
      console.log('Private mode or no local storage available. Use in memory storage')
    }

    return this._x_device_id_value
  }

  get hasToken(): boolean {
    return SecurityService.getTokenFromLocalStorage() !== null
  }

  get hasValidToken(): boolean {
    if (!this.hasToken) {
      return false
    }

    const token = SecurityService.getTokenFromLocalStorage()
    const expiryTimeStamp = token.__timeStampAdded + token.expires_in * 1000

    return expiryTimeStamp > new Date().getTime()
  }

  get hasValidRefreshToken(): boolean {
    if (!this.hasToken) {
      return false
    }

    const expiryTimeStamp = SecurityService.getTokenFromLocalStorage().__timeStampAdded + SecurityService.refreshExpiryInSeconds * 1000

    return expiryTimeStamp > new Date().getTime()
  }

  get canMakeAuthenticatedCall(): boolean {
    return this.hasToken && this.hasValidToken && this.hasValidRefreshToken
  }

  get secondsToAccessTokenExpiry(): number {
    if (!this.hasToken) {
      return -1
    }

    const token = SecurityService.getTokenFromLocalStorage()
    const expiryTimeStamp = token.__timeStampAdded + token.expires_in * 1000

    return Math.floor((expiryTimeStamp - new Date().getTime()) / 1000)
  }

  getUncachedToken(): Token {
    return SecurityService.getTokenFromLocalStorage()
  }

  saveToken(token: Token): void {
    this.token = token

    SecurityService.TokenSaved.emit()
  }

  /**
   * Clear token (i.e. on logging out of Tellow)
   *
   * @param {string} caller component/service that called this function (for logging).
   */
  clearToken(caller?: string): void {
    console.log(`${caller ? caller : 'UNKNOWN'} called to clear token`)

    localStorage.removeItem(SecurityService.token)
    localStorage.removeItem('device')
    localStorage.removeItem('intercom')

    SecurityService.TokenCleared.emit()
  }

  /**
   * Prevent users from trying to
   * access the wrong administration
   * after logging out of Tellow.
   */
  clearDefaultAdministrationId() {
    localStorage.removeItem('default_administration_id')
  }

  showUserPaywall(): void {
    this._showPaywall = true
  }

  dontShowUserPaywall(): void {
    this._showPaywall = false
  }

  getUserPaywallStatus(): Observable<boolean> {
    return of(this._showPaywall)
  }

  saveUrlAfterPaywall(url: string): void {
    localStorage.setItem('paywallUrl', url)
  }

  getUrlAfterPaywall(): string {
    let url = localStorage.getItem('paywallUrl')
    const paramCount = (url.match(/;/g) || []).length

    if (paramCount) {
      url = url.slice(0, url.indexOf(';'))
    }

    return url ? url : ''
  }

  getUrlParams() {
    const url = localStorage.getItem('paywallUrl')
    const paramCount = (url.match(/;/g) || []).length

    if (!paramCount) {
      return
    }

    const paramUrl = url.slice(url.indexOf(';') + 1, url.length)
    const p = {}

    // Loop through params
    for (let index = 0; index < paramCount; index++) {
      const startPos = index === 0 ? 0 : this.getPosition(paramUrl, '=', index)
      const key = paramUrl.slice(startPos, this.getPosition(paramUrl, '=', index + 1))
      const val = paramUrl.slice(key.length + 1, this.getPosition(paramUrl, ';', index + 1))

      if (key && val) {
        p[key] = val
      }
    }

    return p
  }

  existingUser(existing: boolean): void {
    this._isExistingUser = existing
  }

  isExistingUser(): boolean {
    return this._isExistingUser
  }

  private getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length
  }

  /**
   * Get decoded token from local storage.
   */
  private static getTokenFromLocalStorage(): Token | null {
    const encoded = localStorage.getItem('token')

    if (!encoded) {
      return null
    }

    return JSON.parse(window.atob(encoded))
  }
}
