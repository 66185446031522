import { Observable, of as observableOf } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiGateway } from '../../core/remote/api.gateway'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { endpoints } from '../../shared/config/endpoints'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationService } from '../administration/administration.service'
import { WizardPage } from '../../domain/onboarding/wizardpage.model'
import { VatRate } from '../../domain/administration/vat-rate.model'
import { QuoteListItem } from '../../domain/quote/quote-listitem.model'
import { QuoteSearchObject } from './quote-search-object'
import { Quote, QuoteLine } from '../../domain/quote/quote.model'
import { EditQuote, EditQuoteLine } from '../../domain/quote/edit-quote.model'
import { QuoteStates } from '../../domain/quote/quote-states.constants'
import { DownloadedBlobWithImage } from '../documents/document.service'
import { FileService } from '../../core/logic/files/file.service'
import { PdfService } from '../documents/pdf.service'
import { JustificationDocument } from '../../domain/justification/justification-document.model'
import { CustomQuoteTemplateService } from './custom-quote-template.service'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'

export type DocumentType = Quote | JustificationDocument

@Injectable()
export class QuotesService {
  suspendedQuote: EditQuote
  private _resumeQuoteScreenUrl: string
  private _resumeQuoteScreenUrlParams: any
  private _createQuoteUrl = environment.routerLinks.quotes.index + '/' + environment.routerLinks.quotes.createQuote

  constructor(
    private readonly _fileService: FileService,
    private readonly _api: ApiGateway,
    private readonly _pdfService: PdfService,
    private readonly _administration: AdministrationService,
    private readonly _router: TranslateRouteService,
    private readonly _quoteTemplateService: CustomQuoteTemplateService,
    private readonly _list: ListHelper,
  ) {}

  getQuote(quoteId: number): Observable<Quote> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<Quote>(endpoints.quotes.quotes, {
          administrationId: administration.id,
          quoteId: quoteId,
        }),
      ),
    )
  }

  getQuotes(searchObject?: QuoteSearchObject): Observable<QuoteListItem[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._api.get<QuoteListItem[]>(endpoints.quotes.quotes, params)
      }),
    )
  }

  getQuoteQuestions(context: string): Observable<WizardPage> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<WizardPage>(endpoints.administration.complement, {
          administrationId: administration.id,
          context: context,
        }),
      ),
    )
  }

  getQuoteForEditing(id: number): Observable<EditQuote> {
    let rates: VatRate[]

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration: Administration) => {
        rates = administration.vat_rates

        return this.getQuote(id)
      }),
      map((existing) => {
        return <EditQuote>{
          id: existing.id,
          number: existing.number,
          status: existing.status,
          date: existing.date,
          expiry_date: existing.expiry_date,
          expense: existing.expense,
          cost_type: existing.cost_type,
          reference_number: existing.reference_number,
          concerns: existing.concerns,
          description: existing.description,
          header: existing.header,
          including_vat: existing.including_vat,
          footer: existing.footer,
          type: existing.type,
          attachment_id: existing.attachment_id,
          contact_id: existing.contact && existing.contact.id,
          address_id: existing.address && existing.address.id,
          template_id: existing.template && existing.template.id,
          lines: this.linesToEditLines(existing.lines, rates),
        }
      }),
    )
  }

  routeToCreationScreen(requestedUrl?: string, params?: any): void {
    void this.navigateToCreationScreen(requestedUrl, params)
  }

  navigateToCreationScreen(requestedUrl?: string, params?: any): void {
    if (requestedUrl) this._resumeQuoteScreenUrl = requestedUrl
    if (params) this._resumeQuoteScreenUrlParams = params

    const url = this._resumeQuoteScreenUrl || this._createQuoteUrl
    void this._router.navigate(url, this._resumeQuoteScreenUrlParams)
  }

  resumeToNewQuotesScreen(): void {
    if (this._resumeQuoteScreenUrl) {
      this.navigateToCreationScreen(this._resumeQuoteScreenUrl, this._resumeQuoteScreenUrlParams)
    } else {
      this.navigateToCreationScreen('/contacts', { quote: true, import: true })
    }
  }

  increaseQuoteIndexNumber(): Observable<any> {
    return this._administration.defaultAdministrationSettings.pipe(map((settings) => settings.quote_number_index++))
  }

  updateOverviewList(list: GroupedMonthlyState<QuoteListItem[]>, quote: Quote): void {
    if (!quote || !quote.id || !list) {
      return
    }

    const listQuote = this._list.findInStateObject<QuoteListItem>(list, quote)

    if (!listQuote) {
      return
    }

    listQuote.status = quote.status
    listQuote.number = quote.number
    listQuote.total_inc = quote.total_inc
    listQuote.expiry_date = quote.expiry_date
    listQuote.contact_name = quote.contact_name
    listQuote.date = quote.date
    listQuote.type = quote.type
    listQuote.total_ex = quote.total_ex
  }

  removeFromOverviewList(list: GroupedMonthlyState<QuoteListItem[]>, quote: Quote | EditQuote): void {
    if (!list || !quote) {
      return
    }

    const listQuote = this._list.findInStateObject<QuoteListItem>(list, quote)

    if (listQuote) {
      this._list.removeFromStateObject<QuoteListItem>(list, quote)
    }
  }

  deleteQuote(quote: Quote | QuoteListItem): Observable<void> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.delete(endpoints.quotes.quotes, {
          administrationId: administration.id,
          quoteId: quote.id,
        }),
      ),
    )
  }

  // TODO: Actually give this conditions?
  isEditable(quote: Quote): Observable<boolean> {
    if (!quote.is_editable) {
      return observableOf(false)
    }

    return observableOf(true)
  }

  // TODO: Actually give this conditions?
  isDeletable(quote: Quote): Observable<boolean> {
    if (!quote.is_deletable) {
      return observableOf(false)
    }

    return observableOf(true)
  }

  convert(quote: Quote) {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        /**
         * TODO
         * Find out why this hacky solution is required.
         * Somehow, the vat_rate_id is placed in vat_period.
         * The backend rejects this, as the body is incorrect.
         *
         * Moving the vat_rate_id to the root of the object,
         * solves the issue for now. Definitely fix this later.
         */
        // const fix = quote.lines.map((line: any) => {
        //   const copy = line
        //   line.vat_rate_id = line.vat_period.vat_rate_id

        //   return copy
        // })

        // regular.
        // const state = {
        //   contact_id: quote.contact.id,
        //   lines: quote.lines,
        //   is_quote: false,
        // }

        const params = {
          quoteId: quote.id,
          administrationId: administration.id,
        }

        return this._api.post(endpoints.quotes.promoteIntoInvoice, null, params)
      }),
    )
  }

  reject(quote: Quote) {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const state = { status: QuoteStates.REJECTED }
        const params = { quoteId: quote.id, administrationId: administration.id }

        return this._api.patch(endpoints.quotes.setStatus, state, null, params)
      }),
    )
  }

  private linesToEditLines(existing: QuoteLine[], rates: VatRate[]): EditQuoteLine[] {
    const hasVat = existing.any((existing) => existing.vat_period !== null)

    return existing.map((existing) => {
      const rate: VatRate = hasVat && rates.firstOrUndefined((rate) => rate.vat_periods.any((vp) => vp.id == existing.vat_period.id))

      const line = <EditQuoteLine>{
        description: existing.description,
        quantity: existing.quantity,
        price: existing.price,
        discount_amount: existing.discount_amount,
        discount_percentage: existing.discount_percentage,
        vat_amount: existing.vat_amount,
        total_amount_after_discount: existing.total_amount_after_discount,
        total_amount_before_discount: existing.total_amount_before_discount,
      }

      if (rate) {
        line.vat_rate_id = rate.id
      } else if (hasVat) {
        line.vat_rate_id = rates.firstOrUndefined() && rates.firstOrUndefined().id
      }

      return line
    })
  }

  downloadImageOrPdf(document: DocumentType, original?: boolean): Observable<DownloadedBlobWithImage> {
    const result: DownloadedBlobWithImage = <any>{}

    return this.downloadDocument(document.id, 'application/pdf', original).pipe(
      mergeMap((blob) => {
        result.blob = blob

        return this._pdfService.pdfToImage(blob, 2)
      }),
      map((src) => {
        result.image = src.image

        return result
      }),
    )
  }

  private downloadDocument(id: number, mime: string, original?: boolean): Observable<Blob> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        const options = this._fileService.createFileOptions(mime)
        const params = <any>{ quoteId: id, administrationId: administration.id }

        if (original) {
          params.original = original
        }

        return this._fileService.fetchAsyncFileContent(this._api.get(endpoints.quotes.pdf, params, options, true), mime)
      }),
    )
  }
}
