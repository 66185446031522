import { mergeMap } from 'rxjs/operators'
import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { AdministrationService } from '../../administration/administration.service'
import { AccountingService } from '../../accounting/accounting.service'

@Injectable()
export class StartBalanceGuard implements CanActivate {
  constructor(private readonly _administrationService: AdministrationService, private readonly _accountingService: AccountingService) {}

  canActivate(): any {
    return this._administrationService.defaultAdministrationSettings.pipe(
      mergeMap((settings) => this._accountingService.actionInFiscalYearAllowed(moment(settings.start_date))),
    )
  }
}
