import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Prospect, ProspectWithToken } from '../../domain/onboarding/prospect.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { environment } from '../../../environments/environment'
import { SecurityService } from '../../core/security/security.service'
import { Token } from '../../domain/user/token.model'

/**
 * @deprecated Since Bizzy has been stopped since October 2020.
 */
@Injectable()
export class OnboardingService {
  private static item = 'prospect'
  private static token = 'invitation-token'
  private _verificationCode: string
  private _prospect: Prospect
  private _token: string
  private _activationFlow: boolean

  constructor(private readonly _api: ApiGateway, private readonly _security: SecurityService) {}

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  startOnboarding(token: string): Observable<Prospect> {
    if (this.getProspect()) {
      return observableOf(this.getProspect())
    }

    // return this._api.get<Prospect>(`${endpoints.prospect.activation}`,{token: token})
    return this._api.get<Prospect>(`${endpoints.prospect.activation}${token}`).pipe(
      map((res: ProspectWithToken) => {
        res._token = token
        this.setProspect(res)

        return res
      }),
      catchError((error: any) => {
        return observableThrowError(error)
      }),
    )
  }

  /**
   * @deprecated
   */
  refreshExpiredToken(expiredToken: string): Observable<string> {
    return this._api.post(`${endpoints.onboarding.requestToken}`, { token: expiredToken })
  }

  /**
   * @deprecated
   */
  sendVerificationCode(): Observable<any> {
    const body = {
      token: (<ProspectWithToken>this.getProspect())._token,
    }

    return this._api.post(endpoints.onboarding.validateProspect, body)
  }

  /**
   * @deprecated
   */
  checkVerficiationCode(code: string, deviceDetails?: any): Observable<any> {
    this._verificationCode = code

    return this._api.post(
      endpoints.prospect.validateCode,
      {
        sms_code: this._verificationCode,
        device: deviceDetails,
      },
      { token: (<ProspectWithToken>this.getProspect())._token },
    )
  }

  /**
   * @deprecated
   */
  checkActivationToken(token?: string): Observable<any> {
    const t = this.getProspect() ? (<ProspectWithToken>this.getProspect())._token : token

    if (!t) {
      return observableThrowError('No valid token found')
    }

    return this._api.post(endpoints.prospect.validate, { token: t }, { token: t })
  }

  /**
   * @deprecated
   */
  sendCorrectPhoneNumber(number: string) {
    const token = (<ProspectWithToken>this.getProspect())._token

    return this._api.post(endpoints.onboarding.incorrectInfo, { token: token, phone_number: number })
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  getProspect(): Prospect {
    // Getting prospect data means being in activation flow
    this._activationFlow = true

    if (this._prospect) {
      return this._prospect
    }

    try {
      return JSON.parse(localStorage.getItem(OnboardingService.item))
    } catch (error) {
      this._activationFlow = false

      return null
    }
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  setProspect(item: Prospect) {
    this._prospect = item

    try {
      localStorage.setItem(OnboardingService.item, JSON.stringify(item))
    } catch (error) {
      console.info('Private mode or no local storage available. Use in memory storage')
    }
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  resetProspect() {
    this._prospect = null

    try {
      localStorage.removeItem(OnboardingService.item)
    } catch (error) {
      console.info('Private mode or no local storage available. Use in memory storage')
    }
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  activateInvitedUser(data: any) {
    let token

    if (this._token) {
      // Get local token
      token = this._token
    } else {
      // Get token from cache (if page is refreshed oid)
      token = JSON.parse(localStorage.getItem(OnboardingService.token))
    }

    data.token = token

    return this._api.post(endpoints.onboarding.activateInvitee, data)
  }

  hasToken(): boolean {
    return !!this._token
  }

  setActivationFlow() {
    this._activationFlow = true
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  saveTokenForInvitedUser(token: any): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return this._api.get(`${endpoints.onboarding.validateToken}?token=${token}`).pipe(
      map(() => {
        this._token = token

        try {
          localStorage.setItem(OnboardingService.token, JSON.stringify(token))
        } catch (error) {
          console.info('No local storage available. Use memory storage')
        }
      }),
    )
  }

  verifyUserToken(token: string): Observable<any> {
    return this.verifyAndRefreshToken(token)
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  private verifyAndRefreshToken(token: string): Observable<any> {
    const body = {
      token: token,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }

    return this._api.post(endpoints.onboarding.tokenVerification, body).pipe(
      mergeMap((value: { token: string }) => {
        return this.getOauthWithRefreshToken(value.token)
      }),
      catchError((error: any) => {
        return observableThrowError(error)
      }),
    )
  }

  /**
   * @deprecated Since Bizzy has been stopped since October 2020.
   */
  private getOauthWithRefreshToken(refreshToken: string): Observable<Token> {
    const body = {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }

    return this._api.post(endpoints.login.token, body).pipe(
      map((token: Token) => {
        this._security.saveToken(token)

        return token
      }),
      catchError((error: any) => {
        return observableThrowError(error)
      }),
    )
  }
}
