import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CookieService } from 'ngx-cookie-service'
import { LottieModule } from 'ngx-lottie'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.route'
import { CoreModule } from './core/core.module'
import { UiComponentsModule } from './ui-components/ui-components.module'
import { LayoutComponentsModule } from './layout-components/layout-components.module'
import { LogicModule } from './logic/logic.module'
import { PaywallComponent } from './app-ui/paywall/paywall.component'
import { RedirectComponent } from './redirect/redirect.component'
import { SentryErrorHandlerService } from './logic/external-services/sentry-error-handler.service'
import { CustomTranslateLoader } from './custom-translate-loader'
import { HotToastModule } from '@ngneat/hot-toast'
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'

export function playerFactory() {
  return import('lottie-web')
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return ''
  }
}

const disableAnimations = !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))

@NgModule({
  declarations: [AppComponent, PaywallComponent, RedirectComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    LottieModule.forRoot({ player: playerFactory }),
    UiComponentsModule,
    CoreModule.forRoot(),
    LogicModule.forRoot(),
    LayoutComponentsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    AppRoutingModule,
    HotToastModule.forRoot({
      position: 'bottom-right',
      style: {
        zIndex: 1234511,
      },
      dismissible: true,
      duration: 4000,
      iconTheme: {
        primary: '#002E33',
        secondary: '#fff',
      },
      success: {
        className: 'success',
        iconTheme: {
          primary: '#20d69d',
          secondary: '#fff',
        },
      },
      warning: {
        className: 'warning',
        iconTheme: {
          primary: '#ff8e4f',
          secondary: '#fff',
        },
      },
      error: {
        className: 'error',
        iconTheme: {
          primary: '#ee5656',
          secondary: '#fff',
        },
      },
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandlerService },
    { provide: CookieService, useClass: CookieService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
