import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { globals } from '../../../shared/config/globals'

@Component({
  selector: 'confetti',
  styleUrls: ['./confetti.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="confetti-container" #container>
      <canvas #canvas></canvas>
    </div>
  `,
})
export class ConfettiComponent implements OnInit {
  @ViewChild('container', { static: true }) container: ElementRef
  @ViewChild('canvas', { static: true }) canvas: ElementRef

  @Input() confettiCount: number
  @Input() snow: boolean

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    Confetti.color = () => this.color()
    setTimeout(() => this.run())
  }

  private run(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this

    const canvas = Confetti.createCanvas(this.container.nativeElement, this.canvas.nativeElement)

    const config = {
      angle: 0.01,
      tiltAngle: 0.1,
      letNotDisappear: this.snow,
      draw: draw,
      updatePosition: updatePosition,
      updateState: updateState,
    }

    const particles = []

    for (let x = 0; x < (this.confettiCount || 20); x++) {
      particles.push(
        Confetti.create({
          x: Confetti.randomFrom(0, canvas.width),
          y: 0,
          r: Confetti.randomFrom(5, 30),
          tilt: Confetti.randomFrom(-10, 0),
          tiltAngle: 0,
          tiltAngleIncrement: Confetti.randomFrom(0.05, 0.12, 100),
        }),
      )
    }

    canvas.step(particles, config)()

    function draw(confetti): void {
      canvas.context.beginPath()
      if (!that.snow) {
        canvas.context.lineWidth = confetti.r / 2
        canvas.context.strokeStyle = confetti.color
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        canvas.context.moveTo(confetti.x + confetti.tilt + confetti.r / 4, confetti.y)
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        canvas.context.lineTo(confetti.x + confetti.tilt, confetti.y + confetti.tilt + confetti.r / 4)
        canvas.context.stroke()
      } else {
        const radius = 2.5 // Arc radius
        const startAngle = 0 // Starting point on circle
        const endAngle = Math.PI * 2 // End point on circle

        canvas.context.fillStyle = '#fff'
        canvas.context.arc(confetti.x, confetti.y, radius, startAngle, endAngle, false)
        canvas.context.fill()
      }
    }

    function updatePosition(confetti, idx): void {
      confetti.tiltAngle += confetti.tiltAngleIncrement
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      confetti.y += (Math.cos(config.angle + confetti.d) + 1 + confetti.r / 2) / (!that.snow ? 2 : 5)
      confetti.x += Math.sin(config.angle)
      confetti.tilt = 15 * Math.sin(confetti.tiltAngle - idx / 3)

      if (confetti.isFlakeExiting(canvas)) {
        if (!(idx % 5 > 0 || idx % 2 === 0)) {
          if (Math.sin(config.angle) > 0) {
            confetti.x = -5
            confetti.y = Confetti.randomFrom(0, canvas.height)
            confetti.tilt = Confetti.randomFrom(-10, 0)
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            confetti.x = canvas.width + 5
            confetti.y = Confetti.randomFrom(0, canvas.height)
            confetti.tilt = Confetti.randomFrom(-10, 0)
          }
        } else {
          confetti.x = Confetti.randomFrom(0, canvas.width)
          confetti.y = -10
          confetti.tilt = Confetti.randomFrom(-10, 0)
        }
      }
    }

    function updateState(): void {
      this.angle += 0.01
      this.tiltAngle += 0.1
    }
  }

  private color(): string {
    const r = Math.floor(Math.round(Math.random() * 100) / 10)

    switch (r) {
      case 1:
      case 2:
        return globals.colors.blue
      case 3:
      case 4:
        return globals.colors.green
      case 5:
        return globals.colors.orange
      case 6:
      case 7:
        return globals.colors.yellow
      case 8:
        return globals.colors.red
      case 9:
        return globals.colors.purple
      default:
        return globals.colors.red
    }
  }
}
