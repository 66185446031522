export enum VatPeriodStates {
  HISTORY,
  CURRENT,
  FUTURE,
}

// TODO: Typescript 2.4 will support string enums! :)
export class VatPeriodStatesString {
  static enumToString(input: VatPeriodStates): string {
    switch (input) {
      case VatPeriodStates.HISTORY:
        return 'HISTORY'
      case VatPeriodStates.CURRENT:
        return 'CURRENT'
      case VatPeriodStates.FUTURE:
        return 'FUTURE'
    }
  }
}
