import { Component, OnInit, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { indicate } from '../../core/operators/indicate'
import { SegmentHelper } from '../../logic/external-services/segment.helper'
import { ToastrHelper } from '../../logic/helpers/toastr.helper'
import { TlwAuxilaryModalComponent } from '../tlw-auxilary-modal/tlw-auxilary-modal.component'
import { EmailService } from '../../logic/user/email.service'
import { NotificationService } from '../../logic/notifications/notification.service'

export type OpenMethodProperties = {
  context: VerificationEmailModalSources
  args?: any[]
}

export enum VerificationEmailModalSources {
  Notification = 'notification',
  SendInvoice = 'send-invoice',
  SendQuote = 'send-quote',
}

@Component({
  selector: 'tlw-email-verification-modal',
  templateUrl: 'tlw-email-verification-modal.component.html',
  styleUrls: ['tlw-email-verification-modal.component.scss'],
})
export class TlwEmailVerificationModalComponent implements OnInit {
  @ViewChild(TlwAuxilaryModalComponent) auxilary: TlwAuxilaryModalComponent

  protected resendingEmail$ = new BehaviorSubject<boolean>(false)

  /**
   * Auxilary context is stored as a state
   * through the Angular router. We can
   * obtain it from the ViewChild.
   */
  get getAuxilaryContext(): VerificationEmailModalSources {
    return this.auxilary?.state?.context
  }

  get getCounterCurrentValue$(): BehaviorSubject<number> {
    return this._email.getCounterCurrentValue$
  }

  get isCounterInProgress$(): Observable<boolean> {
    return this._email.isCounterInProgress$
  }

  get isOnSendScreen(): boolean {
    return this.getAuxilaryContext == VerificationEmailModalSources.SendInvoice || this.getAuxilaryContext == VerificationEmailModalSources.SendQuote
  }

  get entityName(): string {
    return this._translate.instant(this.getAuxilaryContext == VerificationEmailModalSources.SendInvoice ? 'MAIN.INVOICE' : 'MAIN.QUOTE')
  }

  constructor(
    private readonly _segment: SegmentHelper,
    private readonly _email: EmailService,
    private readonly _translate: TranslateService,
    private readonly _toastr: ToastrHelper,
    private readonly _notification: NotificationService,
  ) {}

  /**
   * Lifecycle method.
   */
  ngOnInit() {
    void this.track('Modal opened')
  }

  /**
   * Save the data to the backend.
   */
  resendVerificationEmail(): void {
    if (this.resendingEmail$.value) return

    // Get all i18n keys.
    const success: string = this._translate.instant('MESSAGES.SUCCESS')
    const loadingToast = this._toastr.loading(this._translate.instant('MESSAGES.LOADING'))

    this._email
      .requestVerificationEmail()
      .pipe(
        indicate(this.resendingEmail$),
        this._toastr.observe({
          success: () => success,
        }),
        tap(() => this.track('Resent email')),
      )
      .subscribe(
        () => {
          this._email.beginCounter()
          loadingToast.close()
        },
        (error) => {
          loadingToast.close()

          switch (error?.status_code) {
            case 409:
              this._toastr.success(this._translate.instant('EMAIL_VERIFICATION.ERROR.ALREADY_VERIFIED.TITLE'))
              this._notification.loadNotifications(false)
              this.auxilary.close()
              break
            default:
              this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
          }
        },
      )
  }

  /**
   * Submit tracking code to Segment.
   * Small helper to make code more DRY.
   */
  private track(action: 'Modal opened' | 'Resent email'): void {
    return this._segment.track(`Email Verification - ${action}`, {
      location: this.getAuxilaryContext,
    })
  }
}
