import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { HttpHandler } from '@angular/common/http'
import { SecureHttp } from './remote/httpclient'
import { ApiGateway } from './remote/api.gateway'
import { SecurityService } from './security/security.service'
import { TranslateRoutePipe } from './pipes/translate-route.pipe'
import { LoginService } from './security/login.service'
import { FncCurrencyPipe } from './pipes/fnc-currency.pipe'
import { LoginGuard } from './security/login.guard'
import { TranslateRouteService } from './logic/i18n/translate-route.service'
import { TranslateHelper } from './logic/i18n/translate-helper.service'
import { FncFullCurrencyPipe } from './pipes/fnc-full-currency.pipe'
import { FileService } from './logic/files/file.service'
import { FncDatePipe } from './pipes/fnc-date.pipe'
import { DebugService } from './logic/debug.service'
import { ObjectToKeyValueListPipe } from './pipes/obj-to-keyvaluelist.pipe'
import { HelperProvider } from './helpers/helper.provider'
import { ValidateIbanDirective } from './validators/directives/validate-iban.directive'
import { DecimalPipe } from './pipes/decimal.pipe'
import { FncRolePipe } from './pipes/fnc-role.pipe'
import { ListSkipPipe } from './pipes/list-skip.pipe'
import { FncInvoiceType } from './pipes/fnc-invoice-type.pipe'
import { ConfirmLeaveOnChangeGuard } from './routing/guards/confirm-leaveonchange.guard'
import { Autosize } from './directives/autosize.directive'
import { PaywallGuard } from './security/paywall.guard'
import { CustomTranslateLoader } from '../custom-translate-loader'
import { TooltipDirective } from './directives/tooltip.directive'
import { YearsAgoPipe } from './pipes/years-ago.pipe'
import { IfPipe } from './pipes/if.pipe'
import { NotPipe } from './pipes/not.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { AnnotationGuard } from './routing/guards/annotation.guard'
import { TranslatePersist } from './logic/i18n/translate-persist.service'
import { CapitalizePipe } from './pipes/capitalize.pipe'
import { TrimPipe } from './pipes/trim.pipe'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { NoScrollInputDirective } from './directives/noScrollInputDirective.directive'
import { NoEulersNumberAllowedDirective } from './directives/noEulersNumberAllowed.directive'
import { OrderBy } from './pipes/order-by.pipe'
import { JwtService } from './security/jwt.service'
import { LaravelClient } from './remote/laravelClient'
import { DateAgoPipe } from './pipes/date-ago.pipe'
import { EmailValidationService } from './security/email-validation.service'

export function httpFactory(handler: HttpHandler, securityService: SecurityService, router: Router) {
  return new SecureHttp(handler, securityService, router)
}

export function laravelServiceFactory(handler: HttpHandler, apiGateway: ApiGateway, jwtService: JwtService, router: Router) {
  return new LaravelClient(handler, apiGateway, jwtService, router)
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
  ],

  declarations: [
    ObjectToKeyValueListPipe,
    TranslateRoutePipe,
    FncCurrencyPipe,
    FncFullCurrencyPipe,
    FncDatePipe,
    DecimalPipe,
    FncRolePipe,
    ListSkipPipe,
    YearsAgoPipe,
    DateAgoPipe,
    TrimPipe,
    NotPipe,
    IfPipe,
    FncInvoiceType,
    Autosize,
    ValidateIbanDirective,
    TooltipDirective,
    FilterPipe,
    CapitalizePipe,
    ClickOutsideDirective,
    NoScrollInputDirective,
    NoEulersNumberAllowedDirective,
    OrderBy,
  ],

  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ObjectToKeyValueListPipe,
    TranslateRoutePipe,
    FncCurrencyPipe,
    FncFullCurrencyPipe,
    FncDatePipe,
    DecimalPipe,
    FncRolePipe,
    ListSkipPipe,
    YearsAgoPipe,
    DateAgoPipe,
    TrimPipe,
    NotPipe,
    IfPipe,
    FncInvoiceType,
    Autosize,
    ValidateIbanDirective,
    TooltipDirective,
    FilterPipe,
    CapitalizePipe,
    ClickOutsideDirective,
    NoScrollInputDirective,
    NoEulersNumberAllowedDirective,
    OrderBy,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: SecureHttp,
          useFactory: httpFactory,
          deps: [HttpHandler, SecurityService, Router],
        },
        {
          provide: LaravelClient,
          useFactory: laravelServiceFactory,
          deps: [HttpHandler, ApiGateway, JwtService],
        },
        HelperProvider,
        ApiGateway,
        SecurityService,
        TranslateRouteService,
        TranslatePersist,
        TranslateHelper,
        LoginService,
        FileService,
        DebugService,
        LoginGuard,
        ConfirmLeaveOnChangeGuard,
        PaywallGuard,
        AnnotationGuard,
        JwtService,
        EmailValidationService,
      ],
    }
  }
}
