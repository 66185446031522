import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations'
import { globals } from '../config/globals'

const speed = globals.animations.speed.default

export const onboarding_animations: AnimationTriggerMetadata[] = [
  trigger('routeAnimation', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 1 }), animate(speed)]),
    transition(':leave', animate(speed, style({ opacity: 0 }))),
  ]),

  trigger('shrinkOut', [
    state('in', style({ opacity: 0, height: 0 })),
    transition(':enter', [style({ height: '*', opacity: 1 }), animate(speed)]),
    transition(':leave', [style({ height: '*', opacity: 1 }), animate(speed, style({ height: 0, opacity: 0 }))]),
  ]),
]

export const step_animation: AnimationTriggerMetadata[] = [
  trigger('stepAnimation', [
    state('*', style({ 'max-height': '1500px', opacity: 1 })),
    transition(':enter', [style({ 'max-height': '0px', opacity: 0 }), animate(speed)]),
    transition(':leave', animate(0, style({ 'max-height': 0, opacity: 0 }))),
  ]),
]

export const error_animation: AnimationTriggerMetadata[] = [
  trigger('errorAnimation', [
    state('*', style({ height: '24px', opacity: 1 })),
    transition(':enter', [style({ height: '0px', opacity: 0 }), animate(speed)]),
    transition(':leave', animate(speed, style({ opacity: 0 }))),
  ]),
]

export const fade_animation: AnimationTriggerMetadata[] = [
  trigger('fadeInOut', [
    transition(':enter', [
      // :enter is alias to 'void => *'
      style({ opacity: 0 }),
      animate(speed, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      // :leave is alias to '* => void'
      animate(speed, style({ opacity: 0 })),
    ]),
  ]),
]

export const onboarding_host = {
  '[@routeAnimation]': 'true',
  '(window:resize)': 'resizeContent()',
}
