import moment from 'moment'

export class InvoiceNumberBuilder {
  private static regex = /(\{(.*?)\}|.)/g

  fromTemplateToBuilderBlocks(template: string, date?: moment.Moment, index?: number): BuilderBlock[] {
    if (!template) {
      throw new Error('Template cannot be null or undefined')
    }

    const result = []

    let m: RegExpExecArray
    while ((m = InvoiceNumberBuilder.regex.exec(template)) !== null) {
      const match = m[0]

      if (match.contains('{d:')) {
        // Date block
        result.push(new BuilderBlock(BuilderBlockType.Date, match, date || moment()))
      } else if (match.contains('{num:')) {
        // Number (index) block
        result.push(new BuilderBlock(BuilderBlockType.Index, match, index || 1))
      } else {
        // Other symbols
        result.push(new BuilderBlock(BuilderBlockType.Symbol, match))
      }
    }

    return result
  }

  fromBuilderBocksToTemplate(blocks: BuilderBlock[]): string {
    return blocks.map((b) => b.template).join('')
  }

  getBuilderBlocksDisplayValue(blocks: BuilderBlock[], date?: moment.Moment, index?: number): string {
    return blocks
      .map((b) => {
        switch (b.type) {
          case BuilderBlockType.Date:
            return b.getDisplayValue(date)
          case BuilderBlockType.Index:
            return b.getDisplayValue(index)
          case BuilderBlockType.Symbol:
            return b.getDisplayValue()
          default:
            break
        }
      })
      .join('')
  }
}

export enum BuilderBlockType {
  // Prefix,
  Date,
  Index,
  Symbol,
}

export class BuilderBlock {
  // Either the dateformat or number padding.
  private templateInfo: any

  constructor(public type: BuilderBlockType, public template: string, public value?: any) {
    this.setInfo(type, template)
  }

  setTemplate(template: string): void {
    this.template = template
    this.setInfo(BuilderBlockType.Index, template)
  }

  setInfo(type?: BuilderBlockType, template?: string): void {
    if (type == BuilderBlockType.Index || type == BuilderBlockType.Date) {
      const info = template.split(':')[1].replace('}', '')

      if (type == BuilderBlockType.Index) {
        this.templateInfo = parseInt(info)
      } else {
        this.templateInfo = info
      }
    }
  }

  getDisplayValue(value?: any): any {
    switch (this.type) {
      case BuilderBlockType.Symbol:
        return this.template
      case BuilderBlockType.Index:
        return this.getFormattedIndexValue(value || this.value)
      case BuilderBlockType.Date:
        return this.getFormattedDateValue(value || this.value)
      default:
        break
    }
  }

  copy(): BuilderBlock {
    return new BuilderBlock(this.type, this.template, this.value)
  }

  private getFormattedIndexValue(value?: number): string {
    const val = value || 1

    return val.toString().padLeft('0', this.templateInfo)
  }

  private getFormattedDateValue(value: moment.Moment): string {
    const val = value || moment()

    let jsFormat: string

    switch (this.templateInfo) {
      case 'Y':
        jsFormat = 'YYYY'
        break
      case 'y':
        jsFormat = 'YY'
        break
      case 'm':
        jsFormat = 'MM'
        break
      default:
        break
    }

    return val.format(jsFormat)
  }
}
