import { Justification } from '../justification/justification.model'

export enum TransactionState {
  BOOKED = 'BOOKED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  UPCOMING = 'UPCOMING',
  RELEASED = 'RELEASED',
}

export enum TransactionItemStatus {
  BOOKED = 'booked',
  UNRECONCILED = 'unreconciled',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  RELEASED = 'released',
}

export interface TransactionListItem {
  amount: number
  amount_type: 'positive' | 'negative'
  bank_account_id: string
  booking_date: string // YYYY-MM-DD
  currency: string | 'EUR'
  debit: boolean
  description: string
  fully_justified: boolean
  id: number
  is_psd2: true
  justification_in_progress: boolean
  justifications: Justification[]
  justified_amount: number
  lyanthe_status: string | null
  mutation_type: TransactionMutationType
  mutation_code: string | null
  opposite_iban: string | null
  opposite_name: string | null
  origin: string
  period_name: string // DD-MM-YYYY
  psd2_metadata: Psd2Metadata | null
  psd2_transaction_id: string | null
  relevance: string | null
  status: string | null
  status_reason: string | null
  status_remarks: string | null
  trx_date: string // YYYY-MM-DD HH:MM:SS
  user_note: string | null
  transaction_index_of_month: number
  state: TransactionState
}

export interface TransactionMutationType {
  short_description: string
}

export interface Psd2Metadata {
  type: 'SepaCreditTransferIn' | 'SepaCreditTransferOut' | 'SepaInstantCreditTransferOut' | 'SepaInstantCreditTransferIn' | string | undefined
  [key: string]: any
}
