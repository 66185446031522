import { filter, map, mergeMap } from 'rxjs/operators'
import { Injectable, NgZone } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { ApiGateway } from '../../core/remote/api.gateway'
import { DashboardStat } from '../../domain/stats/dashboard-stats.model'
import { AdministrationService } from '../administration/administration.service'
import { endpoints } from '../../shared/config/endpoints'
import { LoginService } from '../../core/security/login.service'
import { AdministrationNotificationService } from '../firebase/administration-notifications.service'
import { SecureHttp } from '../../core/remote/httpclient'
import { VatStats } from '../../domain/stats/vat-stats.model'

@Injectable()
export class StatsService {
  get documentsInProgress(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.documents_in_progress_count))
  }

  get documentsRejected(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.documents_rejected_count))
  }

  get invoicesToRemind(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.sale_invoice_count_to_remind))
  }

  get expensesWithoutReceipt(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.no_receipt_expense_count))
  }

  get openExpensesAmount(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.open_expenses_amount))
  }

  get openSalesAmount(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.open_sales_amount))
  }

  get turnoverCurrentYear(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.turnover_amount_current_year))
  }

  get vatAmountCurrentPeriod(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.vat_amount_current_period))
  }

  get openPurchaseInvoices(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.open_purchase_invoice_count))
  }

  get selfAnnotationRequiredInvoices(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.draft_expenses_count))
  }

  get trxToJustify(): Observable<number> {
    return this.dashboardStatsSubject.pipe(map((stats) => stats.trx_to_justify_count))
  }

  private get dashboardStatsSubject(): ReplaySubject<DashboardStat> {
    if (this._dashboardStatsSubject) {
      return this._dashboardStatsSubject
    }

    this._dashboardStatsSubject = new ReplaySubject<DashboardStat>(1)

    // Initialize fetching and refreshing (every 5 minutes)
    setTimeout(() => {
      this.fetchDashboardStats()

      // Prevent Protractor waiting forever for NgZone to cool down.
      this._ngZone.runOutsideAngular(() => {
        this._interval = setInterval(() => {
          this._ngZone.run(() => {
            this.refresh()
          })
        }, 300000)
      })
    }, 0)

    return this._dashboardStatsSubject
  }

  // Do not reference this private property directly instead use dashboardStatsSubject (private get dashboardStatsSubject())
  private _dashboardStatsSubject: ReplaySubject<DashboardStat>
  private _interval = undefined

  constructor(
    private readonly _login: LoginService,
    private readonly _api: ApiGateway,
    private readonly _ngZone: NgZone,
    private readonly _administration: AdministrationService,
    private readonly _push: AdministrationNotificationService,
    private readonly _https: SecureHttp,
  ) {
    // Clear cache on (re)login
    this._login.onLoggedIn.subscribe(() => this.clearCache())
    this._administration.onSwitchedAdministration.subscribe(() => this.clearCache())

    // Update numbers when a push has been received
    this._push.onAdministrationNotificationReceived.subscribe(() => this.refresh())

    // Clear dashboard stats interval on logout so no new stats are retrieved
    this._login.onLoggedOut.subscribe(() => this.clearInterval())
    this._https.onSessionExpired.subscribe(() => this.clearInterval())
  }

  // Cancel the interval of dashboard stats refresh
  clearInterval(): void {
    if (this._interval) {
      clearInterval(this._interval)
    }
  }

  clearCache(): void {
    this._dashboardStatsSubject = null
  }

  refresh(): void {
    // this.clearCache();
    this.fetchDashboardStats()
  }

  private fetchDashboardStats(): void {
    this._administration.defaultAdministration
      .pipe(
        filter((administration) => administration != null),
        mergeMap((administration) => this._api.get<DashboardStat>(endpoints.dashboard.stats, { administrationId: administration.id })),
      )
      .subscribe((stats) => this.dashboardStatsSubject.next(stats))
  }

  getVatStats(): Observable<VatStats> {
    return this._administration.defaultAdministration.pipe(
      filter((administration) => administration != null),
      mergeMap(({ id }) => this._api.get<VatStats>(endpoints.dashboard.vatStats, { administrationId: id })),
    )
  }
}
