<tlw-auxilary-modal variant="medium">
  <div class="email-verification-modal">
    <!-- Modal title and back button -->
    <div class="email-verification-modal--header">
      <a (click)="auxilary.close()">
        <svg viewBox="0 0 20 21" fill="inherit" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.70679 17.2072C9.51926 17.3947 9.26495 17.5 8.99979 17.5C8.73462 17.5 8.48031 17.3947 8.29279 17.2072L2.29279 11.2072C2.10532 11.0197 2 10.7654 2 10.5002C2 10.235 2.10532 9.98074 2.29279 9.79321L8.29279 3.79321C8.48139 3.61105 8.73399 3.51026 8.99619 3.51254C9.25838 3.51482 9.5092 3.61999 9.69461 3.8054C9.88001 3.9908 9.98518 4.24162 9.98746 4.50381C9.98974 4.76601 9.88894 5.01861 9.70679 5.20721L5.41379 9.50021L16.9998 9.50021C17.265 9.50021 17.5194 9.60557 17.7069 9.79311C17.8944 9.98064 17.9998 10.235 17.9998 10.5002C17.9998 10.7654 17.8944 11.0198 17.7069 11.2073C17.5194 11.3949 17.265 11.5002 16.9998 11.5002L5.41379 11.5002L9.70679 15.7932C9.89426 15.9807 9.99957 16.235 9.99957 16.5002C9.99957 16.7654 9.89426 17.0197 9.70679 17.2072Z"
            fill="inherit"
          />
        </svg>
      </a>
      <h2>{{ 'MODALS.EMAIL_VERIFICATION.TITLE' | translate }}</h2>
    </div>

    <!-- Modal body with icon -->
    <div class="email-verification-modal--body">
      <svg viewBox="0 0 80 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.2381 13.7935C17.5591 13.001 19.2744 13.4289 20.067 14.7498C20.8595 16.0707 20.4316 17.7861 19.1107 18.5786L7.84 25.3433C6.43721 26.1805 5.5814 27.6949 5.5814 29.3284V66.0466C5.5814 67.2819 6.07256 68.4614 6.94326 69.3359C7.81767 70.2066 8.99721 70.6977 10.2326 70.6977H69.7674C71.0028 70.6977 72.1823 70.2066 73.0567 69.3359C73.9274 68.4614 74.4186 67.2819 74.4186 66.0466V29.3284C74.4186 27.6949 73.5628 26.1805 72.16 25.3396L60.8893 18.5786C59.5684 17.7861 59.1405 16.0707 59.933 14.7498C60.7256 13.4289 62.4409 13.001 63.7619 13.7935L75.0326 20.5545C78.1135 22.4038 80 25.734 80 29.3284V66.0466C80 68.7591 78.9209 71.3638 77.0047 73.2838C75.0847 75.2 72.48 76.2791 69.7674 76.2791H10.2326C7.52 76.2791 4.91535 75.2 2.99535 73.2838C1.07907 71.3638 0 68.7591 0 66.0466V29.3284C0 25.734 1.88651 22.4038 4.96744 20.5545L16.2381 13.7935Z"
          fill="#002E33"
        />
        <path d="M19 3H61V40L40 49L19 40V3Z" fill="#DBD4FF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65.1163 37.7674C65.1163 39.3079 63.8661 40.5581 62.3256 40.5581C60.7852 40.5581 59.535 39.3079 59.535 37.7674V6.51163C59.535 6.26605 59.4382 6.02791 59.2633 5.85302C59.0884 5.67814 58.8503 5.5814 58.6047 5.5814H21.3954C21.1498 5.5814 20.9117 5.67814 20.7368 5.85302C20.5619 6.02791 20.4652 6.26605 20.4652 6.51163V37.7674C20.4652 39.3079 19.215 40.5581 17.6745 40.5581C16.134 40.5581 14.8838 39.3079 14.8838 37.7674V6.51163C14.8838 4.78512 15.5684 3.1293 16.7926 1.90884C18.0131 0.684651 19.6689 0 21.3954 0H58.6047C60.3312 0 61.987 0.684651 63.2075 1.90884C64.4317 3.1293 65.1163 4.78512 65.1163 6.51163V37.7674Z"
          fill="#DBD4FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.37674 31.7361C0.048373 30.9584 -0.394418 29.2468 0.383257 27.9184C1.16465 26.59 2.87628 26.1435 4.20465 26.9249L33.7935 44.2868C37.6112 46.5268 42.3442 46.5007 46.1358 44.2272L75.773 26.4449C77.0939 25.6523 78.8093 26.0802 79.6019 27.4012C80.3944 28.7221 79.9665 30.4375 78.6456 31.23L49.0084 49.0123C43.4642 52.3388 36.547 52.3723 30.9693 49.1016L1.37674 31.7361Z"
          fill="#002E33"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.9249 45.1906C49.9091 44.0334 50.0244 42.2659 51.1816 41.2501C52.3389 40.2343 54.1063 40.3496 55.1221 41.5068L78.3109 67.9254C79.3268 69.0826 79.2114 70.8501 78.0542 71.8659C76.897 72.8817 75.1295 72.7664 74.1137 71.6092L50.9249 45.1906Z"
          fill="#002E33"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.351 41.957C26.3668 40.7998 28.1305 40.6845 29.2877 41.7003C30.4449 42.7161 30.5603 44.4798 29.5445 45.637L6.75004 71.6091C5.73422 72.7663 3.96678 72.8817 2.80957 71.8659C1.65236 70.85 1.53702 69.0826 2.55283 67.9254L25.351 41.957Z"
          fill="#002E33"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.9817 25.9722C26.8915 24.8857 26.8915 23.1146 27.9817 22.028C29.0682 20.9378 30.8394 20.9378 31.9259 22.028L36.6515 26.7499L48.0748 15.3304C49.1613 14.2401 50.9324 14.2401 52.0189 15.3304C53.1092 16.4169 53.1092 18.188 52.0189 19.2746L38.6236 32.6699C37.5334 33.7601 35.7696 33.7601 34.6794 32.6699L27.9817 25.9722Z"
          fill="#002E33"
        />
      </svg>
      <p *ngIf="!isOnSendScreen">{{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.MAIN_NOTIFICATION' | translate }}</p>
      <p *ngIf="isOnSendScreen">{{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.MAIN_SEND_SCREEN' | translate : { entity: entityName | lowercase } }}</p>
      <p *ngIf="!(isCounterInProgress$ | async)">
        {{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.HAVENT_RECEIVED' | translate }}
        <br />
        {{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.SPAM_OR_RESEND' | translate }}
      </p>
      <p *ngIf="isCounterInProgress$ | async">
        {{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.STILL_HAVENT_RECEIVED' | translate }}
        <br />
        {{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.WAIT' | translate }}
        <b>{{ getCounterCurrentValue$ | async }}</b>
        {{ 'MODALS.EMAIL_VERIFICATION.DESCRIPTION.BEFORE_TRYING_AGAIN' | translate }}
      </p>
    </div>

    <!--
      Allow users to resend the email.
     -->
    <div class="email-verification-modal--footer">
      <fnc-button (fncClick)="resendVerificationEmail()" [disabled]="(resendingEmail$ | async) || (isCounterInProgress$ | async)" big purple wide>
        {{ 'MODALS.EMAIL_VERIFICATION.CTA' | translate }}
      </fnc-button>
    </div>
  </div>
</tlw-auxilary-modal>
