import { Injectable } from '@angular/core'

@Injectable()
export class BrowserService {
  static BrowserWarningDismissedKey = 'dismissed_browser_warning'

  static get userAgent(): string {
    return window.navigator.userAgent.toLowerCase()
  }

  static dismissWarning(key: string): void {
    localStorage.setItem(key, 'true')
  }

  static get isSamsung(): boolean {
    return BrowserService.userAgent.contains('samsungbrowser')
  }

  static get isIe(): boolean {
    return /msie\s|trident\//i.test(BrowserService.userAgent)
  }

  static get isSafari(): boolean {
    if (BrowserService.userAgent.includes('safari')) {
      return !BrowserService.userAgent.includes('chrome') && !BrowserService.userAgent.includes('crios')
    }

    return false
  }

  static get isChrome(): boolean {
    return BrowserService.userAgent.includes('chrome')
  }

  static getWarningIsDismissed(key: string): boolean {
    return !!localStorage.getItem(key)
  }
}
