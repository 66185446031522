import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { FormDirective } from '../html-directives/form.directive'
import { TlwFormChildComponent } from '../tlw-form-child.component'

@Component({
  selector: 'tlw-button',
  styleUrls: ['./tlw-button.component.scss'],
  host: {
    '(click)': 'clickEvent($event)',
    '(mouseenter)': 'mouseEnterEvent($event)',
  },
  template: `
    <button
      #button
      class="button"
      [class.busy]="showSpinnerElement"
      [class.with-icon]="icon"
      [class.icon-only]="iconOnly"
      [disabled]="disabled"
      (click)="btnClicked($event)"
      [attr.type]="isSubmitButton ? 'submit' : 'button'"
    >
      <div class="spinner-container" *ngIf="showSpinnerElement">
        <div #spinner class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active"></div>
      </div>
      <ng-content></ng-content>
      <tlw-icon class="icon" *ngIf="icon" [icon]="icon" height="18"></tlw-icon>
    </button>
  `,
})
export class TlwButtonComponent extends TlwFormChildComponent implements OnInit, AfterViewInit {
  get disabled() {
    return this._disabled || this._formBusy
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled
  }

  @Input()
  set showSpinnerOnFormBusy(show: boolean) {
    this._showSpinnerOnFormBusy = show
  }

  @Input()
  set showSpinner(showSpinner: boolean) {
    this.setShowSpinner(showSpinner)
  }

  @ViewChild('spinner') spinner: ElementRef
  @ViewChild('button', { static: true }) button: ElementRef

  @HostBinding('class')
  @Input()
  fontSize: 'default' | 'medium' | 'large' = 'default'

  @Input() isSubmitButton: boolean
  @Input() iconOnly: boolean
  @Input() icon: string

  @Output() fncClick = new EventEmitter<any>()
  @Output() fncMouseOver = new EventEmitter<any>()

  showSpinnerElement: boolean
  private _formBusy: boolean
  private _disabled: boolean
  private _showSpinnerOnFormBusy: boolean

  constructor(
    @Optional() @Inject(forwardRef(() => FormDirective)) parentForm: FormDirective,
    @Optional() @Inject(forwardRef(() => NgForm)) private _ngForm: NgForm,
    element: ElementRef,
  ) {
    super(element, parentForm)
  }

  ngOnInit(): void {
    if (this.parentForm) {
      this.parentForm.onBusy.subscribe(() => this.onFormStateUpdate())
    }
  }

  ngAfterViewInit(): void {
    if (this.button && this.button.nativeElement.innerHTML && this.button.nativeElement.innerHTML.length) {
      let text = this.button.nativeElement.innerHTML.toString()
      text = text.substr(0, text.indexOf('<'))
      this.button.nativeElement.title = text.trim()
    }
  }

  clickEvent(event: any): void {
    if (!this.disabled) {
      if (this.isSubmitButton && this.parentForm && !this._ngForm) {
        this.parentForm.element.submit()

        return
      }

      this.fncClick.emit(event)
    }
  }

  mouseEnterEvent(event: any): void {
    void this.fncMouseOver.emit(event)
  }

  onFormStateUpdate(): void {
    this._formBusy = this.parentForm.busy

    if (this._showSpinnerOnFormBusy && this._formBusy) {
      this.setShowSpinner(true)
    } else if (this._showSpinnerOnFormBusy) {
      this.setShowSpinner(false)
    }
  }

  btnClicked(event: any): void {
    if (this._disabled || !this.isSubmitButton) {
      event.preventDefault()
    }
  }

  private setShowSpinner(show: boolean): void {
    this.showSpinnerElement = show

    if (show) {
      setTimeout(() => {
        if (this.spinner) {
          componentHandler.upgradeElement(this.spinner.nativeElement)
          this.spinner.nativeElement.classList.add('show')
        }
      }, 0)
    }
  }
}
