import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'
import { SliderOption } from '../../../ui-components/tlw-slider/tlw-slider.component'

const KEY = 'Tellow-App-Language'
type Language = 'nl' | 'en'

@Injectable()
export class TranslatePersist {
  /**
   * Get the current language from
   * the language service (ngx-translate).
   */
  get language(): Language {
    return this._translate.currentLang as Language
  }

  set language(value: Language) {
    void this.setLanguage(value)
  }

  /**
   * Return the stored language.
   */
  get storedLanguage(): Language {
    return localStorage[KEY]
  }

  /**
   * Note that these options are both in their respective language.
   * They do not have to be translated, as you will want to click
   * on the language to appear in the customers native language.
   */
  public options: SliderOption<Language>[] = [
    { label: 'Nederlands', value: 'nl', abbreviatedLabel: 'NL' },
    { label: 'English', value: 'en', abbreviatedLabel: 'EN' },
  ]

  /**
   * Check whether the localStorage has a
   * stored translation key to utilize.
   */
  get hasStoredLanguage(): boolean {
    return Boolean(localStorage[KEY])
  }

  constructor(private readonly _translate: TranslateService) {}

  /**
   * Initialize all i18n-related functionalities.
   */
  public init(): void {
    void moment.locale('nl')

    void this._translate.setDefaultLang('nl')
    void this._translate.addLangs(['nl', 'en'])
    void this.setLanguage(localStorage[KEY] ?? 'nl')

    void this._translate.onLangChange.subscribe((language) => {
      void this._storeLanguage(language.lang as Language)
    })
  }

  public setPreferredLanguageAfterFetchingSettings(language: Language): void {
    if (language) {
      this.setLanguage(language)

      return
    }

    if (this.hasStoredLanguage) {
      this.setLanguage(this.storedLanguage)

      return
    }

    this.setLanguage('nl')
  }

  /**
   * Set the language. Mind that we use the 'use'-method.
   * Using 'setDefaultLang' triggers a different emitter,
   * which is not what we want to happen
   * (we use 'onLangChange').
   */
  public setLanguage(language: Language): void {
    console.debug('Setting language to', language)
    void this._translate.use(language)
  }

  /**
   * Store the selected language
   * to the users localStorage.
   */
  private _storeLanguage(language: Language): void {
    void this._translate.setDefaultLang(language)
    if (localStorage) localStorage.setItem(KEY, language)
  }
}
