import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs'
import { catchError, map, mergeMap, publishReplay, refCount } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http'
import { endpoints } from '../../shared/config/endpoints'
import { environment } from '../../../environments/environment'
import { version } from '../../../environments/version'
import { TellowRequestOptionsArgs } from './httpclient'
import { JwtService } from '../security/jwt.service'
import { ApiGateway } from './api.gateway'
import { Router } from '@angular/router'

@Injectable()
export class LaravelClient extends HttpClient {
  private _refreshInProgressObservable: Observable<boolean>

  constructor(handler: HttpHandler, private readonly _api: ApiGateway, private readonly _jwt: JwtService, private _router: Router) {
    super(handler)
  }

  get<T>(url: string, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.get<T>(url, authOptions), options)
  }

  post<T>(url: string, body: any, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.post<T>(url, body, authOptions), options)
  }

  put<T>(url: string, body: string | object, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.put<T>(url, body, authOptions), options)
  }

  delete<T>(url: string, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.delete<T>(url, authOptions), options)
  }

  patch<T>(url: string, body: any | null, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.patch<T>(url, body, authOptions), options)
  }

  // Security interceptor.
  private intercept<T>(requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>, options?: TellowRequestOptionsArgs): Observable<T> {
    // If necessary wait for a blocking flow to be completed.
    if (this._refreshInProgressObservable) {
      return this._refreshInProgressObservable.pipe(mergeMap(() => this.executeInterceptedRequest<T>(requestFunc, options)))
    }

    return this.executeInterceptedRequest<T>(requestFunc, options)
  }

  private executeInterceptedRequest<T>(requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>, options?: TellowRequestOptionsArgs): Observable<T> {
    return requestFunc(this.getRequestOptionArgs(options)).pipe(
      catchError((err) => {
        if (err.status == 401 && err.url.startsWith(environment.laravelServiceApiUrl)) {
          return this.attemptRefresh().pipe(mergeMap((result) => this.recoverAfterRefresh<T>(result, requestFunc, options)))
        }

        return observableThrowError(err)
      }),
    )
  }

  // get/set access token
  private getRequestOptionArgs(options: TellowRequestOptionsArgs = {}): TellowRequestOptionsArgs {
    if (options == null) {
      options = <TellowRequestOptionsArgs>{}
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
    }

    ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers)
      .append('X-Referer', `${window.location.protocol}//${window.location.host}${window.location.pathname}`)
      .append('X-App', `WEB:${version}`)

    if (sessionStorage.getItem('window') !== null) {
      ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('X-Window', sessionStorage.getItem('window'))
    }

    if (this._jwt.hasJWT) {
      ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('Authorization', `Bearer ${this._jwt.jwt}`)
    }

    return options
  }

  private attemptRefresh(): Observable<boolean> {
    if (this._refreshInProgressObservable) {
      return this._refreshInProgressObservable
    }

    this._refreshInProgressObservable = this._api.get(endpoints.login.jwt).pipe(
      map(({ token }: { token: string }) => token),

      // Save on success
      map((token: string) => {
        this._jwt.saveJWT(token)
        this._refreshInProgressObservable = null

        return true
      }),

      catchError(() => {
        this._jwt.clearJWT()

        return observableOf(false)
      }),
      publishReplay(1),
      refCount(),
    )

    return this._refreshInProgressObservable
  }

  private recoverAfterRefresh<T>(
    result: boolean,
    requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>,
    options?: TellowRequestOptionsArgs,
  ): Observable<T> {
    if (result) {
      // If refresh succeeded, then retry request.
      if (options) {
        if (options.headers) {
          options.headers = (<HttpHeaders>options.headers).delete('Authorization')
        }
      }

      return this.executeInterceptedRequest(requestFunc, options)
    }

    // If refresh failed. return to login page after doing a logoff call..
    // It'll probably never happen here as a failed refresh will already logot in the api gateway.
    this.delete(environment.apiUrl + endpoints.login.logout).subscribe(
      () => {
        this.afterSessionExpired()
      },
      (err) => {
        this.afterSessionExpired()
        console.error('Refresh failed, returning to login page', err)
      },
    )
  }

  private afterSessionExpired() {
    this._jwt.clearJWT()
    void this._router.navigate([''])
    localStorage.removeItem('intercom')
    localStorage.removeItem('intercom-state')

    return observableThrowError('Session expired')
  }
}
