import { EventEmitter, Injectable } from '@angular/core'
import { AdministrationService } from '../administration/administration.service'
import userflow from 'userflow.js'
import { forkJoin } from 'rxjs'
import { UserService } from '../user/user.service'
import { environment } from '../../../environments/environment'
import { LoginService } from '../../core/security/login.service'
import { User, SourceBusterAttribution } from '../../domain/user/user.model'
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector'
import { TranslatePersist } from '../../core/logic/i18n/translate-persist.service'
import { BillingService } from '../administration/billing.service'

type SnakeCaseToPascalCase<S extends string> = S extends `${infer A}_${infer B}` ? `${Capitalize<A>}${SnakeCaseToPascalCase<B>}` : Capitalize<S>

type PrefixedKey<T extends string, P extends string> = `${Lowercase<P>}${Capitalize<T>}`
type PrefixedObject<T extends string, P extends string> = {
  [key in PrefixedKey<T, P>]: string
}

type Attribution = PrefixedObject<keyof SourceBusterAttribution, 'attribution'>
type Device = PrefixedObject<SnakeCaseToPascalCase<keyof DeviceInfo>, 'device'>

@Injectable({
  providedIn: 'root',
})
export class UserflowHelper {
  onChecklistCompleted = new EventEmitter<void>()

  constructor(
    private readonly _login: LoginService,
    private readonly _user: UserService,
    private readonly _administration: AdministrationService,
    private readonly _billing: BillingService,
    private readonly _device: DeviceDetectorService,
    private readonly _i18n: TranslatePersist,
  ) {
    this._login.onLoggedOut.subscribe(() => this.reset())
    this._user.onIdentifyUser.subscribe(() => this.identify())

    this.init()
  }

  protected init(): void {
    void userflow.init(environment.userflowToken)

    userflow.on('checklistEnded', (event) => {
      if (event.checklist.id === '98b35e0c-8efc-4f3c-88f1-be1779b54cec') {
        this._user.toggleSignUps({ completedChecklist: true }).subscribe(() => {
          this.onChecklistCompleted.emit()
        })
      }
    })
  }

  public start(id: string): void {
    void userflow.start(id)
  }

  public identify(): void {
    const settings = this._administration.defaultAdministrationSettings
    const user = this._user.user
    const billing = this._billing.getInfo(true)

    forkJoin([user, settings, billing]).subscribe(
      ([u, s, b]) =>
        void userflow.identify(`${u.id}`, {
          device_type_string: this._device.isMobile() ? 'mobile' : 'desktop',
          locale_code: this._i18n.language ?? 'nl',
          signed_up_at: new Date(u.created_at.replace(/\s/, 'T')).toISOString(),
          legal_form: s.legal_form,
          origin: u.origin_id,
          name: u.first_name,
          role_id: u.role,
          email: u.email,
          current_plan: b.data.plan?.name ?? null,

          ...this.getDeviceInfo(),
          ...this.getAttribution(u),
        }),
    )
  }

  public endAll(): void {
    void userflow.endAll()
  }

  public reset(): void {
    void userflow.reset()
  }

  /**
   * Transform attribution object into consumable keys,
   * since Userflow does not allow values to be objects.
   * Note that the attribution may be 'null'.
   * Protect with an empty object (since null
   * cannot be used in object entries).
   */
  private getAttribution(user: User): Attribution {
    return Object.entries(user.registration_attribution_current ?? {}).reduce(
      (acc, [k, v]) => ({ ...acc, [`attribution${this.capitalize(k)}`]: v }),
      {} as Attribution,
    )
  }

  /**
   * Get additional device information like os and type.
   * Can be used to filter out 'iOS'-only, for instance.
   */
  private getDeviceInfo(): Device {
    return Object.entries(this._device.getDeviceInfo() ?? {}).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [`device${this.capitalize(this.removeSnakeCasing(k))}`]: v,
      }),
      {} as Device,
    )
  }

  /**
   * Capitalize a string. Easy peasy.
   */
  private capitalize(value: string): string {
    return value.replace(/^\p{CWU}/u, (char) => char.toUpperCase())
  }

  /**
   * Remove snake case.
   */
  private removeSnakeCasing(value: string): string {
    return value.replace(/_(.{1})/, (_, group) => {
      return group.toUpperCase()
    })
  }
}
