import { trigger, transition, style, animate } from '@angular/animations'
import { Location } from '@angular/common'
import { AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from 'rxjs'
import { distinctUntilChanged, map, startWith } from 'rxjs/operators'
import ResizeObservable from './resize.observable'

export enum AuxilaryContext {
  Onboarding = 'onboarding',
  EmailVerification = 'email-verification',
}

const animations = [
  trigger('appear', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scale(0.975) translateY(-.1rem)' }),
      animate('.3s ease-in-out', style({ opacity: 1, transform: 'scale(1) translateY(0rem)' })),
    ]),
  ]),
]

/**
 * Auxilary Modal.
 * This enables to have modals with internal routes,
 * that can be fired without the need for weird
 * query parameter setups.
 *
 * @see https://medium.com/@zainzafar/routable-modals-in-angular-64fb213199c7
 * @see https://www.bennadel.com/blog/3620-most-of-your-modal-windows-should-be-directly-accessible-by-route-in-angular-7-2-15.htm
 */
@Component({
  selector: 'tlw-auxilary-modal',
  template: `
    <div #auxilary [@appear] [class]="variant" [style.margin-top]="getCalculatedTopMargin$ | async" class="container">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['tlw-auxilary-modal.component.scss'],
  exportAs: 'modal',
  animations,
})
export class TlwAuxilaryModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('auxilary', { static: true }) auxilary: ElementRef<HTMLDivElement>
  @Input() variant: 'small' | 'medium' | 'large' = 'small'

  /**
   * When clicking on the backdrop (e.g. nodeName 'TLW-AUXILARY-MODAL')
   * close the modal. Keep track of this name through the 'ElementRef'.
   */
  @HostListener('click', ['$event.target'])
  onLocalClick(target: HTMLElement) {
    if (target.nodeName === (this.el.nativeElement as HTMLElement).nodeName) {
      this.close()
    }
  }

  /**
   * Keep track of the ResizeObserver,
   * so we can destroy it later on.
   */
  private _resizeSubscription$: Subscription

  /**
   * Observables to observe the sizes
   * of elements and the window + initial load.
   */
  protected onComponentResize$ = new BehaviorSubject<void>(undefined)
  protected onWindowResize$ = fromEvent(window, 'resize')
  protected onWindowLoad$ = fromEvent(window, 'load')

  /**
   * Public state, so we can share it with children.
   * For example, used to pass on 'context'.
   */
  public state: any

  /**
   * Add top margin:
   * Get the center and add 'element / 2' to it.
   */
  protected getCalculatedTopMargin$: Observable<string> = merge(this.onComponentResize$.asObservable(), this.onWindowResize$, this.onWindowLoad$).pipe(
    startWith(() => this.calculate),
    map(() => this.calculate),
    distinctUntilChanged(),
  )

  /**
   * Calculate the top-margin to
   * offset the modal to the center.
   */
  private get calculate(): string {
    const containerHeight = this.auxilary?.nativeElement?.clientHeight
    const windowHeight = (window as Window).innerHeight

    const topMargin = windowHeight / 2 - containerHeight / 2

    return `${topMargin < 0 ? 0 : topMargin}px`
  }

  constructor(
    private el: ElementRef,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly ngZone: NgZone,
    private readonly _location: Location,
  ) {
    this.state = this._location.getState()
  }

  /**
   * Lifecycle.
   * After the content is visible, start subscribing
   * to the size of the inner content of the modal,
   * so that we can re-calculate the top margins.
   */
  ngAfterViewInit() {
    this._resizeSubscription$ = this.setUpResizing(this.auxilary.nativeElement).subscribe(() => this.onComponentResize$.next(null))
  }

  ngOnDestroy() {
    void this._resizeSubscription$.unsubscribe()
  }

  /**
   * Open the modal.
   */
  public open(modal: AuxilaryContext): void {
    void this._router.navigate(['./', { outlets: { modal } }])
  }

  /**
   * Close the modal.
   * Assure that the URL is replaced, so you cannot
   * go back into the modal by going back/forward in history.
   *
   * @see https://www.bennadel.com/blog/3351-closing-secondary-router-outlet-views-from-within-the-named-route-view-components-in-angular-4-4-4.htm
   */
  public close(): void {
    const options = { replaceUrl: true, relativeTo: this._route.parent }
    const auxilary = { outlets: { modal: null } }
    void this._router.navigate([auxilary], options)
  }

  /**
   *
   */
  private setUpResizing(element: HTMLElement) {
    return new ResizeObservable(element, this.ngZone)
  }
}
