import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Company } from '../../login/models/company.model'
import { Details } from '../../login/models/business-details.model'
import { SecureHttp } from '../../core/remote/httpclient'
import { TellowRequestOptionsArgs } from '../../core/remote/httpclient'
import { HttpHeaders } from '@angular/common/http'

@Injectable()
export class CompanyService {
  constructor(private readonly _http: SecureHttp) {}

  /**
   * Returns list of companies with a matching name
   * @param companyName
   */
  getCompanies(companyName: string): Observable<Company[]> {
    const url = `${environment.integrationsServiceApiUrl}/api/kvk/v1/companies?search=${companyName}`

    return this._http.get<Company[]>(url, this.getOptions()).pipe(
      map((res: Company[]) => res),
      catchError(() => of(null)),
    )
  }

  /**
   * Get all details of the company based on the CoC number
   * @param cocNumber The CoC number
   */
  getAllCompanyDetails(cocNumber: number): Observable<Details> {
    // A CoC number can be prefixed with a 0 apparently, and should always be 8 digits,
    // so performing a left pad with 0s.
    const cocNumberString = `0000${cocNumber}`.slice(-8)

    const url = `${environment.integrationsServiceApiUrl}/api/kvk/v1/company/${cocNumberString}`

    return this._http.get<Details>(url, this.getOptions()).pipe(
      map((res: Details) => res),
      catchError(() => of(null)),
    )
  }

  private getOptions(): TellowRequestOptionsArgs {
    // Create options param with the correct apiKey for the endopint
    const options: TellowRequestOptionsArgs = {}
    options.headers = new HttpHeaders()
    options.headers = options.headers.append('apiKey', environment.integrationsServiceApiKey)

    return options
  }
}
