import { Component, ViewChild } from '@angular/core'
import { FncModalComponent } from '../fnc-modal.component'

@Component({
  selector: 'fnc-loading-modal',
  styleUrls: ['./fnc-loading-modal.component.scss'],
  template: `
    <fnc-modal #modal [size]="'large'" [document]="true" [noBorder]="true" [dontCloseOnDocumentClick]="true">
      <modal-content>
        <div class="loading">
          <fnc-loader translatableMessage="MESSAGES.WAIT_A_MOMENT"></fnc-loader>
        </div>
      </modal-content>
    </fnc-modal>
  `,
})
export class FncLoadingModalComponent {
  @ViewChild('modal', { static: true }) modal: FncModalComponent

  close() {
    this.modal.close()
  }

  open() {
    this.modal.open()
  }
}
