export interface Device {
  active: boolean
  intercom_hash: string
  status: string
  version: string
  model: string
  make: string
  os_version: string
}

export enum DeviceType {
  ANDROID = 1,
  IOS = 2,
  WEB = 3,
}

export interface DeviceWithActiveSession {
  type: DeviceType
  device_id: string
  os_version: string
  make: string
  model: string
  app_version: string
  intercom_hash: string
  device_name: string
  ip: string
  location: string
  updated_at: Date
}
