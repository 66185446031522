import { from as observableFrom, Observable } from 'rxjs'
import { filter, map, mergeMap, publishReplay, refCount, tap, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AdministrationService } from './administration.service'
import { AdministrationBankAccount, Bank, BankAccountAdd } from '../../domain/bankAccount/administration-bank-account.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { LoginService } from '../../core/security/login.service'
import { BankAccountTypes } from '../../domain/bankAccount/bank-account-types.constants'
import { Administration } from '../../domain/administration/administration.model'
import { endpoints } from '../../shared/config/endpoints'
import { AccountingService } from '../accounting/accounting.service'
import { LatestTransaction } from '../../domain/transaction/latest-transaction.model'

@Injectable()
export class BankAccountService {
  private _supportBanks: Observable<Bank[]>
  private _administrationAccounts: Observable<AdministrationBankAccount[]>
  private _specificBankId: number

  /**
   * Returns the corresponding bic belonging to the iban.
   */
  static getBicForIban(banks: Bank[], iban: string): string {
    let bic = ''

    banks.map((bank) => {
      if (iban.contains(bank.iban_abbreviation.firstOrUndefined())) {
        bic = bank.bic
      }
    })

    return bic
  }

  constructor(
    private readonly _api: ApiGateway,
    private readonly _administration: AdministrationService,
    private readonly _accountingService: AccountingService,
    private readonly _http: HttpClient,
    private readonly _login: LoginService,
  ) {
    // Clear cache on (re)login
    _login.onLoggedIn.subscribe(() => this.clearCache())
    _administration.onSwitchedAdministration.subscribe(() => this.clearCache())
  }

  get administrationAccounts(): Observable<AdministrationBankAccount[]> {
    if (this._administrationAccounts) {
      return this._administrationAccounts
    }

    this._administrationAccounts = this._administration.defaultAdministration.pipe(
      filter((admin) => admin != null),
      mergeMap((admin: Administration) =>
        this._api.get<AdministrationBankAccount[]>(endpoints.bankAccounts.administration, {
          administrationId: admin.id,
        }),
      ),
      publishReplay(1),
      refCount(),
    )

    return this._administrationAccounts
  }

  get specificBankId(): number {
    return this._specificBankId
  }

  set specificBankId(id: number) {
    this._specificBankId = id
  }

  get defaultAdministrationCheckingsAccount(): Observable<AdministrationBankAccount> {
    return this.getDefaultAdministrationAccount(BankAccountTypes.CHECKING, this.specificBankId)
  }

  get defaultAdministrationSavingsAccount(): Observable<AdministrationBankAccount> {
    return this.getDefaultAdministrationAccount(BankAccountTypes.SAVINGS)
  }

  get availableIbans(): Observable<string[]> {
    return this.administrationAccounts.pipe(map((accounts: AdministrationBankAccount[]) => accounts.map((a) => a.iban)))
  }

  /**
   * Clear **both** the
   * accounts and the id.
   */
  clearCache() {
    this._administrationAccounts = null
    this._specificBankId = null
  }

  getSupportedBanks(): Observable<Bank[]> {
    if (this._supportBanks) {
      return this._supportBanks
    }

    this._supportBanks = this._api.get<Bank[]>(endpoints.administration.banks).pipe(publishReplay(1), refCount())

    return this._supportBanks
  }

  getSupportedBankId(bankId: number) {
    return this._api.get(endpoints.administration.banks, { bankId: bankId })
  }

  getSupportedImportMethods(bankId: number) {
    return this._api.get(endpoints.administration.bankTypes, { bankId: bankId })
  }

  getAllBankCheckingAccounts(): Observable<AdministrationBankAccount[]> {
    return this.administrationAccounts.pipe(
      map((accounts: AdministrationBankAccount[]) => {
        return accounts.filter((account) => account.type === BankAccountTypes.CHECKING)
      }),
    )
  }

  getAllBankAccounts(): Observable<AdministrationBankAccount[]> {
    return this.administrationAccounts
  }

  clearCacheAndReturnBankAccounts(): Observable<AdministrationBankAccount[]> {
    this._administrationAccounts = null

    // Clear start balance accounts, so new bank-account will be shown when start balance is loaded
    this._accountingService.clearStartBalanceAccounts()

    return this.administrationAccounts
  }

  addBankAccount(account: BankAccountAdd) {
    this._administrationAccounts = null

    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.administration.bankAccountAdd, account, p))
  }

  generateTestSavingsAccount(): Observable<AdministrationBankAccount> {
    return this._administration
      .makeApiCallForDefaultAdministration((p) =>
        this._api.post<AdministrationBankAccount>(
          endpoints.bankAccounts.administration,
          {
            type: BankAccountTypes.SAVINGS,
            iban: this.__generateIban(),
            name: 'TODO: This account has been created by bankaccounts.service.ts',
            bic: 'RABONL2U',
          },
          p,
        ),
      )
      .pipe(tap(() => this.clearCache()))
  }

  getBankAccount(bankAccountId: number): Observable<AdministrationBankAccount> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        return this._api.get<AdministrationBankAccount>(endpoints.bankAccounts.account, {
          administrationId: administration.id,
          bankAccountId,
        })
      }),
    )
  }

  /**
   * Retrieve latest transactionDate for a specific bank account.
   * Endpoints returns the latest transactionDate. If non existent,
   * then the administration start date is returned.
   */
  getBankAccountLatestTransactionDate(bankAccountId: number): Observable<LatestTransaction> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        return this._api.get<LatestTransaction>(endpoints.transactions.dateLastTransaction, {
          administrationId: administration.id,
          bankAccountId,
        })
      }),
    )
  }

  editOrderOfBankAccounts(bankAccounts: number[]): Observable<AdministrationBankAccount[]> {
    return this._administration.makeApiCallForDefaultAdministration((p) =>
      this._api.post(endpoints.bankAccounts.order, { ...bankAccounts }, p).pipe(
        tap(() => this.clearCache()),
        mergeMap(() => this.getAllBankAccounts()),
      ),
    )
  }

  /**
   * Toggle PSD2 active.
   * @see https://api.test.tellow.nl/doc#post--v2-administration-{administrationId}-bank-accounts-{bankAccountId}-toggle-psd2-active
   */
  togglePsd2Active(bankAccountId: string): Observable<AdministrationBankAccount[]> {
    return this._administration.makeApiCallForDefaultAdministration(
      (p) =>
        this._api.post(endpoints.bankAccounts.togglePsd2Active, undefined, p).pipe(
          tap(() => this.clearCache()),
          mergeMap(() => this.getAllBankAccounts()),
        ),
      { bankAccountId },
    )
  }

  /**
   * Promote non-PSD2 bank account to PSD2.
   * @see https://api.test.tellow.nl/doc#patch--v2-administration-{administrationId}-bank-accounts-{bankAccountId}-promote-to-psd2
   */
  promoteToPsd2(bankAccountId: string): Observable<AdministrationBankAccount[]> {
    return this._administration.makeApiCallForDefaultAdministration(
      (p) =>
        this._api.post(endpoints.bankAccounts.promoteToPsd2, undefined, p).pipe(
          tap(() => this.clearCache()),
          mergeMap(() => this.getAllBankAccounts()),
        ),
      { bankAccountId },
    )
  }

  /**
   * Add new PSD2 bank account.
   * @see https://api.test.tellow.nl/doc#post--v2-administration-{administrationId}-bank-accounts-psd2
   */
  addPsd2BankAccount(iban: string, name: string | undefined = undefined): Observable<AdministrationBankAccount[]> {
    return this._administration.makeApiCallForDefaultAdministration((p) =>
      // In the data object set active flag to true always to automatically enable the connection to the bank
      this._api.post(endpoints.bankAccounts.addPsd2BankAccount, { iban, name: name || iban, active: true }, p).pipe(
        tap(() => this.clearCache()),
        mergeMap(() => this.getAllBankAccounts()),
      ),
    )
  }

  private getDefaultAdministrationAccount(type: string, id?: number): Observable<AdministrationBankAccount> {
    return this.administrationAccounts.pipe(
      mergeMap((accounts) => observableFrom(accounts)),
      filter((account) => {
        if (id) {
          return account.type === type && account.id === id
        }

        return account.type === type
      }),
      toArray(),
      map((accounts) => (accounts.length ? accounts[0] : null)),
    )
  }

  // Test functionality.
  private __generateIban(): string {
    const account = this.__generateAccountNumber()
    const bankCode = '27101124' // RABO (+9)
    const landCode = '2321' // NL (+9);

    const iso7064Mod97_10 = (iban) => {
      let remainder = iban,
        block

      while (remainder.length > 2) {
        block = remainder.slice(0, 9)
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length)
      }

      return parseInt(remainder, 10) % 97
    }

    const checksum = 98 - iso7064Mod97_10(bankCode + '0' + account + landCode + '00')

    return `NL${checksum}RABO0${account}`
  }

  private __generateAccountNumber(num?: number): string {
    const digits: number[] = []

    while (digits.length < 9) {
      digits.push(Math.floor(Math.random() * 10))
    }

    const sumFunc = () => {
      let result = 0

      for (let i = 0; i < digits.length; i++) {
        result += (9 - i) * digits[i]
      }

      return result
    }

    let sum = sumFunc()

    if (sum % 11 != 0) {
      sum = sum - digits[8]

      digits[8] = 11 - (sum % 11)

      if (digits[8] > 9) {
        digits[8] = 0
        digits[7] = digits[7] + 1
      }
    }

    if (sumFunc() % 11 != 0 && (!num || num < 100)) {
      return this.__generateAccountNumber(num !== undefined ? num++ : 0)
    }

    if (!num && num == 100) {
      throw new Error('No valid IBAN found')
    }

    return digits.map((d) => `${d}`).join('')
  }
}
