import { Observable, of as observableOf, of, ReplaySubject } from 'rxjs'
import { catchError, map, mergeMap, publishLast, refCount, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { version } from '../../../environments/version'
import { FirebasePushMessageService } from '../firebase/firebase-push-message.service'
import { Device } from '../../domain/user/device.model'
import { DeviceDetectorService } from 'ngx-device-detector'
import { SecurityService } from '../../core/security/security.service'

export type DeviceTokenBody = {
  type: 3
  device_id: string
  push_token: string
  os_version: string
  make: string
  model: string
  app_version: string
  active?: boolean
}

@Injectable()
export class DeviceService {
  device = new ReplaySubject<any>(1)

  private _checkDeviceObservable: Observable<boolean>
  private _deviceToken: string

  constructor(
    private readonly _apiGateway: ApiGateway,
    private readonly _firebasePushService: FirebasePushMessageService,
    private readonly deviceService: DeviceDetectorService,
    private readonly _security: SecurityService,
  ) {
    this._firebasePushService.onNewPushTokenReceived.pipe(tap((token) => this.updateDevice(token))).subscribe((token: string) => {
      this._deviceToken = token
    })
  }

  /**
   * Get fingerprint from local storage,
   * or generate if not present in local
   * storage.
   */
  getDeviceFingerPrint(): string {
    if (this._security.hasDeviceID) {
      return this._security.deviceID
    }

    return this._security.setDeviceID()
  }

  /**
   * Check Device.
   *
   * Allow retries, since the call
   * to this function may throw an
   * error when on login domain.
   */
  checkDevice(): Observable<boolean> {
    if (this._checkDeviceObservable) {
      return this._checkDeviceObservable
    }

    this._checkDeviceObservable = this.registerAndCheckFingerprint().pipe(
      catchError(() => of(false)),
      publishLast(),
      refCount(),
    )

    return this._checkDeviceObservable
  }

  setActiveDevice(): void {
    this._checkDeviceObservable = observableOf(true).pipe(publishLast(), refCount())

    const device = JSON.parse(localStorage.getItem('device'))

    if (device) {
      device.active = true
      localStorage.setItem('device', JSON.stringify(device))
    }
  }

  clearCache(): void {
    this._checkDeviceObservable = null
  }

  getDeviceDetails(): DeviceTokenBody {
    const device = JSON.parse(localStorage.getItem('device'))

    if (device) {
      return device
    }

    const body = this.generateBodyWithToken(this._deviceToken)
    localStorage.setItem('device', JSON.stringify(body))

    return body
  }

  private registerAndCheckFingerprint(): Observable<boolean> {
    /**
     * ! NOTE:
     * Revaluate. The commented out code should work, but needs testing.
     * If that is the case, swap out commented (easier-to-read) code
     * out for the non-commented out code.
     */

    // const getFirebaseToken$ = this._firebasePushService.getToken().pipe(catchError(() => observableOf(null)))
    // const proceedWithoutToken$ = observableOf(null)

    // /**
    //  * Code should be implicitly called as it returns an observable
    //  * that needs to be executed at once in order to proceed.
    //  */
    // return of(this._firebasePushService.hasPermission()).pipe(
    //   mergeMap((hasPermission) => iif(() => hasPermission, getFirebaseToken$, proceedWithoutToken$)),
    //   mergeMap((token: string | null) => this.updateDevice(token)),
    // )

    return (() => {
      if (this._firebasePushService.hasPermission()) {
        return this._firebasePushService.getToken().pipe(catchError(() => observableOf(null)))
      }

      return observableOf(null)
    })().pipe(mergeMap((token: string | null) => (this._security.hasToken ? this.updateDevice(token) : of(false))))
  }

  /**
   * Generate body.
   */
  generateBodyWithToken(token: string | null): DeviceTokenBody {
    return {
      type: 3, // Browser
      device_id: this.getDeviceFingerPrint(),
      push_token: token,
      os_version: `${this.deviceService.os} - ${this.deviceService.os_version}`,
      make: this.deviceService.browser,
      model: this.deviceService.browser_version,
      app_version: version,
      active: true,
    }
  }

  /**
   * Update device by Firebase token.
   */
  private updateDevice(token: string | null): Observable<boolean> {
    const device = JSON.parse(localStorage.getItem('device'))

    // Present in local storage
    if (device?.active) {
      this.device.next(device)
    }

    const body = this.generateBodyWithToken(token)
    localStorage.setItem('device', JSON.stringify(body))

    if (this._security.hasToken && !this._security.hasValidToken && !this._security.hasValidRefreshToken) {
      console.warn('Token expired. Not updating device.')

      return observableOf(true)
    }

    return this._apiGateway.post<Device>(endpoints.device.device, body).pipe(
      tap((result) => this.handleDeviceActivation(result)),
      map((result) => result.status === 'ACTIVE'),
    )
  }

  private handleDeviceActivation(device: Device): void {
    if (device.status === 'ACTIVE') {
      localStorage.setItem('intercom', device.intercom_hash)
      this.device.next(device)
    }
  }
}
