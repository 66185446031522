import { Component, ViewChild } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../environments/environment'
import { DebugService } from './core/logic/debug.service'
import { HelperProvider } from './core/helpers/helper.provider'
import { FncModalComponent } from './ui-components/fnc-modal/fnc-modal.component'
import { BrowserService } from './core/logic/browser.service'
import { StatuspageService } from './logic/external-services/statuspage.service'
import { Statuspage } from './logic/external-services/statuspage.models'
import { fade_animation } from './shared/animations/onboarding.animation'
import { SegmentHelper } from './logic/external-services/segment.helper'
import { AttributionHelper } from './logic/external-services/attribution.helper'
import { OutbrainHelper } from './logic/external-services/outbrain.helper'
import { CloudflareWorkerHelper } from './logic/external-services/cloudflare.helper'
import { UserflowHelper } from './logic/external-services/userflow.helper'
import { ToastrHelper } from './logic/helpers/toastr.helper'
import { AdblockerHelper } from './logic/helpers/adblocker.helper'
import { FirebaseCoreService } from './logic/firebase/firebase-core.service'
import { TranslatePersist } from './core/logic/i18n/translate-persist.service'
import { SecurityService } from './core/security/security.service'
import { JwtService } from './core/security/jwt.service'
import { Subscription, interval } from 'rxjs'

@Component({
  selector: 'app-root',
  animations: [fade_animation],
  template: `
    <router-outlet name="modal"></router-outlet>
    <router-outlet></router-outlet>
    <fnc-modal #debugOutput>
      <modal-content>
        <h2>Debug output:</h2>
        {{ debugMessage }}

        <br />
        <br />
        <span><sup> (Note that only admin users will see this modal for debugging purposes) </sup></span>
      </modal-content>
      <modal-buttons>
        <fnc-button (fncClick)="closeOutput()">{{ 'BUTTONS.CLOSE' | translate }}</fnc-button>
      </modal-buttons>
    </fnc-modal>
    <fnc-modal></fnc-modal>
    <paywall></paywall>
  `,
})
export class AppComponent {
  debugMessage: string
  warningTemporaryDisabled: boolean
  isOnOnboarding = false
  incidents: Statuspage.ViewIncident[] = []
  isLoaded: boolean
  private _jwtRefresher$: Subscription

  @ViewChild('debugOutput') debugModal: FncModalComponent

  constructor(
    helper: HelperProvider,
    debug: DebugService,
    segment: SegmentHelper,
    outbrain: OutbrainHelper,
    attribution: AttributionHelper,
    private statuspage: StatuspageService,
    private readonly _toastr: ToastrHelper,
    private readonly _translate: TranslateService,
    private readonly _adblocker: AdblockerHelper,
    private readonly _jwt: JwtService,
    private readonly _security: SecurityService,
    public translatePersistance: TranslatePersist,
    public worker: CloudflareWorkerHelper,
    public firebase: FirebaseCoreService,
    public userflow: UserflowHelper,
    router: Router,
  ) {
    // Check which page we are so we can show/hide the obsolete browser message
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((data: NavigationEnd) => {
          this.isLoaded = true
          this.isOnOnboarding = data.url.contains(environment.routerLinks.login.index)
        }),
      )
      .subscribe()

    translatePersistance.init()
    segment.loadSegment()
    attribution.init()
    outbrain.init()

    // For AoT purposes, this provider needs to be used so it is detected by the Tree shaking process
    helper.started()

    debug.onDebugOutput.subscribe((output) => {
      console.info(output)
      this.showOutput(output)
    })

    debug.onHttpGetError.subscribe((e) => {
      this._toastr.error(_translate.instant(e.status !== 404 ? 'MESSAGES.REMOTE_ERROR' : 'MESSAGES.NOT_FOUND'))
    })

    this._adblocker.isBlockingTracking().subscribe((isBlocking) => {
      if (isBlocking) {
        // Warn for a half minute; this is urgent.
        this._toastr.warning(
          `
            Je browser lijkt Tellow (deels) te blokkeren,
            dit kan te maken hebben met de instellingen van
            je browser of het gebruik van een adblocker.
            Door app.tellow.nl toe te voegen aan de lijst
            met veilige sites (whitelist) of de blokkering
            op te heffen kan je optimaal gebruikmaken
            van ons platform.
          `,
          'Adblocker waarschuwing',
          { duration: 30 * 1000 },
        )
      }
    })

    // Set window identifier
    sessionStorage.setItem('window', (Math.random() * 100).toString())

    // Your browser might go up in flames
    if (BrowserService.isSamsung) {
      document.documentElement.classList.add('samsung')
    }

    if (this.isIe && !this.warningTemporaryDisabled && !this.isOnOnboarding) this.showIeWarning()
    if (!this.isOnOnboarding) this.fetchIncidents()

    this.consoleWelcomeMessage()
    this.setupTokenEvents()

    // Initial retrieval of the JWT => Fires upon app start / refresh
    if (this._security.hasToken) this._jwt.getJWT(true)
  }

  /**
   * Whether we are Internet Explorer,
   * our favourite browser.
   */
  get isIe(): boolean {
    return BrowserService.isIe
  }

  /**
   * Check for status page incidents.
   * If any, we show the data on top of the page
   */
  fetchIncidents(): void {
    this.statuspage.statusMessages$.subscribe((response) => {
      if (response && response.incidents) {
        response.incidents.filter(({ id }) => !this.getIsDismissed(id)).map((incident) => this.setIncident(incident))
      }
    })
  }

  /**
   * Print info in console.
   */
  consoleWelcomeMessage(): void {
    const fonts = 'Inter, Plus Jakarta Sans, Helvetica Neue, Helvetica'

    const styleIntro =
      `font-family: ${fonts};` +
      'font-size: 1rem;' +
      'background: #002E33;' +
      'color: white;' +
      'text-align: center;' +
      'padding: 0.5rem 1rem;' +
      'width: 100%;' +
      'border-radius: 999rem'

    const styleWarning =
      `font-family: ${fonts};` +
      'font-size: 0.8rem;' +
      'background: #ff8e4f1a;' +
      'color: #ff8e4f;' +
      'text-align: left;' +
      'padding: 0.25rem 1rem;' +
      'border-radius: .4rem'

    console.log('%cTellow: voor freelancers', styleIntro)
    console.log('%cInformatie in dit venster is vertrouwelijk. Geef deze informatie van Tellow nooit aan een vreemde.', styleWarning)
  }

  /**
   * Sets an incident to our incidents array, used to show incident messages.
   * @param incident
   */
  setIncident(incident: Statuspage.Incident): void {
    const existingIncidentIndex = this.incidents.findIndex((i) => i.id === incident.id)

    if (existingIncidentIndex > -1) {
      return
    }

    /**
     * See point 5. of the FAQ for 'onHidden' explanation.
     * @see https://github.com/scttcper/ngx-toastr#faq
     */
    this.incidents.forEach((incident) => this._toastr.warning(incident.message).afterClosed.subscribe(() => this.dismissMessage(incident.id, true)))
  }

  showIeWarning(): void {
    this._translate.get('MAIN.UNSUPPORTED_BROWSERS.INTERNET_EXPLORER').subscribe((x) => {
      this._toastr.warning(x)
    })
  }

  /**
   * Dismisses a specific status message
   * @param key
   * @param storePreference
   */
  dismissMessage(key: string = BrowserService.BrowserWarningDismissedKey, storePreference: boolean = false): void {
    if (storePreference) {
      return BrowserService.dismissWarning(key)
    }

    this.warningTemporaryDisabled = true

    this.getIsDismissed(key)
  }

  /**
   * Returns boolean to check if a specific status message is dismissed by user.
   * @param key
   */
  getIsDismissed(key: string = BrowserService.BrowserWarningDismissedKey): boolean {
    return BrowserService.getWarningIsDismissed(key)
  }

  /**
   * Closes debug output
   */
  closeOutput(): void {
    this.debugModal.close()
    setTimeout(() => (this.debugMessage = ''), 300)
  }

  /**
   * Shows debug output
   * @param output
   */
  showOutput(output: string): void {
    this.debugMessage = output
    this.debugModal.open()

    console.info('Output for modal', this.debugMessage)
  }

  /**
   * Make sure we never show the same messages by tracking them
   * @param index
   * @param item
   */
  trackByFn(index: number, item: Statuspage.ViewIncident): string | number {
    return item && item.id ? item.id : index
  }

  /**
   * Subscribe to token saved and cleared events, and update the JWT accordingly
   */
  private setupTokenEvents(): void {
    SecurityService.TokenSaved.subscribe(() => {
      this._jwt.getJWT(true)
      this._jwtRefresher$ = interval(JwtService.refreshExpiryInSeconds).subscribe(() => this._jwt.getJWT(true))
    })

    SecurityService.TokenCleared.subscribe(() => {
      this._jwt.clearJWT()
      if (this._jwtRefresher$ && !this._jwtRefresher$.closed) this._jwtRefresher$.unsubscribe()
    })
  }
}
