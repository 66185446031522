import { Injectable } from '@angular/core'
import moment from 'moment'
import { globals } from '../../shared/config/globals'
import { AdministrationService } from '../administration/administration.service'
import { UserService } from '../user/user.service'

@Injectable()
export class PrintService {
  administrationName: string
  userName: string

  constructor(private readonly _administrationService: AdministrationService, private readonly _userService: UserService) {}

  getPrintHeader(pageTitle: string, start?: string, end?: string, noDate?: boolean): string {
    this._administrationService.defaultAdministrationSettings.subscribe((res) => (this.administrationName = res.company_name))
    this._userService.user.subscribe((res) => (this.userName = `${res.first_name} ${res.last_name}`))

    let startDate = ''
    let endDate = ''

    if (!noDate) {
      startDate = start ? `${start}` : moment().startOf('year').format(globals.dateFormat)
      endDate = end ? ` - ${end}` : moment().endOf('year').format(globals.dateFormat)
    }

    const date = new Date()
    const currentDateAndTime = `${date.getHours()}:${date.getMinutes()}`

    return `Administratie: ${this.administrationName}, geprint door: ${this.userName}, ${currentDateAndTime}, \n ${pageTitle} ${startDate} ${endDate}`
  }
}
