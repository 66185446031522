import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { Justification } from '../../domain/justification/justification.model'
import { JustificationTypes } from '../../domain/justification/justificationtypes.constants'
import { JustificationStatus } from '../../domain/justification/justificationstatus.constants'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from '../administration/administration.service'
import { endpoints } from '../../shared/config/endpoints'

@Injectable()
export class JustificationService {
  static InvoiceLabelContext = 'INVOICE'
  static TxLabelContext = 'TRANSACTION'
  static SavingsTxLabelContext = 'SAVINGS_TRANSACTION'
  static VatLabelContext = 'VAT'

  private _translations: any

  constructor(private readonly _apiGateway: ApiGateway, private readonly _administrationService: AdministrationService, translator: TranslateService) {
    translator
      .get(['ACCOUNTING.RECONCILIATION.TRANSACTION', 'TRANSACTIONS.INVOICE', 'VAT.SUPPLETION.SUPPLETION', 'ACCOUNTING.PRIVATE', 'DOCUMENTS.BUSINESS'])
      .subscribe((result) => {
        this._translations = {
          TRANSACTION: result['ACCOUNTING.RECONCILIATION.TRANSACTION'],
          INVOICE: result['TRANSACTIONS.INVOICE'],
          SUPPLETION: result['VAT.SUPPLETION.SUPPLETION'],
          PRIVATE: result['ACCOUNTING.PRIVATE'].capitalize(),
          BUSINESS: result['DOCUMENTS.BUSINESS'],
        }
      })
  }

  updateJustification(justification: Justification, description: string): Observable<any> {
    return this._administrationService.makeApiCallForDefaultAdministration(
      (p, o) => this._apiGateway.patch(endpoints.justifications.justification, o, null, p),
      { justificationId: justification.id },
      { remarks_user: description },
    )
  }

  /**
   * Returns a string label to display that represents the given justification.
   * String is based on the justification's state
   * @param justification
   * TODO: optionally pass a context as 2nd argument so we can better decide what label to generate
   */
  justificationLabel(justification: Justification, context: string): string {
    if (context == JustificationService.VatLabelContext && JustificationTypes.PRIVATE == justification.type) {
      return this._translations.PRIVATE
    }

    // If it has a label, return it
    if (justification.booking_label !== null && justification.booking_label.length > 0) {
      return justification.booking_label
    }

    // TODO: Not sure if this needs to be returned.
    // Return user remarks
    if (justification.remarks_user !== null) {
      return justification.remarks_user
    }

    if (JustificationTypes.INVOICE == justification.type) {
      return this.getInvoiceJustifcationTypeLabel(justification, context)
    }

    // NO_INVOICE (invoice from Lyanthe)
    if (justification.type == JustificationTypes.NO_INVOICE && justification.status == JustificationStatus.ACCEPTED) {
      return this.getInvoiceJustifcationTypeLabel(justification, context)
    }

    if (justification.type == JustificationTypes.SUPPLETION) {
      return this._translations.SUPPLETION
    }

    return '- unknown justification -'
  }

  private getInvoiceJustifcationTypeLabel(justification: Justification, context: string): string {
    if (context && context == JustificationService.TxLabelContext) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${this._translations.INVOICE} ${(justification.invoice && justification.invoice.number) || ''}`
    }

    if (context && context == JustificationService.SavingsTxLabelContext) {
      return this._translations.BUSINESS
    }

    if (context && context == JustificationService.InvoiceLabelContext) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${this._translations.TRANSACTION} ${justification.transaction.description}`
    }

    // If a transaction is set, invoice is null and we're currently in details of an invoice
    if (justification.transaction !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${this._translations.TRANSACTION} ${justification.transaction.description}`
    }

    if (justification.invoice !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${this._translations.INVOICE} ${justification.invoice.number || ''}`
    }
  }
}
