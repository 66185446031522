<fnc-modal
  size="xlarge"
  [useBlur]="true"
  [useFoundationColumns]="false"
  [withDefaultPadding]="false"
  [useFoundationRow]="false"
  [useDarkOverlay]="true"
  [withMinHeight]="false"
  [noOverflow]="true"
  #upsellModal
>
  <modal-content>
    <div class="full-screen-modal__content-container">
      <div class="full-screen-modal__content-container__text">
        <div class="full-screen-modal__content-container--pick-a-plan">
          <!-- Plan selection -->
          <ul>
            <li *ngFor="let item of plans | async">
              <fnc-button
                rounded
                (click)="setPlanIndex(item.planId)"
                [disabled]="item.tier < data.tierToUpgradeTo"
                [class.purple]="currentPlanIndex === item.planId"
                [class.white]="currentPlanIndex !== item.planId"
              >
                {{ item.name }}
                <span>
                  {{ item?.lockUserToThisPlanForMonths === 0 ? 1 : 12 }}
                  {{ (item?.lockUserToThisPlanForMonths === 0 ? 'MAIN.MONTH_ABBREVIATED_SINGULAR' : 'MAIN.MONTH_ABBREVIATED_PLURAL') | translate }}
                </span>
              </fnc-button>
            </li>
          </ul>

          <!-- Close icon (for mobile) -->
          <tlw-icon (click)="close()" icon="close" height="18" library="heroicons" variant="mini"></tlw-icon>
        </div>

        <!-- Plan details -->
        <div *ngIf="getCurrentSelectedPlan | async as plan" class="full-screen-modal__content-container__text--plan-info">
          <h1>{{ 'MAIN.TELLOW' | translate }} {{ plan?.name }}</h1>
          <h3>
            {{
              'COMPONENTS.UPSELL_MODAL.PRICING'
                | translate : { price: ((plan?.priceInCents ?? 0) / 100 | fncCurrency), interval: 'MAIN.MONTH' | translate | lowercase }
            }}
          </h3>
          <p>{{ 'COMPONENTS.UPSELL_MODAL.SUBTITLES.' + plan.tier | translate }}</p>

          <!-- Benefits -->
          <ul>
            <li *ngFor="let benefit of benefits[plan.tier]">
              {{ 'COMPONENTS.UPSELL_MODAL.BENEFITS.' + benefit | uppercase | translate }}
            </li>
          </ul>
        </div>

        <!-- Actionables -->
        <div class="full-screen-modal__content-container__text--button-container">
          <p class="tagline">{{ 'COMPONENTS.UPSELL_MODAL.TAGLINES.' + (getCurrentSelectedPlan | async)?.tier | translate }}</p>
          <fnc-button purple refactored-small (click)="confirmUpsell()">
            {{ 'COMPONENTS.UPSELL_MODAL.CHOOSE' | translate : { plan: (getCurrentSelectedPlan | async)?.name } }}
            <tlw-icon icon="arrow-right" height="16" library="heroicons" variant="mini"></tlw-icon>
          </fnc-button>
          <span *ngIf="isCompleetPlan">
            <fnc-button white refactored-small (click)="planCompletePlanCall()">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path
                  stroke-purple
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 5a2 2 0 0 1 2-2h3.28a1 1 0 0 1 .95.68l1.5 4.5a1 1 0 0 1-.5 1.2l-2.26 1.14a11.04 11.04 0 0 0 5.51 5.51l1.13-2.25a1 1 0 0 1 1.21-.5l4.5 1.5a1 1 0 0 1 .68.94V19a2 2 0 0 1-2 2h-1A15 15 0 0 1 3 6V5z"
                />
              </svg>
              {{ 'COMPONENTS.UPSELL_MODAL.PLAN_FREE_CALL' | translate }}
            </fnc-button>
          </span>
          <p *ngIf="!isCompleetPlan" class="questions">{{ 'COMPONENTS.UPSELL_MODAL.QUESTIONS' | translate }}</p>
        </div>
      </div>

      <!-- Image -->
      <div
        class="full-screen-modal__content-container__image"
        style="--background: url({{ '/assets/images/upsell-images/' + images[(getCurrentSelectedPlan | async)?.tier] }})"
      ></div>
    </div>

    <div class="close-icon" (click)="close()">
      <tlw-icon icon="close" height="18" library="heroicons" variant="mini"></tlw-icon>
    </div>

    <!-- Load images so we do not have flash of no-image content. -->
    <div style="position: absolute; height: 0px; width: 0px; visibility: hidden">
      <img *ngFor="let image of images | keyvalue" [attr.src]="'/assets/images/upsell-images/' + images[image.key]" loading="eager" />
    </div>
  </modal-content>

  <modal-message *ngIf="message$ | async" class="warning">
    <p>{{ message$ | async }}</p>
  </modal-message>

  <modal-banner *ngIf="!(message$ | async)" (click)="navigateToBillingOverview()">
    <p>
      {{ 'COMPONENTS.UPSELL_MODAL.BANNER.PLANS' | translate }}
    </p>
    <fnc-button transparent [withTopMargin]="false" style="white-space: pre">
      {{ 'COMPONENTS.UPSELL_MODAL.BANNER.VIEW_ALL' | translate }}
    </fnc-button>
  </modal-banner>
</fnc-modal>

<fnc-confirm-dialogue
  [useHigherZIndex]="true"
  [header]="'SETTINGS.ADMINISTRATION_BILLING_SCREEN.SUBSCRIPTION_UPGRADE' | translate"
  [content]="
    ((getCurrentSelectedPlan | async)?.lockUserToThisPlanForMonths === 0
      ? 'SETTINGS.ADMINISTRATION_BILLING_SCREEN.UPGRADE_MESSAGE'
      : 'SETTINGS.ADMINISTRATION_BILLING_SCREEN.UPGRADE_MESSAGE_ANNUAL'
    ) | translate : { plan: (getCurrentSelectedPlan | async)?.name }
  "
  [cancelKey]="'BUTTONS.BACK' | translate"
  [okKey]="'SETTINGS.ADMINISTRATION_BILLING_SCREEN.UPGRADE' | translate"
  (okButton)="close(); onBillingConfirmation()"
></fnc-confirm-dialogue>
