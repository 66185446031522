import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthorizationService } from '../../../logic/security/authorization/authorization.service'

@Directive({
  selector: '[hideForReadonly]',
})
export class HideForReadonlyDirective implements OnInit {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly _authService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.viewContainer.clear()

    this._authService.hasReadonlyRights().subscribe((result) => {
      if (!result) {
        this.viewContainer.clear()
        this.viewContainer.createEmbeddedView(this.templateRef)
      } else {
        this.viewContainer.clear()
      }
    })
  }
}
