import { Injectable } from '@angular/core'
import { initDatePrototype } from './date.helpers'
import { initStringPrototype } from './string.helpers'
import { initArrayPrototype } from './array.helpers'
import { initNumberPrototype } from './number.helper'
import { environment } from '../../../environments/environment'

@Injectable()
export class HelperProvider {
  constructor() {
    initStringPrototype()
    initDatePrototype()
    initArrayPrototype()
    initNumberPrototype()
  }

  started(): void {
    console.debug(`App version is ${environment.version}`)
  }
}
