import { forkJoin, fromEvent as observableFromEvent, merge as observableMerge } from 'rxjs'
import { Component, ElementRef, EventEmitter, forwardRef, Inject, OnInit, Output, ViewChild } from '@angular/core'
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { LoginService } from '../../core/security/login.service'
import { User } from '../../domain/user/user.model'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { UserService } from '../../logic/user/user.service'
import { AdministrationService } from '../../logic/administration/administration.service'
import { UserRoleTypes } from '../../domain/user/user-role-types.constants'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationSwitcherComponent } from './components/administration-switcher/administration-switcher.component'
import { SessionService } from '../../logic/user/session.service'
import { FncMainLayoutComponent } from '../fnc-main-layout/fnc-main-layout.component'
import { globals } from '../../shared/config/globals'
import { AuthorizationService } from '../../logic/security/authorization/authorization.service'
import { routerlinks } from '../../../environments/routerlinks/routerlinks-nl'

@Component({
  selector: 'fnc-account-menu',
  styleUrls: ['./fnc-account-menu.component.scss'],
  host: {
    '(mouseenter)': 'mouseEnter()',
  },
  template: ` <div class="user">
      <img src="assets/images/default-user.png" *ngIf="!(auth?.role | async)" />
      <span id="role" *ngIf="auth?.role | async">{{ 'USER_ROLES.' + (auth?.role | async) | translate }}</span>
      <span *ngIf="fullName; else skeleton" id="username">{{ fullName }}</span>

      <ng-template #skeleton>
        <span class="skeleton">&nbsp;</span>
      </ng-template>
    </div>
    <ul class="actions">
      <li item (click)="navigateToMyAccount()">{{ 'MAIN.MY_ACCOUNT' | translate }}</li>
      <li item (click)="signOut()" purple space>
        {{ 'MAIN.SIGN_OUT' | translate }}
      </li>
      <li
        class="current-administration"
        #currentAdministration
        [class.has-multiple-administrations]="hasMultipleAdministrations"
        (mouseenter)="openSwitcher(true)"
        (mouseleave)="openSwitcher(false)"
      >
        <p>{{ companyName }}</p>
        <administration-switcher [layout]="layout" *ngIf="hasMultipleAdministrations"></administration-switcher>
      </li>
    </ul>`,
})
export class FncAccountMenuComponent implements OnInit {
  hasMultipleAdministrations: boolean

  @Output() mouseOver = new EventEmitter<boolean>()
  @ViewChild(AdministrationSwitcherComponent) switcher: AdministrationSwitcherComponent
  @ViewChild('currentAdministration', { static: true }) currentAdministrationElement: ElementRef

  private _user: User
  private _settings: AdministrationSettings
  private _hideFloatingElementsTimeout: any
  private administration: Administration

  constructor(
    @Inject(forwardRef(() => FncMainLayoutComponent)) public layout: FncMainLayoutComponent,
    public login: LoginService,
    private _userService: UserService,
    private _administrationService: AdministrationService,
    public auth: AuthorizationService,
    private _sessionService: SessionService,
    private _el: ElementRef,
    private _router: Router,
  ) {}

  get fullName(): string {
    if (!this._user) {
      return ''
    }

    if (this._user.role == UserRoleTypes.ADMIN && this.administration) {
      if (this._settings && this._settings.company_name) {
        return this.administration.name
      }

      return this.administration.name
    }

    return `${this._user.first_name} ${this._user.last_name}`
  }

  get companyName(): string {
    if (this.administration && this.administration.name) {
      return this.administration.name
    }
  }

  ngOnInit(): void {
    this.fetchData()

    this._administrationService.onSwitchedAdministration
      .pipe(mergeMap(() => this._sessionService.startSession()))
      .subscribe(() => setTimeout(() => this.fetchData()))

    // Let mouseenters and mouseleaves cancel each other out. But only close when the mouse has left for half a second.
    observableMerge(observableFromEvent(this._el.nativeElement, 'mouseleave'), observableFromEvent(this._el.nativeElement, 'mouseenter'))
      .pipe(
        debounceTime(750),
        filter((evt: Event) => evt.type == 'mouseleave'),
      )
      .subscribe(() => this.close())

    // Let mouseenters and mouseleaves cancel each other out. But only close when the mouse has left for half a second.
    observableMerge(
      observableFromEvent(this.currentAdministrationElement.nativeElement, 'mouseleave'),
      observableFromEvent(this.currentAdministrationElement.nativeElement, 'mouseenter'),
    )
      .pipe(
        debounceTime(500),
        filter((evt: Event) => evt.type == 'mouseleave'),
      )
      .subscribe(() => {
        if (this.switcher) {
          this.switcher.close()
        }
      })

    this._administrationService.onAdministrationNameChange.subscribe(() => {
      this.fetchData()
    })
  }

  openSwitcher(open: boolean): void {
    if (this.switcher && open) {
      this.switcher.open()
    }
  }

  mouseEnter(): void {
    if (this._hideFloatingElementsTimeout) {
      clearTimeout(this._hideFloatingElementsTimeout)
    }

    // this.mouseOver.emit(true);
    this._el.nativeElement.classList.add('visible')

    if (this.layout.pageSubHeader) {
      this.layout.pageSubHeader.toggleVisibilityOnSnapped()
    }
  }

  close(): void {
    this._el.nativeElement.classList.remove('visible')

    setTimeout(() => {
      if (this.layout.pageSubHeader) {
        this.layout.pageSubHeader.toggleVisibilityOnSnapped()
      }
    }, globals.animations.speed.default)
  }

  signOut(): void {
    this.login.signOut()
    this._userService.clearCache()
  }

  navigateToMyAccount(): void {
    void this._router.navigateByUrl(routerlinks.main.account)
  }

  private fetchData(): void {
    forkJoin([
      this._userService.user.pipe(map((user) => (this._user = user))),
      this._administrationService.userHasMultipleAdministrations.pipe(map((result) => (this.hasMultipleAdministrations = result))),
      this._administrationService.defaultAdministration.pipe(map((a) => (this.administration = a))),
      this._administrationService.defaultAdministrationSettings.pipe(map((settings) => (this._settings = settings))),
    ]).subscribe()
  }
}
