import { Injectable } from '@angular/core'
import { Observable, Subscription, of } from 'rxjs'
import { ApiGateway } from '../remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { catchError, finalize, map } from 'rxjs/operators'

@Injectable()
export class EmailValidationService {
  private _emailValidationSubscription: Subscription

  get emailValidationInProgress(): boolean {
    if (!this._emailValidationSubscription) return false

    return !this._emailValidationSubscription.closed
  }

  constructor(private readonly _api: ApiGateway) {}

  startEmailvalidation(email: string, callback: (isValid: boolean) => void): void {
    this.unsubscribeEmailValidation()
    this._emailValidationSubscription = this._validateEmail(email)
      .pipe(finalize(() => this.unsubscribeEmailValidation()))
      .subscribe(callback)
  }

  unsubscribeEmailValidation(): void {
    this._emailValidationSubscription?.unsubscribe()
  }

  private _validateEmail(email: string): Observable<boolean> {
    return this._api.post<{ is_valid: boolean }>(endpoints.onboarding.emailVerification, { email_address: email }).pipe(
      map((result) => result.is_valid),
      catchError(() => of(true)),
    )
  }
}
