import { Observable, of as observableOf } from 'rxjs'
import { flatMap, switchMap, tap, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { AccountingService } from '../accounting/accounting.service'
import { JustificationSuppletion } from '../../domain/justification/justification-suppletion.model'
import { FiscalYearWithYearIdentifier } from '../../domain/accounting/fiscal-year.model'
import { DeclarationType } from '../../domain/vat/declaration-type.enum'
import { Suppletion } from '../../domain/vat/suppletion.model'

@Injectable()
export class SuppletionService {
  constructor(private readonly _accountingService: AccountingService) {}

  augmentSuppletions(suppletions: Suppletion[] | JustificationSuppletion[]): Observable<Suppletion[] | JustificationSuppletion[]> {
    let fiscalYears: FiscalYearWithYearIdentifier[]

    return this._accountingService.fiscalYears.pipe(
      flatMap((years: FiscalYearWithYearIdentifier[]) => {
        fiscalYears = years

        return observableOf(<Suppletion[]>suppletions)
      }),
      switchMap((x: Suppletion[]) => x),
      tap((sup: any) => {
        sup.__type = DeclarationType.Suppletion
        sup.__year = fiscalYears.find((fy) => fy.id == sup.fiscal_year_id).year
      }),
      toArray(),
    )
  }
}
