/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { InputDirective } from '../html-directives/input.directive'
import { AfterViewInit, Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import moment from 'moment'
import { TranslateService } from '@ngx-translate/core'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'
import { globals } from '../../shared/config/globals'
import { AccountingService } from '../../logic/accounting/accounting.service'
import Pikaday from 'pikaday'
import { nanoid } from 'nanoid'

type PikadayLanguage = {
  previousMonth: string
  nextMonth: string
  months: string[]
  weekdays: string[]
  weekdaysShort: string[]
}

@Component({
  selector: 'fnc-datepicker',
  styleUrls: ['./fnc-datepicker.component.scss'],
  host: {
    '(click)': '$event.stopPropagation();',
    '(document:click)': 'hideWithCallback($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FncDatepickerComponent),
      multi: true,
    },
  ],
  template: `
    <input [ngModel]="displayDate" [id]="pikaIdentifier" type="text" [required]="required" [attr.placeholder]="placeholder" />
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  `,
})
export class FncDatepickerComponent extends BaseHtmlDirective implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  /**
   * Translate all keys since this
   * can not be done in the template
   * (for this component specifically).
   * @returns {PikadayLanguage}
   */
  protected generateLocale(): PikadayLanguage {
    return Object.entries({
      previousMonth: 'Previous Month',
      nextMonth: 'Next Month',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    }).reduce((acc, [key, value]) => {
      // Arrays are treated differently.
      // Get 'months' / 'weekdays' / 'weekdayShort'.
      if (Array.isArray(value)) {
        // Map all possible keys and put the back into an array for Pikaday.
        return { ...acc, [key]: value.map((entry) => this._translate.instant(`PIKADAY.${key.toUpperCase()}.${entry.toUpperCase()}`)) }
      }

      // Translate 'normal' keys.
      return { ...acc, [key]: this._translate.instant(`PIKADAY.${key.toUpperCase()}`) }
    }, {} as PikadayLanguage)
  }

  get initialDate(): Date {
    if (this.innerValue) {
      return this.innerValue.momentObj.toDate()
    }

    if (this._initialDate) {
      return this._initialDate
    }

    return null
  }

  private _dateFormatPattern: string = globals.displayDateFormat

  @Input()
  set maximumDate(date: string) {
    this.maxDate = moment(date).toDate()
    this._initialDate = this.maxDate
  }

  @Input()
  set minimumDate(date: string) {
    if (date) {
      this.minDate = moment(date).toDate()

      if (this.minDate > this._initialDate) {
        this._initialDate = this.minDate
      }
    }
  }

  get value() {
    if (!this.innerValue || !this.innerValue.momentObj) {
      return null
    }

    return this.innerValue.momentObj.format(globals.dateFormat)
  }

  /**
   * Set accessor including
   * call the onChange callback
   */
  set value(value: string | Date | moment.Moment) {
    if (this.setDatePickerDate(value)) {
      this.onChangeCallback(this.value)
    }

    // Fix the floating label
    if (this._parent && value) {
      this._parent.setDirty(true)
    } else if (this._parent) {
      this._parent.setDirty(false)
    }
  }

  /**
   * Get the display date in order to
   * format the field to DD MM YYYY.
   * The returned date needs to be in
   * ISO-format, hence these different
   * handlers for returning/displaying.
   */
  get displayDate(): string {
    if (this.placeholder) return this.placeholder
    if (!this.innerValue || !this.innerValue.momentObj) {
      return ''
    }

    return this.innerValue.momentObj.format(this._dateFormatPattern)
  }

  /**
   * This element is not referenced in this
   * file, but this applies all styling,
   * so please leave it in.
   */
  get element() {
    return this.inputField.element
  }

  @Input() required: boolean
  @Input() placeholder: string
  @Input() openYearOnly: boolean

  @ViewChild(InputDirective, { static: true }) inputField: InputDirective

  maxDate: Date
  minDate: Date

  pikaIdentifier: string = nanoid()
  protected pika: Pikaday

  private innerValue: any
  private _initialDate: Date

  constructor(
    @Optional()
    @Inject(forwardRef(() => FncInputContainerComponent))
    private readonly _parent: FncInputContainerComponent,
    private readonly _translate: TranslateService,
    private readonly _accountingService: AccountingService,
    private readonly elRef: ElementRef,
    public datePickerEl: ElementRef,
  ) {
    super(null)

    this._initialDate = new Date()
  }

  ngOnInit(): void {
    if (this.openYearOnly) {
      this._accountingService.minimumBookableDate.subscribe((min) => {
        if (!this.minimumDate || moment(this.minimumDate).isBefore(min)) {
          this.minDate = min.toDate()
        }
      })
    }

    this.pika = new Pikaday({
      enableSelectionDaysInNextAndPreviousMonths: true,
      showDaysInNextAndPreviousMonths: true,
      container: this.elRef.nativeElement,
      field: this.inputField.element,
      theme: 'pikaday-white',
      format: 'DD-MM-YYYY',
      onSelect: (value: Date): void => {
        this.value = value
      },
      minDate: this.minDate,
      maxDate: this.maxDate,
      i18n: this.generateLocale(),
      firstDay: 1,

      /**
       * Disable so that backspace
       * does not clear the field.
       * */
      keyboardInput: false,
    })
  }

  /**
   * Destroy the Pika instance
   * on leaving the component.
   */
  ngOnDestroy(): void {
    void this.pika.destroy()
  }

  ngAfterViewInit(): void {
    if (this._parent) {
      setTimeout(() => this._parent.addInputNgClassesToContainer())
    }
  }

  /**
   * Shortcut to use both the
   * close and open method.
   */
  toggle(): void {
    void this.pika.isVisible() ? this.pika.hide() : this.pika.show()
  }

  /**
   * Open the Pika instance.
   * Useful for external use.
   */
  open(event: MouseEvent): void {
    event.stopPropagation()
    void this.toggle()
  }

  /**
   * Close the Pika instance.
   * Useful for external use.
   */
  close(): void {
    void this.hideWithCallback()
  }

  // for the next section see: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
  // Placeholders for the callbacks which are later providesd

  // Set touched on blur
  hideWithCallback(): void {
    if (this.pika) this.pika.hide()
    this.onTouchedCallback()
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (this._parent && this._parent.disabled) {
      if (typeof value == 'string') {
        this.placeholder = moment(value).format(globals.displayDateFormat)
      }

      return
    }

    this.setDatePickerDate(value)
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  isValidDate(value: any): boolean {
    const max = moment(this.maxDate)
    const date = moment(value)

    let betweenMinMax = true

    if (date && date['_i'] && date['_i'].momentObj) {
      const correctDateObject = moment(date['_i'].momentObj)
      if (max !== null) {
        betweenMinMax = !correctDateObject.isAfter(max, 'day')
      }
    }

    return betweenMinMax
  }

  // by the Control Value Accessor
  private onTouchedCallback: () => void = () => {}
  private onChangeCallback: (_: any) => void = () => {}

  /**
   * Returns true if value changed
   * @param {string | Date} value
   */
  private setDatePickerDate(value: string | Date | moment.Moment): boolean {
    if (this.maxDate && !this.isValidDate(value)) {
      return false
    }

    if (this.minDate) {
      const date = moment(value)
      if (date.isBefore(this.minDate)) {
        return false
      }
    }

    if (!value) {
      this.innerValue = value

      return true
    }

    if (!this.innerValue || value !== this.innerValue.momentObj.format(this._dateFormatPattern)) {
      const date = moment(value)
      this.innerValue = {
        momentObj: date,
        day: date.get('day'),
        year: date.get('year'),
        month: date.get('month'),
        enabled: true,
        selected: true,
        today: date.isSame(new Date(), 'day'),
      }

      return true
    }

    return false
  }
}
