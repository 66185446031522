export class JustificationStatus {
  static PROPOSED = 'PROPOSED'
  static ACCEPTED = 'ACCEPTED'
  static SYSTEM_ACCEPTED = 'SYSTEM_ACCEPTED'
  static REJECTED = 'REJECTED'
  static IN_PROGRESS = 'IN_PROGRESS'

  static showStateToUser(state: string): boolean {
    return JustificationStatus.PROPOSED == state || JustificationStatus.ACCEPTED == state || JustificationStatus.SYSTEM_ACCEPTED == state
  }
}
