export class InfiniteScrollSearchObject {
  // Limit offset
  limit = 20
  offset = 0

  // Pagination
  paginated: boolean
  page: number

  /**
   * filter field
   */
  search: string

  /**
   * delegate for adding custom parameters
   */
  protected addExtraParameters: (params: any) => void

  /**
   * Get query parameters for GET request
   * @param {boolean} reset if true resets offset to 0. Default = false
   */
  getParameters(reset?: boolean): any {
    if (reset) {
      this.offset = 0
    }

    const result: any = { limit: this.limit, offset: this.offset }

    if (this.search) {
      result.search = this.search
    }

    if (this.addExtraParameters) {
      this.addExtraParameters(result)
    }

    if (this.page) {
      result.page = this.page
    }

    if (this.paginated) {
      result.paginated = this.paginated
      delete result.offset
    } else {
      // Set offset to next offset. if pagination is not used
      this.offset += this.limit
    }

    return result
  }
}
