/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core'

@Injectable()
export class OutbrainHelper {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  /**
   * Method to initialize
   * the loading of the script.
   */
  init(): void {
    this.loadScript()
  }

  /**
   * Track by key.
   * Keys are defined in
   * the Outbrain web portal.
   */
  track(key: string): void {
    const w = window as any
    if (!key || !w.obApi) return console.error('obApi not initialized.')

    void w.obApi('track', key)
  }

  /**
   * This is the script / snippet
   * Outbrain tells you to load,
   * but formatted and linted.
   *
   * This way we have control
   * over the versioning.
   */
  private loadScript(): void {
    const OB_ADV_ID = '00ea240bd4b8751cc4bd7864e805fc1247'

    ;(function () {
      const w = window as any

      if (w.obApi) {
        const toArray = function (object) {
          return Object.prototype.toString.call(object) === '[object Array]' ? object : [object]
        }
        w.obApi.marketerId = toArray(w.obApi.marketerId).concat(toArray(OB_ADV_ID))

        return
      } else {
        const d = document
        const api = function () {
          if ((api as any).dispatch) {
            ;(api as any).dispatch.apply(api, arguments)

            return
          }

          ;(api as any).queue.push(arguments)
        }

        api.version = '1.1'
        api.loaded = true
        api.marketerId = OB_ADV_ID
        api.queue = []

        w.obApi = api

        const load = () => {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://amplify.outbrain.com/cp/obtp.js'
          const x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }

        load()
      }

      w.obApi('track', 'PAGE_VIEW')
    })()
  }
}
