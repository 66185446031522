import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular'
import { environment } from '../../../environments/environment'
import { version } from '../../../environments/version'
import { globals } from '../../shared/config/globals'

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  /**
   * Constructor.
   */
  constructor() {
    this.init()
  }

  captureMessage(text: string): void {
    void Sentry.captureMessage(text)
  }

  /**
   * Method is used by the Angular framework when it's told to do so in the app.module.ts
   * In production mode, this method sends all exceptions to Sentry. Sentry handles them appropriately
   * @param {any} error
   */
  handleError(error: any): void {
    /**
     * Sorry to disagree, but its actually
     * very useful to catch these early on.
     */
    // // Do not send errors from a develop environment to Sentry
    // if (window.location.hostname == 'localhost') {
    //   return
    // }

    if (error !== undefined && error !== null) {
      Sentry.captureException(error?.originalError || error)
    }
  }

  /**
   * For external services.
   */
  trackUser(userId: string, administrationId: string) {
    Sentry.setUser({ id: userId, adminId: administrationId })
    Sentry.setTag('administration_id', administrationId)
    Sentry.setTag('user_id', userId)
  }

  /**
   * Init.
   */
  private init(): void {
    Sentry.configureScope((scope) => {
      scope.setLevel('info')
    })

    Sentry.init({
      dsn: globals.sentry.dsn,
      debug: true,
      environment: environment.environmentName,
      release: version,
      attachStacktrace: true,
      ignoreErrors: ['Non-Error exception captured'],
    })
  }
}
