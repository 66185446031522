import { Component, Input } from '@angular/core'

/**
 * @see https://angular.io/guide/svg-in-templates
 */
@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.svg',
})
export class SpinnerComponent {
  @Input() dark: boolean = false
  @Input() stroke: number = 3
  @Input() hex: string = '#fff'
  @Input() size: string = '38'
  /**
   * Hardcoded HEX value
   * to mirror the default
   * Tellow purple color.
   */
  darkHex: `#${string}` = '#002E33'
}
