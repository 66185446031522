import { Injectable } from '@angular/core'
import moment from 'moment'
import { SelectablePeriod } from '../../domain/administration/time/selectable-period'

// Just a service for keeping track for currently selected filters
@Injectable()
export class FilterService {
  startDate: moment.Moment
  endDate: moment.Moment
  period: SelectablePeriod

  minAmount: number
  maxAmount: number

  selectedContact: number

  setStartDate(startDate: moment.Moment): void {
    this.startDate = startDate
  }

  setEndDate(endDate: moment.Moment): void {
    this.endDate = endDate
  }

  setPeriod(period: SelectablePeriod): void {
    this.period = period
  }

  setMinAmount(min: number): void {
    this.minAmount = min
  }

  setMaxAmount(max: number): void {
    this.maxAmount = max
  }

  setSelectedContact(id: number): void {
    this.selectedContact = id
  }

  getStartDate(): moment.Moment | null {
    return this.startDate ? this.startDate : this.period ? this.period.startDate : null
  }

  getEndDate(): moment.Moment | null {
    return this.endDate ? this.endDate : this.period ? this.period.endDate : null
  }

  getSelectedContact(): number | null {
    return this.selectedContact ? this.selectedContact : null
  }

  // Reset all filters
  resetFilters(): void {
    this.startDate = null
    this.endDate = null
    this.period = null
    this.selectedContact = null
  }

  resetAmountFilters(): void {
    this.minAmount = null
    this.maxAmount = null
  }

  resetAll(): void {
    this.resetFilters()
    this.resetAmountFilters()
  }

  hasPeriod(): SelectablePeriod {
    return this.period
  }

  hasDateFilters(): boolean {
    return !!this.startDate || !!this.endDate
  }

  hasAmountFilters(): boolean {
    return !!this.minAmount || !!this.maxAmount
  }

  hasSelectedContactFilter(): boolean {
    return !!this.selectedContact
  }

  // Return true if any filter is saved
  showFilters(): boolean {
    return this.hasAmountFilters() || this.hasDateFilters() || this.hasSelectedContactFilter()
  }
}
