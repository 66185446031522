export function initStringPrototype() {
  String.prototype.startsWith = function (prefix: string) {
    return this.indexOf(prefix) === 0
  }

  String.prototype.endsWith = function (suffix: string) {
    if (this.length < suffix.length) {
      return false
    }

    return this.lastIndexOf(suffix) === this.length - suffix.length
  }

  String.prototype.contains = function (str: string): boolean {
    return this.indexOf(str) > -1
  }

  String.prototype.capitalize = function () {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return this.charAt(0).toUpperCase() + this.slice(1)
  }

  // padding left
  String.prototype.padLeft = function (paddingChar, length): string {
    let s = String(this)

    if (this.length < length && paddingChar.toString().length > 0) {
      for (let i = 0; i < length - this.length; i++) {
        s = paddingChar.toString().charAt(0).concat(s)
      }
    }

    return s
  }

  // padding right
  String.prototype.padRight = function (paddingChar, length): string {
    let s = String(this)

    if (this.length < length && paddingChar.toString().length > 0) {
      for (let i = 0; i < length - this.length; i++) {
        s = s.concat(paddingChar.toString().charAt(0))
      }
    }

    return s
  }

  // Returns true / false if input has lowercase characters
  String.prototype.hasLowercase = function () {
    return /[a-z]/.test(this)
  }

  // Returns true / false if input has uppercase characters
  String.prototype.hasUppercase = function () {
    return /[A-Z]/.test(this)
  }

  String.isNotNullOrEmpty = function (input) {
    return input && input.length > 0
  }

  String.isNotNullNoWhitespace = function (input) {
    return String.isNotNullOrEmpty(input) && input !== ' '
  }
}
