import { Injectable } from '@angular/core'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from '../administration/administration.service'
import { finalize, map, mergeMap, switchMap } from 'rxjs/operators'
import { endpoints } from '../../shared/config/endpoints'
import { Product } from '../../domain/product/product.model'
import { BehaviorSubject, Observable, Subject, of } from 'rxjs'
import { Administration } from '../../domain/administration/administration.model'
import { BillingService } from '../administration/billing.service'
import { Cacheable } from 'ts-cacheable'

export const HAS_DISMISSED_STANDARD_PRODUCTS = 'hasDismissedStandardProducts'
const cacheBusterObserver = new Subject<void>()

@Injectable()
export class ProductsService {
  /** Shared loading states by all invoice lines */
  initialLoadingCompleted$ = new BehaviorSubject<boolean>(false)
  isUpgrading$ = new BehaviorSubject<boolean>(false)

  /** Shared UI states by all invoice lines */
  hasCreatedProducts$ = new BehaviorSubject<boolean>(false)
  canUseStandardProducts$ = new BehaviorSubject<boolean>(true)
  shouldSeeUpsell$ = new BehaviorSubject<boolean>(false)

  /** Main property for storing all products for the Administration */
  private _allProducts$ = new BehaviorSubject<Product[]>([])

  constructor(private readonly _api: ApiGateway, private readonly _administration: AdministrationService, private readonly _billing: BillingService) {
    this.loadStates()
  }

  /**
   * Returns the products for display, either all or filtered by description
   */
  getProductsForDisplay(description?: string): Observable<Product[]> {
    return description && description.length > 0 ? this.getProducts(description) : this._allProducts$
  }

  /**
   * Sets the initial states for UI
   */
  loadStates(): void {
    this._billing.canUseStandardProducts$
      .pipe(
        switchMap((canUseStandardProducts: boolean) => {
          this.canUseStandardProducts$.next(canUseStandardProducts)

          // Load products if the user can use standard products
          if (canUseStandardProducts) return this.getProducts()

          // Check if the user has dismissed the upsell
          const dismissed = localStorage.getItem(HAS_DISMISSED_STANDARD_PRODUCTS)
          if (dismissed === null) this.shouldSeeUpsell$.next(true)

          return of([])
        }),
      )
      .subscribe((products: Product[]) => {
        this._allProducts$.next(products)

        this.hasCreatedProducts$.next(products.length > 0)
        this.initialLoadingCompleted$.next(true)
        this.isUpgrading$.next(false)
      })
  }

  manualRefresh(): void {
    cacheBusterObserver.next()
    this.getProducts().subscribe((products) => this._allProducts$.next(products))
  }

  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  getProducts(description?: string): Observable<Product[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        /*
         * Take only the first 50 products for display - for more the user must use search.
         * Then sort the products by description for easier navigation.
         *
         * As of 10th May 2024 - 6 users have more than 50 products, so this is a good compromise for performance for these users.
         * After all which sane person would scroll through a 1000 products in a dropdown which shows 3 at a time?
         */
        const params: any = {
          administrationId: administration.id,
          limit: 50,
        }
        if (description && description.length > 0) params.search = description

        return this._api.get<Product[]>(endpoints.products.products, params)
      }),
      map((products: Product[]) => products.sort((a, b) => a.description.localeCompare(b.description))),
    )
  }

  getProduct(productId: number): Observable<Product> {
    return this._administration.defaultAdministration.pipe(
      mergeMap(({ id }: Administration) => {
        const params: any = {
          administrationId: id,
          productId: productId,
        }

        return this._api.get<Product>(endpoints.products.product, params)
      }),
    )
  }

  saveProduct(product: Product): Observable<Product> {
    return this._administration.defaultAdministration.pipe(
      mergeMap(({ id }: Administration) => {
        const params: any = {
          administrationId: id,
        }

        return this._api.post<Product>(endpoints.products.products, product, params)
      }),
      finalize(() => this.manualRefresh()),
    )
  }

  updateProduct(product: Product): Observable<Product> {
    return this._administration.defaultAdministration.pipe(
      mergeMap(({ id }: Administration) => {
        const params: any = {
          administrationId: id,
          productId: product.id,
        }

        return this._api.put<Product>(endpoints.products.product, product, params)
      }),
      finalize(() => this.manualRefresh()),
    )
  }

  deleteProduct(product: Product): Observable<void> {
    return this._administration.defaultAdministration.pipe(
      mergeMap(({ id }: Administration) => {
        const params: any = {
          administrationId: id,
          productId: product.id,
        }

        return this._api.delete(endpoints.products.product, params)
      }),
      finalize(() => this.manualRefresh()),
    )
  }
}
