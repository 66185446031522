export class LayoutService {
  private _menuShadeProperties: any

  get menuShadeProperties() {
    const result = this._menuShadeProperties

    this._menuShadeProperties = null

    return result
  }

  set menuShadeProperties(properties: any) {
    this._menuShadeProperties = properties
  }
}
