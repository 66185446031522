import { Injectable } from '@angular/core'
import { CanActivateChild, NavigationStart, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class AnnotationGuard implements CanActivateChild {
  private _pattern: string = `\/${environment.routerLinks.documents.index}\/[0-9]*(?:\/?)$`
  private _lastTrigger: 'imperative' | 'popstate' | 'hashchange'

  get isGoingBack(): boolean {
    return this._lastTrigger === 'popstate'
  }

  get isReturningToAnnotation(): boolean {
    return new RegExp(this._pattern, 'ig').test(this._router.url)
  }

  constructor(private _router: Router) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._lastTrigger = event.navigationTrigger
      }
    })
  }

  canActivateChild(): boolean {
    if (this.isGoingBack && this.isReturningToAnnotation) {
      console.warn(`
        ⚠️ Warning:
        Trying to navigate back to self-annotation.
        This is not allowed, as the document is already annotated.
      `)

      return false
    }

    return true
  }
}
