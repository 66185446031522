import { Injectable } from '@angular/core'
import { AdministrationService } from '../administration/administration.service'
import { Observable, of } from 'rxjs'
import { Administration } from '../../domain/administration/administration.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { endpoints } from '../../shared/config/endpoints'
import { map, mergeMap, tap } from 'rxjs/operators'
import { TellowNotificationName } from '../../domain/notification/notification.model'
import { ActivationState } from '../../domain/swan/activation-state.model'

@Injectable({ providedIn: 'root' })
export class DismissedNotificationService {
  public allDismissedNotifications: string[] = []

  constructor(private readonly _administration: AdministrationService, private readonly _api: ApiGateway) {}

  clearCache(): void {
    this.allDismissedNotifications = []
  }

  /**
   * Fetches all dismissed notifications for the administration.
   */
  getDismissedNotifications$(): Observable<string[]> {
    if (this.allDismissedNotifications.length) return of(this.allDismissedNotifications)

    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((administrationId) => this._api.get<string[]>(endpoints.reminders.getDismissed, { administrationId })),
      tap((notifications) => (this.allDismissedNotifications = notifications)),
    )
  }

  dismissNotification$(name: TellowNotificationName | ActivationState, fully: boolean): Observable<void> {
    return this._administration.makeApiCallForDefaultAdministration((p) => this._api.post(endpoints.reminders.dismiss, { name, fully }, p))
  }
}
