import { map, mergeMap, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LoginService } from '../../core/security/login.service'
import { UserService } from '../user/user.service'
import { User } from '../../domain/user/user.model'
import { AdministrationService } from '../administration/administration.service'
import { environment } from '../../../environments/environment'
import { TellowExternalCategory } from '../../domain/tellow/tellow-help-category'
import { SecureHttp } from '../../core/remote/httpclient'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { AsyncSubject, combineLatest, forkJoin, Observable } from 'rxjs'
import { BankAccountService } from '../administration/bankaccounts.service'
import { AdministrationBankAccount } from '../../domain/bankAccount/administration-bank-account.model'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { AdblockerHelper } from '../helpers/adblocker.helper'

@Injectable()
export class IntercomService {
  booted: boolean = false
  urlShortenerBusy: boolean
  intercomInShutdownMode = false

  private _showUI: boolean
  private _intercomLoaded$ = new AsyncSubject<boolean>()

  private get window(): any {
    return window as any
  }

  constructor(
    private readonly _loginService: LoginService,
    private readonly _adminService: AdministrationService,
    private readonly _userService: UserService,
    private readonly _httpService: SecureHttp,
    private readonly _translate: TranslateService,
    private readonly _bankAccounts: BankAccountService,
    private readonly _router: TranslateRouteService,
    private readonly _adblocker: AdblockerHelper,
  ) {
    this.loadScriptThroughSegment()

    /**
     * Running the session events when not
     * being signed in, breaks registration.
     *
     * This check fixes it.
     */
    const register = this._router.translate('/register')
    if (!this.window.location.pathname.endsWith(register)) {
      this.registerSessionEvents()
    }
  }

  /**
   * Replace Intercom with mocked
   * no-op function when blocked.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private noop = (): void => {}

  /**
   * Do not return Intercom when adblocker is detected.
   * Calling Intercom despite blocks causes issues.
   *
   * Returning a noop catches all 'Intercom()' calls.
   * This prevents Sentry from being angry at us.
   *
   * Attribute should only use 'number'
   * when used by 'startTour' method.
   */
  instance(command: string, attribute?: string | number | { [s: string]: string | boolean }): void {
    this._adblocker.isBlockingTracking('High').subscribe((active) => {
      if (active || typeof this.window.Intercom !== 'function') {
        void this.noop
      } else {
        void this.window.Intercom(command, attribute)
      }
    })
  }

  open(): void {
    // Boot if in shutdown mode
    if (this.intercomInShutdownMode) {
      this.registerSessionEvents()
    }

    this.instance('show')
  }

  triggerEvent(event: string): void {
    if (!event) {
      return
    }

    this.instance('trackEvent', event)
  }

  startTour(event: number): void {
    if (!event) {
      return
    }

    this.instance('startTour' as any, event)
  }

  openIntercomMessengerHome(): void {
    this.instance('show')
  }

  openForObject(objectType: TellowExternalCategory, objectId: number, extraData?: any): void {
    this.urlShortenerBusy = true

    let objectTranslation: string
    let article: string
    let link: string

    combineLatest([
      this._translate.get([objectType.translationKey, 'COMPONENTS.INTERCOM.ARTICLE']).pipe(
        tap((t) => {
          objectTranslation = t[objectType.translationKey]
          article = t['COMPONENTS.INTERCOM.ARTICLE']
        }),
      ),

      this.createSupportLink(objectType.category, objectId, extraData).pipe(tap((l) => (link = l))),
    ])
      .pipe(
        mergeMap(() =>
          this._translate.get('COMPONENTS.INTERCOM.MESSAGE', {
            article: objectType.isEntity ? ' ' + article : '',
            objectTranslation: objectTranslation,
            link: link,
          }),
        ),
      )
      .subscribe(
        (message) => {
          this.instance('showNewMessage', message)
          this.urlShortenerBusy = false
        },
        () => (this.urlShortenerBusy = false),
      )
  }

  /**
   * Open Intercom chat window and prefill
   * with administration termination request.
   */
  openForAdministrationTermination(): void {
    const user = this._userService.user
    const administration = this._adminService.defaultAdministration

    forkJoin([user, administration]).subscribe(([user, administration]) => {
      return this.instance(
        'showNewMessage',
        `Ik wil mijn administratie beëindigen. Ik begrijp dat ik na het verlopen van de wettelijke bewaartermijn van mijn data straks geen toegang meer heb. Mijn abonnement heb ik reeds stopgezet en ik heb een backup gemaakt van mijn data. \n\nExtra informatie: \nGebruiker ID: ${user.id} \nAdministratie ID: ${administration.id} \nAdministratie naam: ${administration.name}\nReden: ____ajb_invullen______ \n\nGroet,\n${user.first_name}`,
      )
    })
  }

  openForPayment(objectType: TellowExternalCategory, objectId: number, extraData?: any, paymentIntercom?: boolean): void {
    this.urlShortenerBusy = true
    let link: string
    const intercomText = paymentIntercom ? 'COMPONENTS.INTERCOM.PAYMENT' : 'COMPONENTS.INTERCOM.ARTICLE'

    combineLatest([this.createSupportLink(objectType.category, objectId, extraData).pipe(tap((l) => (link = l)))])
      .pipe(
        mergeMap(() =>
          this._translate.get(intercomText, {
            link: link,
          }),
        ),
      )
      .subscribe(
        (message) => {
          this.instance('showNewMessage', message)
          this.urlShortenerBusy = false
        },
        () => (this.urlShortenerBusy = false),
      )
  }

  openForBookkeeperPlan(): void {
    void this._adminService.defaultAdministration
      .pipe(
        mergeMap(({ id, name }) =>
          this._translate.get('COMPONENTS.INTERCOM.FINANCIAL_ADVICE', {
            administrationID: id,
            administrationName: name,
          }),
        ),
      )
      .subscribe((message) => {
        this.instance('showNewMessage', message)
      })
  }

  showUI(): void {
    this._showUI = true
    this.instance('update', { hide_default_launcher: false })
  }

  hideUI(): void {
    this._showUI = false
    this.instance('update', { hide_default_launcher: true })
  }

  shutdown(): void {
    // Close prev session
    if (this.booted) {
      this.instance('shutdown')
      this.intercomInShutdownMode = true
    }
  }

  /**
   * Updates given intercom fields
   * @param updates
   */
  updateIntercomFields(updates: { [s: string]: string | boolean } = {}): void {
    this.instance('update', updates)
  }

  private registerSessionEvents(): void {
    const user = this._userService.user
    const admin = this._adminService.defaultAdministrationSettings
    const accounts = this._bankAccounts.defaultAdministrationCheckingsAccount

    /**
     * forkJoin will wait for all passed
     * observables to complete and then
     * it will emit an array or an object with
     * last values from corresponding observables.
     *
     * @see https://rxjs-dev.firebaseapp.com/api/index/function/forkJoin
     */
    forkJoin([admin, user, accounts]).subscribe(([admin, user, accounts]) => this.init(user, admin, accounts))

    this._loginService.onLoggedOut.subscribe(() => this.shutdown())
    this._httpService.onSessionExpired.subscribe(() => this.shutdown())
  }

  private init(user: User, administration: AdministrationSettings, bankAccounts: AdministrationBankAccount): void {
    this.instance('boot', {
      app_id: environment.intercomAppId,
      name: UserService.getFullName(user),
      email: user.email,
      user_id: String(user.id),
      user_hash: localStorage.getItem('intercom'),
      hide_default_launcher: !this._showUI,
      user_origin: UserService.getUserTypeName(user.origin_id),
      created_at_tellow: user.created_at,
      coc_number: administration.coc_number,
      is_subscribed_to_newsletter: user.is_subscribed_to_newsletter,
      is_beta: user.is_beta,
      phone_number: user.phone_number,
      validated_email: user.validated_email,
      company_name: administration.company_name,
      is_psd2: bankAccounts?.is_psd2,
      psd2_active: bankAccounts?.psd2_active,
      psd2_upgraded_at: bankAccounts?.psd2_upgraded_at,
      language: administration?.language,
    })

    this.booted = true
  }

  private createSupportLink(objectType: string, objectId: number, extraData?: any): Observable<string> {
    return this._adminService.defaultAdministration.pipe(
      map((admin) => {
        let url = `${environment.baseUrl}/login/enter;related_object=${objectType};related_object_id=${objectId};administration_id=${admin.id}`

        if (extraData) {
          for (const key in extraData) {
            // eslint-disable-next-line no-prototype-builtins
            if (extraData.hasOwnProperty(key)) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              url += `;${key}=${extraData[key]}`
            }
          }
        }

        return url
      }),
    )
  }

  private loadScriptThroughSegment(): void {
    this.notifyIntercomLoaded()
  }

  private notifyIntercomLoaded(): void {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      if (this.window.Intercom?.booted) {
        this._intercomLoaded$.next(true)
        this._intercomLoaded$.complete()

        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }
}
