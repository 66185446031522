import { Observable } from 'rxjs'
import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'

/**
 * @deprecated
 * Used to be used for Rabobank users.
 * (they used to have different onboarding flows).
 * Now unused, and should be taken out.
 */
@Injectable()
export class UserAdministrationGuard implements CanActivate {
  canActivate(): boolean | Observable<boolean> {
    return true
  }
}
