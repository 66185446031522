import { Injectable } from '@angular/core'
import { map, take } from 'rxjs/operators'
import { User } from '../../domain/user/user.model'
import { UserService } from '../user/user.service'
import { UserRoleTypes } from '../../domain/user/user-role-types.constants'
import { Observable, Subject } from 'rxjs'
import { fetchAndActivate, RemoteConfig, getValue } from 'firebase/remote-config'
import { Analytics, setUserId, setUserProperties } from 'firebase/analytics'
import { FirebaseCoreService } from '../firebase/firebase-core.service'
import { Cacheable } from 'ts-cacheable'

const cacheBusterObserver = new Subject<void>()

type ActiveFeatures = 'isPaymentLinksEnabled' | 'isInvitedToTellowBank' | 'isShownOpenBankNotification' | 'isShownOrderPhysicalCardNotification'

@Injectable({
  providedIn: 'root',
})
export class FirebaseHelper {
  private _remoteConfig: RemoteConfig = this._firebase.remoteConfig
  private _analytics: Analytics = this._firebase.analytics

  constructor(private readonly _user: UserService, private readonly _firebase: FirebaseCoreService) {
    this.load()
  }

  /**
   * Lifecycle.
   * @see https://firebase.google.com/docs/remote-config/use-config-web
   */
  protected load(): void {
    if (process.env.NODE_ENV === 'development') {
      this._remoteConfig.settings.minimumFetchIntervalMillis = 3600
    }

    void fetchAndActivate(this._remoteConfig)
      .then(() => {
        cacheBusterObserver.next()
        console.log('🔥 Firebase Remote Config | Activated.')
      })
      .catch((error) => console.error(error))
  }

  /**
   * Log where the Remote Config comes from;
   * but only when you are signed in as admin.
   */
  debug(source: string): void {
    switch (source) {
      case 'remote':
        console.debug('🔥 Firebase Remote Config | Parameter value was from the Firebase servers.')
        break
      case 'default':
        console.debug('🔥 Firebase Remote Config | Parameter value was from a default value.')
        break
      default:
        console.debug('🔥 Firebase Remote Config | Parameter value was from a locally cached value.')
        break
    }
  }

  @Cacheable({ maxAge: 12 * 60 * 60 * 1000, cacheBusterObserver })
  isPaymentLinksEnabled(defaultValue?: boolean): Observable<boolean> {
    return this.isFeatureEnabled('isPaymentLinksEnabled', defaultValue)
  }

  @Cacheable({ maxAge: 12 * 60 * 60 * 1000, cacheBusterObserver })
  isInvitedToTellowBank(defaultValue?: boolean): Observable<boolean> {
    return this.isFeatureEnabled('isInvitedToTellowBank', defaultValue)
  }

  @Cacheable({ maxAge: 12 * 60 * 60 * 1000, cacheBusterObserver })
  isShownOpenBankNotification(defaultValue?: boolean): Observable<boolean> {
    return this.isFeatureEnabled('isShownOpenBankNotification', defaultValue)
  }

  @Cacheable({ maxAge: 12 * 60 * 60 * 1000, cacheBusterObserver })
  isShownOrderPhysicalCardNotification(defaultValue?: boolean): Observable<boolean> {
    return this.isFeatureEnabled('isShownOrderPhysicalCardNotification', defaultValue)
  }

  /**
   * Feature flagging through Remote Config.
   */
  isFeatureEnabled(feature: ActiveFeatures, defaultValue?: boolean): Observable<boolean> {
    return this._user.user.pipe(take(1)).pipe(
      /**
       * Set attributes in Firebase Analytics
       * so we can Segment our users.
       */
      map((user) => {
        void setUserId(this._analytics, user.id.toString())
        void setUserProperties(this._analytics, {
          email: user.email,
        })

        return user
      }),
      /**
       * Get the value from the flag.
       * Return as a boolean.
       */
      map((user: User) => {
        /**
         * Default value to fall back to (i.e. when none has been set)
         * @see https://firebase.google.com/docs/remote-config/use-config-web
         */
        if (defaultValue !== undefined) {
          this._remoteConfig.defaultConfig = {
            feature: defaultValue,
          }
        }

        /**
         * Get the value.
         * If none available, it will fall back to above (or false).
         */
        const flag = getValue(this._remoteConfig, feature)

        if (user.role === UserRoleTypes.ADMIN) {
          void this.debug(flag.getSource())
        }

        return flag.asBoolean()
      }),
    )
  }

  isFeatureEnabledWithoutIdentifyingUser(feature: ActiveFeatures): boolean {
    return getValue(this._remoteConfig, feature)?.asBoolean().valueOf()
  }
}
