import { Injectable } from '@angular/core'
import { endpoints } from '../../shared/config/endpoints'
import { ApiGateway } from '../remote/api.gateway'
import { AdministrationService } from '../../logic/administration/administration.service'

@Injectable()
export class JwtService {
  private static jwt = 'jwt'
  public static refreshExpiryInSeconds = 30 * 60 * 1000

  get jwt(): string {
    return JwtService.getJWTFromLocalStorage()
  }

  set jwt(token: string) {
    try {
      // Serialize and encode data.
      localStorage.setItem(JwtService.jwt, token)
    } catch (error) {
      console.log('Private mode or no local storage available. Use in memory storage')
    }
  }

  get hasJWT(): boolean {
    return JwtService.getJWTFromLocalStorage() !== null
  }

  constructor(private readonly _api: ApiGateway, private readonly _adminstration: AdministrationService) {
    this._adminstration.onSwitchedAdministration.subscribe(() => this.getJWT(true))
  }

  getJWT(refresh = false): void {
    if (!this.hasJWT || refresh) {
      this._api.get(endpoints.login.jwt).subscribe(({ token }: { token: string }) => this.saveJWT(token))
    }
  }

  saveJWT(token: string): void {
    this.jwt = token
  }

  /**
   * Clear JWT (i.e. on logging out of Tellow)
   */
  clearJWT(): void {
    localStorage.removeItem(JwtService.jwt)
  }

  /**
   * Get jwt from local storage.
   */
  private static getJWTFromLocalStorage(): string | null {
    return localStorage.getItem('jwt')
  }
}
