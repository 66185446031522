import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  HostBinding,
} from '@angular/core'
import { OptionDirective } from '../html-directives/option.directive'
import { SelectDirective } from '../html-directives/select.directive'
import { NgModel } from '@angular/forms'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { TranslateService } from '@ngx-translate/core'
import { TranslateHelper } from '../../core/logic/i18n/translate-helper.service'

export class SelectOption {
  constructor(public value: any, public text: string, public checked: boolean, public maxlength?: number) {}
}

@Component({
  selector: 'fnc-select',
  styleUrls: ['./fnc-select.component.scss'],
  templateUrl: './fnc-select.component.html',
  host: {
    '(click)': 'toggleActive($event)',
    '(document:click)': 'setInactive($event)',
  },
})
export class FncSelectComponent extends BaseHtmlDirective implements OnInit, AfterContentInit {
  searchString: string
  active = false
  options: SelectOption[]

  @Input() integer: boolean
  @Input() booleanValue: boolean
  @Input() enableSearch: boolean
  @Input() inputSelect: boolean
  @Input() percentage: boolean

  @HostBinding('attr.disabled')
  @Input()
  disabled: boolean = false

  @ViewChild('list', { static: true }) list: ElementRef
  @ViewChild('searchField') searchField: ElementRef

  @ContentChild(NgModel) select: NgModel
  @ContentChild(SelectDirective) selectElement: SelectDirective

  @Output()
  onFocusStateChanged = new EventEmitter<boolean>()

  private removeClassTimeOut
  private _options: SelectOption[]

  constructor(
    private readonly _el: ElementRef,
    private readonly _translate: TranslateService,
    private readonly _translateHelper: TranslateHelper,
    @Optional() @Inject(forwardRef(() => FncInputContainerComponent)) private readonly _parent: FncInputContainerComponent,
  ) {
    super(_el)
  }

  get selectedLabel() {
    if (!this.options || !this.options.length) {
      return
    }

    const first = this.options.firstOrUndefined((o) => o.checked)

    // show Select value if value selected. Otherwise display placeholder. If placeholder is
    if (first) {
      return first.text
    }

    if (this.active) {
      return this.selectElement.placeholder
    }

    return ''
  }

  get ngModel(): NgModel {
    return this.select
  }

  ngOnInit(): void {
    void this._translate.onLangChange.subscribe(() => {
      void this._setOptions()
      void this.valueChanged()
    })
  }

  ngAfterContentInit() {
    this.changeOptions()
    this.valueChanged()

    this.selectElement.options.changes.subscribe(() => this.changeOptions())

    if (this.select) {
      this.select.valueChanges.subscribe(() => this.valueChanged())
    }
  }

  locked(option: SelectOption): boolean {
    return option?.value === 'locked'
  }

  optionClicked(index: number) {
    if (this.options[index]?.value === '-1') return

    if (this.select) {
      let value = this.options[index].value

      if (this.integer) {
        value = parseInt(value)
      }

      this.select.control.setValue(value)
    }

    setTimeout(() => this.setInactive(), 100)
  }

  toggleActive(evt: Event) {
    evt.preventDefault()
    evt.cancelBubble = true
    evt.stopPropagation()

    this.active = !this.active

    if (this.active) {
      this.setActive()
      clearTimeout(this.removeClassTimeOut)
    } else {
      this.setInactive()
    }

    if (this.inputSelect) {
      this._el.nativeElement.focus()
    }

    return false
  }

  hasPlaceholder(): boolean {
    return typeof this.selectElement.placeholder === 'string'
  }

  // Body clicked. Or dismissed
  setInactive() {
    this.active = false

    if (this._parent && this.select && (this.select.control.value == undefined || this.select.control.value == '')) {
      this._parent.container.nativeElement.classList.remove('is-focused')
    }

    this.list.nativeElement.style.height = '0'
    this._el.nativeElement.style.zIndex = null

    // Only remove class after animation is done.
    this.removeClassTimeOut = setTimeout(() => {
      this.list.nativeElement.classList.remove('visible')
    }, 300)

    this.onFocusStateChanged.emit(false)

    this.searchString = null
    this.options = this._options
  }

  search(searchString: string) {
    this.searchString = searchString
    this.performSearch()
  }

  private _setOptions(): void {
    this._options = this.selectElement.options.toArray().map((o: OptionDirective) => {
      const value =
        o.value && o.value.contains(': ')
          ? o.value.split(': ')[1]
          : this.booleanValue && (o.value === 'true' || o.value === 'false')
          ? Boolean(o.value === 'true')
          : o.value

      /**
       * If the incoming text is a 'raw' translation key (e.g. `INVOICES.SOMETHING.SOMETHING`),
       * utilize the translation with the `instant()`-method. Otherwise, just use the text as is.
       */
      const text = this._translateHelper.hasTranslation(o.text) ? this._translate.instant(o.text) : o.text

      return new SelectOption(value, text, this.select && this.select.control.value == o.value)
    })
  }

  private changeOptions() {
    this._setOptions()
    this.performSearch()
  }

  private valueChanged() {
    if (this.inputSelect) {
      if (this.select.value) {
        this._parent.label.element.textContent = this.select.value
      }
    }

    if (!this.select) {
      return
    }

    this.options.forEach((o) => (o.checked = o.value == this.select.control.value))
    this._options.forEach((o) => (o.checked = o.value == this.select.control.value))

    if (this._parent.container && this.select.control.value !== null && this.select.control.value !== undefined) {
      this._parent.container.nativeElement.classList.add('is-dirty')
    }
  }

  private setActive() {
    this._el.nativeElement.style.zIndex = '4'

    if (this._parent) {
      this._parent.container.nativeElement.classList.add('is-focused')
    }

    // let it grow. So we can get a measure of the actual height.
    this.list.nativeElement.style.height = 'auto'

    // Now measure the height.
    const height = this.list.nativeElement.getBoundingClientRect().height

    // Revert back to 0 height so we can animate to the actual height.
    this.list.nativeElement.style.height = '0'

    // Set animatable class.
    this.list.nativeElement.classList.add('visible')

    // Now set the actual height for animation.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    setTimeout(() => (this.list.nativeElement.style.height = `${height}px`))

    if (this.enableSearch) {
      setTimeout(() => {
        ;(<HTMLInputElement>this.searchField.nativeElement).focus()
      }, 100)
    }

    this.onFocusStateChanged.emit(true)
  }

  private performSearch() {
    if (!this.searchString || !this._options.length) {
      this.options = this._options

      return
    }

    const search = this.searchString.toLowerCase()

    this.options = this._options.filter((o: SelectOption) => {
      // Always also return the titles of our list(s) during search.
      if (['all', '-1', 'locked'].includes(o.value)) {
        return o
      }

      return o.text.toLowerCase().contains(search)
    })
  }
}
