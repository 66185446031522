import { Component, Input } from '@angular/core'
import { MenuService, MenuItems } from '../menu.service'

@Component({
  selector: 'menu-group',
  template: `
    <ng-container>
      <button>
        <ng-content></ng-content>
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" [class.active]="menu.state[name]" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </ng-container>
  `,
})
export class TlwMenuGroupComponent {
  /**
   * Name and state are required.
   * Name is the key in the menu state.
   */
  @Input() name: MenuItems

  constructor(public menu: MenuService) {}
}
