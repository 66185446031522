import { Injectable } from '@angular/core'
import { combineLatest as observableCombineLatest, Observable, of as observableOf, of } from 'rxjs'
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators'
import { AdministrationService } from '../administration/administration.service'
import { UserService } from './user.service'
import { StatsService } from '../dashboard/stats.service'
import { BankAccountService } from '../administration/bankaccounts.service'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { FirebasePushMessageService } from '../firebase/firebase-push-message.service'
import { AccountingService } from '../accounting/accounting.service'
import { StatuspageService } from '../external-services/statuspage.service'
import { SegmentHelper } from '../external-services/segment.helper'
import { LoginService } from '../../core/security/login.service'
import { BillingService } from '../administration/billing.service'

@Injectable()
export class SessionService {
  constructor(
    private _administrationService: AdministrationService,
    private _userService: UserService,
    private _statsService: StatsService,
    private _bankAccountService: BankAccountService,
    private _accountingService: AccountingService,
    private _firebasePushMessageService: FirebasePushMessageService,
    private _statusPage: StatuspageService,
    private _segment: SegmentHelper,
    private _loginService: LoginService,
    private readonly _user: UserService,
    private readonly _billing: BillingService,
  ) {
    // Reset session from in-memory when logging out.
    this._loginService.onLoggedOut.subscribe(() => (this.sessionInitiated = false))
  }

  // In-memorary value to check if session has started.
  private sessionInitiated = false

  cacheMinimalRequiredObjectsForApplicationRun(): Observable<boolean> {
    return this._administrationService.hasAdministration.pipe(
      mergeMap((result) => {
        if (result === false) {
          return observableOf(false)
        }

        return observableCombineLatest([
          this._administrationService.defaultAdministration,
          this._administrationService.defaultAdministrationSettings,
          this._userService.user,
          this._accountingService.minimumBookableDate,
          this._bankAccountService.defaultAdministrationCheckingsAccount,
          this._billing.getInfo(),
          this._billing.getPlans(),
          this._statusPage.getUnresolvedIncidentsForUser(),
        ]).pipe(
          /**
           * We only need to cache the results.
           * 'Take 1', and leave the rest as is.
           */
          take(1),
          map(() => true),
        )
      }),
      /**
       * This can fail, but make it be an Observable,
       * so subsequent calls will still go through.
       */
      catchError(() => of(false)),
    )
  }

  /**
   * Start a session:
   * - Cache minimal data (e.g. user and settings data)
   * - Get token for firebase push messages
   * - Log the session
   */
  startSession(from?: string): Observable<boolean> {
    console.debug('StartSession called from', from)

    return this.cacheMinimalRequiredObjectsForApplicationRun().pipe(
      mergeMap((result) => {
        if (result === false) {
          return observableOf(null)
        }

        return this._administrationService.defaultAdministrationSettings
      }),
      map((settings: AdministrationSettings) => {
        if (!settings) {
          return false
        }

        if (settings.push_enabled && this._firebasePushMessageService.hasPermission()) {
          this._firebasePushMessageService.init()
        }

        // If no in-memory session is found, log a session.
        if (!this.sessionInitiated) {
          this._segment.track('Session - Started')
          this._user.onIdentifyUser.emit()
          this.sessionInitiated = true
        }

        return true
      }),
    )
  }

  clearCache(): void {
    this._administrationService.clearCache()
    this._statsService.clearCache()
    this._userService.clearCache()
    this._accountingService.clearCache()
    this._bankAccountService.clearCache()
  }
}
