export const UserOriginTypes = {
  USER: 1,
  INTERNAL: 2,
  OTHER_USER: 3,
  NEW_ACCOUNT: 4,
  NEW_ACCOUNT_BATCH: 5,
  MFO: 6,
  SELF_REGISTRATION: 7,
  FCC_USER: 8,
  ROO_RIDERS: 9,
  UBR_RIDERS: 10,
  ZP_ZAKEN: 11,
  GO_RIDERSGO: 12,
  REFERRAL: 13,
  FREELANCER_NL: 14,
  PZO: 15,
  QOMMUNITY: 16,
  ZELFBOEKHOUDEN: 17,
  IBCAMPAIGN2022: 18,
  'ZZP-NEDERLAND': 19,
  TELLOWBANKING: 20,
  BOEKHOUDER: 21,
  IBCAMPAIGN2023: 22,
  IBCAMPAIGN2024: 23,
}
