export enum AgerasFinanceCustomerApprovalState {
  DRAFT = 'draft',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface AgerasFinanceCustomer {
  id: string
  providerId: string
  externalId: string
  emailAddress?: string
  phoneNumber?: string
  companyName: string
  locale: 'nl-NL'
  companyRegistrationNumber: string
  primaryApplicantId?: string
  customerPayoutFormat?: string
  ibanAccountNumber?: string
  danishBankCode?: string
  danishAccountNumber?: string
  country: 'NL'
  customerState: string
  createdAt: string
  updatedAt: string
  beneficialOwners: AgerasFinanceCustomerBenefitialOwner[]
  status: CustomerStatus
  address?: AgerasFinanceCustomerAddress
  approval_state: AgerasFinanceCustomerApprovalState
}

interface AgerasFinanceCustomerBenefitialOwner {
  id: string
  name?: string
  ownershipAmount?: string
  dataSource?: string
  externalId?: string
  locale?: 'nl-NL'
  nationalIdentificationNumber?: string
  email?: string
  createdAt?: string
  updatedAt?: string
  address?: AgerasFinanceCustomerAddress
}

interface AgerasFinanceCustomerAddress {
  country?: 'NL'
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
}

interface CustomerStatus {
  has_beneficial_owners: boolean
  has_provided_payout_info: boolean
  has_selected_primary_beneficial_owner: boolean
  owners: CustomerBenefitialOwnerStatus[]
}

interface CustomerBenefitialOwnerStatus {
  id: string
  has_national_identification_number: boolean
  has_email: boolean
  has_completed_invoice_financing_signing: boolean
  has_completed_identity_verification: boolean
}

export interface AccessToken {
  accessToken: string
}
