import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { SubscriptionResponse, TellowPlans } from '../../../domain/billing/plan.model'
import { UserRoleTypes } from '../../../domain/user/user-role-types.constants'
import { BillingService } from '../../../logic/administration/billing.service'
import { UserService } from '../../../logic/user/user.service'
import { combineLatest } from 'rxjs'
import { User } from '../../../domain/user/user.model'

@Directive({
  selector: '[showForPlan]',
})
export class ShowForPlanDirective {
  development: boolean = process.env.NODE_ENV === 'development'

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly _billing: BillingService,
    private readonly _user: UserService,
  ) {}

  /**
   * The directive takes an array in order to
   * check if the user can use this feature.
   * For instance, this: ['Plus', 'Compleet']
   * will activate for 'Plus' and 'Compleet'.
   * Doh.
   */
  @Input() set showForPlan(condition: TellowPlans[]) {
    if (!condition.some((entity) => entity in TellowPlans)) {
      console.warn('ShowForPlanDirective used in incorrect manner.')
    }

    combineLatest([this._user.user, this._billing.getInfo()]).subscribe(([user, billing]: [User, SubscriptionResponse]) => {
      const superAdmin = user.role === UserRoleTypes.ADMIN && user.email.contains('@tellow.nl')
      const match = condition.includes(billing?.data?.plan?.name)

      if ((!this.development && superAdmin) || match) {
        this.viewContainer.clear()
        this.viewContainer.createEmbeddedView(this.templateRef)
      } else {
        this.viewContainer.clear()
      }
    })
  }
}
