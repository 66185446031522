import { combineLatest, from, Observable, Observer, Subject } from 'rxjs'
import { EventEmitter, Injectable } from '@angular/core'
import { map, mergeMap } from 'rxjs/operators'
import { getToken, isSupported, Messaging, onMessage } from 'firebase/messaging'
import * as firebaseServiceWorker from 'firebase/messaging/sw'
import { Cacheable } from 'ts-cacheable'
import { FirebaseCoreService } from './firebase-core.service'

const DEVELOPMENT = process.env.NODE_ENV === 'development'
const SW_DIRECTORY = 'assets/sw/firebase-messaging-sw.js'
const cacheBusterObserver = new Subject<void>()

/**
 * This whole implementation is dated
 * on highly likely not 100% functional.
 *
 * @see https://firebase.google.com/docs/web/learn-more#modular-version
 */

@Injectable()
export class FirebasePushMessageService {
  onPushNotificationReceived = new EventEmitter<any>()
  onNewPushTokenReceived = new EventEmitter<string>()

  private _initialized: boolean
  private _messaging: Messaging
  private _worker: ServiceWorkerRegistration

  constructor(private _firebase: FirebaseCoreService) {}

  @Cacheable({ cacheBusterObserver })
  firebaseMessaging(): Observable<Messaging> {
    return combineLatest([from(isSupported()), firebaseServiceWorker.isSupported()]).pipe(
      mergeMap(([isSupported, isSwSupported]) => {
        if (isSupported && isSwSupported) {
          return this.registerServiceWorker()
        } else {
          throw new Error('Browser does not support ServiceWorkers.')
        }
      }),
      map((sw) => {
        if (!sw) {
          throw new Error('ServiceWorker: None found.')
        }

        if (this._firebase.messaging === undefined) {
          throw new Error('ServiceWorker: Not initialized.')
        }

        this._messaging = this._firebase.messaging
        this.registerMessageHandler()

        return this._messaging
      }),
    )
  }

  init(): void {
    // Can only happen once.
    if (this._initialized) return
    this._initialized = true

    // TODO: Reconsider/refactor.
    // Register new token.
    // this.firebaseMessaging()
    //   .pipe(mergeMap(() => this.getToken()))
    //   .subscribe(
    //     (token) => this.onNewPushTokenReceived.emit(token),
    //     (err) => console.info('FB subscribe error: ', err),
    //   )
  }

  getToken(): Observable<string> {
    return this.firebaseMessaging().pipe(mergeMap(() => from(getToken(this._messaging, { serviceWorkerRegistration: this._worker }))))
  }

  hasPermission(): boolean {
    return window['Notification']?.permission === 'granted'
  }

  private registerMessageHandler(): void {
    onMessage(this._messaging, (message) => {
      this.onPushNotificationReceived.emit(message)
    })
  }

  private registerServiceWorker(): Observable<ServiceWorkerRegistration | null> {
    return new Observable((observer: Observer<any>) => {
      navigator.serviceWorker
        .register(SW_DIRECTORY, {
          scope: DEVELOPMENT ? '/assets/sw/' : './',
        })
        .then(
          (registration) => {
            this._worker = registration
            observer.next(registration)
            observer.complete()
          },
          (error) => {
            console.error('ServiceWorker Error:', error)
            observer.error(error)
          },
        )
    })
  }
}
