import { mergeMap, filter, switchMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable, ReplaySubject } from 'rxjs'
import { SelectablePeriod, SelectableQuarter, SelectableYear } from '../../domain/administration/time/selectable-period'
import { AdministrationService } from './administration.service'
import { LoginService } from '../../core/security/login.service'
import { AccountingService } from '../accounting/accounting.service'
import { FiscalYear } from '../../domain/accounting/fiscal-year.model'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'

@Injectable()
export class PeriodsService {
  private _selectableYearsSubject$: ReplaySubject<SelectableYear[]> = new ReplaySubject(1)
  private _initialized: boolean

  constructor(
    private readonly _administration: AdministrationService,
    private readonly _loginService: LoginService,
    private readonly _accountingService: AccountingService,
  ) {
    _loginService.onLoggedIn.subscribe(() => this.reset())
    _administration.onSwitchedAdministration.subscribe(() => this.reset())
  }

  get allSelectablePeriods(): ReplaySubject<SelectableYear[]> {
    if (!this._initialized) {
      this.reset()
    }

    return this._selectableYearsSubject$
  }

  get currentYear(): Observable<SelectableYear> {
    const now = moment()

    return this.allSelectablePeriods.pipe(
      switchMap((years) => years),
      filter((year) => now.clone().startOf('year').isSame(year.startDate) && now.clone().endOf('year').isSame(year.endDate)),
    )
  }

  get currentQuarter(): Observable<SelectableQuarter> {
    const now = moment()

    return this.currentYear.pipe(
      switchMap((year) => year.quarters),
      filter((quarter: SelectableQuarter) => now.clone().startOf('quarter').isSame(quarter.startDate) && now.clone().endOf('quarter').isSame(quarter.endDate)),
    )
  }

  get currentMonth(): Observable<SelectableYear> {
    const now = moment()

    return this.currentQuarter.pipe(
      switchMap((quarter) => quarter.months),
      filter((month: SelectablePeriod) => now.clone().startOf('month').isSame(month.startDate) && now.clone().endOf('month').isSame(month.endDate)),
    )
  }

  reset() {
    this._initialized = true

    let settings: AdministrationSettings

    this._administration.defaultAdministrationSettings
      .pipe(
        mergeMap((s) => {
          settings = s

          return this._accountingService.fiscalYears
        }),
      )
      .subscribe((years) => this._selectableYearsSubject$.next(this.generateSelectablePeriods(moment(settings.start_date), years)))
  }

  private generateSelectablePeriods(administartionStart: moment.Moment, years: FiscalYear[]): SelectableYear[] {
    if (!years.length) {
      return
    }
    const end = moment(years[0].date_end)
    const start = administartionStart.clone()
    const selectableYears = []

    const diffYears = end.year() - start.year()

    for (let y = 0; y <= diffYears; y++) {
      const tempYear = start.clone().add(y, 'year')

      const currentYear = new SelectableYear(
        PeriodsService.getYearName(tempYear.year()),
        tempYear.year().toString(),
        tempYear.clone().startOf('year'),
        tempYear.clone().endOf('year'),
      )

      selectableYears.push(currentYear)

      let currentQuarter: SelectableQuarter
      let currentQuarterIndex = 1

      for (let m = 0; m < 12; m++) {
        if (m % 3 == 0) {
          const quarter = tempYear.clone().month(m)

          if (currentQuarter) {
            currentQuarter.months = currentQuarter.months.reverse()
          }

          // TODO: Make translateable.
          const label = `Kwartaal ${currentQuarterIndex}`

          currentQuarter = new SelectableQuarter(label, `${label} ${tempYear.year()}`, quarter.clone().startOf('quarter'), quarter.clone().endOf('quarter'))
          currentYear.quarters.push(currentQuarter)
          currentQuarterIndex++
        }

        if (y == 0 && m < administartionStart.month()) {
          continue
        }

        const currentMonth = tempYear.month(m)
        const label = currentMonth.format('MMMM').capitalize()
        currentQuarter.months.push(
          new SelectablePeriod(label, `${label} ${tempYear.year()}`, currentMonth.clone().startOf('month'), currentMonth.clone().endOf('month')),
        )
      }

      if (currentQuarter) {
        currentQuarter.months = currentQuarter.months.reverse()
      }

      if (currentQuarter && !currentQuarter.months.length) {
        currentYear.quarters.remove(currentQuarter)
      }

      currentYear.quarters = currentYear.quarters.reverse()
    }

    return selectableYears.reverse()
  }

  private static getYearName(year: number): string {
    return "'" + year.toString().substr(2, 2)
  }
}
