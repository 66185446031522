import { EditInvoice, EditInvoiceLine } from '../../domain/invoice/edit-invoice.model'
import { LineitemsEntity, VatitemsEntity } from '../../domain/document/klippa.model'
import { InvoiceTypes } from '../invoice/invoice-types.constants'
import { KlippaLedgerOption } from '../tellow/accounts-icon-map'

export type DocumentWithoutLines = Omit<EditInvoice, 'lines' | 'contact_id'>
export interface PostDocument extends Pick<DocumentWithoutLines, 'date'> {
  lines: Partial<EditInvoiceLine>[]
  vat_deductable?: boolean
  contact_name?: string
  contact_id?: number
  type?: InvoiceTypes
  number?: string
}

export type GenerationType = 'VATITEMS' | 'INVOICELINES' | 'BLANK'
export type MerchantType = {
  id?: number
  name: string
  street: string
  city: string
  zipcode: string
}

export type MetaDataType = {
  purchasedate: string
  number?: string
  type?: string | InvoiceTypes
}

export type AnnotatedLine = Partial<EditInvoiceLine>
export interface GeneratedLineWithType {
  type: GenerationTypes
  merchant: MerchantType
  meta: MetaDataType
  ledger: KlippaLedgerOption
  data: AnnotatedLine[]
  _info?: AnnotationInfo
}

export type AnnotationInfo = {
  vatExcluded: boolean
}

export enum GenerationTypes {
  BLANK = 'BLANK',
  VATITEMS = 'VATITEMS',
  INVOICELINES = 'INVOICELINES',
}

export type AdditionalRawDataType = {
  countryCode?: string
  vatContext?: string
  amountExclVat: number
  amount: number
}

export type MutatedKlippaOcrResponse = {
  meta: MetaDataType & Partial<MetaDataType>
  ledger: KlippaLedgerOption
  merchant: MerchantType
  vatitems: VatitemsEntity[]
  lines: LineitemsEntity[]
  raw: AdditionalRawDataType
}
