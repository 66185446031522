import { Observable, of } from 'rxjs'
import moment from 'moment'
import { catchError, map, mergeMap, publishReplay, refCount, startWith } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { EditInvoice, EditInvoiceLine } from '../../domain/invoice/edit-invoice.model'
import { ApiGateway } from '../../core/remote/api.gateway'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { InvoiceListItem, InvoiceListResponse, InvoiceMetadataResponse } from '../../domain/invoice/invoice-listitem.model'
import { endpoints } from '../../shared/config/endpoints'
import { Invoice, InvoiceLine } from '../../domain/invoice/invoice.model'
import { VatRate } from '../../domain/administration/vat-rate.model'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationService } from '../administration/administration.service'
import { Account } from '../../domain/administration/account.model'
import { InvoiceType } from '../../domain/invoice/type'
import { InvoiceSearchObject } from './invoice-search-object'
import { PurchaseInvoice } from '../../domain/invoice/purchase-invoice.model'
import { AccountingService } from '../accounting/accounting.service'
import { environment } from '../../../environments/environment'
import { WizardPage } from '../../domain/onboarding/wizardpage.model'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'

type GuardOptions = GroupedMonthlyState<InvoiceListItem[]> | InvoiceListItem[]
function isGroupedState(item: GuardOptions): item is GroupedMonthlyState<InvoiceListItem[]> {
  return typeof item === 'object'
}

@Injectable()
export class InvoiceService {
  suspendedInvoice: EditInvoice

  private _resumeInvoiceScreenUrl: string
  private _resumeInvoiceScreenUrlParams: any
  private _createInvoiceUrl = environment.routerLinks.invoices.index + '/' + environment.routerLinks.invoices.createInvoice

  public hasCreatedInvoices$: Observable<boolean> = this.getInvoices().pipe(
    map((results) => Boolean(results?.data?.invoice_list?.length)),
    startWith(undefined),
    publishReplay(1),
    refCount(),
  )

  constructor(
    private readonly _api: ApiGateway,
    private readonly _administration: AdministrationService,
    private readonly _accountingService: AccountingService,
    private readonly _router: TranslateRouteService,
    private readonly _list: ListHelper,
  ) {}

  /**
   * Get a list of invoices.
   * Note: This is a v2 endpoint.
   */
  getInvoices(searchObject?: InvoiceSearchObject): Observable<InvoiceListResponse> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._api.get<InvoiceListResponse>(endpoints.invoices.listInvoices, params)
      }),
    )
  }

  /**
   * Get the metadata for an invoice.
   * This is used for i.e. invoice limitation.
   */
  getInvoiceMetadata(): Observable<InvoiceMetadataResponse> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => {
        return this._api.get<InvoiceMetadataResponse>(endpoints.invoices.metadata, {
          administrationId: administration.id,
        })
      }),
    )
  }

  /**
   * Get the number of invoices
   * made in the current month.
   */
  getAmountForCurrentMonth(): Observable<number> {
    return this.getInvoiceMetadata().pipe(
      map(({ data }) => data.number_invoices_current_month),
      catchError((error) => {
        console.error('getAmountForCurrentMonth failed', error)

        return of(0)
      }),
    )
  }

  getInvoice(invoiceId: number): Observable<Invoice> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<Invoice>(endpoints.invoices.invoices, {
          administrationId: administration.id,
          invoiceId: invoiceId,
        }),
      ),
    )
  }

  getInvoiceQuestions(context: string): Observable<WizardPage[]> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.get<WizardPage[]>(endpoints.administration.complement, {
          administrationId: administration.id,
          context: context,
        }),
      ),
    )
  }

  duplicateInvoice(invoice: Invoice | InvoiceListItem): Observable<Invoice | null> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.post<Invoice>(endpoints.invoices.duplicate, null, {
          administrationId: administration.id,
          invoiceId: invoice.id,
        }),
      ),
    )
  }

  deleteInvoice(invoice: Invoice | InvoiceListItem): Observable<void> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._api.delete(endpoints.invoices.invoices, {
          administrationId: administration.id,
          invoiceId: invoice.id,
        }),
      ),
    )
  }

  rebookInvoice(invoice: InvoiceType, account: Account): Observable<any> {
    return this._administration.makeApiCallForDefaultAdministration(
      (p, b) => this._api.post(endpoints.invoices.rebook, b, p),
      {
        invoiceId: invoice.id,
      },
      {
        account_id: account.id,
      },
    )
  }

  getInvoiceForEditing(id: number): Observable<EditInvoice & Invoice> {
    let rates: VatRate[]

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration: Administration) => {
        rates = administration.vat_rates

        return this.getInvoice(id)
      }),
      map(
        (existing) =>
          ({
            ...existing,
            contact_id: existing.contact && existing.contact.id,
            address_id: existing.address && existing.address.id,
            template_id: existing.template && existing.template.id,
            lines: this.linesToEditLines(existing.lines, rates),
            self_iban: existing.details.self_iban,
          } as EditInvoice & Invoice),
      ),
    )
  }

  navigateToCreationScreen(requestedUrl?: string, params?: any): void {
    if (requestedUrl) this._resumeInvoiceScreenUrl = requestedUrl
    if (params) this._resumeInvoiceScreenUrlParams = params

    const url = this._resumeInvoiceScreenUrl || this._createInvoiceUrl
    void this._router.navigate(url, this._resumeInvoiceScreenUrlParams)
  }

  resumeToNewInvoiceScreen(extraParams?: { [key: string]: boolean }): void {
    if (this._resumeInvoiceScreenUrl) {
      this.navigateToCreationScreen(this._resumeInvoiceScreenUrl, { ...this._resumeInvoiceScreenUrlParams, ...extraParams })
    } else {
      this.navigateToCreationScreen('/contacts', { invoice: true, import: true })
    }
  }

  increaseInvoiceIndexNumber(): Observable<any> {
    return this._administration.defaultAdministrationSettings.pipe(map((settings) => settings.invoice_number_index++))
  }

  updateOverviewList(list: GroupedMonthlyState<InvoiceListItem[]> | InvoiceListItem[], invoice: Invoice): void {
    if (!invoice || !invoice.id || !list) {
      return
    }

    // TODO: Move to 'findInStateObject' when all lists are ported to GroupedMonthlyState.
    const listInvoice = Array.isArray(list)
      ? // Its an array, so its 'InvoiceListItem[]'
        list.find((i) => i.id == invoice.id)
      : // Its not an array, so its 'GroupedMonthlyState<T>'
        this._list.findInStateObject(list, invoice)

    // If there's no result, we need to add it.
    if (!listInvoice && isGroupedState(list)) {
      this._list.addToStateObject(list, invoice)
    }

    if (!listInvoice) {
      return
    }

    listInvoice.status = invoice.status
    listInvoice.number = invoice.number
    listInvoice.total_inc = invoice.total_inc
    listInvoice.expiry_date = invoice.expiry_date
    listInvoice.contact_name = invoice.contact_name
    listInvoice.date = invoice.date
    listInvoice.paid_date = invoice.paid_date
    listInvoice.paid_in_full = invoice.paid_in_full
    listInvoice.total_ex = invoice.total_ex
    listInvoice.type = invoice.type
  }

  removeFromOverviewList(list: GroupedMonthlyState<InvoiceListItem[]>, invoice: Invoice | EditInvoice): void {
    if (!list || !invoice) {
      return
    }

    const listInvoice = this._list.findInStateObject(list, invoice)

    if (listInvoice) {
      this._list.removeFromStateObject<InvoiceListItem>(list, invoice)
    }
  }

  isPositive(invoice: Invoice | PurchaseInvoice | InvoiceListItem): boolean {
    return (invoice.sale && !invoice.credit) || (!invoice.sale && invoice.credit)
  }

  // TODO: This could also work with PurchaseInvoice. See parameter definition of public isPositive(...) above.
  isEditable(invoice: Invoice): Observable<boolean> {
    if (!invoice.is_editable) {
      return of(false)
    }

    return this._accountingService.actionInFiscalYearAllowed(moment(invoice.date)).pipe(
      mergeMap((isAllowed) => {
        if (!isAllowed) {
          return of(false)
        }

        if (!invoice.credit) {
          return of(isAllowed)
        }

        return of(true)
      }),
    )
  }

  // TODO: This could also work with PurchaseInvoice. See parameter definition of public isPositive(...) above.
  isDeletable(invoice: Invoice): Observable<boolean> {
    if (!invoice.is_deletable) {
      return of(false)
    }

    return this._accountingService.actionInFiscalYearAllowed(moment(invoice.date)).pipe(mergeMap((allowed) => (!allowed ? of(allowed) : of(true))))
  }

  private linesToEditLines(existing: InvoiceLine[], rates: VatRate[]): EditInvoiceLine[] {
    const hasVat = existing.any((e) => e.vat_period !== null)

    return existing.map((e) => {
      const rate: VatRate = hasVat && rates.firstOrUndefined((r) => r.vat_periods.any((vp) => vp.id == e.vat_period.id))

      const line = <EditInvoiceLine>{
        description: e.description,
        quantity: e.quantity,
        price: e.price,
        discount_amount: e.discount_amount,
        unit_description: e.unit_description,
        discount_percentage: e.discount_percentage,
        vat_amount: e.vat_amount,
        total_amount_after_discount: e.total_amount_after_discount,
        total_amount_before_discount: e.total_amount_before_discount,
      }

      if (rate) {
        line.vat_rate_id = rate.id
      } else if (hasVat) {
        line.vat_rate_id = rates.firstOrUndefined() && rates.firstOrUndefined().id
      }

      return line
    })
  }
}
