import { combineLatest as observableCombineLatest, Observable, of as observableOf, of, Subject } from 'rxjs'
import { find, map, mergeAll, mergeMap, startWith, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from '../administration/administration.service'
import { endpoints } from '../../shared/config/endpoints'
import { CustomTemplate, NewCustomTemplateForm } from '../../domain/templating/template.model'
import { BaseTemplate } from '../../domain/templating/template.model'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationSettings } from '../../domain/administration/administration-settings.model'
import { Cacheable } from 'ts-cacheable'

const cacheBusterObserver = new Subject<void>()

@Injectable()
export class CustomInvoiceTemplateService {
  get currentTemplate(): CustomTemplate {
    return this._currentTemplate
  }

  set currentTemplate(template: CustomTemplate) {
    if (template) {
      this._currentTemplate = Object.assign({}, template)
    } else {
      this._currentTemplate = null
    }
  }

  private _currentTemplate: CustomTemplate

  constructor(private readonly _apiGateway: ApiGateway, private readonly _administrationService: AdministrationService) {
    cacheBusterObserver.subscribe(() => console.debug('👷 | Busted invoice template cache.'))
  }

  @Cacheable({ cacheBusterObserver })
  getCustomTemplates(): Observable<CustomTemplate[]> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<CustomTemplate[]>(endpoints.invoices.customTemplates, {
          administrationId: administration.id,
        }),
      ),
    )
  }

  getCustomTemplate(identifier: number): Observable<CustomTemplate> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.get<CustomTemplate[]>(endpoints.invoices.customTemplates, {
          administrationId: administration.id,
        }),
      ),
      mergeAll(),
      find(({ id }) => id === identifier),
    )
  }

  getTemplateLogoUrl(identifier: number): Observable<string | null> {
    if (!identifier) {
      return of(null)
    }

    return this.getCustomTemplate(identifier).pipe(
      map((template) => template?.logo),
      startWith(null),
    )
  }

  @Cacheable()
  getTemplates(): Observable<BaseTemplate[]> {
    return this._apiGateway.get<BaseTemplate[]>(endpoints.invoices.templates)
  }

  saveOrUpdateCustomTemplate(custom: NewCustomTemplateForm): Observable<number> {
    let administration: Administration
    let settings: AdministrationSettings

    // Prepare upload for existing template
    if (this.currentTemplate && !custom.logo) {
      delete custom.logo
    }

    return observableCombineLatest([
      this._administrationService.defaultAdministration.pipe(map((a) => (administration = a))),
      this._administrationService.defaultAdministrationSettings.pipe(map((s) => (settings = s))),
    ]).pipe(
      mergeMap(() => {
        const params = <any>{ administrationId: administration.id }

        if (this.currentTemplate) {
          params.id = this.currentTemplate.id
        }

        return this._apiGateway.postMultipart(endpoints.invoices.customTemplates, custom, params)
      }),

      mergeMap((template: CustomTemplate) => {
        // If no default present. Set default.
        if (settings.generated_template) {
          settings.generated_template = false
          settings.default_invoice_template = template.id

          return this._apiGateway
            .patch(endpoints.administration.settings, { default_invoice_template: template.id }, ['default_invoice_template'], {
              administrationId: administration.id,
            })
            .pipe(map(() => template.id))
        }

        return observableOf(template.id)
      }),
      tap(() => console.debug('📝 | Patched template')),
      tap(() => this.clearCache()),
    )
  }

  removeCustomTemplate(template: CustomTemplate): Observable<void> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.delete(endpoints.invoices.customTemplates, {
          administrationId: administration.id,
          id: template.id,
        }),
      ),
      tap(() => this.clearCache()),
    )
  }

  public clearCache(): void {
    void cacheBusterObserver.next()
  }
}
