import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(private readonly _router: Router) {}

  ngOnInit(): void {
    void this._router.navigateByUrl('')
  }
}
