import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core'
import { NgForm } from '@angular/forms'
import { FncFormChildComponent } from '../fnc-form-child.component'
import { FormDirective } from '../html-directives/form.directive'

@Component({
  selector: 'fnc-button',
  styleUrls: ['fnc-button.component.scss'],
  host: {
    '(click)': 'clickEvent($event)',
    '(mouseenter)': 'mouseEnterEvent($event)',
  },
  template: `
    <button
      mdl
      #button
      [ngClass]="{ 'mdl-button mdl-js-button': !iconOnly, icononly: iconOnly }"
      class="{{ fontSize }}"
      [disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [class.busy]="showSpinnerElement"
      (click)="btnClicked($event)"
      [attr.type]="isSubmitButton ? 'submit' : 'button'"
      [attr.hidden]="hidden"
    >
      <ng-content></ng-content>
      <div class="spinner-container" *ngIf="showSpinnerElement">
        <div #spinner class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active"></div>
      </div>
    </button>
  `,
})
export class FncButtonComponent extends FncFormChildComponent implements OnInit, AfterContentInit {
  get disabled() {
    return this._disabled || this._formBusy
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled
  }

  @Input()
  set showSpinnerOnFormBusy(show: boolean) {
    this._showSpinnerOnFormBusy = show
  }

  @Input()
  set showSpinner(showSpinner: boolean) {
    this.setShowSpinner(showSpinner)
  }

  @HostBinding('class.with-top-margin')
  @Input()
  withTopMargin: boolean = false

  @HostBinding('class.inline-spinner')
  @Input('inlineSpinner')
  inlineSpinner: boolean

  @HostBinding('class')
  @Input('spacing')
  spacing: 'narrow'

  @HostBinding('class')
  @Input('fontSize')
  fontSize: 'xs' | 'xs-sm' | 'sm' | 'base' | 'lg'

  showSpinnerElement: boolean
  @ViewChild('spinner') spinner: ElementRef
  @ViewChild('button', { static: true }) button: ElementRef
  @ViewChild('underline') line: ElementRef

  @Input() form: string
  @Input() isSubmitButton: boolean
  @Input() iconOnly: boolean
  @Input() hidden: boolean

  @Output() fncClick = new EventEmitter<any>()
  @Output() fncMouseOver = new EventEmitter<any>()

  private get _formBusy(): boolean {
    return this.parentForm?.busy ?? false
  }

  private _disabled: boolean
  private _showSpinnerOnFormBusy: boolean

  constructor(
    @Optional() @Inject(forwardRef(() => FormDirective)) parentForm: FormDirective,
    @Optional() @Inject(forwardRef(() => NgForm)) private _ngForm: NgForm,
    element: ElementRef,
  ) {
    super(element, parentForm)
  }

  ngOnInit(): void {
    if (this.parentForm) {
      this.parentForm.onBusy.subscribe(() => {
        this.onFormStateUpdate()
      })
    }
  }

  ngAfterContentInit(): void {
    if (this.button && this.button.nativeElement.innerHTML && this.button.nativeElement.innerHTML.length) {
      let text = this.button.nativeElement.innerHTML.toString()
      text = text.substr(0, text.indexOf('<'))
      this.button.nativeElement.title = text.trim()
    }
  }

  clickEvent(evt: Event): void {
    if (!this.disabled) {
      if (this.isSubmitButton && this._ngForm) {
        // this._ngForm.onSubmit(null);
        // return;
      } else if (this.isSubmitButton && this.parentForm) {
        this.parentForm.element.submit()

        return
      }

      this.fncClick.emit(evt)
    }
  }

  mouseEnterEvent(event: Event): void {
    void this.fncMouseOver.emit(event)
  }

  onFormStateUpdate(): void {
    if (this._showSpinnerOnFormBusy && this._formBusy) {
      this.setShowSpinner(true)
    } else if (this._showSpinnerOnFormBusy) {
      this.setShowSpinner(false)
    }
  }

  btnClicked(evt: Event): void {
    if (this._disabled || !this.isSubmitButton) {
      evt.preventDefault()
    }
  }

  private setShowSpinner(show: boolean): void {
    this.showSpinnerElement = show

    if (show) {
      setTimeout(() => {
        if (this.spinner) {
          componentHandler.upgradeElement(this.spinner.nativeElement)
          this.spinner.nativeElement.classList.add('show')
        }
      }, 0)
    }
  }
}
