/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { OptionLabelValue } from '../../domain/onboarding/question.model'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IconVariants, IconLibraries } from '../tlw-icon/tlw-icon.component'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { TranslateService } from '@ngx-translate/core'

export interface SliderOption<T> extends OptionLabelValue<T> {
  abbreviatedLabel?: string
}

export interface SliderWithIcon<T = any> extends OptionLabelValue<T> {
  icon: {
    icon: string
    variant?: IconVariants
    library?: IconLibraries
  }
}

type AvailableOptions = string | boolean | number

@Component({
  selector: 'tlw-slider',
  styleUrls: ['./tlw-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TlwSliderComponent),
      multi: true,
    },
  ],
  template: `
    <span tabindex="0" class="slider" role="checkbox" [class.disabled]="disabled" [class.active]="value === truthyCondition">
      <span aria-hidden="true" class="slider__selector" [class.active]="value === truthyCondition"></span>
      <div
        class="slider__option"
        [style.--min-width]="minLengthCalculation"
        *ngFor="let option of options; trackBy: trackByFn"
        [class.abbreviated-on-mobile]="option.abbreviatedLabel"
        [ngClass]="value === option.value ? 'active' : 'inactive'"
        (click)="$event.preventDefault(); $event.target.blur(); valueClicked(option.value)"
      >
        <span>
          <ng-container *ngIf="option.icon !== undefined">
            <tlw-icon [icon]="option.icon.icon" [height]="iconSizeForVariant" [variant]="option.icon.variant" [library]="option.icon.library"></tlw-icon>
          </ng-container>

          {{ (option.label | translate) || option.label }}
        </span>

        <!-- Leave this in; CSS hides it by default -->
        <span>{{ option.abbreviatedLabel }}</span>
      </div>
    </span>
  `,
})
export class TlwSliderComponent extends BaseHtmlDirective implements OnChanges, ControlValueAccessor {
  @Input() options: (SliderOption<AvailableOptions> | SliderWithIcon<AvailableOptions>)[]
  @Input() truthyCondition: AvailableOptions = true

  @HostBinding('class')
  @Input()
  variant: 'default' | 'medium' | 'large' | 'xlarge' = 'default'

  /**
   * Internval sorted options, to assure a regulated order.
   * If boolean, do not sort the array.
   */
  protected get internalOptions(): (SliderOption<AvailableOptions> | SliderWithIcon<AvailableOptions>)[] {
    return this.options?.any(({ value }) => typeof value === 'boolean') ? this.options : this.options?.sort((a, b) => (a.value as any) - (b.value as any))
  }

  private _disabled: boolean = false

  get disabled() {
    return this._disabled
  }

  @HostBinding('class.disabled')
  @Input()
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled
  }

  private innerValue: AvailableOptions

  @HostBinding('style.--data-length')
  get style() {
    return this.internalOptions?.length ?? 2
  }

  public set value(v: AvailableOptions) {
    if (v !== this.innerValue) {
      this.writeValue(v)
      this.change.emit(v)
      this.onChangeCallback(v)
    }
  }

  @Input()
  @HostBinding('style.--data-position')
  public get value(): AvailableOptions {
    return this.innerValue
  }

  @HostBinding('style.--options-index')
  get valueIndex(): number {
    return this.internalOptions?.findIndex((option) => option.value === this.innerValue)
  }

  @Output()
  public change = new EventEmitter<AvailableOptions>()

  protected get iconSizeForVariant(): number {
    switch (this.variant) {
      case 'medium':
        return 14
      case 'large':
      case 'xlarge':
        return 16
      default:
        return 12
    }
  }

  /**
   * Align the width of all label items.
   * Find the longest string, and use this to
   * set a maximum character width as value.
   */
  protected get minLengthCalculation(): string {
    const longestValue = this.assuredTranslatedValue(
      this.internalOptions
        .sort((a, b) => {
          const firstValue = this.assuredTranslatedValue(b.label).length
          const secondValue = this.assuredTranslatedValue(a.label).length

          return firstValue - secondValue
        })
        .lastOrUndefined().label,
    )

    return `${longestValue.length}ch`
  }

  constructor(public elRef: ElementRef, private readonly _translate: TranslateService) {
    super(elRef)
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const property in changes) {
      if (property) {
        this[property] = changes[property].currentValue
      }
    }
  }

  // for the next section see: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor

  valueClicked(value: AvailableOptions) {
    // If options exist; use them.
    if (this.options) {
      this.value = this.internalOptions.find((option) => option.value === value)?.value

      return
    }

    // Else, just set 'the value'.
    this.value = value
  }

  // From ControlValueAccessor interface
  writeValue(value: AvailableOptions) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  private onTouchedCallback = (_: any): void => {}
  private onChangeCallback = (_: any): void => {}

  protected trackByFn(index: number, item: SliderOption<AvailableOptions> | SliderWithIcon<AvailableOptions>) {
    return index
  }

  private assuredTranslatedValue = (value: string): string => {
    return value.includes('.') ? this._translate.instant(value) : value
  }
}
