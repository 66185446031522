import { map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import moment from 'moment'
import { ApiGateway } from '../../core/remote/api.gateway'
import { InvoiceListItem } from '../../domain/invoice/invoice-listitem.model'
import { endpoints } from '../../shared/config/endpoints'
import { Invoice } from '../../domain/invoice/invoice.model'
import { AdministrationService } from '../administration/administration.service'
import { PurchaseInvoice, PurchaseInvoiceLine } from '../../domain/invoice/purchase-invoice.model'
import { UploadDocument } from '../../domain/document/upload-document.model'
import { VatRate } from '../../domain/administration/vat-rate.model'
import { Administration } from '../../domain/administration/administration.model'
import { Account } from '../../domain/administration/account.model'
import { AccountingService } from '../accounting/accounting.service'
import { PurchaseInvoiceSearchObject } from './purchase-invoice-search-object'
import { GroupedMonthlyState, ListHelper } from '../helpers/list.helper'

@Injectable()
export class PurchaseInvoiceService {
  constructor(
    private readonly _apiGateway: ApiGateway,
    private readonly _administrationService: AdministrationService,
    private readonly _accountingService: AccountingService,
    private readonly _list: ListHelper,
  ) {}

  getPurchaseInvoices(searchObject?: PurchaseInvoiceSearchObject): Observable<InvoiceListItem[]> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) => {
        let params: any = {}

        if (searchObject) {
          params = searchObject.getParameters()
        }

        params.administrationId = administration.id

        return this._apiGateway.get<InvoiceListItem[]>(endpoints.invoices.invoices, params)
      }),
    )
  }

  getEditablePurchaseInvoice(invoiceId: number): Observable<PurchaseInvoice> {
    // declarations for async data fetching
    let rates: VatRate[]
    let accounts: Account[]

    return this._administrationService.administrationAccounts.pipe(
      mergeMap((a) => {
        // Store accounts and fetch administration for vat_rates
        accounts = a

        return this._administrationService.defaultAdministration
      }),
      mergeMap((administration: Administration) => {
        // Store vat_rates for use in invoice_line to editable invoice_line translation
        rates = administration.vat_rates

        return this._apiGateway.get<Invoice>(endpoints.invoices.invoices, {
          administrationId: administration.id,
          invoiceId: invoiceId,
        })
      }),
      map((invoice: Invoice) => {
        const result: PurchaseInvoice = <any>{}

        result.is_deletable = invoice.is_deletable
        result.id = invoice.id
        result.number = invoice.number
        result.description = invoice.description
        result.amount_paid = invoice.amount_paid
        result.source_document_id = invoice.source_document_id
        result.date = invoice.date
        result.expiry_date = invoice.expiry_date
        result.credit = invoice.credit
        result.file_name = invoice.file_name

        if (invoice.pdf_url) {
          result.pdf_url = `/${invoice.pdf_url}`
        }
        if (invoice.mime_type) {
          result.mime_type = invoice.mime_type
        }

        if (invoice.contact) {
          // For ext purchase invoices
          result.contact_id = invoice.contact.id
        }

        if (invoice.contact_name) {
          result.contact_name = invoice.contact_name
        }

        result.lines = invoice.lines.map((il) => {
          let vat_rate: VatRate
          let account: Account

          if (il.vat_period) {
            vat_rate = rates.find((r) => r.vat_periods.any((vp) => vp.id == il.vat_period.id))
          }

          if (il.booking_data) {
            account = accounts.find((a) => a.id == il.booking_data.account_id)
          }

          return <PurchaseInvoiceLine>{
            description: il.description,
            account_id: account && account.id,
            vat_rate_id: vat_rate && vat_rate.id,
            price: il.price,
            quantity: il.quantity,
            including_vat: invoice.including_vat,
          }
        })

        // Return prepared editable invoice.
        return result
      }),
    )
  }

  isPurchaseInvoiceEditable(invoice: PurchaseInvoice): Observable<boolean> {
    return this._accountingService.actionInFiscalYearAllowed(moment(invoice.date))
  }

  savePurchaseInvoice(invoice: PurchaseInvoice, document: UploadDocument): Observable<PurchaseInvoice> {
    const copy = Object.assign({}, invoice)
    delete copy.is_deletable
    delete copy.documents

    const data = <any>{ document: document.json, invoice: copy }

    if (document.file) {
      data.file = document.file
      data.document.type = 'SOURCE'
      data.document.file_name = document.file.name
    } else {
      delete data.document
    }

    const url = invoice.id ? endpoints.accounting.updatePurchaseInvoice : endpoints.invoices.purchaseInvoice

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        const params = <any>{ administrationId: a.id }

        if (invoice.id) {
          params.invoiceId = invoice.id
        }

        return this._apiGateway.postMultipart(url, data, params)
      }),
    )
  }

  finalizePurchaseInvoice(invoice: PurchaseInvoice): Observable<PurchaseInvoice> {
    const url = endpoints.accounting.finalizePurchaseInvoice

    return this._administrationService.defaultAdministration.pipe(
      mergeMap((a) => {
        const params: any = {
          administrationId: a.id,
          invoiceId: invoice.id,
        }

        return this._apiGateway.post<PurchaseInvoice>(url, invoice, params)
      }),
    )
  }

  removePurchaseInvoice(invoice: Invoice | PurchaseInvoice | InvoiceListItem | number): Observable<void> {
    return this._administrationService.defaultAdministration.pipe(
      mergeMap((administration) =>
        this._apiGateway.delete(endpoints.accounting.removePurchaseInvoice, {
          administrationId: administration.id,
          invoiceId: typeof invoice === 'number' ? invoice : invoice.id,
        }),
      ),
    )
  }

  removeFromOverviewList(list: GroupedMonthlyState<InvoiceListItem[]>, invoice: PurchaseInvoice | Invoice): void {
    if (!list || !invoice) {
      return
    }

    const listInvoice = this._list.findInStateObject<InvoiceListItem>(list, invoice)

    if (listInvoice) {
      this._list.removeFromStateObject<InvoiceListItem>(list, invoice)
    }
  }

  updateMasterList(list: GroupedMonthlyState<InvoiceListItem[]>, invoice: PurchaseInvoice): void {
    if (!list || !invoice) {
      return
    }

    const listInvoice = this._list.findInStateObject<InvoiceListItem>(list, invoice)

    if (!listInvoice) {
      return
    }

    listInvoice.status = invoice.status
    listInvoice.number = invoice.number
    listInvoice.total_inc = invoice.total_inc
    listInvoice.expiry_date = invoice.expiry_date
    listInvoice.contact_name = invoice.contact_name
    listInvoice.date = invoice.date
    listInvoice.paid_date = invoice.paid_date
    listInvoice.paid_in_full = invoice.paid_in_full
    listInvoice.total_ex = invoice.total_ex
  }
}
