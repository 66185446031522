import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs'
import { catchError, map, mergeMap, publishReplay, refCount } from 'rxjs/operators'
import { EventEmitter, Injectable } from '@angular/core'
import { HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { SecurityService } from '../security/security.service'
import { endpoints } from '../../shared/config/endpoints'
import { environment } from '../../../environments/environment'
import { Token } from '../../domain/user/token.model'
import { TellowError } from './error.interface'
import { routerlinks } from '../../../environments/routerlinks/routerlinks-nl'
import { version } from '../../../environments/version'
import { uuid4 } from '@sentry/utils'

export interface TellowRequestOptionsArgs {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  observe?: any // body
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | readonly (string | number | boolean)[]
      }
  reportProgress?: boolean
  responseType?: any | 'arraybuffer' | 'blob' | 'json' | 'text'
  withCredentials?: boolean
  skipPaywallFlow?: boolean
}

@Injectable()
export class SecureHttp extends HttpClient {
  onPaywallActionRequired = new EventEmitter<void>()
  paywallResultSubscriber = new EventEmitter<boolean>()
  onSessionExpired = new EventEmitter<void>()
  private _refreshInProgressObservable: Observable<boolean>
  private _payWallInProgressObservable: Observable<boolean>

  constructor(handler: HttpHandler, private _securityService: SecurityService, private _router: Router) {
    super(handler)
  }

  get<T>(url: string, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.get<T>(url, authOptions), options)
  }

  post<T>(url: string, body: any, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.post<T>(url, body, authOptions), options)
  }

  put<T>(url: string, body: string | object, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.put<T>(url, body, authOptions), options)
  }

  delete<T>(url: string, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.delete<T>(url, authOptions), options)
  }

  patch<T>(url: string, body: any | null, options?: TellowRequestOptionsArgs): Observable<T> {
    return this.intercept<T>((authOptions: TellowRequestOptionsArgs) => super.patch<T>(url, body, authOptions), options)
  }

  // Security interceptor.
  private intercept<T>(requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>, options?: TellowRequestOptionsArgs): Observable<T> {
    // If necessary wait for a blocking flow to be completed.
    if (this._refreshInProgressObservable) {
      return this._refreshInProgressObservable.pipe(mergeMap(() => this.executeInterceptedRequest(requestFunc, options)))
    }

    if (this._payWallInProgressObservable && (!options || !options.skipPaywallFlow)) {
      return this._payWallInProgressObservable.pipe(mergeMap(() => this.executeInterceptedRequest(requestFunc, options)))
    }

    return this.executeInterceptedRequest<T>(requestFunc, options)
  }

  private executeInterceptedRequest<T>(requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>, options?: TellowRequestOptionsArgs): Observable<T> {
    return requestFunc(this.getRequestOptionArgs(options)).pipe(
      catchError((err) => {
        if (
          err.status == 401 &&
          (err.url.startsWith(environment.apiUrl) || err.url.startsWith(environment.laravelServiceApiUrl)) &&
          !err.url.endsWith(endpoints.login.token) &&
          !err.url.endsWith(endpoints.onboarding.activate) &&
          !err.url.endsWith(endpoints.onboarding.userRegistration) &&
          !err.url.endsWith(endpoints.onboarding.companyRegistration) &&
          !err.url.endsWith(endpoints.onboarding.administrationRegistration) &&
          !err.url.endsWith(endpoints.onboarding.tokenVerification) &&
          !err.url.endsWith(endpoints.onboarding.emailVerification) &&
          !err.url.endsWith(endpoints.passkey.generateAuthenticationOptions) &&
          !err.url.endsWith(endpoints.passkey.validateAuthentication)
        ) {
          return this.attemptRefresh().pipe(mergeMap((result) => this.recoverAfterRefresh(result, requestFunc, options)))
        }

        if (err.status == 402) {
          /**
           * Determine if this is quote endpoints; steps:
           *  1 - Take out parameters (e.g. ':adminId').
           *  2 - Split all words at slashes.
           *  3 - Check if each words is present in URL.
           */
          const isQuoteEndpoint = endpoints.quotes.quotes
            .replace(/(:.+?(\/|\b))/gi, '')
            .split('/')
            .every((string) => new RegExp(`${string}\\b`, 'gi').test(err.url))

          /**
           * Exception to not throw the paywall when opening up
           * the 'go to quotes'-logic from the left menu
           * (which means, the 'quick actions'-button).
           */
          if (err.url.startsWith(environment.apiUrl) && isQuoteEndpoint) {
            if (process.env.NODE_ENV === 'development') {
              console.warn('%cThis is a hack. Needs refactoring later.', 'font-size: 2rem')
            }

            return observableThrowError(err)
          }
          // End to exception.

          this._securityService.showUserPaywall()
          this._securityService.saveUrlAfterPaywall(this._router.url)

          const body = err.error

          if (body.error_code == 9012) {
            // ex user
            this._securityService.existingUser(true)
            void this._router.navigate([routerlinks.main.paywall])

            return observableThrowError(err)
          } // trial user

          this._securityService.existingUser(false)
          void this._router.navigate([routerlinks.main.paywall])

          return observableThrowError(err)
        }

        return observableThrowError(err)
      }),
    )
  }

  // get/set access token
  private getRequestOptionArgs(options: TellowRequestOptionsArgs = {}): TellowRequestOptionsArgs {
    if (options == null) {
      options = <TellowRequestOptionsArgs>{}
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
    }

    ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers)
      .append('X-Referer', `${window.location.protocol}//${window.location.host}${window.location.pathname}`)
      .append('X-App', `WEB:${version}`)

    if (sessionStorage.getItem('window') !== null) {
      ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('X-Window', sessionStorage.getItem('window'))
    }

    if (!this._securityService.hasSupportToken) {
      if (this._securityService.hasDeviceID) {
        ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('X-Device-UID', this._securityService.deviceID)
      } else {
        ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('X-Device-UID', this._securityService.setDeviceID())
      }
    }

    if (this._securityService.hasToken) {
      ;(<HttpHeaders>options.headers) = (<HttpHeaders>options.headers).append('Authorization', `Bearer ${this._securityService.token.access_token}`)
    }

    return options
  }

  private attemptRefresh(): Observable<boolean> {
    if (!this._securityService.hasValidRefreshToken) {
      return observableOf(false)
    }

    if (this._refreshInProgressObservable) {
      return this._refreshInProgressObservable
    }

    // Build request body.
    const body = {
      grant_type: 'refresh_token',
      refresh_token: this._securityService.getUncachedToken().refresh_token,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }

    this._refreshInProgressObservable = super.post(environment.apiUrl + endpoints.login.token, body).pipe(
      map((response) => response),

      // Save on success
      map((token: Token) => {
        this._securityService.saveToken(token)

        this._refreshInProgressObservable = null

        return true
      }),

      // Pass error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((error) => {
        this._securityService.clearToken('SecureHttp')

        return observableOf(false)
      }),
      publishReplay(1),
      refCount(),
    )

    return this._refreshInProgressObservable
  }

  private recoverAfterRefresh<T>(
    result: boolean,
    requestFunc: (options: TellowRequestOptionsArgs) => Observable<T>,
    options?: TellowRequestOptionsArgs,
  ): Observable<T> {
    if (result) {
      // If refresh succeeded, then retry request.
      if (options) {
        if (options.headers) {
          options.headers = (<HttpHeaders>options.headers).delete('Authorization')
        }
      }

      return this.executeInterceptedRequest(requestFunc, options)
    }

    // If refresh failed. return to login page after doing a logoff call..
    this.delete(environment.apiUrl + endpoints.login.logout).subscribe(
      () => {
        this.afterSessionExpired()
      },
      (err) => {
        this.afterSessionExpired()
        console.error('Refresh failed, returning to login page', err)
      },
    )
  }

  private afterSessionExpired() {
    this.onSessionExpired.emit()
    this._securityService.clearToken('SecureHttp')
    void this._router.navigate([''])
    localStorage.removeItem('intercom')
    localStorage.removeItem('intercom-state')

    return observableThrowError('Session expired')
  }

  private handlePayWall(): Observable<boolean> {
    if (this._payWallInProgressObservable) {
      return this._payWallInProgressObservable
    }

    this._payWallInProgressObservable = Observable.create((observer) =>
      this.paywallResultSubscriber.subscribe((result) => {
        observer.next(result)
        observer.complete()

        this._payWallInProgressObservable = null
      }),
    )

    this.onPaywallActionRequired.emit()

    return this._payWallInProgressObservable
  }

  private recoverAfterPayWall<T>(
    result: boolean,
    requestFunc: (options: TellowRequestOptionsArgs) => Observable<HttpResponse<T>>,
    options?: TellowRequestOptionsArgs,
  ): Observable<HttpResponse<T>> {
    if (result) {
      return this.executeInterceptedRequest(requestFunc, options)
    }

    return observableThrowError(<TellowError>{
      error_code: -2,
      _isHandled: true,
    })
  }
}
