import { fromEvent as observableFromEvent, Observable, Subscription, forkJoin, of } from 'rxjs'
import { debounceTime, map, switchMap } from 'rxjs/operators'
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MenuDirective } from './menu.directive'
import { AdministrationService } from '../../logic/administration/administration.service'
import { TellowContext } from '../../domain/tellow/tellow-context'
import { FncMainLayoutComponent } from '../fnc-main-layout/fnc-main-layout.component'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { TlwMenuModalsComponent } from './modals/modals.component'
import { MenuItems, MenuService } from './menu.service'
import { BillingService } from '../../logic/administration/billing.service'
import { SubscriptionResponse, TellowPlans } from '../../domain/billing/plan.model'
import { globals } from '../../shared/config/globals'
import { KlippaOcrService } from '../../logic/external-services/klippa.service'
import { AdministrationSettings, LegalFormType } from '../../domain/administration/administration-settings.model'
import { InvoiceService } from '../../logic/invoices/invoice.service'
import { QuotesService } from '../../logic/quotes/quotes.service'
import { IntercomService } from '../../logic/external-services/intercom.service'
import { SegmentHelper } from '../../logic/external-services/segment.helper'
import { environment } from '../../../environments/environment'
import { TellowError } from '../../core/remote/error.interface'
import { LaravelService } from '../../logic/banking/laravel.service'
import { SwanAccount } from '../../domain/swan/account.model'
import { ToastrHelper } from '../../logic/helpers/toastr.helper'
import { TranslateService } from '@ngx-translate/core'
import { PasskeysService } from '../../logic/security/passkeys.service'
import { UserService } from '../../logic/user/user.service'

export type Feature = 'invoice' | 'quote' | 'upload'

export const IS_TAX_BUCKETS_MODAL_DISMISSED = 'isTaxBucketsModalDismissed'
export const IS_PASSKEY_MODAL_DISMISSED = 'isPasskeyModalDismissed'

@Component({
  selector: 'tlw-left-slideout-menu',
  templateUrl: './tlw-left-slideout-menu.component.html',
  styleUrls: ['./tlw-left-slideout-menu.component.scss'],
})
export class TlwLeftSlideoutMenuComponent implements OnInit, OnDestroy {
  isBV: boolean | null = null
  showVatMenuItem: boolean
  scrollElement: HTMLElement

  busySendingToKlippa: boolean = false

  hasSwanAccount: boolean = false
  hasSwanAccountWithIban: boolean = false
  canOnboardToTaxBuckets: boolean = false
  isSwanAccountSuspended: boolean
  isSwanAccountClosed: boolean
  private _hasPasskeys: boolean = false

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>
  @ViewChild('modals', { static: true }) modals: TlwMenuModalsComponent
  @ViewChild(MenuDirective, { static: true }) menuDirective: MenuDirective
  @ViewChild('list', { static: true }) list: ElementRef<HTMLUListElement>
  @ViewChild('content', { static: true }) content: ElementRef

  @Output() onScrollElementFound = new EventEmitter<HTMLElement>()

  planName$: Observable<keyof typeof TellowPlans> = this.billing.getInfo().pipe(map((info: SubscriptionResponse) => info.data?.plan?.name))
  protected plans = TellowPlans

  private _navSubscription: Subscription
  private _resizeSubscription: Subscription
  private _scrollSubscription: Subscription

  public static section: string

  get canSeeTransferButton(): boolean {
    return this.hasSwanAccountWithIban && !this.isSwanAccountSuspended && !this.isSwanAccountClosed
  }

  get canSeeCardsButton(): boolean {
    return this.hasSwanAccount && !this.isSwanAccountClosed
  }

  get shouldSeeBankingCardsNewContent(): boolean {
    return this.menu.isDoneLoadingSeenItems && this.canSeeCardsButton && !this.menu.hasSeenBankingCards
  }

  get shouldSeeBankingNewContent(): boolean {
    return this.shouldSeeBankingCardsNewContent && !this.menu.isBankingToggled
  }

  constructor(
    @Inject(forwardRef(() => FncMainLayoutComponent)) private readonly _parent: FncMainLayoutComponent,
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _translateRouter: TranslateRouteService,
    private readonly _administration: AdministrationService,
    private readonly _invoice: InvoiceService,
    private readonly _quote: QuotesService,
    private readonly _segment: SegmentHelper,
    private readonly _laravel: LaravelService,
    private readonly _route: ActivatedRoute,
    private readonly _toastr: ToastrHelper,
    private readonly _translate: TranslateService,
    private readonly _passkeys: PasskeysService,
    private readonly _userService: UserService,
    public intercom: IntercomService,
    public klippa: KlippaOcrService,
    public billing: BillingService,
    private cd: ChangeDetectorRef,
    public menu: MenuService,
  ) {}

  set activeContext(context: TellowContext) {
    TlwLeftSlideoutMenuComponent.section = context.context
  }

  ngOnInit() {
    this.subscribeOnMouseEvents()
    this.showHideItems()

    /** Change detection for menu service */
    this.menu.changeDetectionEmitter.subscribe(() => this.cd.detectChanges())

    this._navSubscription = this._router.events.subscribe(() => {
      if (this.content.nativeElement.children.length > 1) {
        this.scrollElement = this.content.nativeElement.children[1]

        this.onScrollElementFound.emit(this.scrollElement)

        this._scrollSubscription = observableFromEvent(this.scrollElement, 'scroll')
          .pipe(debounceTime(100))
          .subscribe(() => this.setPageHeaderShadow())
      }

      setTimeout(() => {
        this.setScrollableMenu()
      }, 0)
    })

    this._resizeSubscription = observableFromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.setScrollableMenu()
      })

    // Check if we should show the passkeys modal
    this._checkDisplayPasskeysModal()

    // Check for closed Tellow bank account return
    this._route.queryParamMap.pipe(map((param) => param.get('status'))).subscribe((status) => {
      if (this._isOnHomePage()) {
        if (status && status === 'Accepted') {
          this.modals.bankFeesConsentModal.close()
        }

        if (status && status !== 'Accepted') {
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        }

        if (status) {
          void this._router.navigate([], {
            queryParams: { consentId: null, env: null, resourceId: null, status: null },
            queryParamsHandling: 'merge',
          })

          this._laravel.clearCache()

          if (status === 'Accepted') window.location.reload()
        }
      }

      // Check for the existance of Swan Account and the IDD requirement
      this._checkSwanAccount()

      // Check if we should show the tax buckets onboarding modal
      this._showTaxBucketsModal()
      this._administration.onAdministrationUpdated.subscribe(() => this._showTaxBucketsModal())
    })
  }

  ngOnDestroy(): void {
    if (this._resizeSubscription) {
      this._resizeSubscription.unsubscribe()
    }

    if (this._navSubscription) {
      this._navSubscription.unsubscribe()
    }

    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe()
    }
  }

  /**
   * Bypass dependency injections
   * in order to make '.active' work.
   * Can also be an array, to handle deeply nested routes.
   */
  isActiveBypass(key: string | string[]): boolean {
    return Array.isArray(key) ? this.menu.isActiveArray(key) : this.menu.isActive(key)
  }

  /**
   * Set menu items
   * based on plans.
   *
   * Sets if tellow time
   * item should be shown
   * depending on user
   * creation date.
   */
  showHideItems(): void {
    this._administration.defaultAdministrationSettings.subscribe((settings) => {
      this.showVatMenuItem = settings.has_vat
      this.isBV = Boolean(settings.legal_form === LegalFormType.TYPE_BV)
    })
  }

  /**
   * You're drunk...
   */
  goHome(): void {
    this.menu.closeAllOthers()
    void this._translateRouter.navigate('/')
  }

  /**
   * Upsell 'em, yung boy.
   */
  routeToSettingsForUpselling(): void {
    const { index, account, billingSettings } = environment.routerLinks.settings
    void this._segment.track('Upgrade - Selected', { location: 'Menubar' })
    this.menu.closeAllOthers()
    void this._translateRouter.navigate(`${index}/${account}/${billingSettings}`)
  }

  protected navigateToQuoteOverview(): void {
    void this.billing.canRouteToQuotes$.subscribe(
      () => {
        this.menu.closeAllOthers('sales')
        void this._translateRouter.navigate('/quotes')
      },
      () => this.modals.quoteModal.open(),
    )
  }

  /**
   * Navigate a user from the
   * quick-actions in the menu.
   */
  navigateTo(feature: Feature): void {
    switch (feature) {
      case 'quote':
        this._segment.track('Quote Start', { location: 'Quick action' })
        this.billing.canRouteToQuotes$.subscribe(
          () => {
            this.menu.closeAllOthers('sales')
            this._quote.navigateToCreationScreen()
          },
          () => this.modals.quoteModal.open(),
        )
        break
      case 'invoice':
        this._segment.track('Invoice Start', { location: 'Quick action' })
        this.menu.closeAllOthers('sales')
        void this._invoice.navigateToCreationScreen()
        break
      case 'upload':
        if (this.busySendingToKlippa) return
        this._segment.track('Receipt Start', { location: 'Quick action' })
        void this.fileInput.nativeElement.click()
        break
      default:
        break
    }
  }

  navigateToCards(): void {
    if (this.shouldSeeBankingCardsNewContent) this.menu.setContentAsSeen('menu.banking.cards')

    const { settings } = environment.routerLinks

    this.menu.closeAllOthers('banking')
    void this._translateRouter.navigate(`${settings.index}/${settings.banking}/${settings.cardSettings}`)
  }

  navigateToIncomeTax(): void {
    this.menu.closeAllOthers('tax')
    void this._translateRouter.navigate(`${environment.routerLinks.vat.index}/${environment.routerLinks.vat.incomeTax}`)
  }

  redirectToHelpCenter(): void {
    void window.open(globals.urls.help, '_blank')
  }

  toggleMenuState(key: MenuItems): void {
    this.menu.toggle(key)
  }

  toggleMobileMenu() {
    if (this._el.nativeElement.classList.contains('menu-expanded')) {
      this._el.nativeElement.classList.remove('menu-expanded', 'mobile-expanded')
    } else {
      this._el.nativeElement.classList.add('menu-expanded', 'mobile-expanded')
    }
  }

  checkBankAccountBalance(): void {
    this._segment.track('Transfer - start')

    this._laravel.getBankAccountBalance$.subscribe((balance: number | null) => {
      if (balance !== null && balance > 0) {
        this.menu.closeAllOthers('banking')
        void this._translateRouter.navigate('/transferMoney')
      } else {
        this.modals.noBalanceModal.open()
      }
    })
  }

  public onFileAdded(event: Event): void {
    void this.klippa.addFileToAnnotate(event).subscribe(
      (response) => {
        this.menu.closeAllOthers('expenses')
        void this.klippa.navigateToAnnotation(response, { from: 'quick-actions' })
      },
      (error: TellowError) => {
        if (error.status_code === 406) {
          this.modals.expenseModal.open()
        }
      },
    )
  }

  private _checkDisplayPasskeysModal(): void {
    if (this._hasPasskeys) return

    const dismissed = localStorage.getItem(IS_PASSKEY_MODAL_DISMISSED)

    if (dismissed) return

    const user = this._userService.user
    const passkeys = this._passkeys.getPasskeys()

    forkJoin([user, passkeys]).subscribe(([user, passkeys]) => {
      this._hasPasskeys = passkeys ? passkeys.length > 0 : false

      if (!this._hasPasskeys && user.validated_email) {
        this._segment.track('Passkey Modal - Seen')
        this.modals.passkeysModal.open()
      }
    })
  }

  private _checkSwanAccount(): void {
    forkJoin([this._laravel.getBankAccount$, this._laravel.isAccountSuspended$, this._laravel.isAccountClosed$])
      .pipe(
        map(([account, isSwanAccountSuspended, isSwanAccountClosed]: [SwanAccount, boolean, boolean]) => {
          this.isSwanAccountSuspended = isSwanAccountSuspended
          this.isSwanAccountClosed = isSwanAccountClosed

          if (account !== null) {
            this.hasSwanAccount = true
            this.hasSwanAccountWithIban = account.iban !== null

            if (account.missing_idd_mandate) {
              this.modals.bankFeesConsentModal.open()
            }
          } else {
            this.hasSwanAccount = false
          }

          return account !== null
        }),
      )
      .subscribe((hasAccount) => {
        if (hasAccount) this.menu.checkSeenNewContent()
      })
  }

  private _showTaxBucketsModal(): void {
    const dismissed = localStorage.getItem(IS_TAX_BUCKETS_MODAL_DISMISSED)

    forkJoin([this._administration.defaultAdministrationSettings, this._laravel.isAccountClosedOrClosing$])
      .pipe(
        // Check if user is already using tax buckets and account is open,
        // then check if user has IBAN in a Swan account
        switchMap(([{ tax_bucket_start_date }, isAccountClosedOrClosing]: [AdministrationSettings, boolean]) =>
          isAccountClosedOrClosing || tax_bucket_start_date !== null ? of(false) : this._laravel.hasIBAN$,
        ),
      )
      .subscribe((shouldShowModal: boolean) => {
        this.canOnboardToTaxBuckets = shouldShowModal
        // If user has not dismissed the modal yet, show it
        if (shouldShowModal && dismissed === null) this.modals.taxBucketsOnboardingModal.open()
      })
  }

  private subscribeOnMouseEvents() {
    this.menuDirective.mouseOver.subscribe((value) => {
      if (value) {
        // Delay a little for the navigate classList.contains check
        setTimeout(() => this._el.nativeElement.classList.add('menu-expanded'), 0)
      } else {
        this._el.nativeElement.classList.remove('menu-expanded')
      }
    })

    this.menuDirective.clicked.subscribe((value) => {
      if (Boolean(value)) {
        // Delay a little for the navigate classList.contains check
        setTimeout(() => this._el.nativeElement.classList.add('menu-expanded'), 0)
      } else {
        this._el.nativeElement.classList.remove('menu-expanded')
      }
    })

    this.menuDirective.clicked.subscribe(() => {
      this.toggleMobileMenu()
    })
  }

  private setScrollableMenu() {
    const listBound = this.list.nativeElement.getBoundingClientRect()

    if (listBound.height > window.innerHeight - 102) {
      this.list.nativeElement.classList.add('scroll')
    } else {
      this.list.nativeElement.classList.remove('scroll')
    }
  }

  /**
   * If-statement to check is there is a pageheader.
   * fixes Sentry/Paywall issue.
   */
  private setPageHeaderShadow() {
    if (this._parent.pageHeader) {
      this._parent.pageHeader.showShadow = this.scrollElement.scrollTop > 0
    }
  }

  private _isOnHomePage(): boolean {
    const { url } = this._router

    return '/' === url.replace('#/', '')
  }
}
