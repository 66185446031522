import { Invoice } from './invoice.model'
import { InvoiceListItem } from './invoice-listitem.model'

export class InvoiceTypes {
  static TYPE_EXTERNAL_BEFORE_START_DATE = 'EXT_BEFORE_START'
  static TYPE_EXTERNAL_AFTER_START_DATE = 'EXT_AFTER_START'
  static TYPE_REGULAR = 'REGULAR'
  static TYPE_CREDIT = 'CREDIT'

  static isExternal(invoice: Invoice | InvoiceListItem) {
    return invoice.type == InvoiceTypes.TYPE_EXTERNAL_BEFORE_START_DATE || invoice.type == InvoiceTypes.TYPE_EXTERNAL_AFTER_START_DATE
  }

  static isImportedAfterStartDate(invoice: Invoice | InvoiceListItem) {
    return invoice.type == InvoiceTypes.TYPE_EXTERNAL_AFTER_START_DATE
  }
}
