import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { AppModule } from './app'
import { environment } from './environments/environment'

declare let process: any
const env = process.env.NODE_ENV || ''

if (environment.ng2ProductionMode && env !== 'development') {
  enableProdMode()
} else {
  console.debug('Running in development mode.')
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
