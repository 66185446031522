import { filter } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { AbstractMasterView } from './abstract-views/abstract-master.view'
import { AbstractDetailView } from './abstract-views/abstract-detail.view'

@Injectable()
export class MasterDetailOrchestrator {
  masterViewIsRestored: boolean

  private _masterView: AbstractMasterView<any, any>
  private _detailView: AbstractDetailView<any>
  private _masterDetailViewSetOnLastRun: boolean

  constructor(private readonly _router: Router) {
    this.trackMasterDetailLifeCycles()
  }

  setMasterView(view: AbstractMasterView<any, any>): void {
    // Check if this is a valid details -> master transition
    // And if state needs to be restored
    if (
      this._masterView &&
      view.masterOf == this._masterView.masterOf &&
      this._detailView &&
      this._detailView.detailOf.contains(view.masterOf) &&
      this._masterView.hasSavedState
    ) {
      this._detailView.updateMasterList(this._masterView.masterlist)
      view.restoreViewState(this._masterView.savedState, this._masterView.savedScrollTop)
      this.masterViewIsRestored = true
    }

    this._masterView = view
    this._detailView = null
    this._masterDetailViewSetOnLastRun = true
  }

  setDetailView(view: AbstractDetailView<any>): void {
    if (this._masterView && view.detailOf.contains(this._masterView.masterOf)) {
      this._detailView = view

      this._masterDetailViewSetOnLastRun = true
    }
  }

  getMasterList(): any | any[] {
    if (this.isDetailViewOfCurrentMaster()) {
      return this._masterView.masterlist
    }
  }

  isDetailViewOfCurrentMaster(): boolean {
    return this._detailView && this._masterView && this._detailView.detailOf.contains(this._masterView.masterOf)
  }

  forceMasterViewUpdate(): void {
    this._masterView = null
  }

  private trackMasterDetailLifeCycles(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._router.events.pipe(filter((evt) => evt instanceof NavigationStart)).subscribe((evt: NavigationStart) => {
      if (this._masterView && !this.isDetailViewOfCurrentMaster()) {
        this._masterView.saveViewState()
      }

      this._masterDetailViewSetOnLastRun = false
      this.masterViewIsRestored = false
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((evt) => {
      if (!this._masterDetailViewSetOnLastRun) {
        this._masterView = null
        this._detailView = null
      }
    })
  }
}
