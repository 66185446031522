import { Component, ViewChild } from '@angular/core'
import { TranslateRouteService } from '../../../core/logic/i18n/translate-route.service'
import { Plan, SubscriptionResponse, TellowPlans } from '../../../domain/billing/plan.model'
import { BillingService } from '../../../logic/administration/billing.service'
import { IntercomService } from '../../../logic/external-services/intercom.service'
import { globals } from '../../../shared/config/globals'
import { FncModalComponent } from '../../../ui-components/fnc-modal/fnc-modal.component'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { indicate } from '../../../core/operators/indicate'
import { AdministrationService } from '../../../logic/administration/administration.service'
import { LegalFormType } from '../../../domain/administration/administration-settings.model'
import { SegmentHelper } from '../../../logic/external-services/segment.helper'
import { TlwFullScreenUpsellModalComponent } from '../../../ui-components/tlw-full-screen-upsell-modal/tlw-full-screen-upsell-modal.component'
import { environment } from '../../../../environments/environment'
import { FncConfirmModalComponent } from '../../../ui-components/fnc-modal/fnc-confirm-modal/fnc-confirm-modal.component'
import { TlwOnboardingModalComponent } from '../../../ui-components/tlw-onboarding-modal/tlw-onboarding-modal.component'
import { ToastrHelper } from '../../../logic/helpers/toastr.helper'
import { LaravelService } from '../../../logic/banking/laravel.service'
import { TranslateService } from '@ngx-translate/core'
import { IS_PASSKEY_MODAL_DISMISSED, IS_TAX_BUCKETS_MODAL_DISMISSED } from '../tlw-left-slideout-menu.component'
import { FncAlertModalComponent } from '../../../ui-components/fnc-modal/fnc-alert-modal/fnc-alert.modal.component'

@Component({
  selector: 'modals',
  styleUrls: ['./modals.component.scss'],
  templateUrl: './modals.component.html',
})
export class TlwMenuModalsComponent {
  // Upsell
  @ViewChild('quoteModal', { static: true }) quoteModal: TlwFullScreenUpsellModalComponent
  @ViewChild('expenseModal', { static: true }) expenseModal: TlwFullScreenUpsellModalComponent
  @ViewChild('incomeTaxModal', { static: true }) incomeTaxModal: TlwFullScreenUpsellModalComponent

  // Onboarding
  @ViewChild(TlwOnboardingModalComponent) onboardingModal: TlwOnboardingModalComponent

  // Generic modals
  @ViewChild('agerasModal', { static: true }) agerasModal: FncModalComponent
  @ViewChild('supportModal', { static: true }) supportModal: FncModalComponent
  @ViewChild('tellowTime', { static: true }) tellowTime: FncModalComponent
  @ViewChild('ibModal') ibModal: FncModalComponent
  @ViewChild(FncConfirmModalComponent, { static: true }) confirm: FncConfirmModalComponent
  @ViewChild('upsellModal', { static: true }) upsellModal: FncModalComponent

  // Banking modal
  @ViewChild('bankFeesConsentModal', { static: true }) bankFeesConsentModal: FncModalComponent

  // Tax Buckets Onboarding Modal
  @ViewChild('taxBucketsOnboardingModal', { static: true }) taxBucketsOnboardingModal: FncModalComponent

  // No balance Modal
  @ViewChild('noBalanceModal', { static: true }) noBalanceModal: FncAlertModalComponent

  // Passkeys Modal
  @ViewChild('passkeysModal', { static: true }) passkeysModal: FncModalComponent

  // Internal variables
  planName: TellowPlans
  lockUserToThisPlanForMonths: number = 0

  iconSize: number = 16
  intercomArticles: string = globals.urls.intercomArticles
  calendlySupportCall: string = globals.urls.calendlySupportCall
  inboundSupport: string = globals.inboundSupportNumber
  incomeTaxModalLocation: string

  completePlanActive: boolean
  canMakePhoneCalls: boolean

  disableBankingFeeModalButtons$ = new BehaviorSubject<boolean>(false)
  disableTaxBucketsOnboardingModalButtons$ = new BehaviorSubject<boolean>(false)

  agerasTypeformUrl: string = 'https://nl.ageras.com/contactformulier?utm_source=Tellow&utm_medium=Tellow&utm_campaign=Tellow&utm_term=tellow'
  helpCenterUrl = globals.urls.passkeysHelpCenter

  // Predefine upsell price values to allow for correct rendering
  upsellPrices: { [key: string]: number } = {
    plus: environment.plusUpsellPrice,
    complete: environment.completeUpsellPrice,
  }

  legalForm: LegalFormType

  loading$ = new BehaviorSubject<boolean>(true)

  bankingBillingDate = new Date('2023-10-04T12:00:00')

  taxBucketsLottieAnimationOptions = {
    path: './assets/animations/tax-buckets/onboarding-animation.json',
  }

  constructor(
    private readonly _admin: AdministrationService,
    private readonly _tr: TranslateRouteService,
    private readonly _intercom: IntercomService,
    private readonly _segment: SegmentHelper,
    private readonly _laravel: LaravelService,
    private readonly _toastr: ToastrHelper,
    private readonly _translate: TranslateService,
    public billing: BillingService,
  ) {
    this.setCurrentPlan()
  }

  public open(): void {
    this.upsellModal.open()
  }

  public close(): void {
    this.upsellModal.close()
  }

  public confirmUpsell(): void {
    void this.confirm.open()
  }

  setCurrentPlan(): void {
    const plans = this.billing.getPlans()
    const info = this.billing.getInfo(true)

    combineLatest([plans, info])
      .pipe(indicate(this.loading$))
      .subscribe(([plans, info]: [Plan, SubscriptionResponse]) => {
        this.completePlanActive = info.data?.features.canAskFinancialAdvice ?? false
        this.canMakePhoneCalls = info.data?.features.canMakePhoneCalls ?? false

        this.upsellPrices.plus = plans.data.find((plan) => plan.tier === 2 && plan.lockUserToThisPlanForMonths === 12).priceInCents / 100
        this.upsellPrices.complete = plans.data.find((plan) => plan.tier === 3).priceInCents / 100
        this.lockUserToThisPlanForMonths = info.data?.plan?.lockUserToThisPlanForMonths
        this.planName = info.data?.plan?.name
      })
  }

  isLegalFormBV(): Observable<boolean> {
    return this._admin.defaultAdministrationSettings.pipe(map(({ legal_form }) => legal_form === LegalFormType.TYPE_BV))
  }

  isCompleetUser(): Observable<boolean> {
    return this.billing.getInfo(true).pipe(map((info: SubscriptionResponse) => info.data?.plan?.name === TellowPlans.Compleet || false))
  }

  openBookkeeping(): void {
    this._admin.defaultAdministrationSettings.subscribe((settings) => {
      if (settings.legal_form === LegalFormType.TYPE_BV) {
        this.agerasModal.open()
      } else {
        this.incomeTaxModalLocation = 'Side navigation'
        this.incomeTaxModal.open()
      }
    })
  }

  /**
   * Determines if the user has the complete plan active.
   * Continues to next steps accordingly.
   *
   * @param {string} location Location from which the modal is opened
   * @param {boolean} closeModal Defines if the support modal should be closed. (Defaults to false)
   */
  startFinancialAdvice(location: 'Side navigation' | 'Support modal', closeModal: boolean = false): void {
    this.isCompleetUser().subscribe((isComplete) => {
      if (isComplete) {
        this.agerasModal.open()
      } else {
        this.incomeTaxModalLocation = location
        this.incomeTaxModal.open()
      }

      if (closeModal) this.supportModal.close()
    })
  }

  goToQuotes(): void {
    void this._tr.navigate('/quotes')
  }

  dismissPasskeyModal(): void {
    this._segment.track('Passkey Modal - Dismissed')
    localStorage.setItem(IS_PASSKEY_MODAL_DISMISSED, 'true')
    this.passkeysModal.close()
  }

  goToPasskeys(): void {
    this._segment.track('Passkey Modal - CTA clicked')
    localStorage.setItem(IS_PASSKEY_MODAL_DISMISSED, 'true')
    this.passkeysModal.close()

    const { index, account, securitySettings } = environment.routerLinks.settings
    void this._tr.navigate(`/${index}/${account}/${securitySettings}`)
  }

  startSupport(): void {
    this.supportModal.close()
    this._intercom.open()
  }

  closeModal(): void {
    this.supportModal.close()
    this.quoteModal.close()
  }

  redirectToIntercomArticles(): void {
    this.supportModal.close()
    void window.open(this.intercomArticles, '_blank')
  }

  redirectToCalendly(): void {
    this.supportModal.close()
    void window.open(this.calendlySupportCall, '_blank')
  }

  redirectToAgerasTypeform(): void {
    this._segment.track('Ageras Modal - Get 3 Quotes - Clicked')
    this.agerasModal.close()
    void window.open(this.agerasTypeformUrl, '_blank')
  }

  closeSwanAccount(): void {
    const loadingToast = this._toastr.loading(this._translate.instant('MESSAGES.LOADING'))

    this._laravel
      .closeSwanAccount(window.location.origin)
      .pipe(indicate(this.disableBankingFeeModalButtons$))
      .subscribe(
        ({ consent_url }: { consent_url: string }) => {
          loadingToast.close()
          this._laravel.clearCache()
          window.open(consent_url, '_self')
        },
        () => {
          loadingToast.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  acceptBankingFeesConsent(): void {
    const loadingToast = this._toastr.loading(this._translate.instant('MESSAGES.LOADING'))

    this._laravel
      .setupIddMandate()
      .pipe(indicate(this.disableBankingFeeModalButtons$))
      .subscribe(
        () => {
          loadingToast.close()
          this.bankFeesConsentModal.close()
          this._laravel.clearCache()
          window.location.reload()
        },
        () => {
          loadingToast.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  dismissTaxBucketsOnboadingModal(): void {
    localStorage.setItem(IS_TAX_BUCKETS_MODAL_DISMISSED, 'true')
    this.taxBucketsOnboardingModal.close()
  }

  startTaxBucketsOrUpgrade(): void {
    this.billing.canUseTaxBucket$
      .subscribe((canUseTaxBucket) => {
        if (canUseTaxBucket) {
          this.startUsingTaxBuckets()
        } else {
          this.billing.goToPaywallOrUpgrade('tax bucket', 2, () => this.startUsingTaxBuckets())
        }
      })
      .unsubscribe()
  }

  startUsingTaxBuckets(): void {
    const loadingToast = this._toastr.loading(this._translate.instant('MESSAGES.LOADING'))

    this._admin
      .setTaxBucketStartDate()
      .pipe(indicate(this.disableTaxBucketsOnboardingModalButtons$))
      .subscribe(
        () => {
          loadingToast.close()
          this.taxBucketsOnboardingModal.close()
          this._toastr.success(this._translate.instant('TAX_BUCKETS.ONBOARDING.START_USING_TAX_BUCKETS_SUCCESS'))
        },
        () => {
          loadingToast.close()
          this._toastr.error(this._translate.instant('MESSAGES.SOMETHING_WENT_WRONG'))
        },
      )
  }

  goToTopupScreen(): void {
    void this._tr.navigate('/banking/topup')
  }
}
