import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { SecurityService } from './security.service'
import { TranslateRouteService } from '../logic/i18n/translate-route.service'

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private readonly _security: SecurityService, private readonly _router: TranslateRouteService) {}

  /**
   * Whether we can activate.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this._security.hasValidRefreshToken) {
      return true
    }

    // Store url for redirect after login
    let params: any

    if (state.url && state.url !== '/') {
      params = { redirectUrl: state.url }
    }

    void this._router.navigate('/login', params)
  }
}
