export const JustificationTypes = {
  PRIVATE: 'PRIVATE',
  INVOICE: 'INVOICE',
  NO_INVOICE: 'NO_INVOICE',
  VAT_DECLARATION: 'VAT_DECLARATION',
  SUPPLETION: 'SUPPLETION',
  PAYMENT_DIFFERENCE: 'PAYMENT_DIFFERENCE',
  MANUAL: 'MANUAL',
  BUSINESS_SAVINGS: 'BUSINESS_SAVINGS',
  SAVINGS_INTEREST: 'SAVINGS_INTEREST',
}
