import { Directive, ElementRef, EventEmitter, Output } from '@angular/core'

@Directive({
  selector: '.menu',
  host: {
    '(mouseenter)': 'mouseEnter()',
    '(mouseleave)': 'mouseOut()',
    '(click)': 'click($event)',
  },
})
export class MenuDirective {
  @Output() mouseOver = new EventEmitter<boolean>()
  @Output() clicked = new EventEmitter<void>()

  constructor(public el: ElementRef) {}

  mouseEnter() {
    this.mouseOver.emit(true)
    this.el.nativeElement.classList.add('mouse-over')
  }

  mouseOut() {
    this.mouseOver.emit(false)
    this.el.nativeElement.classList.remove('mouse-over')
  }

  click(evt: any) {
    if (!evt.target.classList.contains('menu')) {
      return
    }
    this.clicked.emit()
  }
}
