/* eslint-disable @typescript-eslint/no-unused-vars */
import { KeyValue } from '@angular/common'
import { Injectable } from '@angular/core'
import { EditInvoice } from '../../domain/invoice/edit-invoice.model'
import { Invoice } from '../../domain/invoice/invoice.model'
import { Quote } from '../../domain/quote/quote.model'
import { EditQuote } from '../../domain/quote/edit-quote.model'
import { PurchaseInvoice } from '../../domain/invoice/purchase-invoice.model'
import { TransactionListItem } from '../../domain/transaction/transaction-listitem.model'
import { InvoiceListItem } from '../../domain/invoice/invoice-listitem.model'
import { QuoteListItem } from '../../domain/quote/quote-listitem.model'
import { DocumentListItem } from '../../domain/document/document-listitem.model'
import { Document } from '../../domain/document/document.model'
import { Expense } from '../../domain/expenses/expense.model'
import { TranslateService } from '@ngx-translate/core'
import { TransactionListItemWithOpenStatus } from '../../views/transactions/tx-overview-table/tx-overview-table.component'

export type StateObjectTypes =
  | Invoice
  | InvoiceListItem
  | EditInvoice
  | Quote
  | QuoteListItem
  | EditQuote
  | PurchaseInvoice
  | TransactionListItem
  | TransactionListItemWithOpenStatus
  | Document
  | DocumentListItem
  | Expense

export type ZeroIndexMonths = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

// This type will serve us until 2999. LOL
export interface GroupedMonthlyState<T extends Partial<StateObjectTypes>[]> {
  [key: `2${number}-${ZeroIndexMonths}`]: T
}

/**
 * User-Defined Type Guards
 */
function isTransaction(item: StateObjectTypes): item is TransactionListItem {
  return (item as TransactionListItem).booking_date !== undefined
}

function isDocument(item: StateObjectTypes): item is DocumentListItem | Document {
  return (item as DocumentListItem | Document).created_at !== undefined
}

/**
 * Expenses either inherit the id from
 * a file/invoice, or a transaction.
 * They do not have one of their own.
 */
function isExpense(item: StateObjectTypes): item is Expense {
  return (item as Expense).related_id !== undefined
}

function getDateGuard(item: StateObjectTypes) {
  if (isTransaction(item)) {
    return item.booking_date
  }

  if (isDocument(item)) {
    return item.created_at
  }

  return item.date
}

@Injectable()
export class ListHelper {
  constructor(private readonly _translate: TranslateService) {}
  /**
   * Helper to stamp a date on the
   * monthly filter shown below.
   */
  protected stamp(date: string): `${string}-${string}` {
    return `${new Date(date).getFullYear()}-${new Date(date).getMonth()}`
  }

  /**
   * Find data in nested state object.
   * TODO: Refactor for performance.
   */
  findInStateObject<T extends StateObjectTypes>(object: GroupedMonthlyState<T[]>, entity: StateObjectTypes): T | null {
    const stamp = this.stamp(getDateGuard(entity))
    const monthIdx = Object.keys(object).findIndex((key) => key === stamp)
    const itemIdx = Object.values(object)[monthIdx]?.findIndex(({ id }) => {
      return id === (isExpense(entity) ? entity.related_id : entity.id)
    })

    if (typeof monthIdx !== 'number') {
      console.warn('No match for month found in list.')

      return null
    }

    if (typeof itemIdx !== 'number') {
      console.warn('No match for item found in list.')

      return null
    }

    return Object.values(object)[monthIdx][itemIdx]
  }

  /**
   * Push new entity into the state object.
   * Useful for e.g. updating the list after duplicating.
   * TODO: Refactor for performance.
   */
  addToStateObject<T extends StateObjectTypes>(object: GroupedMonthlyState<T[]>, entity: StateObjectTypes): void {
    const stamp = this.stamp(getDateGuard(entity))
    const monthIdx = Object.keys(object).findIndex((key) => key === stamp)

    if (monthIdx === -1) {
      // Add month to object.
      object[stamp] = []

      // Recurse fn-call.
      return this.addToStateObject(object, entity)
    }

    // Get two digit day string (e.g. '06')
    const today = new Date().toLocaleDateString('en-GB', { day: '2-digit' })

    // Find last index of given (above) date.
    const lastDayIndex = Object.values(object)[monthIdx]?.findLastIndex((item) => {
      return new RegExp(`-${today}$`).test(item?.date)
    })

    // If there are no entries for 'today', add add the beginning.
    if ('date' in entity && lastDayIndex === -1) {
      Object.values(object)[monthIdx].unshift(entity)
    }

    // Date available? Put at end of day in the array.
    if ('date' in entity && lastDayIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      Object.values(object)[monthIdx].splice(lastDayIndex + 1, 0, entity)
    }

    // No date? Just put at end of array.
    if (!('date' in entity)) {
      Object.values(object)[monthIdx].push(entity)
    }
  }

  /**
   * Find and remove from state object.
   * TODO: Refactor for performance.
   */
  removeFromStateObject<T extends StateObjectTypes>(object: GroupedMonthlyState<T[]>, entity: StateObjectTypes): void {
    const stamp = this.stamp(getDateGuard(entity))
    const monthIdx = Object.keys(object).findIndex((key) => key === stamp)
    const itemIdx = Object.values(object)[monthIdx].find(({ id }) => {
      return id === (isExpense(entity) ? entity.related_id : entity.id)
    })

    Object.values(object)[monthIdx].removeById(itemIdx)
  }

  /**
   * Same as 'findInStateObject', but you
   * can supply a custom filter function.
   * TODO: Refactor for performance.
   */
  findInFilteredStateObject<T extends StateObjectTypes>(fn: (expression) => boolean, object: GroupedMonthlyState<T[]>, entity: StateObjectTypes): T {
    const stamp = this.stamp(getDateGuard(entity))
    const monthIdx = Object.keys(object).findIndex((key) => key === stamp)
    const itemIdx = Object.values(object)[monthIdx].findIndex(fn)

    return Object.values(object)[monthIdx][itemIdx]
  }

  /**
   * Filter used for month filtering
   * in the infinite scroller logic.
   */
  toMonthlyState<T extends StateObjectTypes>(value: T[], state: GroupedMonthlyState<T[]> = {}): GroupedMonthlyState<T[]> {
    for (let index = 0; index < value.length; index++) {
      const key = this.stamp(getDateGuard(value[index] as any))
      state[key] = [...(state[key] || []), value[index]]
    }

    return state
  }

  /**
   * Sorting function with moment,
   * returns descending by date.
   * Comparable with Array.sort().
   */
  descender<T extends StateObjectTypes>(a: KeyValue<number, T[]>, b: KeyValue<number, T[]>): number {
    return new Date(b.key).getTime() - new Date(a.key).getTime()
  }

  /**
   * Sorting function with moment,
   * returns ascending by date.
   * Comparable with Array.sort().
   */
  ascender<T extends StateObjectTypes>(a: KeyValue<number, T[]>, b: KeyValue<number, T[]>): number {
    return new Date(a.key).getTime() - new Date(b.key).getTime()
  }

  /**
   * Keep track of groups for
   * transitions when loading.
   */
  trackByGroup = (index: number, _: any) => index

  /**
   * Track items by id for performance.
   */
  trackById = (_: number, item: StateObjectTypes) => (isExpense(item) ? item.related_id : item.id)

  /**
   * Get the month as a string
   * (e.g. February) based on
   * zero-index month number.
   */
  getMonthString(n: string, month: string = n.slice(5)) {
    const locale = this._translate.currentLang === 'en' ? 'en-EN' : 'nl-NL'

    return new Date(1970, parseInt(month), 1).toLocaleString(locale, { month: 'long' })
  }

  /**
   * Same, but by year.
   */
  getYearString(n: string, year: string = n.slice(0, 4)) {
    return new Date(parseInt(year), 0, 1).getFullYear()
  }

  /**
   * Diff the state object to e.g. compare new changes.
   * (prevent undefined values; Object.entries does not allow this.)
   */
  hasDifferences<T extends StateObjectTypes>(a: GroupedMonthlyState<T[]>, b: GroupedMonthlyState<T[]>): boolean {
    if (a === undefined) {
      return null
    }

    return this.testObjectsForDeepEquality(a, b)
  }

  /**
   * Check object for deep equality.
   * This is way faster than using Object.entries + .reduce().
   * Default values are empty objects, to prevent 'non-iterable' issues.
   *
   * Altered version of code from article below (fairly generic).
   * @see https://dmitripavlutin.com/how-to-compare-objects-in-javascript/
   */
  private testObjectsForDeepEquality<T extends StateObjectTypes>(a: GroupedMonthlyState<T[]> = {}, b: GroupedMonthlyState<T[]> = {}): boolean {
    const cachedObjectKeys = Object.keys(a)
    const secondCompareObject = Object.keys(b)

    if (cachedObjectKeys.length !== secondCompareObject.length) {
      return false
    }

    for (const key of cachedObjectKeys) {
      const firstValue = a[key]
      const secondValue = b[key]
      const areObjects = this.isObject(firstValue) && this.isObject(secondValue)

      if ((areObjects && !this.testObjectsForDeepEquality(firstValue, secondValue)) || (!areObjects && firstValue !== secondValue)) {
        return false
      }
    }

    return true
  }

  private isObject(object: any): boolean {
    return object != null && typeof object === 'object'
  }
}
