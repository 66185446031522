import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import moment from 'moment'
import { Justification } from '../../domain/justification/justification.model'
import { endpoints } from '../../shared/config/endpoints'
import { AdministrationService } from '../administration/administration.service'
import { ApiGateway } from '../../core/remote/api.gateway'
import { JustificationTypes } from '../../domain/justification/justificationtypes.constants'
import { Document } from '../../domain/document/document.model'
import { globals } from '../../shared/config/globals'
import { Account } from '../../domain/administration/account.model'
import { Invoice } from '../../domain/invoice/invoice.model'
import { PeriodOrDeclaration } from '../../domain/vat/period-or-declaration.type'
import { DeclarationType } from '../../domain/vat/declaration-type.enum'
import { SegmentHelper } from '../external-services/segment.helper'

@Injectable()
export class VatJustificationService {
  constructor(
    private readonly _administrationService: AdministrationService,
    private readonly _apiGateway: ApiGateway,
    private readonly _segment: SegmentHelper,
  ) {}

  getSuppletionJustifications(suppletionId: number): Observable<Justification[]> {
    return this._administrationService.makeApiCallForDefaultAdministration((p) => this._apiGateway.get(endpoints.justifications.suppletion, p), {
      suppletionId: suppletionId,
    })
  }

  justifyAsPrivate(declaration: PeriodOrDeclaration, description?: string, amount?: number, date?: moment.Moment): Observable<Document> {
    const body = {
      type: JustificationTypes.PRIVATE,
      amount: amount,
      remarks_user: description,
      booking_date: date && date.format(globals.dateFormat),
    }

    if (!body.remarks_user) {
      delete body.remarks_user
    }
    if (!body.booking_date) {
      delete body.booking_date
    }
    if (!body.amount) {
      delete body.amount
    }

    return this.makeVatApiCall(declaration, (p, o) => this._apiGateway.post(VatJustificationService.getJustificationUrl(declaration), o, p), body)
  }

  justifyWithManualAccount(
    declaration: PeriodOrDeclaration,
    account: Account,
    description?: string,
    amount?: number,
    bookingDate?: moment.Moment,
  ): Observable<Invoice> {
    const body: any = {
      type: JustificationTypes.MANUAL,
      account_id: account.id,
      booking_date: bookingDate && bookingDate.format(globals.dateFormat),
    }

    if (description) {
      body.description = description
    }

    if (amount) {
      body.amount = amount
    }

    return this.makeVatApiCall(declaration, (p, o) => this._apiGateway.post(VatJustificationService.getJustificationUrl(declaration), o, p), body)
  }

  deleteJustification(declaration: PeriodOrDeclaration, justification: Justification): Observable<Document> {
    // Segment
    this._segment.track('Justification - Deleted', {
      id: justification.id,
      location: 'declaration',
      'transaction id': justification.transaction ? justification.transaction.id : null,
      'invoice id': justification.invoice ? justification.invoice.id : null,
      'document id': justification.document ? justification.document.id : null,
      'vat declaration id': justification.vat_declaration ? justification.vat_declaration.id : null,
      type: justification.type.toLowerCase().replace('_', ' '),
    })

    return this.makeVatApiCall(declaration, (p) => this._apiGateway.delete(VatJustificationService.getJustificationUrl(declaration), p), null, {
      justificationId: justification.id,
    })
  }

  private makeVatApiCall<T>(declaration: PeriodOrDeclaration, call: (params?: any, object?: any) => Observable<T>, object?: any, params?: any): Observable<T> {
    if (!params) {
      params = {}
    }

    if (declaration.__type == DeclarationType.Suppletion) {
      params.suppletionId = declaration.id
    } else {
      params.vatDeclarationId = declaration.vat_declaration.id
    }

    return this._administrationService.makeApiCallForDefaultAdministration(call, params, object)
  }

  private static getJustificationUrl(declaration: PeriodOrDeclaration): string {
    return declaration.__type == DeclarationType.Suppletion ? endpoints.justifications.suppletion : endpoints.justifications.vat
  }
}
