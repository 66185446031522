import { combineLatest as observableCombineLatest, Observable } from 'rxjs'
import { map, startWith, take } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { UserService } from '../../user/user.service'
import { AdministrationService } from '../../administration/administration.service'
import { UserRoleTypes } from '../../../domain/user/user-role-types.constants'
import { User } from '../../../domain/user/user.model'
import { Administration } from '../../../domain/administration/administration.model'
import { UserRole } from '../../../domain/user/user-role.model'
import { endpoints } from '../../../shared/config/endpoints'
import { ApiGateway } from '../../../core/remote/api.gateway'
import { AdministrationRoleTypes } from '../../../domain/administration/administration-role-types.constants'

@Injectable()
export class AuthorizationService {
  constructor(
    private readonly _administrationService: AdministrationService,
    private readonly _userService: UserService,
    private readonly _apiGateway: ApiGateway,
  ) {}

  public role: Observable<UserRoleTypes> = this._userService.user.pipe(
    take(1),
    map(
      (user) =>
        UserRoleTypes[
          Object.values(UserRoleTypes)
            .find((value) => value === user?.role)
            ?.replace('ROLE_', '')
        ],
    ),
    startWith(UserRoleTypes.USER),
  )

  hasAdminRights(): Observable<boolean> {
    return this._userService.user.pipe(map((user) => user && user.role == UserRoleTypes.ADMIN))
  }

  hasReadonlyRights(): Observable<boolean> {
    return this._administrationService.defaultAdministration.pipe(
      map((a) => a && a.user_roles.length === 1 && a.user_roles.contains(AdministrationRoleTypes.READ_ONLY)),
    )
  }

  hasAccountingRights(): Observable<boolean> {
    return observableCombineLatest([this._userService.user, this._administrationService.defaultAdministration]).pipe(
      map(([user, administration]) => AuthorizationService.hasRoleAuthorization(AdministrationRoleTypes.ACCOUNTANT, user, administration)),
    )
  }

  isCurrentAdministrationOwner(excludeAdmin?: boolean): Observable<boolean> {
    return observableCombineLatest([this._userService.user, this._administrationService.defaultAdministration]).pipe(
      map(([user, administration]) => {
        if (!excludeAdmin) {
          return AuthorizationService.hasRoleAuthorization(AdministrationRoleTypes.OWNER, user, administration)
        }

        if (!administration) {
          return false
        }

        return administration.user_roles.contains(AdministrationRoleTypes.OWNER)
      }),
    )
  }

  // Gets all roles that a user can have
  getAvailableRoles(): Observable<UserRole[]> {
    return this._apiGateway.get(endpoints.role.roles)
  }

  private static hasRoleAuthorization(role: string, user: User, administration: Administration): boolean {
    if (!user) {
      return false
    }
    if (user.role == UserRoleTypes.ADMIN) {
      return true
    }

    if (!administration) {
      return false
    }

    return administration.user_roles.contains(role)
  }
}
