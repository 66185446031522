import { Injectable } from '@angular/core'
import { TransferData } from '../../domain/transfer/transfer-data.model'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'

@Injectable()
export class TransferService {
  private _prepopulatedTransferData: TransferData

  get prepopulatedTransferData(): TransferData {
    return this._prepopulatedTransferData
  }

  constructor(private readonly _router: TranslateRouteService) {}

  setTransferData(data: TransferData): void {
    this._prepopulatedTransferData = data
  }

  clearPrepopulatedTransferData(): void {
    this._prepopulatedTransferData = undefined
  }

  goToTransferScreenWithPrepopulatedData(data: TransferData): void {
    this.setTransferData(data)

    void this._router.navigate('/transferMoney')
  }
}
