import { Injectable, OnInit } from '@angular/core'
import { iif } from 'rxjs'
import { tap } from 'rxjs/operators'
import { VatPeriodDisplayItem } from '../../domain/administration/vat-period.model'
import { AnnotatedLine } from '../../domain/document/annotation.model'
import { EditInvoiceLine } from '../../domain/invoice/edit-invoice.model'
import { PurchaseInvoiceLine } from '../../domain/invoice/purchase-invoice.model'
import { VatTotals } from '../../invoices/create-invoice/create-invoice.component'
import { AdministrationService } from '../administration/administration.service'

export type CalculationConfig = { [key: string]: boolean | string }
export type ExpectedTotalTypes = EditInvoiceLine | Partial<EditInvoiceLine> | PurchaseInvoiceLine | Partial<PurchaseInvoiceLine> | AnnotatedLine
export type LineTypes = ExpectedTotalTypes[]

/**
 * User-Defined Type Guard
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
 */
function isAnnotatedLineType(line: ExpectedTotalTypes): line is AnnotatedLine {
  return (line as EditInvoiceLine).quantity === undefined
}

function isSalesType(line: ExpectedTotalTypes): line is EditInvoiceLine {
  return (line as EditInvoiceLine).discount_amount !== undefined
}

@Injectable({ providedIn: 'root' })
export class CalculationHelper implements OnInit {
  private _vatRates: VatPeriodDisplayItem[] = []
  private _totalsVatRates: VatPeriodDisplayItem[] = []

  protected _config: CalculationConfig = {
    isVatExcluded: false,
    isSales: true,
    date: null,
  }

  constructor(private readonly _administration: AdministrationService) {}

  ngOnInit() {
    this.setRates()
  }

  public setConfig(config: CalculationConfig) {
    this._config = { ...this._config, ...config }
    this.setRates()
  }

  calculateTotal(lines: LineTypes): number {
    if (this._config.isVatExcluded === true) {
      return this.calculateSubTotal(lines) + this.calculateVatTotals(lines).sum((vt) => vt.amount)
    }

    return lines.sum((line) => this.calculateLineTotal(line))
  }

  calculateSubTotal(lines: LineTypes): number {
    if (!lines || !lines.length) {
      return
    }

    if (this._config.isVatExcluded) {
      return lines.sum((line) => this.calculateLineTotal(line))
    }

    const total = lines.sum((line) => this.calculateLineTotal(line))
    const vat = this.calculateVatTotals(lines).sum((vt) => vt.amount)

    return total - vat
  }

  calculateLineTotal(line: ExpectedTotalTypes): number {
    const isIncludingVatLine = 'including_vat' in line && line.including_vat === true
    let total: number

    if (isAnnotatedLineType(line) === false) {
      total = (line as Partial<EditInvoiceLine>).price * (line as Partial<EditInvoiceLine>).quantity
    } else {
      if (isIncludingVatLine) {
        const rate = this._vatRates?.firstOrUndefined(({ id }) => id == line.vat_rate_id)
        total = line.price / ((100 + rate?.percentage) / 100)
      } else {
        total = line.price
      }
    }

    if (isSalesType(line) && line.discount_percentage) {
      total = total - (total / 100) * line.discount_percentage
    }

    if (isSalesType(line) && line.discount_amount) {
      total = total - line.discount_amount
    }

    return total
  }

  calculateVatTotals(lines: LineTypes): VatTotals[] {
    const isPurchaseInvoiceLines = lines?.some((line) => 'including_vat' in line)

    if (!this._totalsVatRates || !this._totalsVatRates?.length) {
      return []
    }

    return this._totalsVatRates?.map(({ label, percentage, start_date, end_date }) => {
      if (Boolean(this._config?.date) && (start_date > this._config?.date || end_date < this._config?.date)) {
        return { label: 'invalid', amount: 0 }
      }

      if (!lines) {
        return { label, amount: 0 }
      }

      let vatTotal: number
      vatTotal = lines
        // Only take lines that actually have a vat rate
        .filter((line) => line.vat_rate_id)
        // Find the right percentages to work width
        .filter((line) => this._vatRates.firstOrUndefined(({ id }) => id == line.vat_rate_id)?.percentage == percentage)
        .sum((line) => {
          const isPurchaseInvoiceLine = 'including_vat' in line

          /**
           * If exluded from having to submit vat, or
           * line does not have any, just take the total.
           */
          if (isPurchaseInvoiceLine) {
            return this.calculateIncludedVat(line)
          }

          if (this._config.isVatExcluded) {
            return this.calculateLineTotal(line)
          }

          return this.calculateIncludedVat(line)
        })

      if (isPurchaseInvoiceLines) {
        return { label, amount: vatTotal }
      }

      if (this._config.isVatExcluded) {
        vatTotal = (vatTotal / 100) * percentage
      } else {
        vatTotal = vatTotal / ((100 + percentage) / 100)
      }

      return { label, amount: vatTotal }
    })
  }

  calculateTotalDiscount(lines: LineTypes): number {
    let totalDiscount = 0
    lines?.forEach((line) => {
      if ('discount_amount' in line && line.discount_amount) {
        totalDiscount += line.discount_amount
      }

      if (isSalesType(line) && line.discount_percentage) {
        const vatPercentage = this._vatRates?.firstOrUndefined((vr) => vr.id == line.vat_rate_id)?.percentage

        const price = Number(line.price)

        if (this._config.isVatExcluded) {
          totalDiscount += (price * line.quantity * line.discount_percentage) / 100
        } else if (!!vatPercentage) {
          /**
           * `input-currency.directive` parses price to be string
           * TODO: Fix dependency. Read string above.
           */
          const priceExclVat = price / (1 + vatPercentage / 100)
          const priceTimesQuantity = line.quantity * priceExclVat

          totalDiscount += (priceTimesQuantity * line.discount_percentage) / 100
        }
      }
    })

    return totalDiscount
  }

  calculateIncludedVat(line: ExpectedTotalTypes): number {
    const isExcludingVatLine = 'including_vat' in line && line.including_vat === false
    const isIncludingVatLine = 'including_vat' in line && line.including_vat === true

    const rate = this._vatRates?.firstOrUndefined(({ id }) => id == line.vat_rate_id)
    const discountAmount: number = isSalesType(line) ? line.discount_amount : 0
    const quantityOrFallback: number = line.quantity || 1

    let total = ((line.price * quantityOrFallback - discountAmount) / 100) * rate?.percentage

    if (isExcludingVatLine) {
      return total
    }

    if (isIncludingVatLine) {
      total = (line.price / (100 + rate?.percentage)) * rate?.percentage
    }

    if (isSalesType(line) && line.discount_percentage) {
      total = total - (total / 100) * line.discount_percentage
    }

    return total
  }

  private setRates(): void {
    const sales = this._administration.vatSaleRatePeriods
    const purchase = this._administration.vatPurchaseRatePeriods

    iif(() => Boolean(this._config.isSales), sales, purchase)
      .pipe(
        tap((rates) => {
          this._vatRates = rates
        }),
      )
      .subscribe((rates) => {
        this._totalsVatRates = rates
          .filter((rate) => rate.percentage)
          .distinct((rate) => rate.percentage)
          .orderBy((rate) => rate.percentage)
      })
  }
}
