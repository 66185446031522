import { of as observableOf, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from './administration.service'
import { endpoints } from '../../shared/config/endpoints'

@Injectable()
export class PaymentService {
  private _paymentStatus: string // Default
  private _orderId: string // Rabo param
  private _signature: string // Rabo param

  constructor(private readonly _api: ApiGateway, private readonly _adminService: AdministrationService) {}

  payForUse(params?: any): Observable<any> {
    return this._adminService.makeApiCallForDefaultAdministration((p) =>
      this._api.post(endpoints.administration.enableSubscription, params, p, {
        skipPaywallFlow: true,
      }),
    )
  }

  // Upgrades account from trial to subscription
  upgradeAccount(order_id: string, status: string, signature: string): Observable<any> {
    return this._adminService.makeApiCallForDefaultAdministration((p) =>
      this._api.post(endpoints.administration.upgradeSubscription, { order_id: order_id, status: status, signature: signature }, p),
    )
  }

  setBankParams(params: { order_id: string; status: string; signature: string }): void {
    this._orderId = params.order_id
    this._paymentStatus = params.status
    this._signature = params.signature
  }

  resetParamsAfterCancel(): void {
    if (this._paymentStatus == 'CANCELLED') {
      this._orderId = null
      this._paymentStatus = null
      this._signature = null
    }
  }

  getPaymentParams(): Observable<any> {
    return observableOf({ status: this._paymentStatus, order_id: this._orderId, signature: this._signature })
  }

  getPaymentStatus(): Observable<string> {
    return observableOf(this._paymentStatus)
  }
}
