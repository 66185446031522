import { tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpResponse } from '@angular/common/http'
import { FileService } from '../../core/logic/files/file.service'
import { FiscalYear } from '../../domain/accounting/fiscal-year.model'
import { SelectablePeriod } from '../../domain/administration/time/selectable-period'
import { ApiGateway } from '../../core/remote/api.gateway'
import { AdministrationService } from './administration.service'
import { endpoints } from '../../shared/config/endpoints'
import { globals } from '../../shared/config/globals'

@Injectable()
export class ExportService {
  constructor(private readonly _fileService: FileService, private readonly _apiGateway: ApiGateway, private readonly _adminService: AdministrationService) {}

  // TODO: Wait for API's
  exportCsv(period: SelectablePeriod): Observable<any> {
    const options = this._fileService.createFileOptions('application/zip')

    const start = period.startDate.format(globals.dateFormat)
    const end = period.endDate.format(globals.dateFormat)

    return this._adminService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.get<any>(endpoints.export.csv, p, options, true), {
        start_date: start,
        end_date: end,
      })
      .pipe(tap((res) => this.download(res)))
  }

  exportAuditFile(fiscalYear: FiscalYear): Observable<any> {
    const options = this._fileService.createFileOptions('application/xml')

    return this._adminService
      .makeApiCallForDefaultAdministration((p) => this._apiGateway.get<any>(endpoints.export.auditFile, p, options, true), { fiscalYearId: fiscalYear.id })
      .pipe(tap((res) => this.download(res)))
  }

  private download(res: HttpResponse<any>): void {
    const blob = new Blob([res.body])
    const name = this._fileService.getFilenameFromResponse(res)

    this._fileService.offerFileAsDownload(blob, name)
  }
}
