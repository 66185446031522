import { Component, ElementRef, forwardRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core'
import { OptionLabelValue } from '../../domain/onboarding/question.model'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { FncButtonComponent } from '../fnc-button/fnc-button.component'

@Component({
  selector: 'fnc-toggle',
  styleUrls: ['./fnc-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FncToggleComponent),
      multi: true,
    },
  ],
  template: `
    <div class="fnc-toggle-container" [class.boolean]="boolean" [class.disabled]="disabled" [class.small]="small">
      <fnc-button
        *ngFor="let option of options"
        (click)="$event.preventDefault(); $event.target.blur()"
        (fncClick)="valueClicked(option.value)"
        [class.active]="option.value == value"
        transparent
      >
        {{ option.label | translate }}
        <span *ngIf="option.count" class="counter">
          {{ option.count }}
        </span>
      </fnc-button>
    </div>
  `,
})
export class FncToggleComponent extends BaseHtmlDirective implements OnChanges, ControlValueAccessor {
  @Input() options: OptionLabelValue[]
  @Input() required: boolean
  @Input() boolean: boolean
  @Input() disabled: boolean
  @Input() small: boolean
  @Input() large: boolean

  @ViewChildren(FncButtonComponent) buttons: QueryList<FncButtonComponent>

  private innerValue: any

  constructor(el: ElementRef) {
    super(el)
  }

  // get accessor
  get value(): any {
    return this.innerValue
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v
      this.onChangeCallback(v)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const property in changes) {
      if (property) {
        this[property] = changes[property].currentValue
      }
    }
  }

  // for the next section see: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  // private onTouchedCallback: () => void = noop;

  valueClicked(value: any) {
    if (!this.disabled) {
      this.value = value
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  // private onChangeCallback: (_: any) => void = noop;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: any) => void = () => {}
}
