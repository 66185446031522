import { Observable } from 'rxjs'

export enum TellowNotificationStyle {
  DEFAUlT = 'default',
  COLORED = 'colored',
}

export enum TellowNotificationIconStyle {
  DEFAUlT = 'default',
  BIG = 'big',
}

export enum TellowNotificationIconColor {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  PURPLE = 'purple',
  WHITE = 'white',
}

export interface TellowNotification {
  name: TellowNotificationName
  style: TellowNotificationStyle
  title?: Observable<string>
  subTitle: Observable<string>
  icon: {
    style: TellowNotificationIconStyle
    color?: TellowNotificationIconColor
    name: string
  }
  action?: {
    actionText: Observable<string>
    redirectAction: () => void
  }
  dismiss?: (fully: boolean) => void
}

/**
 * Used for dismissing notifications. This is the value stored in the DB.
 * IMPORTANT: These values should be aligned with the implementation on the Mobile application.
 * @see https://github.com/hellotellow/mobile/blob/develop/src/contexts/alerts/types.ts#L10
 */
export enum TellowNotificationName {
  INITIAL_UPSELL = 'initial_upsell',

  BANK_START_ONBOARDING = 'bank_start_onboarding',
  BANK_UNFINISHED_ONBOARDING = 'bank_unfinished_onboarding',
  BANK_START_IDENTIFICATION = 'bank_start_identification',
  BANK_REVIEW_PENDING = 'bank_review_pending',
  BANK_CARD_READY = 'bank_card_ready',
  BANK_ORDER_PHYSICAL_CARD = 'bank_order_physical_card',
  BANK_FIRST_TOP_UP = 'bank_first_top_up',
  BANK_ACCOUNT_SUSPENDED = 'bank_account_suspended',

  OPP_UNFINISHED_ONBOARDING = 'opp_unfinished_onboarding',

  // --------------------------------------------------------------
  // The below values are not currently used for dismissing as accounting notifications shouldn't be dismissable.
  CONSENT_EXPIRED = 'consent_expired',
  TRANSACTIONS_WITHOUT_RECEIPT = 'transactions_without_receipt',
  DRAFT_EXPENSES = 'draft_expenses',

  EMAIL_VERIFICATION = 'email_verification',

  // When the user has no notifications the notification with 'You're all up to date' is displayed in the drawer.
  NO_NOTIFICATION = 'no_notifications',
}
