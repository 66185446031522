import { EventEmitter, Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { SentryErrorHandlerService } from '../../logic/external-services/sentry-error-handler.service'

@Injectable()
export class DebugService {
  onDebugOutput = new EventEmitter<string>()
  onHttpGetError = new EventEmitter<any>()

  /**
   * Constructor.
   */
  constructor(private readonly _sentry: SentryErrorHandlerService) {}

  logOutput(output: any) {
    if (output && output.status && output.status >= 500 && output.status < 600) {
      this._sentry.handleError(output)
    }

    // Only output stuff when in development mode.
    if (environment.verboseDebugOutput) {
      if (typeof output === 'string') {
        this.onDebugOutput.emit(output)
      } else {
        this.onDebugOutput.emit(JSON.stringify(output))
      }
    }
  }

  logErrorOnGet(output: any) {
    if (output && output.status && output.status >= 500 && output.status < 600) {
      this._sentry.handleError(output)
    }

    this.onHttpGetError.emit(output)
  }
}
