interface InstitutionPresentation {
  color: string
  logos: {
    large: {
      png: string
      svg?: string
    }
  }
}

interface InstitutionStatus {
  message: string
  syncMode: string
  tryAgain: string
  updated: string
}

export interface InstitutionResponse {
  id: AiiaPsd2BankIds
  countryCode: string
  name: AiiaPsd2Banks
  presentation?: InstitutionPresentation
  status?: InstitutionStatus
}

/**
 * Single source of truth
 * for Aiia providers.
 */
export enum Provider {
  ING = 'NL_Ing',
  Bunq = 'NL_Bunq',
  Rabobank = 'NL_Rabobank',
  ABNAMRO = 'NL_AbnAmro',
  ASNBank = 'NL_AsnBank',
  Knab = 'NL_Knab',
  RegioBank = 'NL_RegioBank',
  SNSBank = 'NL_SnsBank',
  Triodos = 'NL_Triodos',
  N26 = 'NL_N26',
  Revolut = 'NL_Revolut',
  PayPal = 'NL_PayPal',

  // Following are AIIA Test banks
  DKTestBank = 'DK_TestBank',
  FITestBank = 'FI_TestBank',
}

export type AiiaPsd2BankIds = keyof typeof Provider

export type AiiaPsd2Banks =
  | 'ING'
  | 'SNS Bank'
  | 'Rabobank'
  | 'AbnAmro'
  | 'ABN AMRO'
  | 'Knab'
  | 'ASN Bank'
  | 'RegioBank'
  | 'Triodos'
  | 'Bunq'
  | 'N26'
  | 'Revolut'
  | 'PayPal'

// As per banks in Tellow's DB
export type TellowDbBanks =
  | 'Rabobank'
  | 'ABN AMRO'
  | 'ING Bank'
  | 'Knab'
  | 'Bunq'
  | 'Triodos'
  | 'SNS Bank'
  | 'ASN Bank'
  | 'Regio Bank'
  | 'N26'
  | 'Revolut'
  | 'PayPal'
  | 'Tellow'
