import { catchError, map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Moment } from 'moment'
import { AdministrationService } from '../administration/administration.service'
import { ApiGateway } from '../../core/remote/api.gateway'
import { Justification } from '../../domain/justification/justification.model'
import { Transaction } from '../../domain/transaction/transaction.model'
import { TransactionListItem } from '../../domain/transaction/transaction-listitem.model'
import { endpoints } from '../../shared/config/endpoints'
import { JustificationTypes } from '../../domain/justification/justificationtypes.constants'
import { UploadDocument } from '../../domain/document/upload-document.model'
import { DocumentService } from '../documents/document.service'
import { InvoiceListItem } from '../../domain/invoice/invoice-listitem.model'
import { Invoice } from '../../domain/invoice/invoice.model'
import { PurchaseInvoice } from '../../domain/invoice/purchase-invoice.model'
import { VatPeriod } from '../../domain/vat/vat-period.model'
import { Account } from '../../domain/administration/account.model'
import { JustificationInstruction } from '../../domain/justification/justification-instruction'
import { Suppletion } from '../../domain/vat/suppletion.model'
import { SegmentHelper } from '../external-services/segment.helper'
import { Predictions } from '../../domain/administration/administration-settings.model'
import { KlippaOcrService } from '../external-services/klippa.service'

@Injectable()
export class TransactionJustificationService {
  constructor(
    private readonly _administrationService: AdministrationService,
    private readonly _documentService: DocumentService,
    private readonly _apiGateway: ApiGateway,
    private readonly _segment: SegmentHelper,
    private readonly _klippa: KlippaOcrService,
  ) {}

  /**
   * Get justifications, but based on the transaction id only.
   */
  getJustificationsByTxId(id: number): Observable<JustificationInstruction[]> {
    return this.getJustifications({ id } as Transaction | TransactionListItem)
  }

  /**
   * Get justifications for a transaction.
   */
  getJustifications(tx: Transaction | TransactionListItem): Observable<JustificationInstruction[]> {
    return this.makeTxApiCall(tx, (p) => this._apiGateway.get(endpoints.justifications.transaction, p)).pipe(
      map((justifications: Justification[]) =>
        justifications.map((j: JustificationInstruction) => {
          j.saved = true

          return j
        }),
      ),
    )
  }

  justifyAsInterest(tx: Transaction | TransactionListItem, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.SAVINGS_INTEREST,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyAsPrivate(tx: Transaction | TransactionListItem, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.PRIVATE,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyAsPaymentDifference(tx: Transaction | TransactionListItem, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.PAYMENT_DIFFERENCE,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  /**
   * The same as 'justifyAsBusinessWithDocument',
   * but instead we send the data to Klippa,
   * so the user can go through self-annotation.
   */
  justifyAsBusinessThroughSelfAnnotation<T>(tx: Transaction | TransactionListItem, document: UploadDocument): Observable<T | null> {
    return this._klippa
      .addFileToAnnotate([document.file as File], {
        description: document.json?.description ?? null,
      })
      .pipe(
        map((id: number) => this._klippa.navigateToAnnotation(id, { from: 'transaction', transactionId: tx.id })),
        catchError(() => of(null)),
      )

    // return this._klippa
    //   .addFile([document.file as File], {
    //     description: document.json?.description ?? null,
    //   })
    //   .pipe(
    //     map((id: number): { type: string; invoice_id: number } => {
    //       if (!id) {
    //         throw new Error('There was a problem with the file upload.')
    //       }

    //       return {
    //         type: JustificationTypes.INVOICE,
    //         invoice_id: id,
    //       }
    //     }),
    //     mergeMap((body) => this.makeTxApiCall<T>(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)),
    //     catchError(() => of(null)),
    //   )
  }

  /**
   * Unused while 'Barbie'-flow is not enabled.
   * May be removed later on, perhaps.
   */
  justifyAsBusinessWithDocument(tx: Transaction | TransactionListItem, document: UploadDocument): Observable<any> {
    return this._documentService.uploadDocument(document).pipe(
      map((newDocument) => ({
        type: JustificationTypes.INVOICE,
        document_id: newDocument.id,
      })),
      mergeMap((body) => this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)),
    )
  }

  justifyAsBusinessWithoutDocument(tx: Transaction | TransactionListItem, amount?: number, remarks?: string): Observable<any> {
    const body = {
      type: JustificationTypes.NO_INVOICE,
      amount: amount,
      remarks_user: remarks,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyAsBusinessSavings(tx: Transaction | TransactionListItem, amount?: number, remarks?: string): Observable<any> {
    const body = {
      type: JustificationTypes.BUSINESS_SAVINGS,
      amount: amount,
      remarks_user: remarks,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyWithInvoice(tx: Transaction | TransactionListItem, invoice: Invoice | InvoiceListItem | PurchaseInvoice, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.INVOICE,
      invoice_id: invoice.id,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyWithVatDeclaration(tx: Transaction | TransactionListItem, periodDeclaration: VatPeriod, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.VAT_DECLARATION,
      vat_declaration_id: periodDeclaration.id,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyWithSuppletion(tx: Transaction | TransactionListItem, suppletion: Suppletion, amount?: number): Observable<any> {
    const body = {
      type: JustificationTypes.SUPPLETION,
      suppletion_id: suppletion.id,
      amount: amount,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyWithManualAccount(
    tx: Transaction | TransactionListItem,
    account: Account,
    description?: string,
    amount?: number,
    bookingDate?: Moment,
  ): Observable<any> {
    const body = {
      type: JustificationTypes.MANUAL,
      account_id: account.id,
      amount: amount,
      user_remarks: description,
      booking_date: bookingDate,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  justifyTransactionManually(tx: Transaction | TransactionListItem, account: Account, description?: string, transactionId?: number): Observable<any> {
    const body = {
      type: JustificationTypes.MANUAL,
      account_id: account.id,
      transaction_id: transactionId,
      user_remarks: description,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.justifications.transaction, o, p), null, body)
  }

  deleteJustification(tx: Transaction | TransactionListItem, justification: Justification): Observable<any> {
    this._segment.track('Justification - Deleted', {
      id: justification.id,
      location: 'transaction',
      'transaction id': justification.transaction ? justification.transaction.id : null,
      'invoice id': justification.invoice ? justification.invoice.id : null,
      'document id': justification.document ? justification.document.id : null,
      'vat declaration id': justification.vat_declaration ? justification.vat_declaration.id : null,
      type: justification.type.toLowerCase().replace('_', ' '),
    })

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.delete(endpoints.justifications.transaction, p, o), {
      justificationId: justification.id,
    })
  }

  deleteJustificationById(transactionId: number, justification: Justification): Observable<any> {
    return this.deleteJustification({ id: transactionId } as Transaction | TransactionListItem, justification)
  }

  /**
   * Return feedback to ML endpoint.
   */
  postPredictionFeedback(tx: Transaction | TransactionListItem, selected: Partial<Account>, prediction: Predictions): Observable<any> {
    const body = {
      rgs_number: selected.number,
      uuid: prediction.id,
    }

    return this.makeTxApiCall(tx, (p, o) => this._apiGateway.post(endpoints.transactions.selected, o, p), null, body)
  }

  private makeTxApiCall<T>(
    tx: Transaction | TransactionListItem,
    call: (params?: any, object?: any) => Observable<T>,
    params?: any,
    object?: any,
  ): Observable<T> {
    if (!params) {
      params = {}
    }

    params.transactionId = tx.id

    return this._administrationService.makeApiCallForDefaultAdministration(call, params, object)
  }
}
