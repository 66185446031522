import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FncModalComponent } from '../fnc-modal.component'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'fnc-alert-dialogue',
  template: `
    <fnc-modal [size]="modalSize || 'medium'">
      <modal-header>
        {{ headerText || ('COMPONENTS.ALERT_MODAL.DEFAULT_TITLE' | translate) }}
      </modal-header>
      <modal-content>
        <p>{{ contentText || ('COMPONENTS.ALERT_MODAL.DEFAULT_TEXT' | translate) }}</p>
      </modal-content>
      <modal-buttons>
        <!--Only show retry button in certain circumstances, retrying errors usually won't do anything anyway-->
        <a class="btn-link medium-2 columns" yellow *ngIf="retryAction.observers.length" (click)="retry()">{{ 'BUTTONS.RETRY' | translate }}</a>
        <a class="btn-link medium-2 columns" purple (click)="close()">{{ 'BUTTONS.CONTINUE' | translate }}</a>
      </modal-buttons>
    </fnc-modal>
  `,
})
export class FncAlertModalComponent implements OnInit {
  @Input('header') headerText: string
  @Input('content') contentText: string
  @Input('size') modalSize: string
  @Output('retry') retryAction = new EventEmitter<void>()
  @Output() onDismiss = new EventEmitter<void>()
  @ViewChild(FncModalComponent, { static: true }) modal: FncModalComponent

  constructor(private readonly _translate: TranslateService) {}

  @Input()
  set positive(isPositive: boolean) {
    if (isPositive) {
      this._translate.get('COMPONENTS.ALERT_MODAL.DEFAULT_POSITIVE_TITLE').subscribe((t) => {
        if (!this.headerText) {
          this.headerText = t
        }
      })
    }
  }

  ngOnInit() {
    this.modal.visibleChange.subscribe((visible) => {
      if (!visible) {
        this.onDismiss.emit()
      }
    })
  }

  retry() {
    if (this.retryAction) {
      this.modal.close()

      setTimeout(() => this.retryAction.emit(), 0)
    }
  }

  close() {
    this.modal.close()
  }

  open() {
    this.modal.open()
  }
}
