export class AccountTypes {
  static BALANCE = 'BALANCE'
  static PROFIT_LOSS = 'PROFIT_LOSS'
}

// hand-picked popular ledger numbers
export const BVPopularLedgerNumbers = ['4206010', '7001010', '4204200', '4215010', '4203060', '4206030', '4201040']
export const solePropPopularLedgerNumbers = ['4206010', '7001010', '4204200', '4215010', '4203060', '4206030', '4201040']

// hand-picked sole prop ledger numbers
export const solePropLedgerNumbers = [
  '0202010',
  '0213040',
  '0213010',
  '4209080',
  '4215010',
  '4204200',
  '1002010.01',
  '1002010.03',
  '1002010.04',
  '1002010.05',
  '0215010',
  '4206070',
  '4201105',
  '0805020',
  '0805021',
  '0805022',
  '0107010',
  '4201040',
  '4206010',
  '7001010',
  '7003010',
  '1003010',
  '0805030',
  '0805031',
  '0805032',
  '1103005',
  '1103007',
  '1103008',
  '1103009',
  '4001040',
  '1206010',
  '0509090',
  '4003010',
  '1204090',
  '4012190',
  '4002010',
  '4203070',
  '8406050',
  '8404050',
  '8403020',
  '4203060',
  '4203220',
  '4208020',
  '3001010',
  '4004170',
]

// hand-picked BV ledger numbers
export const BVLedgerNumbers = [
  '4215010',
  '4204200',
  '1002010.01',
  '1002010.03',
  '1002010.04',
  '1002010.05',
  '0215010',
  '4210070',
  '4206070',
  '0301010',
  '0301040',
  '0301050',
  '0301060',
  '0301070',
  '0401010',
  '4201105',
  '0805020',
  '0805021',
  '0805022',
  '0107010',
  '4201040',
  '4206010',
  '7001010',
  '7003010',
  '1003010',
  '0805030',
  '0805031',
  '0805032',
  '1103005',
  '1103007',
  '1103008',
  '1103009',
  '4001040',
  '1206010',
  '1204010',
  '4006050',
  '4201270',
  '0506005',
  '1204075',
  '0704120',
  '4003010',
  '1204090',
  '4012190',
  '4002010',
  '4203070',
  '1002010',
  '8406050',
  '8404050',
  '8403020',
  '4203060',
  '4206030',
  '1204040',
  '1207010',
  '9101010',
  '4203220',
  '4208020',
  '3101010',
  '0704080',
  '1101030',
  '4004170',
]
