import { Injectable, OnInit } from '@angular/core'
import { combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, map, mergeMap, startWith, take, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { SecureHttp } from '../../core/remote/httpclient'
import {
  BankAccountResponse,
  CreateBankAccountDTO,
  CreateMerchantDTO,
  MerchantDTO,
  MerchantResponse,
  PaymentLinkResponse,
  RichMerchantResponse,
  PaymentMethodResponse,
  ComplianceStatus,
  MerchantErrors,
  Status,
  BankStatus,
  ComplianceRequirements,
} from '../../domain/payments/payments.model'
import { AdministrationService } from '../administration/administration.service'
import { Administration } from '../../domain/administration/administration.model'
import { Cacheable } from 'ts-cacheable'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { UserService } from '../user/user.service'
import { TranslatePersist } from '../../core/logic/i18n/translate-persist.service'
import { FirebaseHelper } from '../external-services/firebase.helper'

const cacheBusterObserver = new Subject<void>()

@Injectable({ providedIn: 'root' })
export class PaymentsService implements OnInit {
  /*
   * Payments feature-flag.
   */
  public isPaymentsAvailable$: Observable<boolean> = of(true)
  // combineLatest([
  //   this._firebase.isFeatureEnabled('isPaymentLinksEnabled'),
  //   this._user.user.pipe(map((user) => user?.is_beta)),
  // ]).pipe(map(([hasFeatureFlag, isBeta]) => hasFeatureFlag || isBeta || false))

  /**
   * Get all your merchant information from here.
   */
  public get merchant$(): Observable<RichMerchantResponse | null> {
    return this.getMerchant().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return throwError(MerchantErrors.NoAccount)
        }

        return of(null)
      }),
    )
  }

  /**
   * Check whether backend throws a 404 code.
   * If they do, the customer does _not_
   * have a merchant account yet.
   */
  public hasMerchantAccount$ = this.merchant$.pipe(
    map(() => of(true)),
    catchError((error: MerchantErrors) => (error === MerchantErrors.NoAccount ? of(false) : of(true))),
    startWith(undefined),
  )

  /**
   * Check whether a user has a terminated account.
   * Can be used to scale up to also exclude
   * e.g. blocked users in the future.
   */
  public isBlocked$: Observable<boolean> = this.merchant$.pipe(map((merchant) => [Status.BLOCKED].includes(merchant?.oppMerchantStatus)))
  public isSuspended$: Observable<boolean> = this.merchant$.pipe(map((merchant) => [Status.SUSPENDED].includes(merchant?.oppMerchantStatus)))
  public isTerminated$: Observable<boolean> = this.merchant$.pipe(map((merchant) => [Status.TERMINATED].includes(merchant?.oppMerchantStatus)))

  /**
   * Collector to check for
   * all above 'bad' states.
   */
  public isLockedOut$: Observable<boolean> = combineLatest([this.isTerminated$, this.isBlocked$, this.isSuspended$]).pipe(
    map(([terminated, blocked, suspended]) => terminated || blocked || suspended),
  )

  /**
   * Check whether a user is completely
   * finished with the KYC flow.
   */
  public isCompletelyFinished$: Observable<boolean | undefined> = combineLatest([this.merchant$, this.isLockedOut$]).pipe(
    map(([merchant, isLockedOut]) => {
      if (isLockedOut) {
        return undefined
      }

      return (
        merchant?.oppMerchantStatus === Status.LIVE &&
        merchant?.bankAccount?.oppStatus === BankStatus.APPROVED &&
        merchant?.oppMerchantComplianceStatus === ComplianceStatus.VERIFIED
      )
    }),
    catchError(() => of(undefined)),
    startWith(undefined),
  )

  /**
   * Check if a user still needs to
   * perform actions to finish account.
   * More forgiving than 'isCompletelyFinished$',
   * as it will start with 'undefined', not triggering
   * any dashboard notifications if you never started.
   */
  public needsToFinishKYC$: Observable<boolean | undefined> = combineLatest([this.merchant$, this.isLockedOut$]).pipe(
    map(([merchant, isLockedOut]) => {
      if (isLockedOut) {
        return undefined
      }

      return merchant?.oppMerchantComplianceStatus === ComplianceStatus.UNVERIFIED
    }),
    catchError(() => of(undefined)),
    startWith(undefined),
  )

  /**
   * Check whether there is a bank account present in OPP
   */
  public hasCreatedBankAccount$: Observable<boolean> = this.merchant$.pipe(
    map((merchant) => Boolean(merchant?.bankAccount?.oppBankAccountId)),
    catchError(() => of(false)),
  )

  /**
   * Check whether a user is awaiting
   * OPP's approval on their bank account.
   */
  public isFinishedCreatingBankAccount$: Observable<boolean> = this.merchant$.pipe(
    map((merchant) => merchant?.oppMerchant?.compliance?.requirements),
    map((requirements) => requirements.find((item) => item.type === ComplianceRequirements.BANK)),
    map((data) => {
      if (!data) {
        /**
         * If 'ComplianceRequirements.BANK' does not
         * exist anymore (at this point), it means that
         * the 'requirement' is done, and we've
         * moved on to 'compliance'.
         */
        return true
      }

      /**
       * Unverified in this case, means
       * 'has not started', since the next
       * state would be 'pending', and
       * then 'verified' after.
       */
      return data?.status !== ComplianceStatus.UNVERIFIED
    }),
    catchError(() => of(false)),
  )

  public hasCompletedBankAccountOnboardingStep$: Observable<boolean> = forkJoin([this.hasCreatedBankAccount$, this.isFinishedCreatingBankAccount$]).pipe(
    map(([createdAccount, finishedCreading]) => createdAccount && finishedCreading),
    catchError(() => of(false)),
  )

  /**
   * Check whether a user is pending
   * with the KYC flow.
   */
  public isPending$: Observable<boolean | undefined> = combineLatest([this.merchant$, this.isLockedOut$]).pipe(
    map(([merchant, isLockedOut]) => {
      if (isLockedOut) {
        return undefined
      }

      return merchant?.oppMerchantComplianceStatus === ComplianceStatus.PENDING
    }),
    catchError(() => of(undefined)),
    startWith(undefined),
  )

  /**
   * Condition to check if customer is eligible to
   * skip the 'complicated' merchant flow, and
   * just created a merchant straight away.
   */
  public hasKvkAndEmail$: Observable<boolean> = this._administration.defaultAdministrationSettings.pipe(
    map((settings) => Boolean(settings?.coc_number && settings?.email_address)),
  )

  /**
   * Switch between baseUrl's.
   * OPP disallows localhost, but in order to test
   * this flow on a preview PR, this is desirable.
   */
  get baseUrl(): string {
    const { origin } = window.location
    const base = environment.baseUrl

    const isNotProd = origin.startsWith(base) === false
    const isDevelopment = origin.includes('localhost')

    return isNotProd && !isDevelopment ? `${origin}/` : base
  }

  /**
   * Public getter for checking ability to create links.
   * Using internal private function in order to
   * enabled this data to be cached.
   */
  public get canMakePaymentLinks$(): Observable<boolean | undefined> {
    return this._canMakePaymentLinks()
  }

  constructor(
    private readonly _http: SecureHttp,
    private readonly _administration: AdministrationService,
    private readonly _user: UserService,
    private readonly _firebase: FirebaseHelper,
    private readonly _translatePersist: TranslatePersist,
  ) {
    this._administration.onSwitchedAdministration.subscribe(() => this.clearCache())
  }

  /**
   * In order to make sure the payment request button
   * is always up to date, we make sure we have
   * the cached data available from init on.
   */
  ngOnInit(): void {
    void this.canMakePaymentLinks$.pipe(take(1)).subscribe(() => {
      console.debug('Initial state fetched.')
    })
  }

  /*
   * Utility
   */
  public clearCache(): void {
    void cacheBusterObserver.next()
  }

  /**
   * Create a new merchant
   */
  createMerchant(data: MerchantDTO): Observable<MerchantResponse> {
    const { index, onboarding } = environment.routerLinks.payments

    const body: CreateMerchantDTO = {
      return_url: `${this.baseUrl}${index}/${onboarding}?step=2&callback=merchant`,
      locale: this._translatePersist.language,
      ...data,
    }

    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.post<MerchantResponse>(`${environment.paymentsServiceApiUrl}/v1/accounts/${id}`, body)),
      tap(() => this.clearCache()),
    )
  }

  /**
   * Get merchants from OPP for user.
   * This is cached, and invalidated every few minutes,
   * or whenever users updates the merchant.
   *
   * Return 'null' on failures;
   * this way Angular wont start
   * infinite looping requests.
   */
  @Cacheable({ maxAge: 300000, cacheBusterObserver })
  getMerchant(): Observable<RichMerchantResponse | null> {
    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.get<HttpResponse<RichMerchantResponse>>(`${environment.paymentsServiceApiUrl}/v1/accounts/${id}`, { observe: 'response' })),
      map((response) => response?.body),
      catchError((error) => throwError(error)),
    )
  }

  /**
   * Create a bank account by
   * going through connect flow.
   */
  createBankAccount(): Observable<BankAccountResponse> {
    const { index, onboarding } = environment.routerLinks.payments

    const body: CreateBankAccountDTO = {
      return_url: `${this.baseUrl}${index}/${onboarding}?step=3&callback=bank`,
    }

    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.post<BankAccountResponse>(`${environment.paymentsServiceApiUrl}/v1/bankAccounts/${id}`, body)),
    )
  }

  /**
   * Create payment link.
   */
  createPaymentLink(invoiceId: number): Observable<PaymentLinkResponse> {
    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.post<PaymentLinkResponse>(`${environment.paymentsServiceApiUrl}/v1/${id}/invoices/${invoiceId}`, null)),
    )
  }

  /**
   * Get merchants from OPP for user.
   * This is cached, and invalidated every few minutes,
   * or whenever users updates the merchant.
   *
   * Return 'null' on failures;
   * this way Angular wont start
   * infinite looping requests.
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  getPaymentMethods(): Observable<PaymentMethodResponse | null> {
    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) =>
        this._http.get<HttpResponse<PaymentMethodResponse>>(`${environment.paymentsServiceApiUrl}/v1/${id}/invoices/paymentMethods`, { observe: 'response' }),
      ),
      map((response) => response?.body),
      catchError(() => of(null)),
    )
  }

  setPaymentMethods(body: PaymentMethodResponse): Observable<PaymentMethodResponse | null> {
    return this._administration.defaultAdministration.pipe(
      map((administration: Administration) => administration.id),
      mergeMap((id) => this._http.put<Observable<PaymentMethodResponse>>(`${environment.paymentsServiceApiUrl}/v1/${id}/invoices/paymentMethods`, body)),
      catchError(() => of(null)),
    )
  }

  /**
   * Internal / helpers
   */
  @Cacheable({ maxAge: 300000, slidingExpiration: true, cacheBusterObserver })
  protected _canMakePaymentLinks(): Observable<boolean | undefined> {
    return combineLatest([this.getMerchant(), this.isPaymentsAvailable$]).pipe(
      // Filter out the status; no status equals unablitity to create links and
      // check whether global feature flag from this service is enabled.
      map(([merchant, available]) => (available ? merchant?.oppMerchant?.compliance?.status !== undefined : false)),
      // Handle errors. Start with false.
      catchError(() => of(false)),
      startWith(undefined),
    )
  }
}
