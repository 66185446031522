import { Component, ContentChildren, ElementRef, forwardRef, Inject, Input, OnInit, QueryList } from '@angular/core'
import { NgModel } from '@angular/forms'
import { FormControl, FormGroup } from '@ngneat/reactive-forms'
import { FncInputContainerComponent } from '../fnc-input-container/fnc-input-container.component'
import { FncValidatorMessageForDirective } from './fnc-validator-message-for.directive'

@Component({
  selector: 'fnc-validator-messages',
  styleUrls: ['./fnc-validator-messages.component.scss'],
  template: `<ng-content></ng-content>`,
})
export class FncValidatorMessagesComponent implements OnInit {
  @Input() maxMessagesAtOnceCount: number = 10

  @Input() parentControlName: string
  @Input() parentFormGroup: FormGroup<any>

  @ContentChildren(FncValidatorMessageForDirective) messages: QueryList<FncValidatorMessageForDirective>

  get entity(): FormControl<any> | NgModel {
    return this.parentFormGroup ? this.parentFormGroup?.get(this.parentControlName) : this._parent.ngModel
  }

  constructor(@Inject(forwardRef(() => FncInputContainerComponent)) private _parent: FncInputContainerComponent, private _elRef: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => this.toggleMessages(), 0)

    if (!this.entity) {
      return
    }

    // Subscribe on validation changes
    this.entity.statusChanges.subscribe(() => {
      setTimeout(() => this.toggleMessages(), 0)
    })
  }

  show(show: boolean) {
    if (show) {
      this._elRef.nativeElement.classList.add('show')
    } else {
      this._elRef.nativeElement.classList.remove('show')
    }
  }

  private toggleMessages(): void {
    if (!this.messages || this.messages.length === 0) {
      return
    }

    // Retrieve input field errors.
    const errors = this.entity?.errors
    let errorCount = 0

    this.messages.forEach((m: FncValidatorMessageForDirective) => {
      // Yes, there is an error for this message.
      if (errors && errors[m.messageFor] && errorCount < this.maxMessagesAtOnceCount) {
        errorCount++
        m.show(true)
      } else {
        m.show(false)
      }
    })
  }
}
