import { AfterContentInit, Component, ContentChildren, ElementRef, forwardRef, Input, QueryList } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { FncRadioComponent } from '../fnc-radio/fnc-radio.component'
import { BaseHtmlDirective } from '../html-directives/basehtml.directive'

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FncRadioGroupComponent),
      multi: true,
    },
  ],
  selector: 'fnc-radio-group',
  template: ` <ng-content></ng-content> `,
})
export class FncRadioGroupComponent extends BaseHtmlDirective implements AfterContentInit, ControlValueAccessor {
  @ContentChildren(FncRadioComponent) options: QueryList<FncRadioComponent>
  @Input() required: boolean
  @Input() disabled: boolean
  private innerValue: any

  constructor(el: ElementRef) {
    super(el)
  }

  // get accessor
  get value(): any {
    return this.innerValue
  }

  // for the next section see: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  // private onTouchedCallback: () => void = noop;

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v
      this.onChangeCallback(v)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngAfterContentInit(): void {}

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  // private onChangeCallback: (_: any) => void = noop;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: any) => void = () => {}
}
