<ng-content></ng-content>
<div class="fnc-select-container" [class.active]="active">
  <div class="fnc-select-value">
    <!-- Percentage icon -->
    <div percentage *ngIf="percentage">
      <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2.88625" cy="2.57375" r="2.01125" fill="inherit" />
        <circle cx="9.36375" cy="9.05125" r="2.01125" fill="inherit" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0709.861468c.3906.390522.3906 1.023692 0 1.414212L2.59759 10.749c-.39053.3906-1.02369.3906-1.41422 0-.390522-.3905-.390522-1.02366 0-1.41418L9.65672.861468c.39048-.390524 1.02368-.390524 1.41418 0z"
          fill="inherit"
        />
      </svg>
    </div>

    <!-- Value -->
    <div class="fnc-select-value-inner">
      <!-- Placeholder or value -->
      {{ !selectedLabel && hasPlaceholder() ? this.selectElement.placeholder : selectedLabel }}
    </div>

    <!-- Dropdown icon -->
    <div icon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
  <div #list class="list">
    <div class="search-container row" *ngIf="enableSearch" (click)="$event.stopPropagation(); $event.preventDefault()">
      <div class="columns">
        <fnc-input-container modern with-icon>
          <span icon>
            <svg width="14" height="14" fill="inherit">
              <path
                d="m13.64 12.61-3.81-3.8a5.34 5.34 0 0 0 1.1-3.27 5.4 5.4 0 1 0-2.13 4.3l3.81 3.8a.74.74 0 0 0 1.03 0 .73.73 0 0 0 0-1.03ZM1.6 5.54a3.94 3.94 0 1 1 3.94 3.94A3.94 3.94 0 0 1 1.6 5.54Z"
              />
            </svg>
          </span>
          <input type="text" #searchField [ngModel]="searchString" (ngModelChange)="search($event)" [placeholder]="'MAIN.SEARCH' | translate" />
          <!-- <label>{{ 'MAIN.SEARCH' | translate }}</label> -->
        </fnc-input-container>
      </div>
    </div>
    <ul>
      <li
        *ngFor="let option of options; let i = index"
        (click)="$event.stopPropagation(); !locked(option) && optionClicked(i)"
        [class.tx-locked-dropdown]="locked(option)"
        [class.is-title]="option.value === '-1'"
        [class.checked]="option.checked"
      >
        <svg *ngIf="locked(option)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clip-rule="evenodd"
          />
        </svg>

        {{ (option.text | translate) || option.text }}
      </li>
    </ul>
  </div>
</div>
