import { fromEvent as observableFromEvent, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'
import { globals } from '../../shared/config/globals'
import { MasterDetailOrchestrator } from '../../logic/ui-logic/master-detail.orchestrator'
import { FncMainLayoutComponent } from '../fnc-main-layout/fnc-main-layout.component'
import { FncPageHeaderTitleComponent } from './components/fnc-page-header-title/fnc-page-header-title.component'
import { NotificationService } from '../../logic/notifications/notification.service'
import { UserService } from '../../logic/user/user.service'
import { Router } from '@angular/router'
import { routerlinks } from 'environments/routerlinks/routerlinks-nl'

const speed = globals.animations.speed.default

@Component({
  selector: 'fnc-page-header',
  styleUrls: ['./fnc-page-header.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'translateY(-10px)' }), animate(speed, style({ opacity: 1, transform: 'translateX(0)' }))]),
      transition(':leave', animate(speed, style({ opacity: 0, transform: 'translateY(-10px)' }))),
    ]),
  ],
  template: `
    <div class="top-container" [class.hidden]="topIsHidden" #topContainer>
      <div class="top" [class.shadow]="showShadow">
        <fnc-icon class="show-on-mobile" (click)="toggleMenu()" icon="menu" small *ngIf="!isDetailPage"></fnc-icon>
        <fnc-icon class="show-on-mobile arrow-left" (click)="goBack()" icon="arrow" small *ngIf="isDetailPage"></fnc-icon>

        <div class="account">
          <svg
            class="account--referral-program-icon"
            (click)="navigateToReferral()"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-purple
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
            />
          </svg>

          <fnc-account-menu></fnc-account-menu>
          <svg class="account--notification-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" (click)="toggleNotifications()">
            <path
              d="M14.236 21H9.764a.25.25 0 00-.248.222 2.319 2.319 0 00-.016.278 2.5 2.5 0 105 0 2.319 2.319 0 00-.016-.278.248.248 0 00-.248-.222zM21.446 19.276a16.366 16.366 0 01-1.746-7.332v-.768a9.114 9.114 0 00-3.866-7.622 6.379 6.379 0 00-2.334-.918V1.5a1.5 1.5 0 10-3 0v1.149a6.738 6.738 0 00-2.851 1.277 9.2 9.2 0 00-3.349 7.25v.768a16.366 16.366 0 01-1.746 7.332A.5.5 0 003 20h18a.5.5 0 00.446-.725z"
            />
          </svg>
          <div
            class="notify"
            *ngIf="!(notifications.isLoadingNotfications$ | async) && notifications.hasNotificationsToDisplayInDrawer"
            (click)="toggleNotifications()"
          >
            {{ notifications.numberOfNotificationsToDisplayInDrawer }}
          </div>
        </div>
      </div>
    </div>
    <div #content class="page-header-content" [@inOutAnimation]="enableRouteAnimation">
      <ng-content></ng-content>
    </div>
    <div class="scroll-background" *ngIf="fixedHeader" #scrollBackground [style.height]="height + 'px'" aria-hidden="true"></div>
  `,
})
export class FncPageHeaderComponent implements AfterContentInit, AfterViewInit {
  enableRouteAnimation = true
  showShadow: boolean
  @Input() topIsHidden: boolean
  @Input() fixedHeader = true

  @HostBinding('class.without-content') @Input() withoutContent: boolean = false
  @HostBinding('class.without-background') @Input() unsetBackground: boolean = false

  @ViewChild('content', { static: true }) content: ElementRef
  @ViewChild('topContainer', { static: true }) topContainer: ElementRef
  @ViewChild('scrollBackground', { static: true }) scrollBackground: ElementRef
  @ContentChild(FncPageHeaderTitleComponent) title: FncPageHeaderTitleComponent
  @Output() accountMenuMouseOver = new EventEmitter<boolean>()
  @Output() onSizeChanged = new EventEmitter<number>()
  private _bottomMargin = 0
  private _titleSubscription: Subscription
  private _resizeSubscription: Subscription
  private _height: number

  isBeta: boolean = false

  constructor(
    @Inject(forwardRef(() => FncMainLayoutComponent)) private _parent: FncMainLayoutComponent,
    public readonly notifications: NotificationService,
    public el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private readonly _user: UserService,
    private _router: Router,
    orchestrator: MasterDetailOrchestrator,
  ) {
    this._user.isBetaUser().subscribe((betaUser) => (this.isBeta = betaUser))

    setTimeout(() => (this.enableRouteAnimation = !orchestrator.masterViewIsRestored))
  }

  get height() {
    return this._height
  }

  // Set for Card Component
  @HostBinding('style.paddingBottom')
  get paddingBottom(): string {
    if (this._bottomMargin) {
      return `${this._bottomMargin}px`
    }
  }

  // Set for Card Component
  @HostBinding('style.marginBottom')
  get marginBottom(): string {
    return `${this._bottomMargin * -1}px`
  }

  get isDetailPage(): boolean {
    return this._parent.isDetailPage
  }

  private get elementHeight(): number {
    return this.el.nativeElement.getBoundingClientRect().height
  }

  ngAfterContentInit(): void {
    if (this.title) {
      this._titleSubscription = this.title.onTitleChanged.subscribe(() => this.setHeight())
    }

    this._resizeSubscription = observableFromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => this.setHeight())

    setTimeout(() => this.setHeight(), globals.animations.speed.default)
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges()
  }

  ngOnDestroy() {
    if (this._resizeSubscription) {
      this._resizeSubscription.unsubscribe()
    }

    if (this._titleSubscription) {
      this._titleSubscription.unsubscribe()
    }
  }

  toggleNotifications(): void {
    this._parent.toggleNotifications()
  }

  // Set for Card Component
  setBottomMargin(padding: number): void {
    this._bottomMargin = padding - 40
    this.setHeight()
  }

  toggleMenu(): void {
    this._parent.tlwLeftMenu.toggleMobileMenu()
  }

  goBack(): void {
    void window.history.go(-1)
  }

  setHeight(): void {
    // Initial change.
    setTimeout(() => {
      this._height = this.elementHeight
      this.onSizeChanged.emit(this.height)
    })

    // Change after animation
    setTimeout(() => {
      this._height = this.elementHeight
      this.onSizeChanged.emit(this.height)
    }, globals.animations.speed.default)
  }

  navigateToReferral(): void {
    void this._router.navigateByUrl(`${routerlinks.settings.index}/${routerlinks.settings.account}/${routerlinks.settings.referralProgram}`)
  }
}
