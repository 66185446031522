import { Injectable } from '@angular/core'
import { uuid4 } from '@sentry/utils'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { ApiGateway } from '../../core/remote/api.gateway'
import { VatPeriodDisplayItem } from '../../domain/administration/vat-period.model'
import { AdditionalRawDataType, AnnotatedLine, GeneratedLineWithType, GenerationTypes, MutatedKlippaOcrResponse } from '../../domain/document/annotation.model'
import { DocumentRelevance } from '../../domain/document/document-relevance.constants'
import { DocumentStates } from '../../domain/document/document-states.constants'
import { DocumentTypes } from '../../domain/document/document-types.constants'
import { Document } from '../../domain/document/document.model'
import { KlippaData, VatitemsEntity } from '../../domain/document/klippa.model'
import { UploadDocument } from '../../domain/document/upload-document.model'
import { endpoints } from '../../shared/config/endpoints'
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs'
import { tap, catchError, map, mergeMap } from 'rxjs/operators'
import { AdministrationService } from '../administration/administration.service'
import { ToastrHelper } from '../helpers/toastr.helper'
import { DocumentService } from '../documents/document.service'
import { TranslateService } from '@ngx-translate/core'
import { indicate } from '../../core/operators/indicate'
import { SegmentHelper } from './segment.helper'
import { TellowError } from '../../core/remote/error.interface'
import { InvoiceTypes } from '../../domain/invoice/invoice-types.constants'

@Injectable({
  providedIn: 'root',
})
export class KlippaOcrService {
  uploading = new BehaviorSubject<boolean>(false)

  private _rates: VatPeriodDisplayItem[]
  private _defaultRate: number
  private _zeroVat: number
  private _hasVat: boolean

  constructor(
    private readonly _administration: AdministrationService,
    private readonly _translateRoute: TranslateRouteService,
    private readonly _translate: TranslateService,
    private readonly _document: DocumentService,
    private readonly _segment: SegmentHelper,
    private readonly _toastr: ToastrHelper,
    private readonly _api: ApiGateway,
  ) {
    /**
     * Set static vat rate periods for getting the vat context.
     * These values (vat rates) do not change over time,
     * so this is a perfectly safe thing to do.
     */
    this._administration.vatPurchaseRatePeriods.subscribe((rates) => {
      this._rates = rates
    })

    // Set default rates.
    const high = this.findVatRateObjectForPercentage(21 * 100)
    const low = this.findVatRateObjectForPercentage(0)
    forkJoin([high, low]).subscribe(([h, l]) => {
      this._defaultRate = h?.id
      this._zeroVat = l?.id
    })

    // Set whether user is applicable for vat (or exempt).
    this._administration.defaultAdministrationSettings.subscribe((settings) => {
      this._hasVat = settings.has_vat
    })
  }

  defaultVatRate(): number | null {
    return this._hasVat ? this._defaultRate : this._zeroVat
  }

  /**
   * Send document to Klippa OCR for parsing.
   * @see https://custom-ocr.klippa.com/docs#operation/parseDocument
   */
  postDocument(files: File[], options: { [key: string]: string | number } = {}): Observable<Document> {
    const [firstFileInArray] = files
    this.getBlobFileHeader(firstFileInArray, (header) => this.strictMimeTypeCheck(header))

    const data: UploadDocument = {
      json: {
        file_name: firstFileInArray.name,
        relevance: DocumentRelevance.BUSINESS,
        type: DocumentTypes.SOURCE,
        status: DocumentStates.SELF_ANNOTATED,
        ...options,
      },
      file: firstFileInArray,
    }

    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => this._api.postMultipart(endpoints.documents.document, data, { administrationId: administration.id })),
    )
  }

  getDocument(documentId: number): Observable<Document> {
    return this._administration.defaultAdministration.pipe(
      mergeMap((administration) => this._api.get<Document>(endpoints.invoices.invoices, { administrationId: administration.id, invoiceId: documentId })),
    )
  }

  /**
   * Convert body to
   * something pretty.
   */
  transformIntoUniformObject(data: KlippaData): MutatedKlippaOcrResponse {
    return {
      meta: {
        purchasedate: data.purchasedate || new Date().toISOString(),
        number: data.invoice_number || null,
        type: data.invoice_type === 'credit_invoice' ? InvoiceTypes.TYPE_CREDIT : InvoiceTypes.TYPE_REGULAR,
      },
      merchant: {
        id: parseFloat(data.merchant_id) || null,
        name: data.merchant_name,
        street: data.merchant_address,
        city: data.merchant_city,
        zipcode: data.merchant_zipcode,
      },
      raw: {
        countryCode: data.merchant_country_code,
        amountExclVat: data.amountexvat,
        vatContext: data.vat_context,
        amount: data.amount,
      },
      ledger: data.document_category || null,
      // If none are found, return empty array instead.
      vatitems: data.vatitems || [],
      // Taking lines[1] because thats the lineitems.
      // none are found, return empty array instead.
      lines: data.lines[0]?.lineitems || [],
    }
  }

  /**
   * Generate invoicelines.
   * Barbie logic applied.
   *
   * @param {MutatedKlippaOcrResponse} klippa response to parse.
   */
  generateInvoicelines(data: KlippaData): GeneratedLineWithType {
    /**
     * Pour into uniform
     * usable object, first.
     */
    const { vatitems, meta, merchant, ledger, lines, raw } = this.transformIntoUniformObject(data)
    const isSingleLineInvoice = lines.length === 1
    const [firstLine] = lines

    /**
     * These are the same for
     * all returned objects.
     */
    const defaults = {
      meta,
      merchant,
      ledger,
    }

    /**
     * 1.       Receipt has more than 1 'vatitems'.
     *          Additionally; there are 'invoicelines' on the receipt.
     *
     *          If there's only one line, use this description
     *          (it looks weird otherwise if it says 'multiple lines').
     */
    if (vatitems?.length) {
      // Use falsy value as fallback, so default value will be set in 'vatitemsToLines'.
      const description = isSingleLineInvoice ? firstLine.title : null

      return {
        ...defaults,
        type: GenerationTypes.VATITEMS,
        data: this.vatitemsToLines({ vatitems, description }),
      }
    }

    /**
     * 2.       Receipt has no 'vatitems'
     *          (but _does_ have 1 'line').
     */
    if (!vatitems?.length && isSingleLineInvoice) {
      const description = Boolean(firstLine.title) ? firstLine.title : this._translate.instant('DOCUMENTS.ANNOTATION.DESCRIPTIONS.NO_DESCRIPTION')

      return {
        ...defaults,
        type: GenerationTypes.INVOICELINES,
        data: [
          this.generateEmptyLine({
            price: this.normalizeValue(data.amount) ?? 0,
            description,
            raw,
          }),
        ],
      }
    }

    /**
     * 3.       Receipt has no 'vatitems'
     *          (but _does_ have more than 1 'line').
     */
    if (!vatitems?.length && lines.length > 1) {
      const description = this._translate.instant('DOCUMENTS.ANNOTATION.DESCRIPTIONS.NO_VAT')

      return {
        ...defaults,
        type: GenerationTypes.INVOICELINES,
        data: [
          this.generateEmptyLine({
            price: this.normalizeValue(data.amount) ?? 0,
            description,
            raw,
          }),
        ],
      }
    }

    /**
     * Default. Receipt has no 'vatitems'
     *          _and_ no 'lines'.
     */
    return {
      ...defaults,
      type: GenerationTypes.BLANK,
      data: [
        this.generateEmptyLine({
          price: this.normalizeValue(data.amount) ?? 0,
          description: this._translate.instant('DOCUMENTS.ANNOTATION.DESCRIPTIONS.NONE'),
          vatRateFallback: this._defaultRate,
          raw,
        }),
      ],
    }
  }

  /**
   * TODO: Re-evaluate.
   * Currently unused as we switched up Klippa flow.
   * Leaving in code to eventually be able to
   * switch back when required to do so.
   *
   * Generate invoicelines
   * where there is no vatitems.
   */
  // generateWithoutVatitems(lines: LineitemsEntity[]): AnnotatedLine[] {
  //   return lines.reduce<AnnotatedLine[]>((accumulator, current) => {
  //     accumulator.push({
  //       id: uuid4(),
  //       description: current.description.trim() || current.title.trim() || '',
  //       price: this.normalizeValue(current.amount),
  //       vat_rate_id: this._hasVat ? null : this._zeroVat,
  //       including_vat: true,
  //       account_id: null,
  //     })

  //     return accumulator
  //   }, [])
  // }

  /**
   * Helper to turn vatitems
   * into (more useful) lines.
   */
  vatitemsToLines({ vatitems, description }: { vatitems: VatitemsEntity[]; description?: string }): AnnotatedLine[] {
    return vatitems.reduce<AnnotatedLine[]>((accumulator, current) => {
      this.findVatRateObjectForPercentage(current.percentage).subscribe((rate) => {
        accumulator.push({
          id: uuid4(),
          description: description ?? this._translate.instant('DOCUMENTS.ANNOTATION.DESCRIPTIONS.MULTIPLE', { percentage: this.toDecimal(current.percentage) }),
          price: this.normalizeValue(this._hasVat ? current.amount_excl_vat : current.amount_incl_vat),
          vat_rate_id: this._hasVat ? rate?.id : this._zeroVat,
          including_vat: this._hasVat ? false : true,
          account_id: null,
        })
      })

      return accumulator
    }, [])
  }

  /**
   * TODO: Re-evaluate.
   * Currently unused as we switched up Klippa flow.
   * Leaving in code to eventually be able to
   * switch back when required to do so.
   *
   * Generate invoicelines
   * without any given lines.
   */
  // generateWithoutLines(vatitems: VatitemsEntity[]): AnnotatedLine[] {
  //   return vatitems.reduce<AnnotatedLine[]>((accumulator, current) => {
  //     accumulator.push({
  //       id: uuid4(),
  //       description: '',
  //       price: this.normalizeValue(this._hasVat ? current.amount_excl_vat : current.amount_incl_vat),
  //       vat_rate_id: this.defaultVatRate(),
  //       including_vat: true,
  //       account_id: null,
  //     })

  //     return accumulator
  //   }, [])
  // }

  /**
   * Find according VAT rate.
   * @param {number} target percentage (not divided by 100)
   */
  findVatRateObjectForPercentage(target: number): Observable<VatPeriodDisplayItem> {
    return this._administration.vatPurchaseRatePeriods.pipe(
      map((rates) => {
        return rates.find(({ percentage }) => percentage === target / 100)
      }),
    )
  }

  /**
   * Generate an empty line.
   * Used both in service as in annotation.
   */
  generateEmptyLine({
    price = 0,
    description = '',
    vatRateFallback = this._zeroVat,
    raw,
  }: {
    price?: number
    vatRateFallback?: number
    description?: string
    raw?: AdditionalRawDataType
  }): AnnotatedLine {
    const vatContext = this._getVatContext(raw)

    return {
      id: uuid4(),
      description,
      price,
      vat_rate_id: vatContext ? this._getVatContext(raw)?.id : vatRateFallback,
      including_vat: true,
      account_id: null,
    }
  }

  /**
   * Navigate user to document annotation.
   */
  navigateToAnnotation(id: number, args: { [key: string]: string | number } = {}): void {
    void this._translateRoute.navigate(`/uitgaven/annotatie/${id}`, { ...args })
  }

  addFile(input: Event | File[], options: { [key: string]: string | number | null } = {}): Observable<number | TellowError> {
    const files: File[] = input instanceof Array ? input : Array.toArray((input.target as HTMLInputElement).files)

    if (!files || !this._document.filterAllowedTypes(files).length) {
      this._toastr.error('Bestandsformaat niet toegestaan', 'Let op')

      return
    }

    return this.postDocument(files, options).pipe(
      indicate(this.uploading),
      map((response: Document) => {
        this._segment.track('Receipt - Start', { location: 'menu', pipeline: 'self_annotated' })

        return response.id
      }),
      catchError((err: TellowError) => throwError(err)),
    )
  }

  /**
   * Add a file to upload.
   * Takes both an InputEvent, and a File instance.
   * They are filtered out to behave the same.
   * @param {Event | File} input
   */
  addFileToAnnotate(input: Event | File[], options: { [key: string]: string | number | null } = {}): Observable<any> {
    const loading: string = this._translate.instant('DOCUMENTS.ANNOTATION.BUSY')
    const success: string = this._translate.instant('DOCUMENTS.ANNOTATION.PROCESSED')

    const alreadyExists: string = this._translate.instant('DOCUMENTS.ANNOTATION.WARNINGS.ALREADY_UPLOADED')
    const unexpectedError: string = this._translate.instant('DOCUMENTS.ANNOTATION.WARNINGS.UPLOAD_ISSUE')

    return this.addFile(input, options).pipe(
      this._toastr.observe({
        loading,
        success: () => success,
        error: (err: TellowError) => {
          switch (err.error_code) {
            case 8503:
              return alreadyExists
            default:
              return unexpectedError
          }
        },
      }),
      catchError((err: TellowError) => throwError(err)),
      tap((response: any) => {
        if (!response || typeof response !== 'number') {
          throw new Error('No id has been passed to navigate to.')
        }
      }),
    )
  }

  /**
   * Return the first few bytes of the file as a hex string.
   *
   * @param {Blob} blob to get header from.
   * @param callback that is returned.
   */
  private getBlobFileHeader(blob: Blob, callback: (e: string) => string): void {
    const fileReader = new FileReader()
    fileReader.onloadend = (e: any) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4)
      let header = ''
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }
      callback(header)
    }

    fileReader.readAsArrayBuffer(blob)
  }

  /**
   * Get _actual_ mime-type.
   * (no extension renaming here boiiiii).
   *
   * Add more? Take a look at link below.
   * @see http://en.wikipedia.org/wiki/List_of_file_signatures
   */
  private strictMimeTypeCheck(header: string): string {
    switch (header) {
      case '00018':
      case '00020':
      case '00024':
        console.log(`📄 MIME: image/heic`)

        return 'image/heic'
      case '255044462d':
        console.log(`📄 MIME: image/pdf`)

        return 'image/pdf'
      case '89504e47':
        console.log(`📄 MIME: image/png`)

        return 'image/png'
      case '47494638':
        console.log(`📄 MIME: image/gif`)

        return 'image/gif'
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        console.log(`📄 MIME: image/jpg`)

        return 'image/jpeg'
      default:
        return 'unknown'
    }
  }

  /**
   * Get additional 'vat context' based
   * on klippa's vat_context output.
   */
  private _getVatContext(raw: AdditionalRawDataType): Partial<VatPeriodDisplayItem> {
    return this._rates.find(({ relayed, domestic, tag, in_eu }: VatPeriodDisplayItem) => {
      switch (raw?.vatContext) {
        /**
         * There are three cases for relayed:
         *
         *   1. Domestic (in NL) relayed.
         *   2. Relayed outside of EU.
         *   3. Relayed inside of EU.
         */
        case 'relayed':
        case 'vat_relayed':
          const isDutch = new RegExp('NL', 'ig').test(raw?.countryCode)
          const isEuropean = this._ISOEUCountryCodes.any((country) => {
            return new RegExp(country, 'ig').test(raw?.countryCode)
          })

          // Case 1.
          if (isDutch) {
            return relayed && domestic
          }

          // Case 2 & 3.
          return relayed && !domestic && in_eu === isEuropean
        case 'purchase_none':
          return tag === 'none'
      }
    })
  }

  /**
   * Turn negative amounts into positive amounts,
   * as our backend does not accept negativity.
   *
   * ...what can I say; stay postive. :D
   */
  private assurePositiveValue(value: number): number {
    return value < 0 ? value * -1 : value
  }

  /**
   * Convert amount to decimal.
   * Long story short: divide by 100.
   */
  private toDecimal(input: number): number {
    return input / 100
  }

  /**
   * Proxy to utilize above helpers.
   */
  private normalizeValue(value: number): number {
    return this.assurePositiveValue(this.toDecimal(value))
  }

  /**
   * Countries don't change often, so this is fine.
   * @see https://gist.github.com/henrik/1688572
   */
  private _ISOEUCountryCodes: string[] = [
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IE', // Ireland, Republic of (EIRE)
    'IT', // Italy
    'LV', // Latvia
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'GB', // United Kingdom (Great Britain)
  ]
}
